/*============================
 [Table of CSS]

1. General
2. Bootstrap Classes
3. Avatar
4. Nav Tabs
5. Table
6. Modal
7. Header
8. Mobile Menu
9. Home
10. Service Details
11. Listing List
12. Listings Grid
13. Listingmap Grid
14. Categories
15. Blog Grid
16. Blog List
17. Blog Details
18. About Page
19. Gallery
20. Contact us
21. How it works
22. FAQ
23. Pricing Plan
24. Login
25. Dashboard
26. My Listings 
27. Profile 
28. Messages
29. Home Two
30. Select2
31. Home 3
32. Home 4
33. Home 5
34. Home 6
35. Home 7
36. Home 8
37. Home 9
38. Footer
39. Responsive

========================================*/

/*-----------------
    1. General
-----------------------*/

@import url("https://fonts.googleapis.com/css2?family=Encode+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sassy+Frass&display=swap");

body {
  background-color: #ffffff;
  color: #666;
  font-family: "Encode Sans", sans-serif;
  font-size: 16px;
  height: 100%;
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #0d233e;
  font-family: "Encode Sans", sans-serif;
  font-weight: 600;
}
h1 {
  font-size: 36px;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 28px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 16px;
}
a {
  color: #0d233e;
  text-decoration: none;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}
button:focus {
  outline: 0;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/*-----------------
    2. Bootstrap Classes
-----------------------*/

.btn.focus,
.btn:focus {
  box-shadow: none;
}
.btn.btn-white {
  background: #fff;
  border: none;
  color: #c10037;
}
.withdraw-btn {
  padding: 12px 15px;
  font-weight: bold;
}
.btn-primary {
  background-color: #c10037;
  border: 1px solid #c10037;
  box-shadow: inset 0 0 0 #fff;
  border-radius: 5px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  padding: 8px 25px;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
  background-color: #fff;
  border: 1px solid #c10037;
  box-shadow: inset 0 70px 0 0 #ffffff;
  color: #c10037;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
  background-color: #fff;
  border: 1px solid #c10037;
  box-shadow: inset 0 70px 0 0 #c10037;
  color: #c10037;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle {
  background-color: #fff;
  border: 1px solid #c10037;
  box-shadow: inset 70px 0 0 0 #ffffff;
  color: #c10037;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-primary.active:focus:not(:disabled):not(.disabled),
.btn-primary:active:focus:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: unset;
}
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #fff;
  border: 1px solid #c10037;
  box-shadow: inset 70px 0 0 0 #ffffff;
  color: #c10037;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-outline-primary {
  background-color: #fff;
  border: 1px solid #c10037;
  color: #c10037;
  box-shadow: inset 0 0 0 #fff;
  border-radius: 5px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary.active,
.btn-outline-primary:active,
.open > .dropdown-toggle.btn-primary {
  background-color: #c10037;
  border: 1px solid #c10037;
  box-shadow: inset 0 70px 0 0 #c10037;
  color: #fff;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-outline-primary.active.focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.active:hover,
.btn-outline-primary.focus:active,
.btn-outline-primary:active:focus,
.btn-outline-primary:active:hover,
.open > .dropdown-toggle.btn-outline-primary.focus,
.open > .dropdown-toggle.btn-outline-primary:focus,
.open > .dropdown-toggle.btn-outline-primary:hover {
  background-color: #c10037;
  border: 1px solid #c10037;
  box-shadow: inset 0 70px 0 0 #c10037;
  color: #fff;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-secondary {
  background: #f7f7ff;
  border: 1px solid #f7f7ff;
  box-shadow: inset 0 0 0 0 #ffffff;
  color: #68616d;
  border-radius: 10px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.active,
.btn-secondary:active,
.open > .dropdown-toggle.btn-secondary {
  background-color: #fff;
  border: 1px solid #c10037;
  box-shadow: inset 0 70px 0 0 #fff;
  color: #c10037;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-secondary.active.focus,
.btn-secondary.active:focus,
.btn-secondary.active:hover,
.btn-secondary.focus:active,
.btn-secondary:active:focus,
.btn-secondary:active:hover,
.open > .dropdown-toggle.btn-secondary.focus,
.open > .dropdown-toggle.btn-secondary:focus,
.open > .dropdown-toggle.btn-secondary:hover {
  background-color: #fff;
  border: 1px solid #c10037;
  box-shadow: inset 70px 0 0 0 #ffffff;
  color: #c10037;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-secondary.active:not(:disabled):not(.disabled),
.btn-secondary:active:not(:disabled):not(.disabled),
.show > .btn-secondary.dropdown-toggle {
  background-color: #c10037;
  border-color: #c10037;
  color: #fff;
}
.btn-secondary.active:focus:not(:disabled):not(.disabled),
.btn-secondary:active:focus:not(:disabled):not(.disabled),
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: unset;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #c10037;
  border-color: #c10037;
  color: #fff;
}
.btn-danger {
  background: #f2535b;
  border: 1px solid #f2535b;
  box-shadow: inset 0 0 0 0 #ffffff;
  color: #fff;
  border-radius: 10px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open > .dropdown-toggle.btn-danger {
  background-color: #fff;
  border: 1px solid #f2535b;
  box-shadow: inset 0 70px 0 0 #fff;
  color: #f2535b;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
  background-color: #fff;
  border: 1px solid #f2535b;
  box-shadow: inset 70px 0 0 0 #ffffff;
  color: #f2535b;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-secondary.active:not(:disabled):not(.disabled),
.btn-secondary:active:not(:disabled):not(.disabled),
.show > .btn-secondary.dropdown-toggle {
  background-color: #f2535b;
  border-color: #f2535b;
  color: #fff;
}
.btn-secondary.active:focus:not(:disabled):not(.disabled),
.btn-secondary:active:focus:not(:disabled):not(.disabled),
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: unset;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #c10037;
  border-color: #c10037;
  color: #fff;
}
.btn-danger-light {
  background: #fff3f3;
  border: 1px solid #fff3f3;
  color: #ff4949;
  border-radius: 6px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-danger-light:hover {
  background-color: #fff;
  border: 1px solid #ff4949;
  box-shadow: inset 0 70px 0 0 #fff;
  color: #ff4949;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-light-success {
  background: #e6f8eb;
  border: 1px solid #e6f8eb;
  color: #34c85d;
  border-radius: 6px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-light-success:hover {
  background-color: #fff;
  border: 1px solid #34c85d;
  box-shadow: inset 0 70px 0 0 #fff;
  color: #34c85d;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-light-warning {
  background: #fff9e8;
  border: 1px solid #fff9e8;
  color: #ffc107;
  border-radius: 6px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-light-warning:hover {
  background-color: #fff;
  border: 1px solid #ffc107;
  box-shadow: inset 0 70px 0 0 #fff;
  color: #ffc107;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-secondary-outline {
  background: #fff;
  border: 2px solid #dcdcdc;
  color: #454545;
  border-radius: 6px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-secondary-outline:hover {
  background-color: #dcdcdc;
  border: 2px solid #dcdcdc;
  box-shadow: inset 0 70px 0 0 #dcdcdc;
  color: #454545;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.bg-info-light {
  background-color: rgba(2, 182, 179, 0.12) !important;
  color: #1db9aa !important;
}
.bg-primary-light {
  background-color: rgba(17, 148, 247, 0.12) !important;
  color: #2196f3 !important;
}
.bg-danger-light {
  background-color: rgba(242, 17, 54, 0.12) !important;
  color: #e63c3c !important;
}
.bg-warning-light {
  background-color: rgba(255, 152, 0, 0.12) !important;
  color: #f39c12 !important;
}
.bg-success {
  background-color: #28a745 !important;
}
.bg-success-light {
  background-color: rgba(15, 183, 107, 0.12) !important;
  color: #26af48 !important;
}
.bg-purple-light {
  background-color: rgba(197, 128, 255, 0.12) !important;
  color: #c580ff !important;
}
.bg-default-light {
  background-color: rgba(40, 52, 71, 0.12) !important;
  color: #283447 !important;
}
.text-light-success {
  color: #28a745;
}
.text-light-danger {
  color: #dc3545;
}
.form-group {
  margin-bottom: 1rem;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.form-control {
  border-color: #dcdcdc;
  color: #333;
  font-size: 15px;
  min-height: 46px;
  padding: 6px 15px;
}
.form-control:focus {
  border-color: #bbb;
  box-shadow: none;
  outline: 0 none;
}
.breadcrumb-title h2 {
  font-weight: 500;
  font-size: 25px;
  margin: 0;
}
.breadcrumb-title span {
  font-size: 13px;
  color: #8d8d8d;
  margin-top: 9px;
}
.breadcrumbs {
  float: right;
  padding: 0;
  margin: 0;
  list-style: none;
}
.breadcrumbs li {
  float: left;
  margin: 0;
  position: relative;
  padding: 0 11px;
}
.breadcrumbs li a {
  float: left;
  font-size: 13px;
  color: #8d8d8d;
}
.breadcrumbs li::before {
  position: absolute;
  right: -3px;
  top: 50%;
  content: ">";
  font-size: 10px;
  color: #8d8d8d;
  line-height: 10px;
  margin-top: -3px;
}
.breadcrumbs li:last-child {
  padding-right: 0;
}
.breadcrumbs li:last-child::before {
  display: none;
}
.pagination {
  width: 100%;
  text-align: center;
  margin-top: 40px;
}
.pagination > ul {
  width: 100%;
  text-align: center;
  margin: 0;
}
.pagination > ul li {
  display: inline-block;
  margin: 0 1px;
}
.pagination > ul li a {
  width: 35px;
  height: 35px;
  background: #fff;
  border: 1px solid #e8ecec;
  line-height: 34px;
  font-size: 13px;
  color: #8d8d8d;
}
.pagination > ul li span {
  width: 35px;
  height: 35px;
  background: #fff;
  border: 1px solid #e8ecec;
  line-height: 34px;
  font-size: 13px;
  color: #8d8d8d;
}
.pagination > ul li.arrow a {
  background: #f1f1f1;
  font-size: 17px;
  margin: 0 9px;
}
.pagination > ul li.active a {
  background: #c10037;
  border-color: #c10037;
  color: #fff;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #c10037;
  border: 1px solid #c10037;
}
.nav-pills .nav-link.active:hover,
.nav-pills .show > .nav-link:hover {
  background: #c10037;
  color: #fff;
}
.nav-pills .nav-link {
  border-radius: 0.25rem;
  color: #c10037;
  background: #fff;
  border: 1px solid #c10037;
}
.card {
  background: #ffffff;
  border: 2px solid #ebedf0;
  border-radius: 10px;
  margin-bottom: 24px;
}
.text-danger {
  color: #ff0000 !important;
}

/*-----------------
	3. Avatar
-----------------------*/

.avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
}
.avatar > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.avatar-title {
  width: 100%;
  height: 100%;
  background-color: #20c0f3;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.avatar-away::before,
.avatar-offline::before,
.avatar-online::before {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: "";
  border: 2px solid #fff;
}
.avatar-online::before {
  background-color: #0c9e6f;
}
.avatar-offline::before {
  background-color: #ff0100;
}
.avatar-away::before {
  background-color: #ffbc34;
}
.avatar .border {
  border-width: 3px !important;
}
.avatar .rounded {
  border-radius: 6px !important;
}
.avatar .avatar-title {
  font-size: 18px;
}
.avatar-xs {
  width: 29px;
  height: 29px;
}
.avatar-xs .border {
  border-width: 2px !important;
}
.avatar-xs .rounded {
  border-radius: 4px !important;
}
.avatar-xs .avatar-title {
  font-size: 12px;
}
.avatar-xs.avatar-away::before,
.avatar-xs.avatar-offline::before,
.avatar-xs.avatar-online::before {
  border-width: 1px;
}
.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
}
.avatar-sm .border {
  border-width: 3px !important;
}
.avatar-sm .rounded {
  border-radius: 4px !important;
}
.avatar-sm .avatar-title {
  font-size: 15px;
}
.avatar-sm.avatar-away::before,
.avatar-sm.avatar-offline::before,
.avatar-sm.avatar-online::before {
  border-width: 2px;
}
.avatar-lg {
  width: 3.75rem;
  height: 3.75rem;
}
.avatar-lg .border {
  border-width: 3px !important;
}
.avatar-lg .rounded {
  border-radius: 8px !important;
}
.avatar-lg .avatar-title {
  font-size: 24px;
}
.avatar-lg.avatar-away::before,
.avatar-lg.avatar-offline::before,
.avatar-lg.avatar-online::before {
  border-width: 3px;
}

/*-----------------
	4. Nav Tabs
-----------------------*/

.nav-tabs {
  border-bottom: 1px solid #f0f0f0;
}
.nav-tabs > li > a {
  margin-right: 0;
  color: #888;
  border-radius: 0;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  border-color: transparent;
  color: #272b41;
}
.nav-tabs.nav-tabs-solid > li > a {
  color: #272b41;
}
.nav-tabs.nav-tabs-solid > .active > a,
.nav-tabs.nav-tabs-solid > .active > a:hover,
.nav-tabs.nav-tabs-solid > .active > a:focus {
  background-color: #c10037;
  border-color: #c10037;
  color: #fff;
}
.tab-content {
  padding-top: 20px;
}
.nav-tabs .nav-link {
  border-radius: 0;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  background-color: #eee;
  border-color: transparent;
  color: #272b41;
}
.nav-link:focus,
.nav-link:hover {
  color: #858585;
}
.nav-tabs.nav-justified > li > a {
  border-radius: 0;
  margin-bottom: 0;
}
.nav-tabs.nav-justified > li > a:hover,
.nav-tabs.nav-justified > li > a:focus {
  border-bottom-color: #ddd;
}
.nav-tabs.nav-justified.nav-tabs-solid > li > a {
  border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a {
  color: #272b41;
}
.nav-tabs.nav-tabs-solid > li > a.active,
.nav-tabs.nav-tabs-solid > li > a.active:hover,
.nav-tabs.nav-tabs-solid > li > a.active:focus {
  background-color: #c10037;
  border-color: #c10037;
  color: #fff;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
  border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
  border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:focus {
  border-radius: 50px;
}
.nav-tabs-justified > li > a {
  border-radius: 0;
  margin-bottom: 0;
}
.nav-tabs-justified > li > a:hover,
.nav-tabs-justified > li > a:focus {
  border-bottom-color: #ddd;
}
.nav-tabs-justified.nav-tabs-solid > li > a {
  border-color: transparent;
}
.nav-tabs.nav-justified.nav-tabs-top {
  border-bottom: 1px solid #ddd;
}
.nav-tabs.nav-justified.nav-tabs-top > li > a,
.nav-tabs.nav-justified.nav-tabs-top > li > a:hover,
.nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li {
  margin-bottom: 0;
}
.nav-tabs.nav-tabs-top > li > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li.open > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
  border-top-color: #ddd;
}
.nav-tabs.nav-tabs-top > li + li > a {
  margin-left: 1px;
}
.nav-tabs.nav-tabs-top > li > a.active,
.nav-tabs.nav-tabs-top > li > a.active:hover,
.nav-tabs.nav-tabs-top > li > a.active:focus {
  border-top-color: #c10037;
}
.nav-tabs.nav-tabs-bottom > li > a.active,
.nav-tabs.nav-tabs-bottom > li > a.active:hover,
.nav-tabs.nav-tabs-bottom > li > a.active:focus {
  border-bottom-width: 2px;
  border-color: transparent;
  border-bottom-color: #c10037;
  background-color: transparent;
  transition: none 0s ease 0s;
  -moz-transition: none 0s ease 0s;
  -o-transition: none 0s ease 0s;
  -ms-transition: none 0s ease 0s;
  -webkit-transition: none 0s ease 0s;
}
.nav-tabs.nav-tabs-solid {
  background-color: #fafafa;
  border: 0;
}
.nav-tabs.nav-tabs-solid > li {
  margin-bottom: 0;
}
.nav-tabs.nav-tabs-solid > li > a {
  border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a:hover,
.nav-tabs.nav-tabs-solid > li > a:focus {
  background-color: #f5f5f5;
}
.nav-tabs.nav-tabs-solid > .open:not(.active) > a {
  background-color: #f5f5f5;
  border-color: transparent;
}
.nav-tabs-justified.nav-tabs-top {
  border-bottom: 1px solid #ddd;
}
.nav-tabs-justified.nav-tabs-top > li > a,
.nav-tabs-justified.nav-tabs-top > li > a:hover,
.nav-tabs-justified.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0 0;
}

/*-----------------
    5. Table
-----------------------*/

.table tbody td,
.table th,
.table tr {
  padding: 15px;
  font-size: 14px;
}
.table td,
.table th {
  vertical-align: middle;
  white-space: nowrap;
}
.table > :not(:first-child) {
  border-top: 0;
}
table.table td h2 {
  display: inline-block;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
table.table td h2.table-avatar {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  white-space: nowrap;
}
table.table td h2 a {
  font-weight: 500;
  font-size: 16px;
  color: #374b5c;
}
table.table td h2 a:hover {
  color: #c10037;
}
table.table td h2 span {
  color: #888;
  display: block;
  font-size: 12px;
  margin-top: 5px;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 1em;
  content: "\f0de";
  font-family: "Font Awesome 5 Free";
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 1em;
  content: "\f0dd";
  font-family: "Font Awesome 5 Free";
}
.dataTables_paginate .pagination .previous,
.dataTables_paginate .pagination .next {
  display: none;
}
#tablelength label {
  font-weight: 500;
  font-size: 14px;
  color: #68616d;
}
#tablelength label select {
  width: auto;
  display: inline-block;
  border: 0.5px solid #68616d;
  border-radius: 5px;
  padding: 4px 10px;
  min-height: auto;
  color: #68616d;
  margin: 0 8px;
}
#tablepagination ul {
  margin: 0;
}
.table-ingopage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: end;
  -webkit-justify-content: end;
  -ms-flex-pack: end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.table-ingopage .dataTables_info {
  font-weight: 500;
  font-size: 14px;
  color: #68616d;
  margin-right: 10px;
}

/*-----------------
    6. Modal
-----------------------*/

.custom-modal .modal-header {
  padding: 20px;
}
.custom-modal .modal-body {
  padding: 20px;
}
.custom-modal .modal-content {
  border-radius: 10px;
  border: 0;
}
.custom-modal .close-btn {
  background: #f1f1f1;
  border: 0;
  border-radius: 50%;
  color: #4f4f4f;
  font-size: 15px;
  width: 29px;
  height: 29px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  margin: 0;
  opacity: 1;
  padding: 0;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 99;
}
.custom-modal .close-btn:hover {
  background: #c10037;
  color: #fff;
}

/*-----------------
    7. Header
-----------------------*/

.header {
  background: #fff;
  position: fixed;
  box-shadow: 0px 10px 40px rgba(202, 202, 202, 0.2);
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}
.header.fixed {
  background-color: #fff;
  box-shadow: 0px 3px 53px rgb(197 197 197 / 27%);
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}
.logo a {
  float: left;
}
.logo a img {
  float: left;
  max-height: 32px;
}
.header-nav {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  position: relative;
  height: 85px;
  padding: 0;
  margin-bottom: 0;
}
.main-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.main-nav > li > a {
  color: #374b5c;
}
.main-nav > li.active > a,
.header-two .main-nav > li.active > a {
  color: #c10037;
}
.main-nav > li .submenu li a {
  display: block;
  padding: 10px 15px 10px;
  clear: both;
  white-space: nowrap;
  font-size: 14px;
  color: #374b5c;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  width: 100%;
  position: relative;
}
.main-nav > li .submenu li {
  position: relative;
}
.main-nav li a {
  display: block;
  font-weight: 600;
  line-height: 20px;
}
.main-nav li a:hover,
.header-two .main-nav li a:hover {
  color: #c10037;
}
.main-nav li.login-link {
  display: none;
}
.header-navbar-rht {
  margin: 0;
  padding: 0;
}
.header-navbar-rht > li {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding-right: 15px;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.header-navbar-rht > li:last-child {
  padding-right: 0px;
}
.header-navbar-rht > li .dropdown-menu {
  border: 0;
  box-shadow: 0 0 3px rgb(0 0 0 / 10%);
}
.header-navbar-rht > li .fa-bell {
  font-size: 24px;
}
.header-navbar-rht > li .fa-comments {
  font-size: 24px;
}
.header-navbar-rht .dropdown-toggle::after {
  display: none;
}
.header-navbar-rht > li .dropdown-menu .dropdown-item {
  border-top: 1px solid #f0f0f0;
  padding: 10px 15px;
  font-size: 0.9375rem;
  font-weight: 600;
}
.header-navbar-rht > li .dropdown-menu .dropdown-item:first-child {
  border-top: 0;
  border-radius: 5px 5px 0 0;
}
.header-navbar-rht > li .dropdown-menu .dropdown-item:last-child {
  border-radius: 0 0 5px 5px;
}
.header-navbar-rht > li a.header-login:hover,
.header-navbar-rht > li a.header-login:focus {
  background-color: #fff;
  border: 1px solid #c10037;
  box-shadow: inset 210px 0 0 0 #ffffff;
  color: #c10037;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.header-navbar-rht li > .dropdown-menu a:hover {
  color: #c10037;
  background-color: #fff;
}
.header-navbar-rht li > a.header-login {
  background-color: #c10037;
  border: 1px solid #c10037;
  color: #fff;
  box-shadow: inset 0 0 0 #fff;
  border-radius: 4px;
  padding: 11px 19px;
  font-weight: 600;
  text-align: center;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  line-height: normal;
}
.header-navbar-rht li > a.header-login.add-listing {
  background-color: #374b5c;
  border: 1px solid #374b5c;
  display: flex;
  align-items: center;
}
.header-navbar-rht li > a.header-login.add-listing i {
  font-size: 10px;
  margin-right: 5px;
}
.header-navbar-rht li > a.header-login:hover {
  background-color: #fff;
  border: 1px solid #c10037;
  box-shadow: inset 0 70px 0 0 #ffffff;
  color: #c10037;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.header-navbar-rht li > a.header-login.add-listing:hover {
  border: 1px solid #374b5c;
  color: #374b5c;
}
.header-navbar-rht li > a.header-reg {
  font-weight: 600;
  color: #374b5c;
}
.header-navbar-rht li > a.header-reg:hover {
  color: #c10037;
}
.gradient-btn {
  background: linear-gradient(180deg, #ead401 4.59%, #fe017e 95.41%);
  background: -webkit-linear-gradient(180deg, #ead401 4.59%, #fe017e 95.41%);
}
.gradient-btn:hover {
  background: linear-gradient(0deg, #ead401 4.59%, #fe017e 95.41%);
  background: -webkit-linear-gradient(0deg, #ead401 4.59%, #fe017e 95.41%);
}
.header-navbar-rht li > a.header-login img {
  margin-right: 7px;
}
.header .has-arrow .dropdown-toggle:after {
  border-top: 0;
  border-left: 0;
  border-bottom: 2px solid #7e84a3;
  border-right: 2px solid #7e84a3;
  content: "";
  height: 8px;
  display: inline-block;
  pointer-events: none;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 8px;
  vertical-align: 2px;
  margin-right: 10px;
}
.header .has-arrow .dropdown-toggle:hover::after {
  border-bottom: 2px solid #c10037;
  border-right: 2px solid #c10037;
}
.header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
  border-bottom: 2px solid #c10037;
  border-right: 2px solid #c10037;
}
.user-menu {
  float: right;
  margin: 0;
  position: relative;
  z-index: 99;
}
.header a.nav-link {
  color: #374b5c;
  font-weight: 600;
  text-decoration: none;
}
.user-menu.nav > li > a {
  color: #fff;
  font-size: 14px;
  line-height: 58px;
  padding: 0 15px;
  height: 60px;
}
.user-menu.nav > li > a:hover,
.user-menu.nav > li > a:focus {
  background-color: rgba(0, 0, 0, 0.2);
}
.user-menu.nav > li > a:hover i,
.user-menu.nav > li > a:focus i {
  color: #fff;
}
.user-img {
  display: inline-block;
  position: relative;
}
.user-img > img {
  height: 40px;
  object-fit: cover;
  width: 40px;
  border: 3px solid #ffffff;
  outline: 3px solid #d9d9d9;
}
.user-menu.nav > li > a.mobile_btn {
  border: 0;
  position: relative;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.header-navbar-rht .dropdown-menu {
  min-width: 200px;
  padding: 0;
}
.header-navbar-rht .dropdown-menu .dropdown-item {
  padding: 7px 15px;
}
.header-navbar-rht .dropdown-menu .dropdown-item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #e3e3e3;
  padding: 10px 15px;
}
.header-navbar-rht .dropdown-menu .dropdown-item:hover {
  color: #c10037;
}
.user-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 15px;
}
.user-header .user-text {
  margin-left: 10px;
}
.user-header .user-text h6 {
  font-size: 15px;
  margin-bottom: 2px;
}
.header-navbar-rht .logged-item .nav-link {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 14px;
  height: 85px;
  padding: 0 10px;
  color: #fff;
  position: relative;
}
.header-navbar-rht .logged-item .nav-link .badge {
  position: absolute;
  top: 15px;
  right: 0;
}
.header-navbar-rht.log-rht li {
  margin-right: 43px;
}
.header-navbar-rht.log-rht li a {
  color: #374b5c;
  padding: 0;
}
.header-navbar-rht.log-rht li a:hover {
  color: #c10037;
}
.header-navbar-rht.log-rht li:last-child {
  margin-right: 0;
}
.header .dropdown-toggle:after {
  border-top: 0;
  border-left: 0;
  border-bottom: 2px solid #757575;
  border-right: 2px solid #757575;
  content: "";
  height: 8px;
  display: inline-block;
  pointer-events: none;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 8px;
  vertical-align: 2px;
  margin-left: 10px;
}
.flag-nav > a {
  border: 1px solid #e4e4e8;
  border-radius: 8px;
  padding: 13px;
}
.flag-nav .dropdown-item img {
  margin-right: 10px;
  border-radius: 50%;
}
.header-navbar-rht .logged-item .nav-link {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  justify-content: center;
  position: relative;
  width: 41px;
  height: 41px;
  font-size: 16px;
  background: #f6f6fe;
  border-radius: 50%;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
}
.header-navbar-rht .logged-item .nav-link:hover {
  background: #c10037;
  color: #fff;
}
.header-navbar-rht .logged-item .nav-link:hover > img {
  filter: brightness(0) invert(1);
}
.header-navbar-rht .logged-item .nav-link::after {
  display: none;
}
.noti-dropdown .dropdown-toggle {
  color: #fff;
  font-size: 14px;
  line-height: 23px;
  padding: 0 15px;
}
.noti-dropdown .dropdown-toggle::after {
  display: none;
}
.noti-dropdown .dropdown-toggle span {
  background-color: #f43f3f;
  display: block;
  font-size: 10px;
  font-weight: bold;
  min-height: 15px;
  min-width: 15px;
  position: absolute;
  right: 3px;
  top: 5px;
}
.noti-dropdown .dropdown-toggle i {
  float: right;
  line-height: 50px;
  margin-left: 15px;
  font-size: 21px;
  float: left;
  line-height: 50px;
  color: #fff;
  margin: 0px;
  margin-left: 0px;
  cursor: pointer;
}
.user-header {
  background-color: #f9f9f9;
  display: flex;
  padding: 10px 15px;
}
.noti-dropdown .dropdown-menu {
  top: 63px !important;
  font-size: 14px;
}
.notification-message {
  margin-bottom: 0;
}
.notifications {
  padding: 0;
}
.notifications .notification-time {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.35;
  color: #7e84a3;
}
.notifications .media {
  margin-top: 0;
  border-bottom: 1px solid #f5f5f5;
}
.notifications .media:last-child {
  border-bottom: none;
}
.notifications .media a {
  display: block;
  padding: 10px 15px;
  border-radius: 2px;
}
.notifications .media a:hover {
  background-color: #fafafa;
}
.notifications .media > .avatar {
  margin-right: 10px;
}
.notifications .media-list .media-left {
  padding-right: 8px;
}
.topnav-dropdown-header {
  border-bottom: 1px solid #eee;
  text-align: center;
}
.topnav-dropdown-header,
.topnav-dropdown-footer {
  height: 40px;
  line-height: 40px;
  padding-left: 15px;
  padding-right: 15px;
}
.topnav-dropdown-footer {
  border-top: 1px solid #eee;
}
.topnav-dropdown-footer a {
  display: block;
  text-align: center;
  color: #2f3143;
  display: flex;
  align-items: center;
  justify-content: center;
}
.topnav-dropdown-footer a:hover {
  color: #c10037;
}
.topnav-dropdown-footer a i {
  font-size: 17px;
  color: #2f3143;
  margin-left: 5px;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
.topnav-dropdown-footer a:hover i {
  color: #c10037;
  margin-left: 8px;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
.user-menu.nav > li > a .badge {
  background-color: #f43f3f;
  display: block;
  font-size: 10px;
  font-weight: bold;
  min-height: 15px;
  min-width: 15px;
  position: absolute;
  right: 3px;
  top: 6px;
}
.user-menu.nav > li > a > i {
  font-size: 1.5rem;
  line-height: 60px;
}
.noti-details {
  color: #333344;
  margin-bottom: 0;
  font-weight: 500;
}
.notifications .noti-content {
  height: 290px;
  width: 350px;
  overflow-y: auto;
  padding-right: 5px;
  position: relative;
}
.notifications .noti-content::-webkit-scrollbar {
  width: 6px;
  background: #eef1fe;
  height: 10px;
}
.notifications .noti-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(231, 231, 231);
  border-radius: 4px;
}
.notifications .noti-content::-webkit-scrollbar-thumb {
  background: #8a8a95;
  border-radius: 4px;
}
.notifications .noti-content::-webkit-scrollbar-thumb:hover {
  background: #1b2559;
}
.notification-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.notifications ul.notification-list > li {
  margin-top: 0;
  border-bottom: 1px solid #f5f5f5;
}
.notifications ul.notification-list > li:last-child {
  border-bottom: none;
}
.notifications ul.notification-list > li a {
  display: block;
  padding: 10px 15px;
  border-radius: 2px;
}
.notifications ul.notification-list > li a:hover {
  background-color: #fafafa;
}
.notifications ul.notification-list > li .list-item {
  border: 0;
  padding: 0;
  position: relative;
}
.topnav-dropdown-header .notification-title {
  display: block;
  float: left;
  font-weight: 500;
}
.topnav-dropdown-header .clear-noti {
  color: #f0142f;
  float: right;
  font-size: 13px;
  text-transform: uppercase;
}
.topnav-dropdown-header .clear-noti:hover {
  color: #c10037;
}
.noti-time {
  margin: 0;
}
.flag-dropdown .nav-link {
  color: #374b5c;
  padding: 0.5rem 0;
}
.account-item .nav-link {
  padding: 0.5rem 0;
}
.user-info {
  margin-left: 10px;
}
.user-info h6 {
  font-weight: 600;
  font-size: 14px;
  color: #374b5c;
  margin-bottom: 4px;
}
.user-info p {
  font-size: 12px;
  color: #c10037;
  margin-bottom: 0;
}
.user-infos {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.header-two {
  position: relative;
  z-index: 1040;
}
.header-two .header-navbar-rht li > a.header-login {
  padding: 11px 19px;
  font-weight: 500;
}
.header-two .main-nav > li > a {
  font-weight: 500;
}
.header-top {
  border-bottom: 1px solid #d9d9d9;
  padding: 10px 0;
}
.header-top ul {
  text-align: right;
}
.header-top ul li {
  display: inline-block;
  font-size: 14px;
  margin-right: 25px;
}
.header-top ul li:last-child {
  margin-right: 0;
}
.header-top .selection-list {
  justify-content: start;
}
.header-top .header-nav {
  height: 68px;
}
.header-top .lang-select .select-icon {
  color: #374b5c;
}
.header-top
  .selection-list
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #374b5c;
}
.header-top
  .selection-list
  .select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #374b5c;
}
.header-top
  .selection-list
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #374b5c;
  font-size: 14px;
}
.main-nav li.megamenu > ul {
  width: 100%;
}
.megamenu-wrapper {
  padding: 25px 25px 0;
}
.single-demo {
  transition: 0.4s;
  margin-bottom: 25px;
}
.single-demo:hover {
  transform: translateY(-20px);
}
.single-demo.active .demo-info a {
  color: #c10037;
}
.single-demo.active .demo-img {
  box-shadow: 0px 4.8px 24.4px -12px rgba(19, 16, 34, 0.1), 0px 4px 13px -2px rgba(19, 16, 34, 0.06);
}
.single-demo:hover .demo-info {
  opacity: 1;
  bottom: -30px;
}
.demo-info {
  /* position: absolute;
  bottom: -30px; */
  width: 100%;
  margin: 0 auto;
  text-align: center;
  z-index: 1;
  /* opacity: 0; */
  transition: 0.4s;
  margin-top: 10px;
}
.header-three {
  background: transparent;
  box-shadow: none;
}
.header-three .navbar-header {
  display: flex;
  align-items: center;
}
.header-three .main-nav li:not(:last-child) {
  margin-right: 30px;
}
.header-three .main-menu-wrapper {
  margin: 0 15px 0 auto;
}
.header-three .header-nav {
  height: 73px;
}
.header .cta-btn {
  background: #c10037;
  border: 1px solid #c10037;
  border-radius: 40px;
  display: flex;
  color: white;
  text-transform: capitalize;
  min-width: 180px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-weight: 700;
  box-shadow: inset 0 0 0 0 #ffffff;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.header .cta-btn:hover {
  background: #fff;
  border: 1px solid #c10037;
  color: #c10037;
  box-shadow: inset 0 70px 0 0 #ffffff;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.header .cta-btn:hover a {
  color: #c10037;
}
.header .cta-btn a {
  padding: 0;
  border: 0;
  color: #fff;
  font-weight: 700;
}
.header .cta-btn a:hover {
  background: transparent;
}
.header-three a.nav-link.cur-link {
  text-transform: uppercase;
}
.header-three a.nav-link.cur-link,
.header-three a.nav-link.lang-link {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-three a.nav-link.cur-link i,
.header-three a.nav-link.lang-link i {
  font-size: 12px;
}
.header-four .cta-btn {
  border-radius: 4px;
  min-width: 176px;
}
.header-four .main-menu-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 0 15px;
}
.header-four .header-navbar-rht li > a.header-login.add-listing {
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 10px 24px;
  min-width: 40px;
}
.header-four .header-navbar-rht li > a.header-login {
  padding: 10px 22px;
  font-weight: 700;
}
.header-four .header-navbar-rht li > a.header-login.add-listing i {
  font-size: 20px;
  margin-right: 0;
}

/*-----------------
	8. Mobile Menu
-----------------------*/

.sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1039;
  opacity: 0.6;
}
.menu-opened .main-menu-wrapper {
  transform: translateX(0);
}
.menu-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  display: none;
}
.menu-close {
  font-size: 18px;
  color: #c10037;
}
.bar-icon {
  display: inline-block;
  width: 31px;
}
.bar-icon span {
  background-color: #c10037;
  display: block;
  float: left;
  height: 3px;
  margin-bottom: 7px;
  width: 31px;
  border-radius: 2px;
}
.bar-icon span:nth-child(2) {
  width: 16px;
}
.bar-icon span:nth-child(3) {
  margin-bottom: 0;
}
#mobile_btn {
  display: none;
  margin-right: 30px;
}
html.menu-opened body {
  overflow: hidden;
}
.main-menu-wrapper {
  margin: 0 15px 0;
}
.navbar-brand.logo-small {
  display: none;
}
.navbar-brand.logo-small img {
  max-height: 40px;
  width: 30px;
}

/*-----------------
	9. Home
-----------------------*/

.banner-section {
  background: #f3f3f3;
  position: relative;
  padding: 85px 0 0;
}
.banner-section p {
  color: #374b5c;
  margin-bottom: 30px;
}
.banner-section p.explore-text span {
  color: #fff;
  background: #374b5c;
  padding: 12px 35px;
  border-radius: 50px;
  font-size: 18px;
  display: inline-block;
}
.section-search {
  position: relative;
  padding: 140px 0;
}
.banner-section h1 {
  font-weight: 800;
  font-size: 50px;
  color: #0d233e;
  margin-bottom: 25px;
}
.bannerleftarrow {
  position: absolute;
  top: 110px;
  left: -7px;
}
.banner-section h1 span {
  color: #c10037;
}
.banner-circle img {
  filter: invert(1) brightness(100);
}
.banner-circle {
  position: absolute;
  left: -181px;
  top: 0;
  width: 300px;
  height: 300px;
}
.search-box
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 52px;
  padding-left: 15px;
  padding-right: 25px;
  min-width: 227px;
  color: #374b5c;
}
.search-box
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered::placeholder {
  color: #b7b7b7;
}
.filter-content .select2-selection__placeholder {
  color: #8d8d8d;
}
.search-box label {
  color: #c10037;
  font-weight: 600;
}
.search-box .select2-container--default .select2-selection--single,
.filter-content .select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  height: 52px;
  min-width: 285px;
}
.filter-content .select2-container--default .select2-selection--single {
  min-width: auto;
}
.search-box
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow,
.filter-content
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 47px;
  right: 6px;
}
.select2-container .select2-dropdown {
  border: 1px solid #e3e3e3;
}
.select2-results__option {
  padding: 6px 15px;
}
.search-box
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b,
.filter-content
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  position: absolute;
  top: 55%;
  left: 50%;
  border-color: #374b5c;
  border-style: solid;
  border-width: 0 2px 2px 0;
  padding: 3px;
  height: 0;
  margin-left: -10px;
  margin-top: -3px;
  width: 0;
  transform: rotate(45deg) translateY(-50%);
  -webkit-transform: rotate(45deg) translateY(-50%);
  -ms-transform: rotate(45deg) translateY(-50%);
}
.search-box .search-input {
  margin: 0 15px 0 0;
}
.search-box .group-img input {
  padding: 14px 9px 14px 35px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  min-width: 196px;
}
.search-box .group-img input::placeholder {
  color: #8d8d8d;
}
.search-box .group-img i {
  top: 18px;
  color: #8d8d8d;
}
.arrow-img {
  position: absolute;
  top: 157px;
  left: auto;
  right: 120px;
}
.banner-imgs img {
  max-width: 580px;
}
.category-section {
  padding: 100px 0 75px;
}
.section-heading h2 {
  font-weight: 700;
  font-size: 36px;
  color: #0d233e;
  margin: 0 0 15px;
  display: flex;
  align-items: center;
}
.section-heading h2 span {
  color: #fff;
  background: #c10037;
  border-radius: 57%;
  padding: 5px 2px;
  font-size: 32px;
}
.section-heading h2 span {
  color: #fff;
  background: #fff;
  background: url(../img/headingred-bg.png);
  height: 62px;
  width: 62px;
  display: flex;
  align-items: center;
  background-position: center center;
  justify-content: center;
}
.section-heading h2 span.title-left {
  margin-left: 10px;
}
.section-heading h2 span.title-right {
  margin-right: 10px;
}
.btn-view {
  background: #ffffff;
  border: 1px solid #c10037;
  border-radius: 6px;
  padding: 6px 26px;
  color: #c10037;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-view:hover,
.btn-view:focus {
  background: #c10037;
  border: 1px solid #c10037;
  color: #fff;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.category-section a h5 {
  font-weight: 700;
  color: #0d233e;
  font-style: normal;
  margin: 0 0 10px;
  font-size: 16px;
}
.search-btn .btn-primary {
  padding: 13px 28px;
  border-radius: 10px;
  display: block;
  min-width: 143px;
}
.search-btn .btn-primary i {
  margin: 0 5px 0 0;
}
.search-box
  .select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #b7b7b7;
  border-width: 2px 0 0 2px;
  margin-top: 3px;
  padding: 3px;
}
.sortbyset .select2-container--default {
  font-size: 14px;
}
.select2-container--default .select2-results__option[aria-selected] {
  font-size: 15px;
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #c10037;
  color: #fff;
  font-size: 15px;
}
.category-section a.category-links {
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  padding: 16px;
  display: inline-block;
  text-align: center;
  margin: 0 0px 25px 0;
  width: 100%;
}
.owl-prev,
.owl-next {
  margin: 0 12px 0 0;
  border: 1px solid #dee2e7;
  border-radius: 10px;
  color: #7f7f7f;
  background: #fff;
  width: 44px;
  height: 44px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.owl-next {
  margin-right: 0;
}
.owl-prev:hover,
.owl-next:hover {
  border: 1px solid #c10037;
  color: #fff;
  background: #c10037;
  box-shadow: 0px 8px 18px -8px rgba(19, 16, 34, 0.15);
}
.category-section a.category-links span {
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 18px;
  display: block;
  color: #666;
}
.category-section a.category-links:hover {
  background: #374b5c;
  border-color: #374b5c;
  -webkit-transition: ease all 0.5s;
  transition: ease all 0.5s;
}
.category-section a.category-links:hover h5,
.category-section a.category-links:hover span {
  color: #fff;
}
.section-heading {
  margin: 0 0 50px;
}
.featured-section {
  background: #f9fafc;
  padding: 100px 0;
}
.service-widget {
  border: 1px solid #dee2e7;
  border-radius: 10px;
  position: relative;
}
.service-widget .fav-item,
.grid-view .fav-item {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 20px;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  font-size: 14px;
}
.fav-item .fav-icon:hover,
.fav-item .fav-icon.selected {
  background-color: #c10037;
  color: #fff;
}
p:last-child {
  margin-bottom: 0;
}
.fav-item .fav-icon {
  width: 35px;
  height: 35px;
  background: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border-radius: 50%;
}
.Featured-text {
  background: #4c82f7;
  border-radius: 5px;
  color: #fff;
  padding: 5px;
}
.service-content a {
  font-size: 16px;
  color: #0d233e;
}
.featured-section .service-content h3 {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.service-content a:hover {
  color: #c10037;
}
.featured-slider .featured-info img {
  margin-right: 4px;
}
.featured-info {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  font-size: 14px;
}
.featured-info a {
  font-size: 14px;
}
.service-content {
  padding: 10px;
}
.serv-info {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  margin: 14px 0 0;
}
.clearfix {
  clear: both;
}
.ratings,
.featured-location,
.serv-info span {
  font-size: 14px;
}
.serv-info h6 {
  color: #c10037;
}
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}
.service-img .serv-img {
  border-radius: 10px 10px 0 0;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -o-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  width: 100%;
}
.featured-info span.feature-rate {
  background: #ff823b;
  color: #fff;
  padding: 0px 9px;
  margin: 0 6px;
  border-radius: 5px;
}
.ratings i {
  color: #ff823b;
}
.popular-locations {
  padding: 100px 0;
  background-color: #c10037;
  text-align: center;
  color: #fff;
  position: relative;
}
.popular-circleimg {
  position: absolute;
  right: 0;
  bottom: 0px;
  width: 14%;
  z-index: 0;
}
.popularcircle-img img {
  filter: invert(1) brightness(100);
}
.popular-locations .section-heading h2 span {
  color: #c10037;
  background: #fff;
  background: url(../img/headingspan-bg.png);
  height: 62px;
  width: 62px;
  display: flex;
  align-items: center;
  background-position: center center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 0;
}
.popular-locations .section-heading h2 {
  color: #fff;
  justify-content: center;
}
.popular-locations .location-info-details {
  background: #f9fafc;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  padding: 15px;
  align-items: center;
  margin: 0 0px 25px 0px;
}
.popular-locations .location-content p {
  color: #717171;
  margin-bottom: 10px;
  font-size: 14px;
}
.location-info-details .location-img img {
  border-radius: 10px;
}
.location-info:last-child {
  margin-right: 0;
}
.location-details {
  flex-wrap: wrap;
  justify-content: space-between;
}
.popular-locations .location-content {
  padding: 0 0px 0 15px;
  text-align: left;
  width: calc(100% - 148px);
}
.popular-locations .location-content a {
  font-size: 20px;
  font-weight: 600;
  color: #0d233e;
  display: inline-block;
  margin: 0 0 3px;
}
.popular-locations .location-content a:hover {
  color: #c10037;
}
.popular-locations .location-content a.view-detailsbtn {
  background: #fff;
  padding: 8px 8px;
  border-radius: 5px;
  width: 100%;
  margin: 0;
  display: block;
  font-size: 14px;
  text-align: center;
  color: #374b5c;
  font-weight: normal;
  box-shadow: 0px 8px 18px -8px rgba(19, 16, 34, 0.15);
}
.popular-locations .location-content a.view-detailsbtn:hover {
  background: #c10037;
  color: #fff;
}
.browse-btn {
  border: 1px solid #fff;
  color: #fff;
  padding: 7px 28px;
  border-radius: 6px;
  margin: 25px 0 0 0;
  display: inline-block;
}
.browse-btn:hover {
  border: 1px solid #c10037;
  background: #fff;
  color: #c10037;
}
.latestad-section {
  padding: 100px 0 75px;
  background: #f9fafc;
}
.cta-section {
  padding: 100px 0;
  background-image: url(../img/whitebg.png);
  background-repeat: no-repeat;
  background-position: right center;
}
.cta-section h3 {
  color: #0d233e;
  font-size: 36px;
  font-weight: 700;
  margin: 0 0 25px;
}
.cta-section h3 span {
  color: #0d233e;
}
.cta-content p {
  margin-bottom: 25px;
}
.cta-content .postad-btn {
  color: #fff;
  font-size: 16px;
  padding: 12px 31px;
  border-radius: 8px;
}
.cta-content .postad-btn:hover {
  color: #c10037;
}
.cta-content a.browse-btn {
  border: 1px solid #374b5c;
  color: #374b5c;
  padding: 10px 32px;
  margin: 0 0 0 14px;
  border-radius: 8px;
}
.testimonials-section {
  position: relative;
}
.testimonials-section .rightimg {
  background-image: url(../img/testi-bg.png);
  min-height: 600px;
  background-repeat: no-repeat;
  background-size: cover;
}
.testimonials-section .col-lg-4,
.testimonials-section .col-lg-8 {
  padding-left: 0;
  padding-right: 0;
}
.testimonial-heading h4 {
  font-weight: 700;
  font-size: 36px;
  color: #0d233e;
  margin-bottom: 0;
}
.testimonial-heading img {
  margin: 30px 0 0 0;
}
.testimonials-section .owl-carousel .owl-item .testi-img {
  border-radius: 50px;
  width: 86px;
  height: 86px;
  border: 3px solid rgba(62, 102, 223, 0.3);
}
.testimonials-section .owl-carousel .owl-item img {
  width: auto;
  border-radius: 50px;
}
.testimonials-slidersection {
  margin-top: -430px;
  position: relative;
}
.testimonials-slidersection .owl-nav {
  position: absolute;
  right: 14px;
  top: -40px;
}
.testimonial-info {
  background: #fff;
  padding: 40px;
  margin: 30px 0px 30px 0;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 5%);
  border-radius: 10px;
}
.testi-author h6 {
  color: #0d233e;
  font-size: 24px;
  font-weight: 800;
  margin: 0 0 5px;
}
.testi-author {
  margin: 0 0 0 20px;
}
.testi-author p {
  color: #666;
}
.testimonialslider-heading {
  align-items: center;
}
.testimonialslider-content p {
  margin: 15px 0 0 0;
}
.partners-section {
  text-align: center;
  padding: 100px 0;
}
.partners-section .partners-heading {
  font-size: 36px;
  color: #0d233e;
  font-weight: 700;
  margin: 0 0 50px;
}
.partnerslist li a {
  background: #f8f8f8;
  padding: 14px 20px;
  text-align: center;
  display: flex;
  height: 79px;
  border-radius: 10px;
  align-items: center;
}
.pricingplan-section {
  background: url("../img/Rosebg.png");
  padding: 100px 0;
  background-repeat: no-repeat;
  background-size: cover;
}
.pricingplan-section .section-heading p {
  color: #666;
}
.pricingplan-section h2 {
  justify-content: center;
}
.pricingplan-section .section-heading h2 span {
  margin-right: 0;
  margin-left: 10px;
}
.price-card {
  background: #fff;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  padding: 20px;
  z-index: 1;
  position: relative;
  overflow: hidden;
}
.price-level h6 {
  color: #c10037;
  font-weight: 700;
  margin: 0 0 20px;
}
.price-head h4 {
  font-size: 28px;
  font-weight: 800;
  margin: 0 0 20px;
  color: #0d233e;
}
.price-head h4 span {
  font-size: 14px;
  font-weight: 400;
  color: #666;
}
.price-body p,
.price-body li {
  line-height: 27px;
  color: #666;
  margin: 0 0 20px;
}
.price-body li {
  line-height: 20px;
  position: relative;
  padding: 5px 0 0 30px;
  margin: 0 0 15px;
}
.price-level {
  margin: 0 0 15px;
}
.price-body ul {
  margin: 0 0 20px;
}
.price-body ul li:before {
  background-image: url(../img/pricingli-bg.svg);
}
.price-body ul li:before {
  position: absolute;
  content: "";
  top: 4px;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
.price-card:hover::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 100%;
  left: auto;
  height: 120px;
  background-image: url(../img/pricingcircle.png);
  background-repeat: no-repeat;
  z-index: 2;
  opacity: 0.4;
  background-position: right top;
}
.banner-elipse {
  position: absolute;
  bottom: 0;
}
.viewdetails-btn {
  display: block;
  background: #fff;
  border: 1px solid #666666;
  color: #666666;
  font-weight: 600;
  border-radius: 10px;
  padding: 10px 50px;
}
.viewdetails-btn:hover {
  border: 1px solid #c10037;
  color: #c10037;
}
.price-card:hover,
.price-card:hover .price-head h4,
.price-card:hover .price-level h6,
.price-card:hover .price-head h4 span {
  color: #fff;
}
.price-card:hover .price-body p,
.price-card:hover .price-body li {
  color: #fff;
}
.price-card {
  transition: all 0.5s ease-in-out;
  -webkit-transition: ease all 0.5s;
}
.price-card:hover {
  background: #c10037;
  transition: all 0.5s ease-in-out;
  -webkit-transition: ease all 0.5s;
}
.price-card:hover .price-body ul li:before {
  background-image: url(../img/pricinglihover-bg.svg);
}
.price-card:hover .viewdetails-btn {
  background: #fff;
  color: #c10037;
  border: 1px solid #c10037;
}
.blog-section {
  padding: 100px 0;
}
.grid-blog .blog-image {
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}
.service-img {
  position: relative;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}
.blog-image,
.blog-image > a,
.blog-image img {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
}
.blog-content .entry-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 15px;
}
.blog-image img,
.blog-img img,
.venue-image img,
.recommended-widget .img-blk img,
.blog-widget .img-blk img {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -o-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  width: 100%;
  object-fit: cover;
    height: 312px;
}
.blog-image:hover img,
.service-img:hover img,
.blog-img:hover img,
.blog-widget .img-blk:hover img,
.venue-image:hover img,
.recommended-widget .img-blk:hover img {
  -webkit-transform: scale(1.35) rotate(15deg);
  -moz-transform: scale(1.35) rotate(15deg);
  transform: scale(1.35) rotate(15deg);
}
.bloglist-widget .blog-image:hover img {
  -webkit-transform: scale(1.35) rotate(7deg);
  -moz-transform: scale(1.35) rotate(7deg);
  transform: scale(1.35) rotate(7deg);
}
.blog-section .grid-blog .entry-meta li {
  margin: 0;
}
.blog-section .blog-content .viewlink a {
  font-size: 16px;
  color: #374b5c;
  font-weight: normal;
}
.grid-blog .entry-meta li {
  margin-bottom: 15px;
  font-size: 12px;
  color: #9c9c9c;
}
.blog.grid-blog .post-author a {
  margin-bottom: 15px;
  font-size: 12px;
  color: #9c9c9c;
}
.blog-section .blog-content .viewlink a {
  color: #c10037;
}
.blog-content .viewlink a:hover {
  color: #374b5c;
}
.entry-meta li {
  display: inline-block;
}
.grid-blog .blog-title {
  line-height: 30px;
  margin: 0 0 15px;
}
.grid-blog .entry-meta li.date-icon i {
  color: #374b5c;
  margin-right: 5px;
  opacity: 0.5;
}
.grid-blog {
  border: 1px solid #f3f3f3;
  border-radius: 10px;
}
.grid-blog:hover {
  box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.06),
    0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
}
.post-author a {
  font-size: 14px;
}
.blog-content {
  padding: 20px;
}
.post-author a:hover {
  color: #c10037;
}
.blog-category a {
  background: #dee2e7;
  padding: 4px 11px;
  border-radius: 4px;
  font-size: 12px;
  color: #374b5c;
  margin: 0 13px 0px 0;
  display: inline-block;
  line-height: normal;
}
.blog-category a:hover {
  background: #c10037;
  color: #fff;
}
.grid-blog .blog-title a {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #0d233e;
}
.blog-content .viewlink {
  margin: 0;
}
.blog-gridpage .blog-content .viewlink a,
.bloggrid-sidebar .blog-content .viewlink a {
  font-size: 16px;
  color: #374b5c;
  font-weight: normal;
}
.bloglist-widget .viewlink {
  margin: 10px 0 0;
}
.viewlink a {
  font-size: 16px;
  color: #374b5c;
  display: flex;
  align-items: center;
}
.viewlink a i {
  margin-left: 7px;
}
.viewlink a:hover,
.blog-gridpage .blog-content .viewlink a:hover,
.bloggrid-sidebar .blog-content .viewlink a:hover {
  color: #c10037;
}
.viewlink a:hover {
  color: #c10037;
}
.grid-blog .post-author {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.blog-content .post-author {
  display: flex;
  align-items: center;
}
.bloglisting .grid-blog .post-author a,
.blog-gridpage .grid-blog .post-author a {
  margin: 0;
}
.bloglisting .grid-blog .post-author-img img,
.blog-gridpage .grid-blog .post-author-img img {
  border-radius: 50px;
}
.grid-blog .blog-title a:hover {
  color: #c10037;
}
.blog-content p {
  font-size: 15px;
  margin: 0 0 15px;
}
.bloglisting .blog-content p {
  line-height: 27px;
}
.blog-content .blog-description {
  margin: 0 0 5px 0px;
  line-height: 27px;
  height: 88px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blog-image img {
  border-radius: 10px 10px 0 0;
}
.lateestads-content .service-widget {
  margin-bottom: 25px;
}
.cta-content a.browse-btn:hover {
  color: #c10037;
  border: 1px solid #c10037;
}
.testimonials-slidersection .owl-dots {
  display: none;
}
.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.progress-wrap {
  position: fixed;
  right: 12px;
  bottom: 25px;
  height: 35px;
  width: 35px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  box-shadow: inset 0 0 0 2px rgb(75 64 237 / 20%);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  transform: translateY(15px);
  -webkit-transform: translateY(15px);
  -moz-transform: translateY(15px);
  -ms-transform: translateY(15px);
  -o-transform: translateY(15px);
}
.progress-wrap svg.progress-circle path {
  stroke: #c10037;
  stroke-width: 4;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress-wrap svg path {
  fill: none;
}
.progress-wrap::after {
  position: absolute;
  content: "\f062";
  font-family: "FontAwesome";
  text-align: center;
  line-height: 35px;
  font-size: 17px;
  color: #c10037;
  left: 0;
  top: 0;
  height: 35px;
  width: 35px;
  cursor: pointer;
  display: block;
  z-index: 1;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.featured-slider.grid-view .card {
  background: #fff;
  padding: 0;
  border: 1px solid #e3e3e3;
  box-shadow: none;
  border-radius: 10px;
  margin-bottom: 0;
}
.featured-slider.grid-view .card:hover {
  box-shadow: 0px 4.4px 20px -1px rgba(19, 16, 34, 0.05),
    0px 4px 9px -1px rgba(19, 16, 34, 0.03);
}
.featured-slider.grid-view .lateestads-content .card {
  margin-bottom: 25px;
}
.owl-carousel.featured-slider.grid-view .owl-item .bloglist-content img {
  width: auto;
  display: inline;
  margin-right: 5px;
  border-radius: 50px;
}
.grid-view.featured-slider .blog-img {
  border-radius: 10px 10px 0 0;
}
.grid-view.featured-slider .bloglist-content .blogfeaturelink {
  display: flex;
  margin: 15px 0;
  align-items: center;
  justify-content: space-between;
}
.blog-location-details .location-info i {
  margin-right: 5px;
  color: #374b5c;
  opacity: 0.6;
}
.blog-location-details .location-info i.fa-calendar-days {
  opacity: 0.5;
}
.blog-location-details .location-info {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}

/*-----------------
	10. Service Details
-----------------------*/

.descriptionlinks ul {
  display: flex;
}
.authordetails h5 {
  font-size: 24px;
  font-weight: 800;
  color: #0d233e;
  margin-bottom: 15px;
}
.authordetails .rating {
  display: flex;
  align-items: center;
}
.callnow {
  text-align: right;
}
.authordetails p {
  margin-bottom: 0.5rem;
  color: #374b5c;
}
.details-description {
  padding: 30px 0;
  background: #fff;
  box-shadow: 3px 5px 13px rgb(222 226 231 / 44%);
}
.about-headings .author-img img {
  border-radius: 50px;
  width: 100px;
  height: 100px;
}
.author-img {
  margin: 0 20px 0 0;
  border-radius: 50%;
}
.rating i {
  font-size: 12px;
  margin-right: 3px;
}
.rating i.filled {
  color: #ffa800;
}
.rate-details h2 {
  font-size: 40px;
  color: #0d233e;
}
.rate-details p {
  font-size: 14px;
}
.about-headings {
  width: 80%;
  display: flex;
}
.rate-details {
  width: 20%;
  text-align: right;
}
.average-rating {
  margin: 0px 0 0 10px;
  color: #374b5c;
}
.about-details {
  border-bottom: 1px solid #dee2e7;
  padding: 0 0 15px;
  margin: 0 0 30px;
  display: flex;
}
.descriptionlinks ul li {
  margin-right: 25px;
  text-transform: capitalize;
}
.descriptionlinks ul li a {
  display: flex;
  align-items: center;
  color: #666;
}
.descriptionlinks ul li a img {
  padding-right: 10px;
}
.descriptionlinks ul li a:hover {
  color: #c10037;
}
.descriptionlinks ul li a i {
  color: #c10037;
  padding-right: 10px;
  font-size: 20px;
}
.callnow a {
  background: #c10037;
  color: #fff;
  padding: 12px 58px;
  border-radius: 10px;
  border: 1px solid #c10037;
}
.callnow a i {
  margin-right: 3px;
}
.callnow a:hover {
  background: #fff;
  color: #c10037;
  border: 1px solid #c10037;
}
.details-main-wrapper {
  background: #f9fafc;
  padding: 20px 0;
}
.card {
  background: #fff;
  padding: 25px;
  box-shadow: 3px 5px 13px rgba(222, 226, 231, 0.44);
  margin: 0 0 25px;
  border: 0;
}
.card-header h4 {
  font-size: 20px;
  margin: 0 0 0 12px;
}
.card .bar-icon {
  margin: 5px 0 0;
}
.bar-icon span {
  margin-bottom: 5px;
}
.details-main-wrapper .card-header {
  padding: 0 0 25px;
  border-bottom: 1px solid #dee2e7;
  margin: 0 0 25px;
  display: flex;
  border-radius: 0;
  background: none;
}
.card-header i {
  color: #c10037;
  font-size: 28px;
}
.details-main-wrapper .card-header i {
  font-size: 24px;
}
.details-main-wrapper .card-body {
  padding: 0;
}
.bar-icon span:nth-child(2) {
  width: 20px;
}
.bar-icon span:nth-child(3) {
  width: 16px;
}
.card p {
  line-height: 25px;
}
.feature-img {
  background: #f1f1f1;
  padding: 11px;
  border-radius: 50%;
  margin: 0 15px 0 0;
}
.access-feature {
  margin-bottom: 0px;
}
.featues-info h6 {
  color: #666;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.4;
}
.lisiting-featues {
  flex-wrap: wrap;
  display: flex;
}
.feature-img img {
  width: 28px;
  height: 28px;
}
.featureslist {
  margin: 0 0px 20px 0;
}
.access-feature.featureslist {
  margin: 0;
}
.gallery-widget {
  position: relative;
}
.gallery-section .gallery-widget {
  margin: 0 0px 25px 0;
}
.gallery-section .gallery-widget a img {
  border-radius: 10px;
}
.gallery-section .gallery-widget a:hover::before {
  background: #374b5c;
  opacity: 0.9;
  display: block;
}
.gallery-content {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card.gallery-section {
  padding-bottom: 5px;
}
.gallery-content .gallery-widget a:before,
.gallery-content .gallery-widget a:after {
  display: none;
}
.gallery-section .gallery-widget a:hover::after {
  display: block;
}
.gallery-section .gallery-widget a:hover::before {
  content: " ";
  background: #374b5c;
  opacity: 0.9;
  position: absolute;
  top: 0;
  color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.gallery-section .gallery-widget a:hover::after {
  content: "";
  position: absolute;
  background: url("../img/eye-icon.png");
  top: 45%;
  left: 45%;
  color: #fff;
  width: 25px;
  height: 25px;
}
.ratings-info {
  background: #f9fafc;
  padding: 20px;
  text-align: center;
}
.gallery-content .gallery-widget .overlay {
  display: none;
}
.gallery-content .gallery-widget:hover .overlay {
  display: block;
  cursor: pointer;
}
.overlay i {
  position: absolute;
  top: 50%;
  left: 45%;
  font-weight: normal;
  color: #fff;
}
.ratings-score span {
  font-size: 40px;
}
.card .ratings-info p.ratings-score {
  font-weight: 800;
  font-size: 24px;
  color: #374b5c;
}
.card .ratings-info p {
  margin: 0 0 25px;
  font-size: 16px;
}
.card .review-details p {
  margin-bottom: 0px;
}
.card .ratings-info p:last-child {
  margin: 0;
}
.review-details h6 {
  font-size: 20px;
  margin-bottom: 30px;
  color: #374b5c;
}
.feedbackbox .review-details h6 {
  font-size: 20px;
  color: #0d233e;
}
.review-details {
  margin-left: 18px;
}
.review-details .rating {
  display: inline-flex;
}
.review-details .rating div {
  font-size: 14px;
  color: #666666;
  line-height: 20px;
}
.review-details .rating div {
  margin-right: 30px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.review-details .rating .fa-calendar-days {
  color: #c10037;
  font-size: 18px;
  margin: 0 10px 0 0;
}
.feedbackbox .form-control,
.feedbackbox .textarea {
  color: #374b5c;
  border-radius: 8px;
  border: 1px solid #e3e3e3;
}
.feedbackbox textarea.form-control {
  background: #f9fafc;
  color: #374b5c;
  font-size: 15px;
  padding: 15px 15px;
}
.feedbackbox .form-group textarea::placeholder,
.feedbackbox .form-group input::placeholder {
  color: #374b5c;
}
.feedbackbox .form-group textarea:focus,
.feedbackbox .form-group input:focus {
  border-color: #374b5c;
}
.review-details .rating .not-filled {
  color: #ffa800;
}
.ratings-info i {
  color: #ffa800;
  font-size: 12px;
}
.star-ratings i {
  font-size: 12px;
}
.blogdetail-content .feedbackbox .form-group {
  margin-bottom: 20px;
}
.blogdetail-content .feedbackbox .reviewbox-rating p {
  font-size: 16px;
}
.blogdetail-content .feedbackbox textarea.form-control {
  min-height: 198px;
}
.star-ratings i.filled {
  color: #ffa800;
}
.ratings-content table {
  border: 1px solid #f1f1f1;
  width: 100%;
}
.ratings-content table td {
  padding: 10px;
  white-space: nowrap;
  border-bottom: 1px solid #f1f1f1;
}
.ratings-content table td.scrore-width {
  width: 100%;
}
.ratings-content table td.star-ratings i {
  margin-right: 3px;
}
.ratings-content table tr:nth-child(even) {
  background: #f1f1f1;
}
.ratings-table.table-responsive {
  border-radius: 10px;
  border: 1px solid #f1f1f1;
}
.scrore-width span {
  background: #e6e6e6;
  height: 10px;
  width: 100%;
  display: inline-block;
  border-radius: 50px;
}
.rating-color.fa-star:before {
  color: #374b5c;
}
.rating-overall.fa-star:before {
  color: #ffa800;
}
.scrore-width.selected span {
  background: #ffa800;
}
.review-list .review-gallery li {
  width: 150px;
  height: 160px;
  margin: 0 10px 0 0;
  padding: 0;
  background: 0;
  border-radius: 0;
}
.review-img {
  width: 82px;
  height: 82px;
  border-radius: 50%;
}
.review-sec .review-box .review-details h6 {
  margin-bottom: 15px;
}
.review-img img {
  border-radius: 50px;
}
.review-details .reply-box {
  border: 1px solid #dee2e7;
  padding: 10px 20px;
  background: #fff;
  margin: 25px 0 0 0;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.review-details .reply-box p {
  margin: 0;
}
.thumbsup i {
  color: #22c55e;
  margin-right: 3px;
}
.reply-box .thumbsup:hover {
  color: #22c55e;
}
.thumbsdown i {
  color: #ff0000;
  margin-right: 3px;
}
.reply-box .thumbsdown:hover {
  color: #ff0000;
}
.review-details .reply-box p a {
  margin-right: 0;
  margin-left: 22px;
}
.reply-box a:hover {
  color: #c10037;
}
.review-list li {
  background: #f9fafc;
  padding: 25px;
  border-radius: 10px;
  margin: 0 0 25px 0;
}
.reply-box a.replylink i {
  margin: 0 5px 0 0;
}
.review-list li:nth-child(2) {
  margin-left: 60px;
}
.review-list .review-gallery li:nth-child(2) {
  margin-left: 0;
}
.reviewbox-rating {
  margin: 20px 0;
}
.btn-primary {
  padding: 10px 34px;
  border-radius: 8px;
}
.reviewbox-rating p i {
  color: #ff823b;
  font-size: 12px;
}
.namefield .form-group {
  width: 50%;
  margin-right: 20px;
}
.feedbackbox .namefield {
  display: flex;
}
.feedbackbox .review-details {
  margin-left: 0;
}
.reviewbox-rating p span {
  margin: 0 15px 0 0;
}
.listings-sidebar .card,
.rightsidebar .card {
  background: #fff;
  padding: 25px;
  border-radius: 10px;
  margin-bottom: 25px;
}
.list-content .blog-pagination {
  margin-bottom: 25px;
}
.list-content .listingmappage-content .blog-pagination,
.list-content .listingmaplist-content .blog-pagination {
  margin-bottom: 0px;
}
.listings-sidebar .card h4,
.rightsidebar .card h4 {
  border-bottom: 1px solid #dee2e7;
  padding: 0 0 20px 0;
  font-size: 20px;
  color: #0d233e;
  margin: 0 0 25px;
  display: flex;
  align-items: center;
}
.rightsidebar .card h4 i,
.listings-sidebar .card h4 i {
  color: #c10037;
  margin: 0 10px 0 0;
}
.rightsidebar .card h4 img,
.listings-sidebar .card h4 img {
  margin: 0 8px 0 0;
}
.card .info-list {
  margin: 12px 0 0 0;
}
.info-list li {
  display: flex;
  align-items: center;
}
.info-list li img {
  margin-right: 10px;
  color: #c10037;
}
.info-list li i {
  color: #c10037;
  margin-right: 10px;
  font-size: 20px;
}
.rightsidebar .card .socialicons i {
  margin: 0;
}
.rightsidebar .card ul li.socialicons a i {
  color: #666;
}
.rightsidebar .card ul li.socialicons a {
  border: 1px solid #666666;
  color: #666666;
  border-radius: 50%;
  margin: 0 12px 0 0;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sideauthor-img {
  margin: 0 12px 0 0;
  border-radius: 50px;
}
.sideauthor-img img {
  border-radius: 50px;
  width: 50px;
  height: 50px;
}
.sideauthor-info p {
  font-size: 12px;
}
.sideauthor-info p.authorname {
  margin: 0 0 6px;
  font-size: 20px;
  font-weight: 500;
  color: #09153c;
}
.showphotos {
  position: absolute;
  right: 35px;
  bottom: 35px;
}
.bannergallery-section {
  position: relative;
}
.showphotos a {
  background: #fff;
  border-radius: 10px;
  padding: 10px 45px;
}
.showphotos a:hover {
  color: #c10037;
}
.contactbusinessform .submit-section .btn-primary {
  padding: 10px 20px;
  display: block;
  width: 100%;
}
.contactbusinessform input,
.contactbusinessform textarea,
.contactbusinessform input:focus,
.contactbusinessform textarea:focus {
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  min-height: 52px;
}
.contactbusinessform textarea.form-control {
  background: #fff;
  padding: 15px;
}
.contactbusinessform .form-group .form-control::-webkit-input-placeholder {
  color: #8d8d8d;
}
.contactbusinessform .form-group .form-control::-moz-placeholder {
  color: #8d8d8d;
}
.contactbusinessform .form-group .form-control:-ms-input-placeholder {
  color: #8d8d8d;
}
.contactbusinessform .form-group .form-control:-moz-placeholder {
  color: #8d8d8d;
}
.card ul li.socialicons a:hover {
  background: #c10037;
  border: 1px solid #c10037;
  color: #fff;
}
.card ul li.socialicons a:hover i {
  color: #fff;
}
.rightsidebar .card .statistics-list .icons {
  color: #c10037;
  border: 1px solid #e6e6e6;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.rightsidebar .card .statistics-list li {
  background: #f9fafc;
  padding: 10px;
  display: flex;
  align-items: center;
  margin: 0 0 24px;
  border-radius: 10px;
}
.rightsidebar .card ul.info-list li {
  justify-content: normal;
}
.map-frame {
  width: 100%;
  overflow: hidden;
}
.map-frame iframe {
  width: 100%;
}
.rightsidebar .card ul li {
  padding-bottom: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rightsidebar .card ul li span {
  color: #0d233e;
}
.sorting-div .sortbyset {
  display: flex;
  align-items: center;
  margin: 0 11px 0 0;
}
.grid-listview ul {
  display: flex;
}
.grid-listview ul li {
  margin: 0 10px 0 0;
}
.grid-listview ul li:last-child {
  margin-right: 0;
}
.statistics-list li .statistic-details {
  align-items: center;
  display: flex;
}
.review-box {
  display: flex;
}
.review-box.feedbackbox {
  display: block;
}
.review-box.feedbackbox .form-group {
  margin-bottom: 20px;
}
.review-box.feedbackbox input,
.review-box.feedbackbox textarea:focus {
  background: #f9fafc;
}
.review-gallery {
  display: flex;
  margin-top: 25px;
}
.review-gallery img {
  border-radius: 10px;
}
.sidebarauthor-details {
  display: flex;
}

/*-----------------.
	11. Listing List
-----------------------*/

.breadcrumb {
  justify-content: center;
  margin: 0;
}
.breadcrumb li a:hover {
  color: #c10037;
}
.breadcrumb-title {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
}
.breadcrumb-bar {
  background: #384b5c;
  padding: 170px 0 90px;
  position: relative;
  z-index: 1;
  width: 100%;
}
.breadcrumb-bar:before {
  content: "";
  background: url("../img/breadcrumbleft-bg.png") no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 175px;
  height: 149px;
  background-size: cover;
}
.breadcrumb-bar:after {
  content: "";
  background: url("../img/breadcrumbright-bg.png") no-repeat;
  position: absolute;
  right: 0;
  width: 175px;
  height: 149px;
  background-size: cover;
  bottom: 0;
}
.breadcrumb-bg {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
}
.breadcrumb-bg img {
  height: 234px;
  opacity: 0.1;
}
.breadcrumb li a,
.breadcrumb-item.active {
  color: #fff !important;
}
.breadcrumb-item.active,
.breadcrumb-item + .breadcrumb-item::before {
  color: #fff;
}
.listings-sidebar {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.06),
    0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
}
.list-content {
  background: #f9fafc;
  padding: 70px 0 45px;
}
.slidecontainer .irs--flat .irs-bar {
  top: 25px;
  background-color: #c10037;
}
.slidecontainer .irs--flat .irs-line,
.slidecontainer .irs--flat .irs-bar {
  height: 13px;
}
.slidecontainer .irs-with-grid .irs-grid,
.slidecontainer .irs--flat .irs-min,
.slidecontainer .irs--flat .irs-max {
  display: none;
}

.slidecontainer .irs--flat .irs-handle > i:first-child {
  position: absolute;
  display: block;
  top: -4px;
  left: -3px;
  width: 25px;
  height: 25px;
  margin-left: -1px;
  background-color: #c10037;
  border-radius: 50px;
  border: 3px solid #fff;
  cursor: pointer;
}
.slidecontainer .irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
  color: white;
  font-size: 12px;
  line-height: 1.333;
  text-shadow: none;
  padding: 4px 10px;
  background-color: #c10037;
  border-radius: 2px;
  top: -10px;
}
.slidecontainer .listings-sidebar .card {
  padding: 0;
  margin: 0 0 8px;
  border: none;
  box-shadow: none;
}
.filter-content {
  margin-bottom: 20px;
  color: #8d8d8d;
}
.listings-sidebar .filter-content.amenities,
.listings-sidebar .filter-content.region {
  margin-bottom: 30px;
}
.listings-sidebar .filter-content.radius {
  margin-bottom: 15px;
}
.filter-content .form-control,
.filter-content.form-control input {
  color: #374b5c;
}
.filter-content
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 52px;
  color: #374b5c;
  padding: 0px 15px;
}
.filter-content.looking-input input {
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  color: #374b5c;
  min-height: 52px;
}
.filter-content.form-group input::placeholder,
.filter-content.form-group .input-placeholder input::placeholder {
  color: #8d8d8d;
}
.filter-content .group-img i {
  color: #8d8d8d;
  top: 18px;
}
.filter-content
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #8d8d8d;
}
.descriptionlinks ul li:last-child {
  margin-right: 0;
}
.sortbyset
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #0d233e;
  line-height: 50px;
  font-size: 15px;
  padding-left: 10px;
}
.sortbyset
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #8d8d8d;
}
.sortbyset .select2-results__option[aria-selected] {
  font-size: 14px;
}
.group-img {
  position: relative;
}
.slidecontainer input {
  width: 100%;
  margin: 40px 0 0;
}
.group-img input {
  padding: 6px 15px 6px 35px;
}
.slider-title {
  font-size: 16px;
  font-weight: 700;
  color: #374b5c;
}
.slider-info .demo {
  color: #666d7a;
}
.group-img i {
  position: absolute;
  top: 16px;
  left: 14px;
  color: #374b5c;
}
.filter-content .custom_check {
  color: #9c9c9c;
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-size: 0.9375rem;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  top: -6px;
  margin: 0;
}
.filter-content.amenities li {
  margin-bottom: 10px;
}
.filter-content .custom_check .checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #b7b7b7;
  background-color: #fff;
  border-radius: 3px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.filter-content .custom_check input:checked ~ .checkmark {
  background-color: #c10037;
  border: 1px solid #c10037;
}
.filter-content .custom_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.filter-content .custom_check input:checked ~ .checkmark:after {
  display: block;
}
.filter-content .custom_check .checkmark::after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  display: none;
  left: 4px;
  top: 0px;
  color: #fff;
  font-size: 11px;
}
.card .filter-content.amenities h4 {
  border-bottom: none;
  padding: 0 0 5px 0;
  margin: 0 0 20px;
}
.card .filter-content .slider-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px;
}
.card .filter-content .slider-info h4 {
  margin: 0;
}
.card .amenities .form-group {
  display: flex;
}
.card .amenities .form-group input {
  margin: 0 10px 0 0;
}
.form-group input::placeholder {
  color: #b7b7b7;
}
.card .amenities .form-group input:last-child {
  margin-right: 0;
}
.amenities .search-btn {
  display: flex;
  flex-direction: column;
  margin: 20px 0 0 0;
}
.amenities .search-btn .btn {
  margin: 0 0 20px;
}
.amenities .search-btn .btn.btn-reset {
  color: #374b5c;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 25px;
  border-radius: 10px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.amenities .search-btn .btn.btn-reset i {
  margin-right: 7px;
}
.amenities .search-btn .btn.btn-reset:hover {
  background: #c10037;
  color: #fff;
}
.sortbyset .select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  height: 50px;
  font-size: 14px;
}
.sortbyset
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 47px;
  right: 6px;
}
.sortbyset
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  position: absolute;
  top: 55%;
  left: 50%;
  border-color: #3c4758;
  border-style: solid;
  border-width: 0 2px 2px 0;
  padding: 3px;
  height: 0;
  margin-left: -10px;
  margin-top: -3px;
  width: 0;
  transform: rotate(45deg) translateY(-50%);
  -webkit-transform: rotate(45deg) translateY(-50%);
  -ms-transform: rotate(45deg) translateY(-50%);
}
.sortbyset .sorting-select {
  min-width: 160px;
  margin-left: 10px;
}
.grid-listview a.active,
.grid-listview a:hover {
  background: #c10037;
  border-color: #c10037;
  color: #fff;
}
.grid-listview a {
  background: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #edf1f1;
  color: rgba(104, 97, 109, 0.5);
  border-radius: 5px;
}
.sorting-div {
  color: #666;
  margin: 0 0 25px;
}
.count-search span {
  color: #c10037;
}
.blog-listview .card {
  padding: 0;
  border: 1px solid #f1f1f1;
  box-shadow: none;
}
.blog-listview .card-body {
  padding: 25px;
}
.blog-listview .blog-img {
  position: relative;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}
.list-content .blog-listview .blog-img {
  border-radius: 10px 0px 0 10px;
}
.blog-listview .bloglist-content {
  width: calc(100% - 294px);
}
.list-content .blog-listview .blog-img:hover {
  border-radius: 10px 0px 0 10px;
}
.blog-listview .card .blog-widget {
  display: flex;
  width: 100%;
  align-items: center;
}
.blog-listview .card .fav-item {
  position: absolute;
  top: 0;
  left: 0;
  padding: 15px;
  width: 100%;
}
.fav-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  cursor: pointer;
}
.bloglist-content .blogfeaturelink {
  display: flex;
  align-items: center;
}
.listgrid-sidebar .blogfeaturelink,
.listmap-grid .blogfeaturelink {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.listmaplist-content .blogfeaturelink {
  justify-content: normal;
}
.bloglist-content .blog-features {
  margin: 0 15px 0 0;
}
.bloglist-content .blog-author {
  display: flex;
  align-items: center;
}
.bloglist-content .blog-author .blog-author-img {
  border-radius: 50px;
  width: 25px;
  height: 25px;
  margin: 0 8px 0 0;
}
.bloglist-content .blog-author-img img {
  margin: 0 5px 0 0;
  border-radius: 50px;
  width: 25px;
  height: 25px;
}
.bloglist-content .blogfeaturelink i,
.bloglist-content .blogfeaturelink img {
  margin: 0 5px 0 0;
}
.blog-location-details {
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: space-between;
  margin: 0 0 15px;
}
.blog-listview .Featured-text {
  font-size: 14px;
  padding: 7px 10px;
}
.blogfeaturelink a {
  color: #9c9c9c;
  font-size: 12px;
}
.blogfeaturelink a:hover,
.blogfeaturelink a:hover i {
  color: #c10037;
}
.blog-listview h6 {
  font-size: 18px;
  font-weight: 600;
  margin: 15px 0 15px;
  white-space: nowrap;
  width: 395px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.blog-listview h6 a:hover {
  color: #c10037;
}
.blog-listview .card .ratings span {
  background: #ff823b;
  color: #fff;
  padding: 2px 10px;
  margin: 0 6px 0 0;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #ff823b;
}
.blog-listview .card p {
  font-size: 12px;
  line-height: 25px;
  color: #9c9c9c;
  margin-bottom: 15px;
}
.blog-listview .card .amount {
  font-size: 12px;
  color: #9c9c9c;
}
.blog-listview .card .amount-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.blog-listview .card .amount span {
  text-decoration: line-through;
}
.clearfix {
  clear: both;
}
.blog-listview .card .amount-details a {
  color: #0d233e;
}
.blog-listview .card .amount-details a:hover {
  color: #c10037;
}
.blog-listview .card .amount-details .validrate {
  color: #c10037;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
}
.blog-pagination .pagination {
  margin-top: 0px;
}
.bloglisting .blog-pagination .pagination,
.bloggrid-sidebar .blog-pagination .pagination {
  margin-bottom: 25px;
}
.pagination-center {
  justify-content: center;
  display: flex;
  width: 79%;
}
.pagelink ul {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 10px;
}
.pagelink ul li a {
  border: 1px solid #dee2e7;
  background: #fff;
  border-radius: 10px;
  color: #0d233e;
  padding: 10px 19px;
  margin: 0 12px 0 0;
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagelink ul li a:hover {
  background: #374b5c;
  border-color: #374b5c;
  color: #fff;
}
.pagelink .page-item:first-child .page-link {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.pagelink .page-item:last-child .page-link {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.pagelink .active > .page-link {
  background: #374b5c;
  color: #fff;
  border: 1px solid #374b5c;
}
.page-link:focus {
  box-shadow: none;
}
.previtem a:hover,
.nextlink a:hover,
.previtem a:focus,
.nextlink a:focus {
  background: #fff;
  color: #374b5c;
  box-shadow: none;
}
.previtem a,
.nextlink a {
  color: #374b5c;
  min-width: 150px;
  padding: 10px 38px;
}
.previtem a:hover,
.nextlink a:hover {
  background: #374b5c;
  border-color: #374b5c;
  color: #fff;
}
.previtem a:hover i,
.nextlink a:hover i {
  color: #fff;
}
.page-item:first-child .page-link,
.page-item:last-child .page-link {
  border-radius: 10px;
}
.previtem a i,
.nextlink a i {
  color: #c10037;
}
.previtem a i {
  margin-right: 5px;
}
.nextlink a i {
  margin-left: 5px;
}
.sorting-div .col-lg-8 {
  justify-content: flex-end;
  display: flex;
  padding-right: 0;
}
.map-section {
  position: relative;
}
.showfilter {
  position: absolute;
  top: 20px;
  right: 30px;
  width: 50%;
  text-align: right;
}
.filterbtn,
.filterbtn:hover,
.filterbtn:focus {
  background: #fff;
  padding: 11px 48px;
  border-radius: 10px;
  color: #374b5c;
  font-weight: 600;
  min-width: 215px;
  border: none;
  margin: 0 0 20px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.filterbtn i {
  color: #c10037;
}
.hidefilter-btn {
  opacity: 0;
  visibility: hidden;
  display: none;
}
.filter-open .showfilter-btn {
  opacity: 0;
  visibility: hidden;
  display: none;
}
.showfilter .listings-sidebar {
  opacity: 0;
  visibility: hidden;
  display: none;
}
.filter-open .hidefilter-btn,
.showfilter.filter-open .listings-sidebar {
  opacity: 1;
  visibility: visible;
  display: block;
  width: auto;
}
.showfilter .listings-sidebar {
  text-align: left;
  max-height: 400px;
  overflow: auto;
  border-radius: 0px;
}
.showfilter .listings-sidebar .card {
  border-radius: 0;
  margin: 0;
}

/*-----------------.
	12. Listings Grid
-----------------------*/

.list-content .grid-view .card {
  background: #fff;
  padding: 0;
  border: 1px solid #e3e3e3;
  box-shadow: none;
  border-radius: 10px;
  margin-bottom: 25px;
}
.list-content .grid-view .card:hover {
  box-shadow: 0px 4.4px 20px -1px rgb(19 16 34 / 5%),
    0px 4px 9px -1px rgb(19 16 34 / 3%);
}
.grid-view .blog-img {
  overflow: hidden;
  position: relative;
  border-radius: 10px 10px 0 0;
  z-index: 1;
}
.listmapgrid-content .grid-view .blog-img,
.listmapgrid-content .grid-view .blog-img img {
  border-radius: 10px 10px 0 0;
}
.listmaplist-content .blog-listview .blog-img,
.listmaplist-content .blog-listview .blog-img img {
  border-radius: 10px 0 0 10px;
}
.grid-view .card-body {
  position: relative;
  padding: 25px;
}
.grid-view .grid-author {
  position: absolute;
  top: -18px;
  z-index: 2;
  border-radius: 50px;
}
.grid-view .grid-author img {
  border-radius: 50px;
  width: 40px;
  height: 40px;
}
.grid-view .bloglist-content .blogfeaturelink {
  margin: 15px 0;
}
.grid-view .bloglist-content .blog-author span {
  font-size: 12px;
}
.grid-view .card .amount-details .validrate {
  color: #c10037;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
}
.amount-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.grid-view .card .amount {
  font-size: 12px;
  color: #9c9c9c;
}
.grid-view .card .amount span {
  text-decoration: line-through;
}
.grid-view .card h6 {
  margin: 0 0 15px;
  width: 100%;
  color: #0d233e;
  font-size: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.grid-view .card h6 > a {
  color: #0d233e;
}
.grid-view .card h6 > a:hover {
  color: #c10037;
}
.grid-view .Featured-text {
  background: #4c82f7;
  padding: 7px 10px;
}
.grid-view .blog-pagination .pagination {
  margin: 0;
}
.grid-view .blog-author span,
.grid-view .blog-location-details,
.grid-view .ratings {
  color: #9c9c9c;
  font-size: 12px;
}
.grid-view .card .ratings span {
  background: #ff823b;
  color: #fff;
  padding: 2px 9px;
  margin: 0 6px 0 0;
  border-radius: 4px;
  font-size: 14px;
}

/*-----------------.
	13. Listingmap Grid
-----------------------*/

.list-content.listmap-grid {
  padding: 0;
}
.list-content.listmap-grid .count-search p,
.sorting-div .sortbyset {
  font-size: 16px;
  color: #0d233e;
}
.listmaplist-content .blog-listview h6 {
  width: 100%;
}
.pro-content h3 a {
  color: #fff;
  font-size: 16px;
  margin: 0 0 15px;
}
.pro-content {
  position: absolute;
  left: 15px;
  top: 25px;
}
.map-listing .profile-widget {
  position: relative;
  z-index: 1;
}
.map-listing .profile-widget::after {
  content: "";
  background: rgba(0, 0, 0, 0.53);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 10px;
}
.pro-content h3 {
  font-weight: normal;
  line-height: 20px;
  align-items: center;
  display: flex;
  line-height: 20px;
  height: 20px;
  margin: 0 0 5px;
}
.available-info li {
  color: #fff;
  display: flex;
  align-items: center;
}
.available-info li.mapaddress {
  margin: 0 0 65px;
  font-size: 14px;
}
.available-info .map-amount {
  font-size: 20px;
}
.available-info .map-amount span {
  font-size: 14px;
  color: #fff;
}
.available-info li.mapaddress i {
  color: #c10037;
  font-size: 25px;
}

/*-----------------.
	14. Categories
-----------------------*/

.categories-content a {
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  display: block;
  background: #fff;
  position: relative;
  overflow: hidden;
  z-index: 2;
  -webkit-transition: ease all 0.5s;
  transition: ease all 0.5s;
}
.categories-content a:hover {
  background: #374b5c;
  color: #fff;
  -webkit-transition: ease all 0.5s;
  transition: ease all 0.5s;
}
.categories-content a:hover h6,
.categories-content a:hover span {
  color: #fff;
}
.categories-content a:hover::before {
  opacity: 0;
}
.categories-content a:before {
  background-image: url(../img/categoriesbg.png);
  background-repeat: no-repeat;
  background-position: left bottom;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: -1;
  opacity: 1;
}
.categories-content {
  margin-bottom: 25px;
}
.categorieslist-section {
  padding: 70px 0 45px;
  background: #f9fafc;
}
.categorieslastsec.categories-content {
  margin-bottom: 0px;
}
.categorieslist-section h6 {
  font-weight: 700;
  color: #0d233e;
  margin: 20px 0 15px;
  line-height: 20px;
}
.categorieslist-section span.ads {
  margin: 0 0 10px;
}
.categorieslist-section span {
  display: block;
  color: #666;
  font-size: 14px;
}

/*-----------------.
	15. Blog Grid
-----------------------*/

.breadcrumb-bar.blogpagebreadscrumb,
.bloglist-section {
  background: #f9fafc;
}
.breadcrumb-bar.blogpagebreadscrumb:after,
.breadcrumb-bar.blogpagebreadscrumb:before {
  display: none;
}
.bloglist-section {
  padding: 70px 0 45px;
}
.blogpagebreadscrumb .breadcrumb li a,
.blogpagebreadscrumb .breadcrumb-item.active,
.blogpagebreadscrumb .breadcrumb-item + .breadcrumb-item::before {
  color: #374b5c;
}
.blogpagebreadscrumb .breadcrumb-title {
  color: #0d233e;
}
.bloglist-section .post-author a:hover {
  color: #c10037;
}
.bloglist-section .grid-blog {
  background: #fff;
  margin-bottom: 25px;
}
.bloglist-section .post-author a,
.bloglist-section .grid-blog .entry-meta li {
  font-size: 12px;
  color: #9c9c9c;
}
.bloglist-section .blog-content {
  padding: 0px 20px 20px;
}
.bloglist-section.bloggrid-sidebar .blog-content {
  padding: 20px;
}
.bloglist-section.bloggrid-sidebar .grid-blog .entry-meta li {
  margin: 0px;
}
.blog-gridpage .blog-content {
  padding: 20px;
}
.blog-gridpage .grid-blog .entry-meta li {
  margin: 0;
}
.bloglist-section .grid-blog .entry-meta li.date-icon i {
  color: #374b5c;
  margin: 0 5px 0 0;
  opacity: 0.5;
}
.bloglist-section .post-author img {
  margin: 0 5px 0 0;
}
.blogcategories-list {
  padding: 0 0 0 17px;
  color: #666;
}
.blogcategories-list li a:hover {
  color: #c10037;
}
.rightsidebar .card ul.blogcategories-list li {
  list-style: disc;
  display: list-item;
}
.bloglisting .blog-content .viewlink {
  margin: 15px 0 0;
}
.bloglisting .grid-blog .entry-meta li {
  margin: 0;
}
.rightsidebar .card ul.blogcategories-list li:last-child {
  padding: 0;
}
.map-listing {
  height: calc(100vh - 85px);
  width: 100%;
  margin-top: -29px;
}
.map-right {
  bottom: 0;
  height: 100%;
  min-height: 100%;
  padding-right: 30px;
  position: fixed;
  right: 0;
  top: 115px;
  z-index: 1029;
}
ul.tags {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
}
.rightsidebar .card ul.tags li {
  background-color: #f1f1f1;
  border-radius: 4px;
  color: #374b5c;
  padding: 5px 10px;
  position: relative;
  margin: 0 10px 15px 0;
  font-size: 14px;
  border: 1px solid #e3e3e3;
  display: inline-block;
  cursor: pointer;
}
.rightsidebar .card ul.tags li:hover {
  background: #c10037;
  border-color: #c10037;
  color: #fff;
}
.rightsidebar .card.tags-widget {
  padding: 25px 25px 15px;
}
.rightsidebar .card .article {
  position: relative;
  border-radius: 10px;
  margin: 0 0 25px;
}
.rightsidebar .card .article .articleimg-1 {
  background-image: url("../img/blog/article-1.jpg");
}
.rightsidebar .card .article .articleimg-2 {
  background-image: url("../img/blog/article-2.jpg");
}
.rightsidebar .card .article .articleimg-3 {
  background-image: url("../img/blog/article-3.jpg");
}
.rightsidebar .card .article a {
  display: block;
  padding: 100px 0;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
}
.rightsidebar .card .article a:after {
  content: "";
  background: linear-gradient(0deg, #000000 16.31%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 10px;
}
.blogsidebar .filter-content {
  margin-bottom: 0;
}
.rightsidebar .card .article ul {
  position: absolute;
  bottom: 20px;
  color: #fff;
  left: 20px;
}
.rightsidebar .card .article ul li {
  padding-bottom: 0;
  display: block;
}
.rightsidebar .card .article ul h6 {
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}
.rightsidebar .card .article:last-child {
  margin: 0;
}
.rightsidebar .card .article li i {
  color: #c10037;
  margin-right: 5px;
}
.rightsidebar .card .article ul h6:hover {
  color: #c10037;
}

/*-----------------.
	16. Blog List
-----------------------*/

.bloglisting .blog-image {
  border-radius: 10px 10px 0 0;
}
.bloglisting .grid-blog .entry-meta li.date-icon i {
  color: #666666;
  margin-right: 5px;
  opacity: 0.5;
}
.blog-content .post-author-img,
.bloglist-content .post-author-img {
  width: 25px;
  margin-right: 7px;
}
.grid-blog .post-author img,
.bloglist-content .post-author-img img {
  width: 25px;
  height: 25px;
  border-radius: 50px;
}
.bloglisting .grid-blog {
  margin-bottom: 25px;
  background: #fff;
}
.bloglisting {
  background: #f9fafc;
  padding: 70px 0 45px;
}
.bloglistleft-widget .entry-meta {
  display: flex;
  justify-content: space-between;
  margin: 0 0 18px;
  align-items: center;
}
.bloglistleft-widget .bloglist-content {
  width: calc(100% - 286px);
}
.bloglistleft-widget.blog-listview h3 {
  font-size: 20px;
  color: #0d233e;
  margin-bottom: 18px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.bloglistleft-widget.blog-listview h3 a {
  color: #0d233e;
}
.bloglistleft-widgetblog-listview h3 a {
  color: #0d233e;
}
.bloglistleft-widget.blog-listview h3 a:hover {
  color: #c10037;
}
.bloglistleft-widget .post-author a,
.bloglistleft-widget.blog-listview .date-icon {
  font-size: 12px;
  color: #9c9c9c;
}
.bloglistleft-widget.blog-listview .date-icon i.fa-calendar-days {
  opacity: 0.6;
}
.bloglistleft-widget .post-author a:hover {
  color: #c10037;
}
.bloglistleft-widget.blog-listview .card p {
  font-size: 15px;
  line-height: 27px;
  color: #666;
  height: 55px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bloglistleft-widget.blog-listview .blog-img {
  position: relative;
  overflow: hidden;
  border-radius: 10px 0px 0 10px;
}
.bloglistleft-widget.blog-listview .blog-category {
  position: absolute;
  top: 20px;
  left: 20px;
}
.bloglistleft-widget.blog-listview .blog-category a {
  background: #4c82f7;
  padding: 7px 15px;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  margin: 0 13px 0px 0;
  display: inline-block;
}

/*-----------------.
	17. Blog Details
-----------------------*/

.blogbanner {
  position: relative;
  background: url("../img/blog/blogbanner.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  text-align: center;
  padding: 90px 0;
  overflow: hidden;
  z-index: 1;
  min-height: 600px;
}
.blogbanner:after {
  content: "";
  background: linear-gradient(0deg, #000000 16.31%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.blogbanner-content {
  position: absolute;
  bottom: 40px;
  text-align: center;
  width: 100%;
  color: #fff;
}
.blogbanner-content ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.blogbanner-content h1 {
  color: #fff;
  font-weight: 700;
  margin: 0 0 15px;
}
.blogbanner-content .post-author-img {
  width: 32px;
  height: 32px;
  border-radius: 50px;
  margin-right: 10px;
}
.blogbanner-content .post-author-img img {
  border-radius: 50px;
  width: 32px;
  height: 32px;
}
.blogbanner-content .post-author,
.bloggrid-sidebar .post-author,
.bloglistleft-widget .post-author {
  display: flex;
  align-items: center;
}
.bloggrid-sidebar .blog.grid-blog .post-author a {
  margin: 0;
}
.blogbanner-content .post-author a:hover {
  color: #c10037;
}
.blogbanner-content .post-author a {
  font-size: 16px;
  color: #fff;
}
.blogbanner-content .entry-meta li {
  margin-right: 20px;
}
.blogbanner-content .date-icon i,
.blogbanner-content .post-author img {
  margin-right: 5px;
}
.blogbanner-content span.blog-hint {
  background: #22c55e;
  padding: 7px 12px;
  border-radius: 4px;
  margin: 0 0 15px;
  display: inline-block;
  font-size: 14px;
}
.blogdetail-content {
  padding: 70px 0;
  background: #f9fafc;
}
.blogdetail-content p {
  color: #666;
  line-height: 27px;
}
.blog-quotes {
  background: #fff;
  padding: 30px;
  text-align: center;
  margin: 25px 0 25px;
  border: 1px solid #f1f1f1;
  border-radius: 20px;
}
.blog-quotes i {
  font-size: 52px;
  color: #c10037;
}
.blog-quotes img {
  margin: 0 0 10px;
}
.blog-quotes h6 {
  color: #374b5c;
  font-weight: 700;
  margin: 0;
}
.bloginner-img {
  margin: 25px 0;
}
.bloginner-img img {
  width: 100%;
  border-radius: 20px;
}
.requiremnts-info h5 {
  font-size: 24px;
  font-weight: 700;
  color: #374b5c;
  margin: 0 0 20px;
}
.requiremnts-info li {
  list-style: disc;
  margin: 0 0 15px;
  line-height: 27px;
  color: #666;
}
.requiremnts-info ul {
  margin: 0 0 0 17px;
}
.tag-list {
  display: flex;
  justify-content: flex-end;
}
.tag-list .tags {
  display: flex;
}
.tag-list .tags li {
  background: #f1f1f1;
  border: 1px solid #f1f1f1;
  padding: 7px 15px;
  font-size: 14px;
  margin-right: 15px;
  border-radius: 5px;
  color: #666;
  cursor: pointer;
}
.tag-list .tags li:hover {
  background: #c10037;
  color: #fff;
}
.tag-list .tags li:last-child {
  margin-right: 0;
}
.share-postsection {
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  padding: 30px 0;
  margin: 25px 0;
}
.share-postsection .sharelink .share-img {
  color: #c10037;
  background: #edeff3;
  padding: 12px 16px;
  font-size: 20px;
  border-radius: 50px;
  margin-right: 10px;
}
.share-postsection .sharelink .share-img:hover {
  color: #fff;
  background: #c10037;
}
.share-postsection .sharelink a {
  color: #666;
  font-size: 14px;
}
.share-postsection .sharelink a.share-text:hover {
  color: #c10037;
}
.blogdetails-pagination ul {
  display: flex;
  justify-content: space-between;
}
.blogdetails-pagination ul li:last-child {
  text-align: right;
}
.blogdetails-pagination ul li .prev-link,
.blogdetails-pagination ul li .next-link {
  color: #c10037;
  font-size: 14px;
  margin: 0 0 20px;
  display: block;
}
.blogdetails-pagination ul li .prev-link i {
  margin-right: 7px;
}
.blogdetails-pagination ul li .next-link i {
  margin-left: 7px;
}
.blogdetails-pagination ul li h3 {
  font-size: 16px;
  font-weight: 700;
  color: #0d233e;
  margin: 0 0 25px;
}
.blogdetails-pagination ul li h3:hover {
  color: #c10037;
}
.blogdetail-content .card-header {
  padding: 0 0 25px;
  border-bottom: 1px solid #dee2e7;
  margin: 0 0 25px;
  display: flex;
  border-radius: 0;
  background: none;
}
.blogdetail-content .card-body {
  padding: 0;
}
.blogdetail-content .card.review-sec {
  margin-top: 25px;
}
.blogdetail-content .card-header h4 {
  font-size: 22px;
  margin: 0 0 0 12px;
  font-weight: 700;
}
.blogdetail-content .feedbackbox .review-details h6,
.blogdetail-content .card-header h4 {
  color: #0d233e;
  font-size: 20px;
}
.blogdetail-content .feedbackbox .review-details h6 {
  margin: 0 0 30px;
}

/*-----------------.
	18. About Page
-----------------------*/

.innerbanner .blogbanner-img img {
  min-height: 250px;
}
.aboutbanner {
  position: relative;
  background: url("../img/banner/aboutbanner.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  padding: 170px 0 90px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.innerbanner .breadcrumb-title {
  font-size: 32px;
  font-weight: 600;
  color: #fff;
}
.about-content {
  padding: 70px 0;
  background: #f9fafc;
}
.about-listee {
  position: relative;
  display: flex;
}
.about-img {
  width: 100%;
}
.about-img img {
  border-radius: 20px;
}
.about-info {
  width: 50%;
  position: absolute;
  right: 78px;
  background: #fff;
  padding: 30px 30px;
  border-radius: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.about-info p {
  color: #666;
  line-height: 27px;
  margin-bottom: 30px;
}
.about-info p:last-child {
  margin: 0;
}
.about-info h4 {
  color: #0d233e;
  font-size: 36px;
  font-weight: 700;
  margin: 0 0 30px;
}
.about-info h4 span {
  color: #c10037;
}
.howitworks {
  background: #f9fafc;
  text-align: center;
}
.howitworks h3 {
  color: #c10037;
  font-size: 36px;
  font-weight: 700;
  margin: 0 0 40px;
}
.howitworks p {
  color: #666;
  line-height: 27px;
  margin: 0 0 20px;
}
.howitworks .howitwork-info p {
  margin: 0;
}
.howitwork-info {
  text-align: left;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  padding: 20px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  margin: 30px 0 0;
}
.howitwork-info h5 {
  font-size: 60px;
  font-weight: 800;
  color: #374b5c;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 0 25px;
}
.howitwork-info h6 {
  font-size: 24px;
  font-weight: 800;
  margin: 0 0 25px;
}
.howitwork-info h5:after {
  content: "";
  border-top: 4px solid #374b5c;
  display: inline-block;
  width: calc(100% - 110px);
  position: absolute;
  top: 35px;
  left: 78px;
}
.howitwork-info h5:before {
  content: "";
  border: 5px solid #374b5c;
  display: inline-block;
  width: 20px;
  position: absolute;
  right: 12px;
  height: 20px;
  background: transparent;
  color: #fff;
  border-radius: 50px;
  top: auto;
}
.howitwork-info:hover h5:after {
  border-top: 4px solid #c10037;
}
.howitwork-info:hover h5:before {
  border: 5px solid #c10037;
}
.howitwork-info:hover h5 {
  color: #c10037;
}
.howitwork-info:hover {
  background: #fff;
  box-shadow: 3px 5px 13px rgba(222, 226, 231, 0.44);
}
.howitwork-info:hover h6 {
  color: #c10037;
}
.aboutpopular-locations .section-heading h2 {
  font-size: 28px;
}
.about-locations p {
  color: #666;
}
.aboutpopular-locations {
  background: #f9fafc;
  padding: 70px 0 45px;
}
.aboutlocation-details {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  height: 240px;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  margin-bottom: 25px;
}
.aboutlocation-details a {
  height: 100%;
  border-radius: 10px;
  position: absolute;
  overflow: hidden;
  z-index: 1;
  left: 0px;
  bottom: 0px;
  width: 100%;
  color: #fff;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}
.aboutlocation-details a {
  display: block;
}
.aboutlocation-details:after {
  content: "";
  background: linear-gradient(0deg, #000000 16.31%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.aboutlocation-details .aboutloc-img img {
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}
.aboutlocation-details h3 {
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}
.aboutlocation-details h3:hover {
  color: #c10037;
}
.aboutlocations-info {
  position: absolute;
  bottom: 30px;
  left: 30px;
  color: #fff;
}
.aboutlocation-details span {
  font-size: 14px;
}
.australi-loc img {
  min-height: 130px;
}

/*-----------------.
	19. Gallery
-----------------------*/

.gallerypage-info {
  padding: 70px 0;
}
.gallerypage-info .gallery-widget {
  position: relative;
  margin: 0 0 25px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.innerpagebg {
  background: #f9fafc;
}
.gallerypage-info .gallery-widget a:before {
  content: " ";
  background: #374b5c;
  opacity: 0.9;
  position: absolute;
  top: 0;
  color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}
.gallerypage-info .gallery-widget a:after {
  content: "";
  position: absolute;
  background: url(../img/eye-icon.png);
  top: 45%;
  left: 45%;
  color: #fff;
  width: 25px;
  height: 25px;
  z-index: 2;
  opacity: 0;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}
.gallerypage-info .gallery-widget a:hover::before,
.gallerypage-info .gallery-widget a:hover::after {
  opacity: 1;
  display: block;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  z-index: 2;
}
.gallerypage-info .gallery-widget a {
  display: inline-block;
  border-radius: 10px;
  transition: ease all 0.7s;
  -webkit-transition: ease all 0.7s;
  -ms-transition: ease all 0.7s;
  z-index: 1;
  position: relative;
  border-radius: 10px;
}
.gallerypage-info .gallery-widget a img {
  border-radius: 10px;
}

/*-----------------.
	20. Contact us
-----------------------*/

.contactbanner {
  position: relative;
  background: url("../img/banner/contactbanner.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  padding: 170px 0 90px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.innerbanner::after {
  content: "";
  background: linear-gradient(0deg, #000000 16.31%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.contactus-info {
  padding: 70px 0;
}
.contactus-img {
  position: relative;
}
.contactinfo-content {
  position: absolute;
  top: 50%;
  background: #c10037;
  padding: 50px;
  right: -80px;
  border-radius: 10px;
  text-align: center;
  color: #fff;
  z-index: 1;
  transform: translateY(-50%);
}
.google-maps {
  height: 100%;
}
.google-maps iframe {
  position: relative;
  height: 100%;
  width: 100%;
}
.contactinfo-content h6 {
  color: #fff;
  font-size: 24px;
  font-weight: 800;
}
.contact-hours {
  margin: 0 0 20px;
}
.contact-hours:last-child {
  margin: 0;
}
.contact-hours li {
  font-size: 16px;
  line-height: 27px;
}
.contactusform-section {
  padding: 0 0 70px;
}
.contact-info {
  text-align: center;
  margin: 0 0 50px;
}
.contactus-form input,
.contactus-form textarea,
.contactus-form input:focus,
.contactus-form textarea:focus {
  background: #f9fafc;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
}
.contactus-form .form-group {
  margin-bottom: 20px;
}
.contact-info h2 {
  font-weight: 700;
  color: #c10037;
  font-size: 36px;
  margin: 0 0 20px;
}
.contact-info h2 span {
  color: #374b5c;
}
.contact-info p {
  font-size: 18px;
}
.contactusform-section .submit-section {
  text-align: right;
}
.contactus-form input {
  color: #374b5c;
  font-size: 15px;
  min-height: 52px;
}
.contactus-form input:focus,
.contactus-form textarea:focus {
  border: 1px solid #000;
}
.contactus-form input::placeholder,
.contactus-form textarea::placeholder {
  color: #374b5c;
}
.contactus-form textarea.form-control {
  padding: 15px;
  color: #374b5c;
  font-size: 15px;
  min-height: 153px;
}

/*-----------------.
	21. How it works
-----------------------*/

.pricingplan-section.how-work {
  background: url(../img/priceplanbg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.work-section {
  padding: 70px 0;
  background: #f9fafc;
}
.work-section h4 {
  color: #0d233e;
  font-weight: 700;
  text-align: center;
  font-size: 36px;
  margin: 0 0 30px;
}
.work-section .description {
  text-align: center;
}
.work-section .work-info {
  border: 1px solid #f9fafc;
  padding: 25px;
  background: #fff;
  border-radius: 10px;
  margin: 0;
  box-shadow: 3px 5px 13px rgb(222 226 231 / 44%);
}
.work-section .work-info h5 {
  color: #c10037;
  font-size: 60px;
  margin: 30px 0;
  font-weight: 800;
  position: relative;
  display: flex;
  align-items: center;
}
.work-section .work-info h5:before {
  content: "";
  border: 5px solid #c10037;
  display: inline-block;
  width: 20px;
  position: absolute;
  right: 12px;
  height: 20px;
  background: transparent;
  color: #fff;
  border-radius: 50px;
  top: auto;
}
.work-section .work-info h5:after {
  content: "";
  border-top: 2px solid #c10037;
  display: inline-block;
  width: calc(100% - 110px);
  position: relative;
  top: auto;
  left: 10px;
}
.work-section .work-info h6 {
  font-size: 24px;
  color: #0d233e;
  font-weight: 800;
  margin-bottom: 30px;
}
.work-heading {
  margin: 0px 0 50px;
}
.work-section .work-info p {
  line-height: 27px;
  color: #666;
}

/*-----------------.
	22. FAQ
-----------------------*/

.faq-section {
  padding: 70px 0 45px;
  background: #f9fafc;
}
.faq-section h2 {
  font-size: 36px;
  font-weight: 700;
  margin: 50px 0 50px;
  color: #0d233e;
}
.faq-section .faq-card {
  background: #fff;
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 25px;
  box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.06),
    0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
}
.faq-section h4 {
  font-size: 20px;
  color: #0d233e;
  margin: 0;
}
.faq-section .faq-card .card-collapse p {
  font-size: 15px;
  margin: 20px 0 0;
  border-top: 1px solid #ccc;
  padding: 20px 0 0;
  line-height: 27px;
}
.faq-title > a:not(.collapsed):after {
  content: "\f068";
}
.faq-title > a:hover {
  color: #c10037;
}
.faq-title > a {
  display: block;
  position: relative;
  padding-right: 60px;
  color: #0d233e;
}
.faq-title > a:after {
  font-family: "Fontawesome";
  font-weight: 900;
  content: "\f067";
  position: absolute;
  top: 4px;
  right: 0;
  color: #c10037;
  font-size: 16px;
}

/*-----------------.
	23. Pricing Plan
-----------------------*/

.pricing-page.pricingplan-section {
  background-image: url(../img/priceplanbg.png);
  padding: 70px 0;
}
.pricinginner-page {
  background-image: url(../img/priceplanbg.png);
}

/*-----------------.
	24. Login 
-----------------------*/

.login-content {
  padding: 70px 0;
}
.login-wrap {
  box-shadow: 0px 4.4px 12px -1px rgb(19 16 34 / 6%),
    0px 2px 6.4px -1px rgb(19 16 34 / 3%);
  padding: 25px;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
}
.login-header {
  text-align: center;
  margin: 0 0 20px;
}
.login-header h3 {
  font-size: 30px;
  font-weight: 800;
  color: #0d233e;
  margin: 0 0 15px;
}
.login-wrap p,
.login-wrap {
  color: #666;
  background: #fff;
  max-width: 425px;
  margin: 0 auto;
}
.login-wrap .group-img input {
  padding: 6px 15px 6px 38px;
  min-height: 52px;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
}
.login-wrap .group-img input::placeholder {
  color: #b7b7b7;
}
.login-wrap .group-img i {
  top: 18px;
}
.toggle-password {
  position: absolute;
  right: 17px;
  top: 50%;
  transform: translateY(-50%);
  color: #797979;
  cursor: pointer;
}
.login-wrap .custom_check input,
.messages-form .custom_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.messages-form .custom_check {
  position: relative;
  padding: 0 0 0 30px;
  margin-bottom: 15px;
  cursor: pointer;
}
.login-wrap .custom_check input:checked ~ .checkmark,
.messages-form .custom_check input:checked ~ .checkmark {
  background-color: #c10037;
  border: 1px solid #c10037;
}
.login-wrap .custom_check .checkmark,
.messages-form .custom_check .checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #dcdcdc;
  background-color: #fff;
  border-radius: 3px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.login-or {
  text-align: center;
  border-top: 1px solid #dee2e7;
  padding: 30px 0 0;
  color: #666;
}
.register-link {
  margin: 30px 0;
}
.btn-google {
  background: #fff;
  border-radius: 4px;
  color: #757575;
  padding: 14px 10px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.084), 0px 1px 1px rgba(0, 0, 0, 0.168);
}
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: inherit;
  background-color: inherit;
  border-color: inherit;
}
.btn-google:hover {
  color: #c10037;
  border: 1px solid #c10037;
}
.btn-apple,
.btn-apple:hover,
.btn-apple:focus {
  background: #000;
  color: #fff;
  margin: 30px 0 20px;
  padding: 13px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-apple:hover {
  background: #c10037;
  color: #fff;
}
.btn-facebook,
.btn-facebook:hover,
.btn-facebook:focus {
  background: #1877f2;
  color: #fff;
  margin: 20px 0;
  padding: 12px 10px;
  font-weight: 500;
}
.btn-facebook:hover {
  background: #c10037;
  color: #fff;
}
.forgot-link {
  color: #c10037;
}
.forgot-link:hover {
  color: #374b5c;
}
.register-link .forgot-link {
  margin-left: 20px;
}
.back-home:hover {
  color: #c10037;
}
.login-wrap .custom_check {
  color: #9c9c9c;
  position: relative;
  padding: 0 0 0 30px;
}
.login-wrap .login-btn {
  margin-top: 20px;
  padding: 13px 25px;
}
.login-wrap .custom_check .checkmark::after,
.messages-form .custom_check .checkmark::after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  display: none;
  left: 3px;
  top: 0px;
  color: #fff;
  font-size: 11px;
}
.login-wrap .custom_check input:checked ~ .checkmark:after,
.messages-form .custom_check input:checked ~ .checkmark:after {
  display: block;
}
.login-wrap .form-group {
  margin-bottom: 20px;
}
.login-wrap .group-img i {
  color: #c10037;
}
.register-form .login-btn {
  margin-top: 0px;
  font-weight: 400;
}
.register-form .login-header h3 {
  font-weight: 700;
}
.register-form .login-header p span {
  color: #c10037;
  font-weight: bold;
}
.terms-content {
  padding: 70px 0;
}
.terms-content .page-title h5 {
  color: #0d233e;
  font-weight: 800;
  font-size: 24px;
  margin: 0 0 30px;
}
.terms-content p {
  color: #666;
  margin-bottom: 30px;
  line-height: 27px;
}
.terms-content p:last-child {
  margin-bottom: 0;
}
.error-logo,
.error-wrap {
  text-align: center;
}
.error-wrap {
  margin-bottom: 85px;
}
.error-wrap.unexpecterror h2 {
  margin: 30px 0;
}
.error-wrap h2 {
  font-weight: 800;
  margin: 30px 0 0;
  color: #0d233e;
  font-size: 45px;
}
.error-wrap .btn-primary {
  padding: 10px 31px;
}
.error-wrap .error-img {
  margin: 0 0 40px;
}

/*-----------------.
	25. Dashboard
-----------------------*/

.dashboard-content {
  padding: 70px 0;
  background: #f9fafc;
}
.dashboard-content ul.dashborad-menus {
  background: #fff;
  border-radius: 10px;
  padding: 25px 12px;
  align-items: center;
  display: flex;
  box-shadow: 3px 5px 13px rgb(222 226 231 / 44%);
  justify-content: space-around;
  margin: 0 0 25px;
}
.dashboard-content ul.dashborad-menus li {
  margin-right: 12px;
}
.dashboard-content ul.dashborad-menus li:last-child {
  margin-right: 0px;
}
.dashboard-content ul.dashborad-menus li a {
  padding: 12px 26px;
  color: #666;
  border-radius: 5px;
}
.dashboard-content ul.dashborad-menus li.active a,
.dashboard-content ul.dashborad-menus li a:hover {
  background: #c10037;
  color: #fff;
  border-radius: 5px;
}
.dashboard-content ul.dashborad-menus li a i {
  margin-right: 5px;
}
.profile-userlink img {
  margin-right: 6px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
}
.profile-userlink {
  font-size: 16px;
  font-weight: 600;
}
.profile-userlink:hover,
.profile-userlink.show {
  color: #c10037;
}
.card.dash-cards {
  padding: 25px;
  text-align: center;
  margin: 0 0 25px;
  border: 0;
  box-shadow: 3px 5px 13px rgba(222, 226, 231, 0.44);
}
.card.dash-cards .card-body {
  padding: 0px;
}
.dashboard-info .card.dash-cards .card-body {
  padding: 25px;
}
.dashboard-info .card.dash-cards .card-body {
  padding: 25px;
}
.dashboard-info .review-details h6 {
  margin-bottom: 15px;
}
.dashcard-img {
  margin: 0 0 15px;
}
.dash-widget-info h6 {
  font-size: 16px;
  font-weight: 400;
  color: #0d233e;
  margin: 0 0 15px;
}
.dash-widget-info h3 {
  font-size: 24px;
  font-weight: 800;
  color: #c10037;
  margin: 0;
}
.dashboard-info .card-header {
  border-radius: 10px 10px 0 0;
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 25px;
  align-items: center;
  border-bottom: 1px solid #f1f1f1;
}
.dashboard-info .card-header h4 {
  color: #0d233e;
  margin: 0;
}
.card-dropdown li.dropdown.has-arrow a.pageviews-link {
  border: 1px solid #e1e1e1;
  padding: 13px 15px;
  border-radius: 10px;
  color: #374b5c;
  font-size: 14px;
  display: block;
  min-width: 170px;
  position: relative;
}
.card-dropdown li.dropdown.has-arrow a.pageviews-link.dropdown-toggle::after {
  position: absolute;
  right: 10px;
  top: 18px;
}
.card-dropdown li.dropdown.has-arrow .dropdown-menu.show {
  display: block;
  width: 170px;
}
.dropdown-item {
  font-size: 14px;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #fff;
  background-color: #c10037;
}
.card-dropdown .select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
}
.card-dropdown
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #374b5c;
  line-height: 50px;
  min-width: 160px;
  font-size: 14px;
}
.card-dropdown
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 47px;
  right: 6px;
}
.card-dropdown .has-arrow .dropdown-toggle:after {
  border-top: 0;
  border-left: 0;
  border-bottom: 2px solid #7e84a3;
  border-right: 2px solid #7e84a3;
  content: "";
  height: 8px;
  display: inline-block;
  pointer-events: none;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 8px;
  vertical-align: 2px;
  margin-right: 10px;
}
.dashboard-info .card {
  text-align: left;
  border: none;
  padding: 0;
  box-shadow: 3px 5px 13px rgba(222, 226, 231, 0.44);
}
.dashboard-info .review-details .rating div {
  margin-right: 20px;
}
.dashboard-info .review-details .rating div:last-child {
  margin-right: 0px;
}
.dashboard-info .card-body p {
  font-size: 15px;
  color: #666;
}
.dashboard-info .review-list li:nth-child(2) {
  margin-left: 0px;
}
.dashboard-info .review-list li:last-child {
  margin-bottom: 0px;
}
#review-chart {
  margin: 25px 0 0;
}
#review-chart .apexcharts-toolbar {
  display: none;
}

/*-----------------.
	26. My Listings 
-----------------------*/

.dash-listingcontent a.header-login {
  background-color: #c10037;
  border: 1px solid #c10037;
  color: #fff;
  box-shadow: inset 0 0 0 #fff;
  border-radius: 10px;
  padding: 10px 20px;
  min-width: 130px;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  line-height: normal;
}
.dash-listingcontent .header-login.add-listing {
  background-color: #374b5c;
  border: 1px solid #374b5c;
}
.dash-listingcontent .header-login.add-listing i {
  font-size: 10px;
  color: #fff;
}
.dash-listingcontent .group-img input {
  width: 300px;
  font-size: 16px;
  color: #374b5c;
  border-radius: 10px;
  border: 1px solid #dee2e7;
  min-height: 50px;
}
.listing-search .sorting-div .sortbyset {
  color: #374b5c;
  margin: 0;
}
.listing-search {
  display: flex;
  justify-content: space-between;
}
.listing-table {
  border-radius: 10px;
  width: 100%;
}
.dash-listingcontent .card-header {
  margin: 0;
}
.listing-table th {
  background: #c10037;
  color: #fff;
}
.listing-table tr th:first-child {
  border-radius: 10px 0 0 0;
  padding-left: 20px;
}
.listing-table tr th:last-child {
  border-radius: 0 10px 0 0;
}
.listing-table th,
.listing-table td {
  padding: 20px 15px;
}
body table.listing-table.datatable {
  border-collapse: collapse !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.dash-listingcontent .table-responsive {
  border: 1px solid #dee2e7;
  border-radius: 10px;
  border-top: none;
}
.listing-table.datatable .status-text.unpublish {
  color: #c10037;
}
.listing-table tr {
  border-bottom: 1px solid #dee2e7;
}
.listing-table tr:last-child {
  border-bottom: none;
}
.listing-table tr:last-child td:first-child {
  border-radius: 0 0 0 10px;
}
.dashboard-info .listing-table tr p {
  margin: 0;
  font-size: 14px;
}
.listing-table .avatar-img {
  border-radius: 10px;
}
.listing-table h6:hover a {
  color: #c10037;
}
.listing-table h6 {
  font-size: 16px;
  color: #0d233e;
  font-weight: 400;
}
.listingtable-rate {
  margin-bottom: 5px;
}
.listing-table h6 a {
  color: #0d233e;
}
.listing-table .fixed-amt,
.listing-table .cat-icon {
  color: #666;
  font-size: 14px;
}
.listingtable-rate a:hover {
  color: #c10037;
}
.listingtable-rate a i {
  margin-right: 3px;
}
.listing-table .discount-amt {
  color: #c10037;
  font-weight: 700;
  font-size: 16px;
  margin: 0 10px;
}
.listing-table .status-text,
.listing-table .views-count {
  color: #00a656;
  font-weight: 700;
  font-size: 16px;
}
.listing-table .views-count {
  color: #374b5c;
}
.action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.listing-table .action-btn {
  background: #f1f1f1;
  border-radius: 50px;
  color: #374b5c;
  width: 40px;
  height: 40px;
  display: flex;
  margin: 0 10px 0 0;
  padding: 0;
  align-items: center;
  justify-content: center;
}
.listing-table .action-btn:hover {
  opacity: 0.7;
}
.listing-table tr td .listingtable-img {
  width: 160px;
  height: 110px;
  border-radius: 10px;
}
.listingtable-img img {
  border-radius: 10px;
}
.listing-table .btn-view {
  border: 1px solid #f1f1f1;
}
.listing-table .btn-edit {
  background: #374b5c;
  color: #fff;
}
.listing-table .btn-trash {
  background: #c10037;
  color: #fff;
  margin: 0;
}
.listing-table tr td:first-child {
  padding-left: 20px;
  padding-right: 0;
}
.listing-table tr th:last-child {
  text-align: center;
}
.listing-table tr td:last-child {
  text-align: right;
  padding-right: 20px;
}
.listing-table tr td:nth-child(4),
.listing-table tr th:nth-child(4) {
  text-align: center;
}
.dash-listingcontent .blog-pagination {
  margin: 20px 0 0;
}
.listing-table tr:first-child {
  border-left: 0;
  border-radius: 0;
}
.listing-search .select2-container--default .select2-selection--single {
  border: 1px solid #dee2e7;
}
.dashboard-info .card-header.reviewpage-header {
  padding: 33px 20px 33px 20px;
  display: flex;
  align-items: center;
  margin: 0;
}
.dashboard-info.reviewpage-content .card-header {
  margin: 0;
}
.dashboard-info .review-list .review-gallery li {
  width: auto;
  height: auto;
}
.dashboard-info .review-list .review-gallery li img {
  width: 99px;
  height: 63px;
}
.dashboard-info.reviewpage-content .review-details h6 {
  font-size: 24px;
  color: #09153c;
}
.dashboard-info .modal-body .form-header {
  text-align: center;
  margin: 0 0 15px;
}
.dashboard-info .modal-body .form-header h3 {
  font-size: 25px;
}
.dashboard-info .modal.custom-modal.show .modal-dialog {
  max-width: 30%;
}
.dashboard-info .modal .btn-primary {
  padding: 10px 25px;
  border-radius: 8px;
}
.dashboard-info table.dataTable thead .sorting:after,
.dashboard-info table.dataTable thead .sorting_asc:after,
.dashboard-info table.dataTable thead .sorting_desc:after,
.dashboard-info table.dataTable thead .sorting_asc_disabled:after,
.dashboard-info table.dataTable thead .sorting_desc_disabled:after {
  content: "\e92e";
  font-family: feather;
}
.dashboard-info table.dataTable thead .sorting:before,
.dashboard-info table.dataTable thead .sorting_asc:before,
.dashboard-info table.dataTable thead .sorting_desc:before,
.dashboard-info table.dataTable thead .sorting_asc_disabled:before,
.dashboard-info table.dataTable thead .sorting_desc_disabled:before {
  content: "\e931";
  font-family: feather;
}
.dashboard-info table.dataTable thead > tr > th.sorting:before,
.dashboard-info table.dataTable thead > tr > th.sorting:after,
.dashboard-info table.dataTable thead > tr > th.sorting_asc:before,
.dashboard-info table.dataTable thead > tr > th.sorting_asc:after,
.dashboard-info table.dataTable thead > tr > th.sorting_desc:before,
.dashboard-info table.dataTable thead > tr > th.sorting_desc:after,
.dashboard-info table.dataTable thead > tr > th.sorting_asc_disabled:before,
.dashboard-info table.dataTable thead > tr > th.sorting_asc_disabled:after,
.dashboard-info table.dataTable thead > tr > th.sorting_desc_disabled:before,
.dashboard-info table.dataTable thead > tr > th.sorting_desc_disabled:after,
.dashboard-info table.dataTable thead > tr > td.sorting:before,
.dashboard-info table.dataTable thead > tr > td.sorting:after,
.dashboard-info table.dataTable thead > tr > td.sorting_asc:before,
.dashboard-info table.dataTable thead > tr > td.sorting_asc:after,
.dashboard-info table.dataTable thead > tr > td.sorting_desc:before,
.dashboard-info table.dataTable thead > tr > td.sorting_desc:after,
.dashboard-info table.dataTable thead > tr > td.sorting_asc_disabled:before,
.dashboard-info table.dataTable thead > tr > td.sorting_asc_disabled:after,
.dashboard-info table.dataTable thead > tr > td.sorting_desc_disabled:before,
.dashboard-info table.dataTable thead > tr > td.sorting_desc_disabled:after {
  opacity: 0.6;
}
.dashboard-info table.dataTable thead > tr > th.sorting_asc:before,
.dashboard-info table.dataTable thead > tr > th.sorting_desc:after,
.dashboard-info table.dataTable thead > tr > td.sorting_asc:before,
.dashboard-info table.dataTable thead > tr > td.sorting_desc:after {
  opacity: 1;
}

/*-----------------.
	27. Profile
-----------------------*/

.profile-content .dashboard-info .card-header,
.profile-content .dashboard-info .card-body {
  padding: 25px;
}
.profile-content .dashboard-info .card {
  padding: 0;
}
.profile-sidebar .card {
  padding: 0;
}
.profile-sidebar .card-header {
  background: #fff;
  padding: 20px;
  margin: 0;
}
.profile-sidebar .card-header h4 {
  margin: 0;
  color: #374b5c;
}
.profile-sidebar .card-body {
  padding: 20px;
}
.profile-sidebar .card-body .col-form-label {
  padding-top: 0;
  padding-bottom: 10px;
  color: #717171;
}
.profile-sidebar .group-img input {
  padding: 6px 15px 6px 38px;
}
.profile-sidebar .card-body .lock-icon i {
  color: #c10037;
}
.profile-sidebar .card-body input {
  color: #717171;
  min-height: 52px;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
}
.profile-sidebar .card-body .btn-primary {
  font-weight: 400;
  padding: 10px;
  display: block;
  width: 100%;
}
.profile-photo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile-img {
  display: flex;
  align-items: center;
}
.settings-upload-img,
.settings-upload-img img {
  border-radius: 50%;
  width: 120px;
  height: 120px;
}
.profile-img-del,
.profile-img-del:hover {
  background: #c10037;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.profile-form {
  margin: 25px 0 0;
}
.profile-form label {
  color: #717171;
  margin: 0;
  margin: 0 0 15px;
  padding: 0;
}
.profile-form input,
.profile-form input:focus {
  color: #717171;
  border: 1px solid #e3e3e3;
  min-height: 52px;
  border-radius: 8px;
}
.profile-form .group-img i {
  color: #c10037;
  top: 16px;
  width: 20px;
  height: 20px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-form textarea.form-control {
  border: 1px solid #e3e3e3;
}
.profile-form .form-group {
  margin-bottom: 25px;
}
.profile-form .group-img input {
  padding: 6px 15px 6px 40px;
}
textarea.form-control {
  background: #f9fafc;
  color: #717171;
  font-size: 15px;
  padding: 10px;
}
.settings-upload-btn .hide-input {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.settings-upload-btn {
  position: relative;
  display: inline-block;
  margin: 0 0 0 20px;
}
.settings-upload-btn .file-upload {
  color: #fff;
  background: #c10037;
  border: 1px solid #c10037;
  box-shadow: inset 0 0 0 0 #fff;
  border-radius: 10px;
  padding: 12px 35px;
  font-size: 16px;
  margin: 0 20px 0 0;
  cursor: pointer;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
  font-weight: 700;
}
.profile-photo span {
  font-size: 16px;
  color: #666666;
}

/*-----------------.
	28. Messages
-----------------------*/

.chat-search input {
  border-radius: 10px;
  background: #f1f1f1;
  color: #374b5c;
  min-height: 50px;
  border: 1px solid #d5d5d5;
  box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.06),
    0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
}
.chat-search .group-img img {
  position: absolute;
  top: 17px;
  left: 10px;
}
.chat-cont-left .avatar-away::before,
.chat-cont-left .avatar-offline::before,
.chat-cont-left .avatar-online::before {
  top: 0;
}
.chat-cont-left .avatar-away::before {
  background-color: #d5d5d5;
}
.chat-cont-left {
  background: #fff;
  padding: 25px;
  border-radius: 10px;
  position: relative;
  z-index: 4;
  left: 0;
  transition: left 0.5s ease-in-out 0s, right 0.5s ease-in-out 0s;
}
.chat-window {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
}
.chat-cont-right .chat-header .back-user-list {
  display: none;
  margin-right: 20px;
  margin-left: -7px;
}
.chat-cont-right {
  position: relative;
  z-index: 4;
}
.chat-window .chat-cont-right .chat-scroll {
  min-height: 300px;
  max-height: calc(100vh - 224px);
  overflow-y: auto;
}
.chat-users-list .chat-scroll a.media .media-img-wrap {
  margin-right: 15px;
  position: relative;
}
.chat-users-list .chat-scroll a.media {
  transition: all 0.2s ease 0s;
}
.chat-users-list .chat-scroll a.media .media-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.chat-users-list {
  max-height: calc(100vh - 92px);
  overflow: auto;
}
.chat-users-list .chat-scroll a.media .media-body > div:last-child {
  text-align: right;
}
.chat-cont-left .chat-users-list a.media:hover {
  background-color: #f5f5f6;
}
.chat-cont-left .chat-users-list a.media {
  border: 1px solid #f1f1f1;
  padding: 20px;
  transition: all 0.2s ease 0s;
  border-radius: 10px;
  margin: 0 0 15px;
}
.chat-cont-left .chat-users-list a.media.active {
  background: #f1f1f1;
}
.chat-users-list a.media .user-name {
  color: #0d233e;
  margin: 0 0 4px;
}
.chat-users-list a.media .user-last-chat {
  font-size: 12px;
  color: #666;
}
.chat-users-list .last-chat-time,
.last-chat-time.block {
  font-size: 12px;
  color: #666;
}
.badge.badge-success {
  background: #374b5c;
  font-size: 10px;
  font-weight: 700;
  width: 24px;
  border-radius: 50px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px 0 0 0;
}
.chat-cont-right .chat-header {
  border-bottom: 1px solid #e1e1e1;
  padding: 25px;
}
.chat-cont-right {
  background: #fff;
  border-radius: 10px;
}
.chat-cont-right .chat-body {
  padding: 25px;
}
.media-img-wrap {
  margin: 0 15px 0 0;
}
.chat-cont-right .media .user-name {
  color: #0d233e;
  font-weight: 600;
}
.chat-body .msg-box > div {
  background: #f9fafc;
  padding: 15px;
  border-radius: 10px 10px 0 0;
  margin: 0 0 20px 12px;
  display: inline-block;
  position: relative;
}
.chat-body .msg-box p {
  color: #0d233e;
  margin: 0;
}
.chat-msg-attachments {
  padding: 4px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 0 -1px;
}
.chat-msg-attachments > div {
  margin: 0 10px 0 0;
}
.chat-body .msg-box .chat-msg-info {
  margin: 12px 0 0 0;
  font-size: 12px;
  color: #646464;
}
.chat-attachment {
  position: relative;
  max-width: 130px;
  overflow: hidden;
  border-radius: 10px;
}
.chat-attachment:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  content: "";
  opacity: 0;
  transition: all 0.4s;
}
.chat-attach-download,
.chat-attach-download:hover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.4s;
  color: #fff;
  width: 32px;
  line-height: 32px;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
}
.chat-attachment:hover:before {
  opacity: 0.6;
}
.chat-attachment:hover .chat-attach-download {
  opacity: 1;
}
.chat-cont-right .chat-body .media.sent .media-body {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  margin-left: 0;
}
.chat-body .media.sent .msg-box > div {
  background: #c10037;
  color: #fff;
}
.chat-body .media.sent .msg-box p {
  color: #fff;
}
.chat-body .media.sent .msg-box .chat-msg-info li {
  text-align: right;
  color: #fff;
}
.chat-msg-attachments > div:last-child {
  margin: 0;
}
.chat-cont-right .chat-footer {
  background-color: #fff;
  border-top: 1px solid #f0f0f0;
  padding: 25px;
  position: relative;
  border-radius: 10px;
}
.chat-cont-right .btn-file input {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 23px;
  height: 100%;
  margin: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.chat-footer .attach-btn {
  width: 90%;
  position: relative;
  margin: 0 15px 0 0;
}
.chat-footer .attach-btn input {
  background: #f9fafc;
  border-radius: 10px;
  min-height: 50px;
  border: 1px solid #f1f1f1;
  padding: 13px;
}
.chat-footer .btn-file {
  position: absolute;
  right: 4px;
  top: 5px;
}
.chat-footer .msg-send-btn {
  background: #c10037;
  border-radius: 10px;
  color: #fff;
  width: 50px;
  margin: 0 0 0 12px;
  border-color: #c10037;
  height: 50px;
}
.chat-footer .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.messages-form input {
  background: none;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  min-height: 52px;
}
.social-info input {
  background: #fff;
}
.listing-map {
  margin: 20px 0;
}
.messages-form .group-img i {
  color: #c10037;
  top: 18px;
  width: 20px;
  height: 20px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.messages-form .group-img input {
  padding: 6px 15px 6px 40px;
}
.messages-form .col-form-label span {
  color: #c10037;
}
.messages-form .col-form-label {
  margin: 0 0 15px;
  padding: 0;
  font-weight: 500;
  color: #666;
}
.messages-form .card-header {
  background: none;
  border: none;
  padding: 0;
  font-size: 20px;
}
.messages-form .card {
  padding: 30px;
  border: 0;
}
.messages-form .listingdescription {
  min-height: 150px;
}
.messages-form .form-group input::placeholder,
.messages-form .form-group textarea::placeholder {
  color: #8d8d8d;
}
.messages-form textarea .tagline {
  min-height: 90px;
}
.messages-form textarea,
.messages-form textarea:focus,
.messages-form input:focus {
  border: 1px solid #e3e3e3;
  background: none;
}
.messages-form textarea.tagline {
  background: #f9fafc;
}
.messages-form .custom_check input {
  margin: 0 5px 0 0;
}
.messages-form .label-heading {
  font-size: 20px;
  margin: 0 0 15px 0px;
  color: #374b5c;
  font-weight: 700;
}
.messages-form .card-header h4 {
  margin: 0 0 20px;
  color: #0d233e;
}
.messages-form .form-group {
  margin: 0 0 20px;
}
.messages-form .form-group.formlast-input {
  margin: 0px;
}
.messages-form .card-body {
  padding: 0;
}
.messages-form .select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  height: 52px;
}
.messages-form
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #666;
  line-height: 50px;
  padding-left: 15px;
  padding-right: 25px;
  min-width: 172px;
}
.messages-form
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  position: absolute;
  top: 55%;
  left: 50%;
  border-color: #666;
  border-style: solid;
  border-width: 0 2px 2px 0;
  padding: 3px;
  height: 0;
  margin-left: -10px;
  margin-top: -3px;
  width: 0;
  transform: rotate(45deg) translateY(-50%);
  -webkit-transform: rotate(45deg) translateY(-50%);
  -ms-transform: rotate(45deg) translateY(-50%);
}
.messages-form
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 47px;
  right: 6px;
}
.featuresform-list ul li {
  width: 50%;
  float: left;
}
.profile-form .form-group.formlast-input {
  margin-bottom: 0;
}
.social-info .group-img i {
  color: #c10037;
}
.messages-form .social-info .group-img i {
  top: 18px;
}
.media-section .media-image {
  padding: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  margin: 0px 0 30px;
}
.media-section .media-image img {
  border-radius: 10px;
  width: 190px;
  height: 190px;
}
.media-section .settings-upload-btn {
  margin: 0;
}
.media-section .settings-upload-btn .file-upload {
  background: #374b5c;
  border: 1px solid #374b5c;
  font-weight: 400;
  margin: 0;
  cursor: pointer;
}
.listing-map iframe {
  width: 100%;
}
.media-section .settings-upload-btn .file-upload:hover {
  border: 1px solid #374b5c;
  color: #374b5c;
  background: #fff;
}
.media-section .gallery-media {
  margin: 30px 0 0;
}
.gallery-upload {
  position: relative;
  margin: 0 20px 0 0;
}
.gallery-upload img {
  border-radius: 10px;
}
.card.media-section {
  margin: 0;
}
.galleryimg-upload {
  display: flex;
  margin: 0 0 30px;
}
.gallery-upload .profile-img-del {
  position: absolute;
  top: 15px;
  right: 15px;
}
.gallery-upload:last-child {
  margin: 0;
}
.media-section .media-title {
  font-weight: 700;
  color: #374b5c;
  margin: 0 0 25px;
}
.messages-form .btn-primary {
  margin: 20px 0 0;
  font-size: 16px;
  font-weight: 700;
  padding: 17px 30px;
  min-width: 283px;
}

.bookmarks-content.featured-slider.grid-view .card {
  margin-bottom: 25px;
}
.password-form {
  text-align: center;
}
.password-form h3 {
  font-size: 30px;
}
.password-form form {
  margin: 0 0 20px;
}
.login-wrap.password-form .login-btn {
  margin-top: 0px;
}

/*-----------------.
	29. Home Two
-----------------------*/

.home-banner-two {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.home-banner-two .banner-content {
  text-align: center;
  padding-top: 95px;
}
.home-banner-two h1 {
  color: #fff;
  font-weight: 700;
  margin-bottom: 15px;
}
.home-banner-two p {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
}
.banner-section-two {
  background: #fff;
  padding: 0;
}
.banner-section-two .search-box {
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 20px;
  margin-top: 20px;
}
.banner-section-two .search-box label {
  font-size: 14px;
  color: #999999;
  font-size: 14px;
  margin-bottom: 0;
}
.banner-section-two
  .search-box
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #374b5c;
  line-height: 25px;
  padding-left: 0;
  min-width: 180px;
}
.banner-section-two
  .search-box
  .select2-container--default
  .select2-selection--single {
  height: 25px;
  font-weight: 500;
  border: 0;
  min-width: 180px;
}
.banner-section-two
  .search-box
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 25px;
  right: 0;
  top: -1px;
}
.banner-section-two
  .search-box
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #374b5c;
}
.banner-section-two .search-icon {
  width: 48px;
  height: 48px;
  background: #f2f2f2;
  border-radius: 50%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 15px;
}
.banner-section-two .search-box .search-input {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 33.33%;
  border-right: 1px solid #d9d9d9;
  padding-right: 15px;
}
.banner-section-two .search-box .search-input:last-child {
  border: 0;
}
.banner-section-two .search-box .form-group {
  min-width: 180px;
  width: 100%;
}
.banner-section-two .search-btn .btn {
  padding: 11px 28px;
  min-width: 230px;
}
.searchbox-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: calc(100% - 230px);
}
.section-heading-two {
  margin-bottom: 50px;
}
.section-heading-two p {
  color: #374b5c;
  font-weight: 500;
}
.section-heading-two h2 {
  font-weight: 700;
  font-size: 36px;
}
.popular-section {
  background: url(../img/bg/popular-bg.png) no-repeat;
  background-position: right center;
  background-size: contain;
  padding: 70px 0;
}
.popular-wrap {
  background: #ffffff;
  border: 1px solid #e5e4f1;
  border-radius: 15px;
  padding: 15px;
  position: relative;
  margin: 38px 0 50px;
}
.popular-img {
  position: relative;
}
.popular-wrap img {
  border-radius: 15px;
}
.popular-wrap a {
  position: absolute;
  bottom: 20px;
  left: 15px;
  right: 15px;
  border: 1px solid rgba(255, 255, 255, 0.9);
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  color: #c10037;
  font-weight: 500;
  padding: 12px 10px;
  display: block;
  box-shadow: inset 0 0 0 #c10037;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.popular-wrap a:hover {
  border: 1px solid #c10037;
  background: #c10037;
  color: #fff;
  box-shadow: inset 0 70px 0 0 #c10037;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.popular-wrap:before {
  content: "";
  background: url(../img/bg/search-bg.png) no-repeat;
  position: absolute;
  top: -38px;
  left: 50%;
  transform: translateX(-50%);
  width: 131px;
  height: 90px;
  z-index: -1;
}
.owl-dots {
  text-align: center;
}
.owl-dots .owl-dot {
  margin-right: 6px;
}
.owl-dots .owl-dot.active span {
  background: #c10037;
}
.owl-dots .owl-dot span {
  width: 9px;
  height: 9px;
  line-height: 9px;
  margin: 0;
  background: #000;
  border-radius: 50%;
  display: block;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.celebrate-section {
  background: url(../img/bg/celebrate-bg.png) no-repeat;
  background-position: right bottom;
  background-size: cover;
  padding: 0 0 70px;
}
.celebrate-wrap {
  background: #fff;
  border: 1px solid #e5e4f1;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 50px;
  position: relative;
}
.celebrate-wrap:before {
  content: "";
  background: url(../img/bg/celebrate-img.png) no-repeat;
  position: absolute;
  bottom: -109px;
  right: -94px;
  width: 330px;
  height: 317px;
  z-index: 9;
}
.celebrate-img {
  position: relative;
}
.celebrate-img img {
  border-radius: 15px;
}
.celebrate-img .play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #c10037;
  border-radius: 50px;
  width: 70px;
  height: 70px;
  color: #fff;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
}
.play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #c10037;
  border-radius: 50px;
}
.animate-circle {
  -webkit-animation: heartbit 1s ease-out;
  animation: heartbit 3s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border: 20px solid #c10037;
  border-radius: 50%;
  height: 100px;
  position: absolute;
  left: -50px;
  top: -50px;
  width: 100px;
  z-index: -1;
}
@keyframes heartbit {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  25% {
    transform: scale(0.1);
    opacity: 0.1;
  }
  50% {
    transform: scale(0.5);
    opacity: 0.3;
  }
  75% {
    transform: scale(0.8);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
.wedding-section {
  background: url(../img/bg/wedding-bg.png) no-repeat;
  background-position: left center;
  background-size: contain;
  padding: 70px 0 56px;
}
.wedding-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
}
.wedding-img {
  flex: 0 0 197px;
  margin-right: 24px;
}
.wedding-img.align-right {
  margin-right: 0;
  margin-left: 24px;
}
.wedding-img img {
  border-radius: 15px;
  flex-shrink: 0;
}
.wedding-content h6 {
  font-size: 18px;
}
.wedding-content h6 a {
  color: #0d233e;
}
.wedding-content h6 a:hover {
  color: #c10037;
}
.wedding-content p {
  font-size: 14px;
  color: #666;
}
.wedding-content a {
  font-size: 16px;
  font-weight: 500;
  color: #c10037;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.wedding-content a i {
  margin-left: 3px;
}
.wedding-content a:hover {
  letter-spacing: 0.5px;
}
.appointment-sec {
  background: url(../img/bg/appointment-bg-01.png) no-repeat;
  background-size: contain;
  background-position: bottom;
}
.wedding-form {
  max-width: 634px;
  width: 100%;
  background: url(../img/bg/appointment-bg.png) no-repeat;
  background-size: cover;
  padding: 170px 100px 36px;
  margin: 0 auto;
}
.wedding-form .select2-container--default .select2-selection--single {
  border-radius: 10px;
}
.wedding-form
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  font-size: 15px;
}
.wedding-form .form-group {
  margin-bottom: 20px;
}
.wedding-form .form-control {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  background: #fff;
}
.wedding-form textarea.form-control {
  padding: 10px 15px;
}
.wedding-form .form-control::placeholder {
  color: #000;
}
.wedding-form .submit-section {
  margin-top: 30px;
}
.wedding-form .btn {
  font-weight: 500;
  padding: 11px 34px;
  width: 100%;
}
.vendor-section {
  padding: 70px 0;
}
.vendor-sec {
  background: #c10037;
  background-image: url(../img/bg/vector-bg-01.png),
    url(../img/bg/vector-bg-02.png);
  background-repeat: no-repeat;
  background-position: left 20px top 10px, right 20px top 10px;
  border-radius: 15px;
  padding: 45px;
}
.vendor-info {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.vendor-info h3 {
  font-size: 28px;
  color: #fff;
  margin-bottom: 20px;
}
.vendor-info .btn {
  background: #374b5c;
  border-radius: 10px;
  color: #fff;
  padding: 11px 27px;
}
.btn-grey {
  background: #374b5c;
  border: 1px solid #374b5c;
  box-shadow: inset 0 0 0 #fff;
  border-radius: 10px;
  color: #fff;
  font-weight: 500;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-grey:hover {
  background: #fff;
  border: 1px solid #fff;
  border-radius: 10px;
  color: #374b5c;
  box-shadow: inset 0 70px 0 0 #fff;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.blog-section-two {
  padding: 70px 0;
  background: url(../img/bg/venue-bg.png) no-repeat;
  background-position: right center;
  background-size: contain;
}
.blog-two {
  background: #fff;
  border: 1px solid #e5e4f1;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 50px;
}
.blog-two .blog-image {
  border-radius: 15px 15px 0px 0px;
  margin-bottom: 10px;
}
.blog-two .blog-image img {
  border-radius: 15px 15px 0px 0px;
}
.blog-two .blog-title a {
  font-size: 18px;
  color: #0d233e;
}
.blog-two .blog-content {
  padding: 0;
}
.blog-two .blog-description {
  height: unset;
  margin-bottom: 12px;
}
.blog-two .blog-content .viewlink a {
  font-weight: 500;
  color: #c10037;
}
.blog-two .blog-content .viewlink a:hover {
  color: #374b5c;
}
.blog-two .entry-meta {
  margin-bottom: 0px;
}
.blog-two .entry-meta li.date-icon {
  font-size: 16px;
  color: #999999;
}
.blog-two .entry-meta li.blog-cat {
  font-size: 14px;
  color: #c10037;
}
.portfolio-wrap {
  margin-bottom: 50px;
}
.port-wrap {
  padding: 8px 8px 0;
}
.feedback-section {
  background: url(../img/bg/wedding-bg.png) no-repeat;
  background-position: left center;
  background-size: contain;
}
.feedback-slider {
  background: url("../img/bg/map.png") no-repeat;
  background-position: center;
}
.testimonial-item {
  margin-bottom: 50px;
}
.testimonial-content {
  padding: 30px 50px;
  position: relative;
  text-align: center;
  margin: 25px 0 35px;
}
.testimonial-content:before {
  content: "";
  position: absolute;
  background: #fff;
  border: 1px dashed #f9b6c9;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  top: 1%;
  bottom: -12%;
  left: 8%;
  right: 7%;
  z-index: -1;
  -webkit-transform: perspective(22em) rotateX(-30deg);
  transform: perspective(22em) rotateX(-30deg);
}
.testimonial-content:after {
  content: "";
  position: absolute;
  border-left: 32px solid transparent;
  border-bottom: 25px solid #fff;
  width: 32px;
  height: 25px;
  left: 60px;
  bottom: -23px;
  transform: rotateX(160deg);
}
.testimonial-content p {
  font-size: 14px;
  color: #000;
  margin-bottom: 0;
}
.testi-icon {
  width: 48px;
  height: 48px;
  background: #c10037;
  border-radius: 100px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 20px;
  position: absolute;
  top: -25px;
  left: 30px;
}
.testimonial-user-info {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
}
.testimonial-user-info img {
  width: 50px !important;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}
.testimonial-user h6 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 2px;
}
.testimonial-user p {
  font-size: 14px;
  color: #999;
  margin-bottom: 2px;
}
.categories-section {
  background: url(../img/bg/venue.png) no-repeat;
  background-position: right bottom;
  background-size: 400px;
  padding: 0 0 46px;
}
.category-item {
  background: #ffffff;
  border: 1px solid #e5e4f1;
  border-radius: 15px;
  padding: 15px;
  position: relative;
  margin-bottom: 24px;
}
.category-img {
  position: relative;
}
.category-img img {
  border-radius: 15px;
  width: 100%;
}
.category-item a {
  position: absolute;
  bottom: 20px;
  left: 15px;
  right: 15px;
  border: 1px solid rgba(255, 255, 255, 0.9);
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  color: #c10037;
  font-weight: 500;
  padding: 12px 15px;
  display: block;
  box-shadow: inset 0 0 0 #c10037;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.category-item a:hover {
  border: 1px solid #c10037;
  background: #c10037;
  color: #fff;
  box-shadow: inset 0 70px 0 0 #c10037;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.portfolio-section {
  background: url(../img/bg/portfolio-bg.jpg) no-repeat;
  background-size: cover;
  padding: 70px 0;
}
.portfolio-item {
  padding: 0 0 8px;
  background: #fff;
  position: relative;
}
.portfolio-item:hover .portfolio-overlay-item {
  opacity: 1;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
}
.portfolio-img img {
  width: 100%;
}
.portfolio-overlay-item {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
}
.portfolio-overlay {
  background: rgba(255, 255, 255, 0.75);
  text-align: center;
  width: calc(100% - 10px);
  height: calc(100% - 18px);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5px 5px 0;
}
.portfolio-overlay h6 {
  font-weight: 600;
  font-size: 18px;
}
.portfolio-overlay p {
  color: #666;
}
.section-heading-two.white-text p,
.section-heading-two.white-text h2 {
  color: #fff;
}
.package-section {
  padding: 70px 0;
  background: url("../img/bg/package-bg-01.png"),
    url("../img/bg/package-bg-02.png");
  background-repeat: no-repeat;
  background-position: left top, right bottom;
  background-size: 400px;
  position: relative;
}
.package-section::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #c10037 0%, #a30834 100%);
  top: 0;
  left: 0;
  z-index: -1;
}
.package-wrap {
  background: url(../img/bg/package-bg.png) no-repeat;
  background-size: 100% 100%;
  padding: 80px 70px;
  position: relative;
  margin-top: 100px;
}
.couple-img {
  position: absolute;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
.package-wrap .section-heading-two {
  margin-bottom: 30px;
}
.package-wrap .section-heading-two h2 {
  font-size: 28px;
  font-weight: 600;
}
.package-wrap .section-heading-two img {
  width: 240px;
}
.package-wrap ul {
  margin-bottom: 30px;
}
.package-wrap ul li {
  font-weight: 500;
  text-align: center;
  margin-bottom: 25px;
}
.package-wrap ul li:last-child {
  margin-bottom: 0;
}
.package-wrap .btn {
  width: 100%;
  font-weight: 600;
  padding: 11px 34px;
}
.success-section {
  background: url(../img/bg/wedding-bg.png), url(../img/bg/venue-bg.png);
  background-position: left center, right center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 70px 0;
}
.success-sec {
  background: #fff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 24px;
}
.success-item img {
  margin-bottom: 15px;
}
.success-item h6 {
  font-weight: 600;
  font-size: 18px;
}
.success-item p {
  color: #666;
  margin-bottom: 0;
}
.offer-section {
  padding: 70px 0;
}
.offer-sec {
  background: url(../img/bg/celebration-bg.png) #f3ebeb;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: cover;
  border-radius: 15px;
  padding: 50px;
  position: relative;
  text-align: center;
}
.offer-img-left img {
  position: absolute;
  left: 20px;
  bottom: 0;
}
.offer-img-right img {
  position: absolute;
  right: 20px;
  top: 20px;
}
.offer-sec img {
  margin-bottom: 5px;
}
.offer-sec h3 {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 20px;
}
.offer-sec p {
  font-size: 14px;
  color: #fff;
}
.offer-sec .btn {
  padding: 10px 32px;
}
.venue-section {
  background: url(../img/bg/venue-bg.png) no-repeat;
  background-position: right center;
  background-size: contain;
  padding: 0 0 70px;
}
.venue-item {
  background: #fff;
  border: 1px solid #e5e4f1;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 50px;
}
.venue-image {
  overflow: hidden;
  border-radius: 15px 15px 0px 0px;
  margin-bottom: 15px;
}
.venue-image img {
  border-radius: 15px 15px 0px 0px;
}
.venue-content h3 {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
}
.venue-content h3 a {
  color: #0d233e;
}
.venue-content h3 a:hover {
  color: #c10037;
}
.venue-content p {
  font-weight: 500;
  color: #999;
  margin-bottom: 10px;
}
.price-review {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}
.price-review p {
  font-weight: 600;
  font-size: 18px;
  color: #c10037;
  margin-bottom: 20px;
}
.price-review h6 {
  font-size: 14px;
  color: #666;
  font-weight: 400;
  margin-bottom: 20px;
}
.price-review h6 span {
  background: #c10037;
  border-radius: 100px;
  font-size: 14px;
  color: #fff;
  padding: 10px 13px;
  margin-right: 10px;
  display: inline-block;
}
.venue-content ul {
  border: 1px solid #e5e4f1;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}
.venue-content ul li {
  text-align: center;
  display: inline-block;
  width: 32.5%;
  border-right: 1px solid #e5e4f1;
  padding: 10px;
}
.venue-content ul li:last-child {
  border: 0;
}
.venue-content ul li span {
  background: #f9b6c9;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  color: #fff;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  margin: 0 auto 10px;
}
.venue-content ul li p {
  font-size: 14px;
  color: #0d233e;
  margin-bottom: 0;
}
.timeline-section {
  padding: 70px 0;
}
.timeline-sec {
  padding: 50px 0;
  background: linear-gradient(
    270deg,
    rgba(255, 215, 222, 0) 0%,
    rgba(249, 117, 155, 0.15) 45.77%,
    rgba(255, 215, 222, 0) 97.65%
  );
}
.timeline-wrap li {
  position: relative;
}
.timeline-wrap li:before {
  position: absolute;
  content: "";
  background: url(../img/bg/line-bg.png) no-repeat;
  width: 76px;
  height: 15px;
  background-position: top center, left center;
  background-size: cover;
  top: 50%;
  left: 120px;
}
.timeline-wrap li:last-child:before {
  content: none;
}
.time-box {
  width: 14.8%;
  position: relative;
  min-height: 310px;
  float: left;
}
.time-box:last-child {
  width: 11%;
}
.time-box .date {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  width: 120px;
  height: 120px;
  border-radius: 100%;
  background: #fff;
  border: 1px solid #c10037;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
}
.time-box .box-content {
  position: absolute;
  left: 60px;
  transform: translateX(-50%);
}
.box-content p {
  font-weight: 600;
  text-align: center;
}
.box-content p span {
  display: block;
  white-space: nowrap;
}
.box-bottom .box-content {
  top: 80%;
}
.box-content::before {
  content: " ";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 33px;
  border-left: 1px solid #ff823b;
}
.box-content::after {
  content: " ";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 9px;
  height: 9px;
  background: #c10037;
  border-radius: 50%;
}
.box-top .box-content p {
  margin-bottom: 20px;
}
.box-bottom .box-content p {
  margin-top: 15px;
}
.box-bottom .box-content::before {
  top: -33px;
}
.box-bottom .box-content::after {
  top: 0;
}
.box-top .box-content::before {
  bottom: -28px;
}
.box-top .box-content::after {
  bottom: 2px;
}
.selection-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: end;
}
.lang-select {
  position: relative;
  min-width: 100px;
}
.lang-select .select-icon {
  position: absolute;
  top: 55%;
  left: 6px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  z-index: 9;
  color: #fff;
}
.selection-list .select2-container {
  width: 95px !important;
}
.selection-list .select2-container--default .select2-selection--single {
  background-color: transparent;
  border: 0;
  height: 24px;
}
.selection-list
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 23px;
}
.selection-list
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 24px;
  right: 0px;
}
.selection-list
  .lang-select
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  right: 10px;
  top: 0;
}
.selection-list
  .currency-select
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  right: 25px;
  top: -1px;
}
.selection-list
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #fff;
}
.selection-list
  .select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #fff;
}
.lang-select
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  padding-left: 30px;
}
.selection-list
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #fff;
  font-size: 14px;
}

/*-----------------
	30. Select2
-----------------------*/

.select2-container .select2-selection--single {
  border: 1px solid #d9d9d9;
  height: 46px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 46px;
  right: 7px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #000;
  border-style: solid;
  border-width: 0 2px 2px 0;
  padding: 3px;
  height: 0;
  left: 50%;
  margin-left: -5px;
  margin-top: -5px;
  position: absolute;
  top: 50%;
  width: 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.select2-container--default.form-control-sm
  .select2-selection--single
  .select2-selection__arrow {
  top: 7px;
}
.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #000;
  border-width: 2px 0 0 2px;
  padding: 3px;
  margin-top: -2px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 15px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #000;
  font-size: 16px;
  font-weight: normal;
  line-height: 46px;
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #c10037;
  color: #fff;
}
.select2-container--default .select2-selection--multiple {
  border: 1px solid #000;
  min-height: 40px;
}
.select2-container--default .select2-selection--single {
  border-radius: 5px;
}

/*-----------------.
	31. Home 3
-----------------------*/

.body-three .select2-results__option {
  padding: 3px 15px;
  font-size: 14px !important;
}
.banner-section.banner-three {
  background: url("../img/banner-01.png") no-repeat 380px 0;
  background-position: top right;
  position: relative;
  padding-top: 86px;
  min-height: 800px;
}
.banner-section.banner-three .banner-info-blk {
  padding-top: 80px;
}
.banner-section.banner-three h1 {
  color: #374b5c;
  margin-bottom: 20px;
}
.banner-section.banner-three h5 {
  color: #666;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
}
.banner-btm {
  position: relative;
  background: #ffffff;
  border-radius: 100px;
  padding: 25px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  z-index: 4;
}
.banner-btm .search-group ul li {
  font-size: 16px;
  font-weight: 700;
}
.banner-btm .search-group > ul > li:not(:last-child) {
  border-right: 1px solid #d6d6d6;
}
.banner-btm .form-control {
  min-height: 20px;
  padding: 0px 15px;
  font-size: 14px;
}
.banner-btm .form-control .label {
  margin-bottom: 5px;
  display: inline-block;
}
.banner-btm .btn {
  font-weight: 700;
  color: #fff;
  min-width: 150px;
  justify-content: center;
  padding: 13px 12px;
}
.search-group > ul {
  display: flex;
  justify-content: end;
}
.datepicker-col > ul {
  justify-content: start;
}
.banner-btm .icon-blk {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eaecf3;
}
.banner-btm .icon-blk {
  color: #c10037 ;
}
.theme-btn {
  border: 1px solid #c10037;
  background: #c10037;
  border-radius: 100px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 190px;
  font-weight: 700;
  box-shadow: inset 0 0 0 0 #ffffff;
  color: #c10037;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.theme-btn:hover {
  border: 1px solid #c10037;
  background: #fff;
  color: #c10037;
  box-shadow: inset 0 70px 0 0 #ffffff;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.theme-btn:hover a {
  color: #c10037;
}
.theme-btn:hover a i {
  color: #fff;
}
.theme-btn input {
  color: #ffffff;
}
.theme-btn {
  position: relative;
  z-index: 1;
}
.theme-btn a {
  display: flex;
  align-items: center;
  padding: 8px 8px 8px 30px;
  column-gap: 10px;
  font-size: 16px;
  text-transform: capitalize;
  justify-content: space-between;
  color: #ffffff;
}
.theme-btn a p {
  color: #ffffff;
  margin: 0;
}
.theme-btn span {
  background: #374b5c;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.theme-btn span img {
  width: auto !important;
}
.banner-btm-img-blk {
  position: relative;
  bottom: -32px;
}
.banner-btm-img-blk {
  position: relative;
  bottom: -32px;
  z-index: -1;
}
.banner-btm-img-blk img {
  position: relative;
  z-index: 5;
}
.banner-btm-img-blk:before {
  position: absolute;
  content: "";
  background: url("../img/banner-btm-shape.png") no-repeat;
  width: 336px;
  height: 302px;
  top: 59px;
  left: 67px;
  z-index: -1;
}
.section-blk .title-blk {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}
.section-blk .title-blk h2 {
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
}
.section-blk .title-blk h2:before {
  position: absolute;
  content: "";
  background: url("../img/trip-icon.png") no-repeat top right;
  width: 55px;
  height: 32px;
  right: -35px;
  top: 20px;
}
.section-blk .title-blk h5 {
  width: 100%;
  color: #374b5c;
  font-weight: 400;
  margin: 0;
  font-size: 16px;
}
.destination-blk {
  position: relative;
}
.destination-blk:before {
  position: absolute;
  content: "";
  background: url(../img/balloon-img.png) no-repeat center left;
  width: 125px;
  height: 182px;
  top: 140px;
  left: 0px;
}
.destination-widget a.desti-link {
  display: block;
  min-height: 450px;
}
.destination-widget a.desti-link img {
  width: 100%;
  height: 450px;
  object-fit: cover;
  border-radius: 30px;
}
.destination-widget a.desti-link .desti-country-list {
  position: absolute;
  bottom: 0px;
  padding: 0px 14px 14px;
  width: 100%;
  display: block;
  text-align: center;
}
.destination-widget a.desti-link span {
  background: #fff;
  border-radius: 100px;
  font-size: 16px;
  line-height: 35px;
  color: #374b5c;
  display: block;
  width: 100%;
  font-weight: 700;
  text-transform: capitalize;
  padding: 10px 0px;
}
.overlay-blk {
  position: absolute;
  bottom: 0;
  right: 0px;
  width: 100%;
  color: #fff;
  text-align: center;
  padding: 14px;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
  border-radius: 30px;
  opacity: 0;
  transform: translateY(100px);
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
.destination-widget:hover .overlay-blk {
  opacity: 1;
  transform: translateY(0px);
}
.overlay-blk h3 {
  color: #fff;
  margin-bottom: 15px;
}
.overlay-blk p {
  color: #ffffff;
  font-size: 14px;
}
.overlay-blk .theme-btn {
  display: block;
  margin-top: 15px;
  box-shadow: none;
}
.overlay-blk .theme-btn:hover {
  border: 1px solid #c10037;
  background: #c10037;
  color: #fff;
  box-shadow: none;
}
.overlay-blk .theme-btn:hover a {
  color: #fff;
}
.destination-widget {
  position: relative;
}
.partners-section .partners-blk {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.partners-section .partners-blk .card-blk {
  background: #ffffff;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  min-height: 79px;
  display: flex;
  align-items: center;
  padding: 0px 14px;
}
.destination-carousel .owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 50px;
}
.section-blk.service-blk {
  background: url("../img/bg/service-bg-01.png"),
    url("../img/bg/service-bg-02.png");
  background-repeat: no-repeat;
  background-position: top left, bottom 80px right 50px;
  padding: 0 0 80px;
  margin: 0;
}
.section-blk .service-content {
  padding: 50px;
}
.section-blk .service-content .service-list {
  flex-wrap: wrap;
}
.section-blk .service-content .service-list li {
  margin-top: 10px;
}
.section-blk .service-content .service-list li:not(:last-child) {
  margin-right: 40px;
}
.section-blk .service-info-card {
  position: relative;
  align-items: flex-end;
  background: #ffffff;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
}
.section-blk .service-info-card .img-blk {
  position: relative;
  width: 400px;
  background: #c10037;
  border-radius: 30px 0 0 30px;
  text-align: center;
  padding: 43px 0;
}
.section-blk .service-info-card .img-blk img {
  bottom: 0px;
}
.section-blk .service-content h2 {
  display: inline-block;
  position: relative;
  color: #374b5c;
  margin-bottom: 30px;
}
.section-blk .service-content h2:before {
  position: absolute;
  content: "";
  background: url("../img/trip-icon.png") no-repeat top right;
  width: 55px;
  height: 32px;
  right: -35px;
  top: 20px;
}
.service-info-card .service-content .body-content {
  margin-bottom: 30px;
}
.service-info-card .service-content .body-content p {
  line-height: 27px;
}
.service-info-card .service-content .body-content p:not(:last-child) {
  margin-bottom: 15px;
}
.service-list .icon-blk {
  background: #c10037;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.service-list h6 {
  font-weight: 500;
  text-transform: capitalize;
  margin: 0;
}
.service-list p {
  font-size: 14px;
  color: #999999;
  margin: 0;
}
.section-blk {
  margin: 80px 0px;
}
.recommended-tabs-blk .tabs-style {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.recommended-widget .top-blk {
  text-align: center;
  text-transform: capitalize;
  position: absolute;
  bottom: 0px;
  left: 20px;
}
.recommended-widget .top-blk.top-rated-style p {
  background: #f5cd07;
}
.recommended-widget .top-blk.gray-bg-style p {
  background: #999999;
}
.recommended-widget .top-blk.bf-style p {
  background: #0f278a;
}
.recommended-widget .top-blk p {
  display: inline-block;
  background: #3554d1;
  color: white;
  padding: 10px 32px;
  border-radius: 15px 15px 0px 0px;
}
.recommended-widget .content-blk {
  background: #ffffff;
  box-shadow: -10px -15px 10px rgba(0, 0, 0, 0.05);
  border-radius: 0 0 30px 30px;
  padding: 20px;
  width: 100%;
}
.content-blk .detail-blk p {
  color: #0f278a;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}
.content-blk .detail-blk p span {
  font-size: 18px;
  font-weight: 500;
}
.content-blk .detail-blk .detail-btm-blk {
  display: flex;
  align-items: center;
}
.content-blk .detail-blk .detail-btm-blk .rating-badge {
  margin-right: 10px;
}
.content-blk .detail-blk .detail-btm-blk .rating-badge span {
  color: #ffaf14;
  margin-right: 5px;
  font-size: 20px;
}
.content-blk .detail-blk .detail-btm-blk small {
  font-size: 14px;
  color: #999999;
}
.offer-blk {
  position: relative;
  padding-top: 140px;
  margin-top: 0 !important;
}
.offer-blk:before {
  position: absolute;
  content: "";
  background: url(../img/offer-bg.png) no-repeat top center;
  width: 100%;
  height: 423px;
  top: 0px;
}
.offer-blk:after {
  position: absolute;
  content: "";
  background: url(../img/balloon-img.png) no-repeat center right;
  width: 125px;
  height: 182px;
  top: 140px;
  left: 0px;
  z-index: -1;
}
.box-style {
  position: relative;
  min-height: 450px;
  overflow: hidden;
  border-radius: 30px;
}
.box-style > img {
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.box-style .btm-box-style {
  background: rgb(13 35 62 / 80%);
  border-radius: 30px;
  color: white;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 30px;
  bottom: 0px;
  width: 100%;
}
.box-style .btm-box-style:before {
  background: url(../img/offer-arrow.svg) no-repeat center left;
  content: "";
  width: 100%;
  height: 80%;
  left: 7px;
  margin-top: -7px;
  background-size: contain;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.box-style .btm-box-style p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
}
.box-style .btm-box-style h3 {
  margin-bottom: 0;
  color: #fff;
  font-size: 24px;
}
.box-style .theme-btn {
  background: #fff;
  border-color: #fff;
  color: #374b5c;
  box-shadow: inset 0 0 0 0 #c10037;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.box-style .theme-btn a {
  width: 100%;
  font-weight: 700;
  color: #374b5c;
}
.box-style .theme-btn:hover {
  border: 1px solid #c10037;
  box-shadow: inset 0 70px 0 0 #c10037;
  color: #fff;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.box-style .theme-btn:hover a {
  color: #fff;
}
.box-style .theme-btn p {
  color: #000000;
}
.input-group-text {
  border: 0px solid transparent;
  background: transparent;
}
.detail-btm-blk .rating-blk span {
  font-size: 16px;
  color: #ffaf14;
}
.style2-blk .content-blk .detail-blk .detail-btm-blk {
  justify-content: space-between;
}
.detail-blk .tags-blk {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.detail-blk .exp-style {
  color: #000000 !important;
}
.detail-blk .tags-blk a {
  background: #f1f1f1;
  padding: 2px 8px;
  color: #7f7f7f;
  border-radius: 100px;
  font-size: 14px;
}
.body-three .owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 50px;
}
.recommended-blk.style2-blk {
  background: url(../img/bg/recom-bg-01.png), url(../img/bg/recom-bg-02.png);
  background-repeat: no-repeat;
  background-position: left bottom, right bottom;
  background-size: contain, 125px;
}
.recommended-blk.style1-blk {
  background: url(../img/recomended-bg-btm.png) #f5fcff no-repeat center bottom;
  width: 100%;
  min-height: 300px;
  padding: 80px 0px;
  margin: 0;
}
.recommended-tabs-blk ul li a {
  background: #ffffff;
  border: 1px solid #e1f6ff;
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 9px 28px;
  color: #374b5c;
  font-size: 14px;
  text-transform: capitalize;
}
.recommended-tabs-blk ul li a span {
  margin-right: 10px;
}
.recommended-tabs-blk ul li a.active {
  border: 1px solid #c10037;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}
.recommended-tabs-blk .tabs-style {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.recommended-widget .top-blk {
  text-align: center;
  text-transform: capitalize;
  position: absolute;
  bottom: 0px;
  left: 20px;
}
.recommended-widget .top-blk.top-rated-style p {
  background: #f5cd07;
}
.recommended-widget .top-blk.gray-bg-style p {
  background: #999999;
}
.recommended-widget .top-blk.bf-style p {
  background: #ffba08;
}
.recommended-widget .top-blk p {
  display: inline-block;
  background: #c10037;
  color: #fff;
  padding: 8px 15px;
  border-radius: 15px 15px 0px 0px;
}
.recommended-widget .img-blk {
  position: relative;
  border-radius: 30px 30px 0 0;
  overflow: hidden;
}
.recommended-widget {
  border-radius: 30px;
  box-shadow: 0px 4.4px 20px -1px rgba(19, 16, 34, 0.05),
    0px 4px 9px -1px rgba(19, 16, 34, 0.03);
  margin-bottom: 20px;
}
.recommended-widget .content-blk {
  background: #ffffff;
  border-radius: 0 0 30px 30px;
  padding: 20px;
  width: 100%;
}
.recommended-widget .content-blk > p {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 20px;
  font-weight: 600;
  color: #374b5c;
  margin-bottom: 10px;
}
.recommended-widget .content-blk > p a {
  color: #374b5c;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.recommended-widget .content-blk > p a:hover {
  color: #c10037;
}
.recommended-widget .content-blk .loc-blk {
  font-size: 14px;
  color: #7f7f7f;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.recommended-widget .content-blk .loc-blk img,
.recommended-widget .content-blk .loc-blk i {
  width: auto;
  margin-right: 8px;
}
.recommended-widget .content-blk .loc-blk span,
.recommended-widget .content-blk .loc-blk p {
  color: #999999;
}
.recommended-widget .content-blk .detail-blk p {
  color: #374b5c;
  font-weight: 600;
}
.recommended-widget .content-blk .detail-blk p span {
  color: #7f7f7f;
  font-size: 14px;
  font-weight: 400;
}
.content-blk .detail-blk .detail-btm-blk .rating-badge {
  background: #c10037;
  color: white;
  display: inline-flex;
  font-size: 14px;
  border-radius: 100px;
  align-items: center;
  padding: 6px 10px;
}
.content-blk .detail-blk p {
  color: #374b5c;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}
.content-blk .detail-blk p span {
  font-size: 18px;
  font-weight: 600;
  color: #c10037;
}
.content-blk .detail-blk .detail-btm-blk {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.content-blk .detail-blk .detail-btm-blk .rating-badge {
  margin-right: 10px;
}
.content-blk .detail-blk .detail-btm-blk .rating-badge span {
  color: #ffaf14;
  margin-right: 5px;
  font-size: 20px;
}
.content-blk .detail-blk .detail-btm-blk small {
  font-size: 14px;
  color: #999999;
}
.recommended-tabs-blk {
  margin-bottom: 30px;
}
.blog-widget .img-blk {
  max-height: 260px;
  border-radius: 30px;
  overflow: hidden;
}
.blog-widget .content-blk {
  padding: 20px 0 0;
}
.blog-widget .content-blk p {
  font-size: 18px;
  font-weight: 600;
  color: #374b5c;
  margin-bottom: 10px;
}
.blog-widget .content-blk p a {
  color: #374b5c;
}
.blog-widget .content-blk p a:hover {
  color: #c10037;
}
.blog-widget .content-blk .date-blk p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  margin-bottom: 0;
}
.testi-blk {
  background: url("../img/testimonial-banner-bg.jpg") no-repeat center top;
  width: 100%;
  position: relative;
  height: 100%;
  min-height: 390px;
  margin-top: 80px;
}
.testi-blk:before {
  background: url(../img/testi-after-img.png) no-repeat center left;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: -140px;
  left: 0;
}
.testi-blk .theme-btn a {
  width: 100%;
  font-weight: 700;
}
.testi-blk .title-blk h2,
.testi-blk .title-blk h5 {
  color: white;
}
.testi-blk .btn-style2 a {
  background: #eec304;
  color: white;
}
.testi-blk .btn-style2 a .shape-bg {
  /* background: url(../img/search-bg-2.png) no-repeat center right; */
  color: #eec304;
}
.testi-blk .title-blk {
  margin-top: 80px;
  margin-bottom: 32px;
  position: relative;
}
.testi-widget {
  background: #ffffff;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  padding: 30px;
  margin-bottom: 10px;
}
.testi-widget h4 {
  font-size: 20px;
  color: #374b5c;
  margin-bottom: 5px;
}
.testi-widget .icon-blk {
  width: 50px;
  height: 50px;
}
.testi-widget .icon-blk img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.testi-widget .testi-info-blk {
  text-align: center;
  position: relative;
  margin-top: 20px;
}
.testi-widget .testi-info-blk:before {
  position: absolute;
  content: "";
  background: url(../img/quote-before.png) no-repeat left top;
  width: 46px;
  height: 32px;
  top: 0;
  opacity: 0.05;
  left: 0;
}
.testi-widget .testi-info-blk:after {
  position: absolute;
  content: "";
  background: url(../img/quote-after.png) no-repeat left top;
  width: 46px;
  height: 32px;
  bottom: 0;
  opacity: 0.05;
  right: 0;
}
.custom-hr {
  color: #d6d6d6;
  margin: 20px 0;
}
.testi-widget .testi-btm-blk {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.testi-widget .testi-btm-blk .circle-grp {
  display: flex;
  align-items: center;
}
.testi-widget .testi-btm-blk .circle-grp img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
}
.testi-widget .testi-btm-blk .circle-grp img:not(:first-child) {
  margin-left: -20px;
}
.testi-widget .testi-btm-blk .circle-right img {
  object-fit: cover;
  height: 40px;
}
.faq-tabs-blk ul li a {
  background: #f6f6f6;
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 7px 23px;
  color: #000000;
  text-transform: capitalize;
}
.faq-tabs-blk ul li a.active {
  background: #c10037;
  color: white;
}
.faq-tabs-blk .tabs-style {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.faq-shadow-blk {
  background: #ffffff;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 24px;
}
.faq-shadow-blk .text-gray-style {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.faq-shadow-blk h5 {
  color: #374b5c;
  margin-bottom: 10px;
}
.faq-blk {
  background: #f5fcff;
  padding: 80px 0;
  margin: 0 !important;
  position: relative;
}
.faq-blk:before {
  position: absolute;
  content: "";
  background: url(../img/balloon-img.png) no-repeat center left;
  width: 125px;
  height: 182px;
  top: 140px;
  left: 20px;
}
.faq-blk .btm-style {
  margin-top: 50px;
  text-align: center;
}
.faq-blk .btm-style span {
  background: #c10037;
  border-radius: 100px;
  color: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  margin: 0px auto;
  justify-content: center;
}
.subscribe-style {
  background: #edeef1;
  overflow: hidden;
  border-radius: 30px;
}
.subscribe-blk {
  position: relative;
  border-radius: 100px;
}
.subscribe-blk:before {
  position: absolute;
  content: "";
  background: url(../img/services-bg.png) no-repeat center right;
  width: 320px;
  height: 399px;
  top: -86px;
  left: -120px;
  z-index: -1;
}
.subscribe-blk .banner-blk {
  background: #c10037;
}
.subscribe-blk .banner-blk img {
  width: 100%;
}
.subscribe-blk .banner-info {
  text-align: center;
}
.subscribe-blk .banner-info h2 {
  color: #c10037;
  margin-bottom: 10px;
}
.subscribe-blk .banner-info p {
  margin-bottom: 30px;
}
.subscribe-blk .input-group {
  padding: 12px 12px 12px 25px;
}
.subscribe-blk .input-group-text {
  color: #c10037;
  padding: 0 10px 0 0;
}
.subscribe-blk .form-control {
  border: 0px solid transparent;
  background: transparent;
  padding-left: 0;
  padding-right: 0;
  min-height: 48px;
}
.subscribe-btn-grp {
  background: #c10037;
  border: 1px solid #c10037;
  border-radius: 100px !important;
  color: white;
  padding: 5px 13px;
  box-shadow: inset 0 0 0 0 #ffffff;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.subscribe-btn-grp:hover {
  box-shadow: inset 0 70px 0 0 #ffffff;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.subscribe-btn-grp:hover input {
  color: #c10037;
}
.subscribe-btn-grp:hover img {
  filter: brightness(0) saturate(100%) invert(8%) sepia(79%) saturate(6939%)
    hue-rotate(337deg) brightness(104%) contrast(103%);
}
.subscribe-btn-grp input {
  color: #fff;
  border: 0;
}
.activities-blk .shadow-box {
  background: #ffffff;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.15);
  border-radius: 100px;
  padding: 7px;
  align-items: center;
}
.activities-blk .shadow-box .icon-blk img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}
.shadow-box h4 {
  font-size: 20px;
  color: #374b5c;
  margin-bottom: 5px;
}
.promo-style {
  background: url("../img/promo-banner-bg.jpg") no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 270px;
  border-radius: 30px;
  text-align: center;
  padding: 32px 0px;
}
.promo-style .flex-style {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: left;
  gap: 32px;
}
.promo-style h3 {
  color: white;
  margin-bottom: 15px;
}
.promo-style .list-style {
  display: inline-block;
}
.promo-style .list-style ul li {
  display: flex;
  align-items: center;
  gap: 15px;
  color: white;
  position: relative;
}
.mb-50 {
  margin-bottom: 50px;
}
.list-style ul li:not(:last-child) {
  margin-right: 23px;
}
.list-style ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.list-style ul li .count-style {
  position: relative;
  width: 35px;
  height: 35px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: black;
  background: #fff;
}
.list-style ul li .position-style {
  position: absolute;
  font-family: "Oleo Script", cursive;
  font-size: 38px;
  color: rgba(0, 0, 0, 0.1);
  left: 17px;
  bottom: -5px;
}
.btn-style2 {
  display: inline-block;
}
.btn-style2 a {
  background: #fff;
  border: 1px solid #fff;
  border-radius: 100px;
  padding: 12px 20px;
  color: #374b5c;
  font-weight: 700;
  padding: 6px 10px 6px 19px;
  display: flex;
  align-items: center;
  width: auto;
  box-shadow: inset 0 0 0 0 #c10037;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-style2 a:hover {
  border: 1px solid #fff;
  box-shadow: inset 0 70px 0 0 #c10037;
  color: #fff;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.btn-style2 a .text-style {
  margin-right: 14px;
  font-size: 18px;
  font-weight: 500;
}
.btn-style2 a .shape-bg {
  background: #374b5c;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.row-gap-14 {
  row-gap: 14px;
}
.price-section {
  background: url("../img/bg/recom-bg-01.png"), url("../img/bg/recom-bg-02.png");
  background-repeat: no-repeat;
  background-position: left bottom, right bottom;
  background-size: contain, 125px;
  padding: 80px 0;
  margin: 0 !important;
}
.price-three.price-card {
  border: 1px solid #e3e3e3;
  border-radius: 10px;
}
.price-three.price-card:hover {
  background: #374b5c;
}
.price-three.price-card:hover::before {
  content: none;
}
.price-three .price-level {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}
.price-three .price-level h6 {
  font-size: 24px;
  color: #374b5c;
}
.price-three .price-level span {
  color: #c10037;
  font-size: 14px;
  background: #ffffff;
  border-radius: 4px;
  display: inline-block;
  padding: 5px 5px;
  margin-bottom: 20px;
}
.price-three .price-body ul li.inactive {
  color: #c6c6c6;
}
.price-three .price-body ul li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  background-image: none;
}
.price-three.price-card:hover .price-body ul li:before {
  background-image: none;
}
.price-three .price-body h4 {
  font-size: 28px;
  font-weight: 800;
  color: #0d233e;
  margin-bottom: 20px;
}
.price-three .price-body h4 span {
  font-size: 14px;
  font-weight: 400;
  color: #666;
}
.price-three.price-card:hover .price-body h4,
.price-three.price-card:hover .price-body h4 span {
  color: #fff;
}
.price-three .viewdetails-btn {
  border: 1px solid #c10037;
  background: #c10037;
  border-radius: 40px;
  color: #fff;
  font-weight: 700;
}
.blog-block {
  background: #f5fcff;
  padding: 80px 0;
  margin: 0 !important;
  position: relative;
}
.blog-block:before {
  position: absolute;
  content: "";
  background: url(../img/balloon-img.png) no-repeat center left;
  width: 125px;
  height: 182px;
  top: 140px;
  right: 20px;
}
.blog-block .grid-blog {
  border: 1px solid #e3e3e3;
}
.blog-block .blog-content .viewlink a:hover {
  color: #c10037;
}
.blog-block .blog-content {
  text-align: left;
}
.blog-block .theme-btn {
  display: inline-flex;
  min-width: auto;
}
.blog-block .theme-btn a {
  padding: 5px 5px 5px 15px;
  font-weight: 700;
}
.blog-block .theme-btn span {
  margin-left: 20px;
  font-weight: 700;
}
.blog-block .top-blk {
  text-align: center;
  text-transform: capitalize;
  position: absolute;
  bottom: 0px;
  left: 20px;
}
.blog-block .top-blk.top-rated-style p {
  background: #f5cd07;
}
.blog-block .top-blk p {
  display: inline-block;
  background: #c10037;
  color: #fff;
  padding: 8px 15px;
  border-radius: 15px 15px 0px 0px;
}
.ban-image {
  position: absolute;
  left: 43%;
  top: 18%;
  animation: jump alternate infinite 1s;
}
@keyframes jump {
  to {
    transform: translateY(-10px);
  }
}
@keyframes jump1 {
  to {
    transform: translateX(-5px);
  }
}
.cloud-one {
  position: absolute;
  right: 30px;
  top: 20px;
  animation: jump alternate infinite 2s;
}
.cloud-two {
  position: absolute;
  right: 0;
  top: 120px;
  animation: jump1 alternate infinite 2s;
}
.ban-image-02 {
  position: absolute;
  right: 10%;
  top: 90px;
}
.ban-image-03 {
  position: absolute;
  left: 35%;
  top: 39%;
}
.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #c10037;
  text-shadow: unset;
}
.bootstrap-datetimepicker-widget table th,
.bootstrap-datetimepicker-widget table td.day {
  font-size: 14px;
  font-weight: 400;
}

/*-----------------.
	32. Home 4
-----------------------*/

.banner-four {
  background: url("../img/bg/banner-bg-04.png"), #f5f8fd;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 60% 100%;
}
.banner-four .banner-imgs {
  position: absolute;
  bottom: 100px;
  right: 200px;
}
.banner-four .banner-imgs img {
  max-width: 650px;
}
.banner-four .section-search {
  position: relative;
  z-index: 9;
  padding: 80px 0 200px;
}
.banner-four h6 {
  font-size: 96px;
  font-weight: 400;
  color: #fff;
  color: #bababa;
  font-family: "Sassy Frass", cursive;
  line-height: 0.8;
  margin-bottom: 24px;
}
.banner-four h1 {
  color: #fff;
}
.banner-four p {
  font-weight: 700;
  color: #fff;
  margin-bottom: 24px;
}
.banner-four .search-box {
  background: linear-gradient(
    90.79deg,
    rgba(242, 242, 242, 0.36) 0%,
    rgba(218, 218, 218, 0.47) 100%
  );
  border: 1px solid #ffffff;
  border-radius: 10px;
  padding: 22px;
}
.banner-four .search-box label {
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  margin-bottom: 6px;
}
.banner-four .search-box .form-control {
  background: transparent;
  border: 0;
  font-size: 14px;
  color: #fff;
  padding: 0;
  min-height: 20px;
}
.banner-four .search-box .form-control::placeholder {
  color: #fff;
}
.banner-four .search-btn .btn-primary {
  min-width: 180px;
}
.banner-four .search-btn .btn-primary i {
  margin: 0 0 0 10px;
}
.banner-four .form-block {
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.category-four-section {
  background: url("../img/bg/category-bg.png"), #f5f8fd;
  background-size: cover;
}
.category-sec {
  border-bottom: 1px solid #d9d9d9;
  padding: 80px 0 56px;
}
.category-box {
  background: linear-gradient(
    90.79deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.62) 100%
  );
  padding: 20px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
}
.category-box span {
  flex-shrink: 0;
  margin-right: 15px;
}
.category-info h6 {
  font-weight: 700;
  margin-bottom: 10px;
}
.category-info p {
  font-size: 14px;
  color: #b7b7b7;
  margin-bottom: 0;
}
.cowork-section {
  background: #f5f8fd;
}
.cowork-sec {
  border-bottom: 1px solid #d9d9d9;
  padding: 80px 0 56px;
}
.cowork-box {
  text-align: center;
  margin-bottom: 24px;
}
.cowork-img img {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -o-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
.cowork-img:hover img {
  -webkit-transform: scale(1.35) rotate(10deg);
  -moz-transform: scale(1.35) rotate(10deg);
  transform: scale(1.35) rotate(10deg);
}
.cowork-img {
  background: linear-gradient(90.79deg, #f6e7e5 0%, #feeacc 100%);
  border-radius: 10px;
  margin-bottom: 20px;
  min-height: 230px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.cowork-info h5 {
  margin-bottom: 0;
}
.cowork-info h5 a:hover {
  color: #c10037;
}
.cate-section {
  background: url("../img/bg/cate-bg.png"), #f5f8fd;
  background-size: cover;
}
.cate-sec {
  border-bottom: 1px solid #d9d9d9;
  padding: 80px 0;
}
.cat-wrap {
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 4.4px 20px -1px rgba(19, 16, 34, 0.05),
    0px 4px 9px -1px rgba(19, 16, 34, 0.03);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  position: relative;
}
.cat-wrap .cat-img {
  height: 64px;
  margin-bottom: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.cat-wrap img {
  width: auto !important;
  margin: 0 auto;
}
.cat-wrap h5 {
  margin-bottom: 20px;
}
.cat-wrap span {
  width: 50px;
  height: 50px;
  background: rgba(234, 234, 234, 0.47);
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
}
.cat-wrap a {
  width: 50px;
  height: 50px;
  background: #292d32;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  color: #fff;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
}
.cat-wrap:hover a {
  bottom: 20px;
  opacity: 1;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
}
.interset-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: end;
  -webkit-justify-content: end;
  -ms-flex-pack: end;
}
.interset-btn .mynav3 {
  margin-right: 20px;
}
.space-section {
  background: #f5f8fd;
}
.space-sec {
  border-bottom: 1px solid #d9d9d9;
  padding: 80px 0 56px;
}
.space-wrap {
  background: #ffffff;
  box-shadow: 0px 4.4px 20px -1px rgba(19, 16, 34, 0.05),
    0px 4px 9px -1px rgba(19, 16, 34, 0.03);
  border-radius: 10px;
  margin-bottom: 24px;
}
.space-img {
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}
.space-img img {
  width: 100%;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -o-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  border-radius: 10px;
}
.space-img:hover img {
  -webkit-transform: scale(1.35) rotate(15deg);
  -moz-transform: scale(1.35) rotate(15deg);
  transform: scale(1.35) rotate(15deg);
}
.space-img .fav-item {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 10px;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  font-size: 14px;
}
.space-img .featured-text {
  background: #ffba08;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  padding: 5px 12px;
}
.space-content {
  position: relative;
  padding: 20px;
}
.space-content .rating {
  margin-bottom: 10px;
}
.space-content .rating span {
  font-size: 14px;
}
.space-cat {
  width: 55px;
  height: 55px;
  background: #f5f8fd;
  box-shadow: 0px 4.4px 20px -1px rgba(19, 16, 34, 0.05),
    0px 4px 9px -1px rgba(19, 16, 34, 0.03);
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: -28px;
  right: 10px;
}
.space-cat img {
  width: 35px;
}
.space-content h5 {
  font-size: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 8px;
}
.space-content h5 a:hover {
  color: #c10037;
}
.space-content ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 18px;
}
.space-content ul li {
  margin-bottom: 10px;
}
.space-content ul li.addr {
  font-size: 14px;
}
.space-content ul li.addr i {
  color: #666;
}
.space-content ul li a {
  color: #b7b7b7;
}
.space-content ul li a:hover,
.space-content ul li .fav-icon.selected i {
  color: #c10037;
}
.space-content .price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.space-content .price span {
  font-size: 20px;
  font-weight: 600;
  color: #0d233e;
}
.space-content .price img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
.interset-btn {
  margin-bottom: 50px;
}
.interset-btn .owl-prev,
.interset-btn .owl-next {
  width: 36px;
  height: 36px;
}
.more-btn {
  padding: 6px 32px;
}
.city-wrap {
  background: #fff;
  border-radius: 10px;
  margin-bottom: 24px;
}
.city-img {
  position: relative;
}
.city-img img {
  width: 100%;
  border-radius: 10px;
}
.city-img a {
  width: 35px;
  height: 35px;
  background: #ffae36;
  color: #fff;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%);
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
}
.city-wrap .city-img:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
  width: 100%;
  height: 100%;
  border-radius: 10px;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
  opacity: 0;
}
.city-wrap:hover .city-img:before {
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
  opacity: 1;
}
.city-wrap:hover .city-img a {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translate(-50%, -50%);
  opacity: 1;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
}
.city-content {
  padding: 10px;
  text-align: center;
}
.city-content h5 {
  font-size: 20px;
  margin-bottom: 5px;
}
.city-content h5 a {
  color: #374b5c;
}
.city-content h5 a:hover {
  color: #c10037;
}
.city-content p {
  font-size: 14px;
  margin-bottom: 0;
}
.city-content p span {
  color: #c10037;
}
.list-sec {
  background: linear-gradient(90.79deg, #34485c 0%, #d80027 100%);
  border-radius: 20px;
  padding: 0 40px;
  margin-top: 80px;
}
.listee-img {
  text-align: end;
}
.listee-content {
  padding: 80px 0;
}
.listee-content h3 {
  font-weight: 700;
  font-size: 36px;
  color: #fff;
  margin-bottom: 10px;
}
.listee-content p {
  font-weight: 600;
  font-size: 20px;
  color: #fff;
  margin-bottom: 30px;
}
.listee-content .btn {
  background: #fff;
  border: 1px solid #fff;
  border-radius: 6px;
  color: #374b5c;
  font-weight: 500;
  font-size: 15px;
  padding: 7px 35px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  box-shadow: inset 0 0 0 #c10037;
}
.listee-content .btn:hover {
  background: #c10037;
  color: #fff;
  box-shadow: inset 0 70px 0 0 #c10037;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.city-section {
  background: url(../img/bg/cate-bg.png), #f5f8fd;
  background-size: cover;
}
.city-sec {
  border-bottom: 1px solid #d9d9d9;
  padding: 80px 0 56px;
}
.testimonial-section {
  background: #f5f8fd;
  padding: 80px 0;
}
.testimonial-info.testi-four {
  padding: 30px;
  margin: 10px 0 0;
  box-shadow: none;
  position: relative;
}
.testi-four .testimonialslider-content span {
  position: absolute;
  top: -15px;
  color: #c10037;
}
.testi-four .testimonialslider-content span i {
  font-size: 30px;
}
.testi-four .testimonialslider-content h6 {
  font-size: 20px;
  margin-bottom: 20px;
}
.testi-four .testimonialslider-content p {
  margin: 0 0 20px;
}
.testi-four .rating {
  margin-bottom: 20px;
}
.testi-four .testi-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.testi-four .testi-author {
  margin: 0 0 0 15px;
}
.testi-four .testi-author h6 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
.testi-four .testi-author p {
  font-size: 14px;
  margin: 0;
}
.blog-section-four {
  background: #f5f8fd;
  padding: 80px 0 56px;
}
.blog-four {
  background: #fff;
  border: 0;
  margin-bottom: 24px;
}
.blog-four .blog-image {
  border-radius: 12px;
}
.blog-four .blog-image img {
  border-radius: 12px;
}
.blog-four .blog-content .post-author-img {
  width: 57px;
  margin-right: 10px;
}
.blog-four .blog-content .entry-meta,
.blog-four .blog-content .entry-meta li {
  margin-bottom: 0;
}
.blog-four .blog-title a {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.blog-four .post-author img {
  width: 57px;
  height: 57px;
}
.blog-four.grid-blog .post-author a {
  color: #0d233e;
  font-size: 16px;
  font-weight: 700;
}
.blog-four.grid-blog .post-author a:hover {
  color: #c10037;
}
.blog-four .post-author p {
  font-size: 14px;
  margin-bottom: 5px;
}
.blog-four .post-author h6 {
  font-size: 12px;
  color: #34485c;
  text-decoration: underline;
  margin: 0;
}
.pricing-section {
  background: url(../img/bg/cate-bg.png), #fff;
  background-size: cover;
  padding: 80px 0;
}
.price-wrap {
  background: #ffffff;
  box-shadow: 0px 4.4px 20px -1px rgba(19, 16, 34, 0.05),
    0px 4px 9px -1px rgba(19, 16, 34, 0.03);
  border-radius: 10px;
  margin: 30px 0;
}
.price-four {
  border: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  flex-direction: column;
}
.price-four .feature {
  background: #ffba08;
  border-radius: 6px;
  color: #fff;
  padding: 9px 10px;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 20px;
}
.price-four .price-head h4 {
  font-size: 36px;
  font-weight: 700;
}
.price-four .price-head h4 span {
  font-size: 16px;
  font-weight: 700;
}
.price-four .price-level h6 {
  font-weight: 800;
  font-size: 24px;
}
.price-four .price-body li {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 13px;
}
.price-four .price-body ul li:before {
  content: "\f058";
  font-family: "FontAwesome";
  background-image: none;
  color: #b7b7b7;
}
.price-four.price-card:hover .price-body ul li:before {
  color: rgb(255 255 255 / 30%);
}
.price-four.price-card:hover .price-body ul li:before {
  background-image: none;
}
.price-four.price-card:hover::before {
  content: none;
}
.price-four .viewdetails-btn {
  background: #374b5c;
  border: 1px solid #374b5c;
  color: #fff;
  font-weight: 400;
  padding: 7px 50px;
}
.price-four.active {
  background: #c10037;
  margin: -30px 0;
}
.price-four.active .price-head h4,
.price-four.active p,
.price-four.active ul li,
.price-four.active .price-level h6,
.price-four.active .price-head h4 span {
  color: #fff;
}
.price-four.active ul li:before {
  color: rgb(255 255 255 / 30%);
}
.price-four.active .viewdetails-btn {
  background: #fff;
  border: 1px solid #fff;
  color: #374b5c;
}
.status-toggle .checktoggle {
  margin: 0 16px;
  color: #9c9c9c;
}
.check {
  display: block;
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
.checktoggle {
  background-color: #c10037;
  border: 1px solid #c10037;
  border-radius: 50px;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 24px;
  margin-bottom: 0;
  position: relative;
  width: 46px;
}
.checktoggle:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(2px, -50%);
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  transition: left 300ms ease, transform 300ms ease;
}
.check:checked + .checktoggle {
  background-color: #374b5c;
  border: 1px solid #374b5c;
}
.check:checked + .checktoggle:after {
  background-color: #fff;
  left: 100%;
  transform: translate(calc(-100% - 2px), -50%);
}

/*-----------------.
	33. Home 5
-----------------------*/

.banner-five {
  background: url("../img/banner/banner-05.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
}
.banner-five h1,
.banner-five p {
  color: #fff;
  text-align: center;
}
.banner-five .search-box {
  background: #ffffff;
  border-radius: 100px;
  padding: 15px;
}
.banner-five
  .search-box
  .select2-container--default
  .select2-selection--single {
  border: 1px solid #e5e7eb;
  border-radius: 999px;
}
.banner-five
  .search-box
  .group-img
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  padding-left: 38px;
}
.banner-five .search-box .group-img i {
  color: #666;
}
.banner-five .search-btn .btn-primary {
  min-width: 50px;
  height: 50px;
  padding: 0;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.banner-five .section-search {
  padding: 250px 0;
}
.banner-five .search-box .search-input {
  width: 100%;
}
.cate-row2 {
  text-align: center;
  margin-top: -36px;
}
.category-five-section {
  padding: 80px 0px;
  background: url(../img/bg/bg-01.png), #fff;
  background-size: cover;
}
.category-items li {
  display: inline-block;
  width: 16.3%;
  padding: 0 6px;
}
.categories-box {
  filter: drop-shadow(0px 1px 4px rgba(19, 16, 34, 0.1));
}
.categories-info {
  position: relative;
  width: 100%;
  height: 200px;
  background-color: #ffffff;
  border: 1px solid #f1f1f1;
  padding: 15px;
  -webkit-clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.categories-info:hover {
  background-color: #c10037;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.categories-info:hover span img {
  filter: invert(1) brightness(100);
}
.categories-info:hover h6,
.categories-info:hover p {
  color: #fff;
}
.categories-info span {
  display: inline-block;
  margin-bottom: 20px;
}
.categories-info h6 {
  font-size: 20px;
  margin-bottom: 6px;
}
.categories-info p {
  font-size: 14px;
  margin-bottom: 0;
}
.section-heading.heading-five {
  padding-bottom: 5px;
}
.section-heading.heading-five h2 {
  justify-content: center;
}
.section-heading.heading-five::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 50%;
  width: 176px;
  height: 1px;
  transform: translateX(-50%);
  border-bottom: 1px solid #d5d5d5;
}
.section-heading.heading-five::after {
  position: absolute;
  content: "";
  bottom: -1px;
  left: 50%;
  width: 67px;
  height: 3px;
  transform: translateX(-50%);
  background: #c10037;
}
.business-slider.owl-carousel .owl-nav .owl-next,
.business-slider.owl-carousel .owl-nav .owl-prev,
.testi-five-slider.owl-carousel .owl-nav .owl-prev,
.testi-five-slider.owl-carousel .owl-nav .owl-next {
  width: 32px;
  height: 32px;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
  border-radius: 50%;
  margin: auto;
}
.business-slider.owl-carousel .owl-nav .owl-next:hover,
.business-slider.owl-carousel .owl-nav .owl-prev:hover,
.testi-five-slider.owl-carousel .owl-nav .owl-prev:hover,
.testi-five-slider.owl-carousel .owl-nav .owl-next:hover {
  background: #c10037;
  color: #fff;
}
.business-slider.owl-carousel .owl-nav .owl-prev,
.testi-five-slider.owl-carousel .owl-nav .owl-prev {
  left: -55px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.business-slider.owl-carousel .owl-nav .owl-next,
.testi-five-slider.owl-carousel .owl-nav .owl-next {
  right: -55px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.testi-five-slider.owl-carousel .owl-nav .owl-prev {
  left: -50px;
}
.testi-five-slider.owl-carousel .owl-nav .owl-next {
  right: -50px;
}
.business-card.card {
  padding: 0;
  margin: 0;
}
.business-card .blog-features {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.grid-view .business-card .Featured-text {
  background: #374b5c;
  font-size: 12px;
  padding: 4px 9px;
  display: inline-block;
  position: absolute;
  top: -13px;
  z-index: 2;
}
.grid-view .business-card .card-body {
  padding: 20px;
}
.grid-view .business-card .grid-author {
  top: -23px;
  right: 20px;
}
.grid-view .business-card .grid-author img {
  width: 45px;
  height: 45px;
  border: 5px solid #fff;
}
.grid-view .business-card .bloglist-content .blogfeaturelink {
  margin: 10px 0;
}
.business-section {
  padding: 80px 0 ;
  background: url("../img/bg/business-bg.png"), #f3f3f3;
  background-size: cover;
}
.feature-section {
  background: #fff;
  padding: 80px 0 65px;
}
.city-box {
  position: relative;
  margin-bottom: 25px;
}
.citi-img {
  border-radius: 20px;
  overflow: hidden;
}
.citi-img img {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -o-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  border-radius: 20px;
}
.citi-img:hover img {
  -webkit-transform: scale(1.35) rotate(15deg);
  -moz-transform: scale(1.35) rotate(15deg);
  transform: scale(1.35) rotate(15deg);
}
.city-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 0px 0px 20px 20px;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.city-name h5 {
  color: #fff;
  margin-bottom: 5px;
}
.city-name ul li {
  display: inline-block;
  color: #fff;
  margin-right: 10px;
  font-size: 12px;
}
.city-name ul li i {
  margin-right: 5px;
}
.city-name ul li:last-child {
  margin-right: 0;
}
.adventure-section {
  background: #c10037;
  padding: 80px 0;
}
.adventure-info p {
  color: #fff;
  margin-bottom: 20px;
}
.advent-info {
  margin-bottom: 40px;
}
.adventure-info .section-heading.heading-five h6,
.adventure-info .section-heading.heading-five h2 {
  color: #fff;
}
.adventure-info .section-heading.heading-five::after,
.adventure-info .section-heading.heading-five::before {
  left: 0;
  transform: translateX(0);
}
.adventure-info .section-heading.heading-five::after {
  background: #fff;
}
.adventure-info .btn {
  padding: 9px 23px;
  border-radius: 6px;
}
.gallery-section-five {
  padding: 80px 0 65px;
}
.gal-wrap {
  margin-bottom: 24px;
  position: relative;
}
.gal-wrap img {
  width: 100%;
  border-radius: 10px;
}
.city-five-overlay .city-name p {
  font-size: 14px;
  color: #fff;
}
.city-overlay.city-five-overlay {
  border-radius: 0px 0px 10px 10px;
}
.city-five-overlay {
  opacity: 0;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.city-five-overlay .city-name h5 {
  font-size: 18px;
}
.gal-wrap:hover .city-five-overlay {
  opacity: 1;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.pricing-plan {
  padding: 80px 0;
}
.price-three.price-five .price-level h6 {
  font-size: 24px;
  color: #181059;
}
.price-three.price-five.price-card:hover {
  background: #c10037;
}
.price-three.price-five.price-card:hover .price-level h6 {
  color: #fff;
}
.price-three .price-body h4 {
  font-size: 25px;
  font-weight: 700;
  color: #181059;
}
.price-three .price-level span {
  color: #8645ff;
  font-size: 12px;
  background: #ffffff;
  font-weight: 700;
}
.price-five.active {
  background: #c10037;
}
.price-head-five.section-heading {
  margin: 0px 0 70px;
}
.price-five .viewdetails-btn {
  border: 1px solid #c10037;
  background: #fff;
  border-radius: 10px;
  color: #c10037;
  font-weight: 700;
  padding: 8px 15px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.price-five.price-card {
  box-shadow: 0px 4.8px 24.4px -6px rgba(19, 16, 34, 0.1),
    0px 4px 13px -2px rgba(19, 16, 34, 0.06);
  border-radius: 24px;
  border: 0;
}
.price-five.active .price-level h6,
.price-five.active .price-body li,
.price-five.active .price-body h4,
.price-five.active .price-body h4 span {
  color: #fff;
}
.testimonial-five-section {
  padding: 80px 0;
  background: url(../img/bg/business-bg.png), #f3f3f3;
  background-size: cover;
}
.testi-four.testi-five .testimonialslider-content span {
  position: relative;
  top: 0;
}
.stay-tuned.stay-turn-five {
  margin-top: 0px;
  border-radius: 0;
  padding: 50px 0;
  position: relative;
  overflow: hidden;
  background: url(../img/footer-bg.png), #c10037;
  background-repeat: no-repeat;
  background-position: center;
}
.latest-blog-five {
  padding: 80px 0px 65px;
}
.articles-grid {
  padding: 15px;
  margin-bottom: 25px;
  border: 2px solid #f1f1f1;
  border-radius: 10px;
}
.articles-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.articles-left {
  margin-right: 20px;
}
.articles-img {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  width: 205px;
  height: 180px;
}
.articles-img img {
  position: relative;
  border-radius: 8px;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -o-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  width: 100%;
}
.articles-img:hover img {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  transform: scale(1.15);
}
.articles-img::before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  display: block;
  content: "";
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
}
.articles-img:hover::before {
  -webkit-animation: circle 1s;
  animation: circle 1s;
}
.articles-content .articles-list {
  margin-bottom: 10px;
}
.articles-content .articles-list li {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #888e9d;
  margin-right: 15px;
}
.articles-content .articles-list li:last-child {
  margin-right: 0;
}
.articles-content .articles-list li i {
  font-size: 18px;
  margin-right: 8px;
}
.articles-content h4 {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
}
.articles-content h4 a {
  color: #0d233e;
}
.articles-content p {
  color: #999;
  margin-bottom: 16px;
  font-size: 15px;
}
.articles-read-more.btn {
  background: #fff;
  border: 1px solid #fff;
  border-radius: 6px;
  color: #c10037;
  font-weight: 600;
  font-size: 15px;
  padding: 8px 15px;
  border: 1px solid #c10037;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  box-shadow: inset 0 0 0 #c10037;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: inline-flex;
}
.articles-read-more.btn:hover {
  background: #c10037;
  color: #fff;
  border: 1px solid #c10037;
  box-shadow: inset 0 70px 0 0 #c10037;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}

/*-----------------.
	34. Home 6
-----------------------*/

.header-six {
  background: transparent;
  box-shadow: none;
}
.header-six .header-top .selection-list {
  gap: 15px;
}
.header.fixed.header-six {
  background-color: #c10037;
}
.header-six .bar-icon span {
  background-color: #fff;
}
.menu-opened .header-six .main-nav > li > a {
  color: #000000;
}
.header-six .header-top {
  border-bottom: none;
}
.header-six .main-nav > li > a {
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
}
.car-top-left li a {
  color: #fff;
  border-bottom: 2px solid #ffffff;
  padding-bottom: 5px;
  font-weight: 600;
}
.banner-section.banner-six {
  background-color: #c10037;
  height: 700px;
  display: flex;
  align-items: center;
  position: relative;
}
.banner-section.banner-six .floating-bg img {
  position: absolute;
  right: 0;
  top: 150px;
  z-index: 2;
  width: 50%;
  transform: translateX(400px);
  transition-timing-function: ease-out;
  animation: drive 2s forwards;
}
@keyframes drive {
  80% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(5px);
  }
}
.banner-section.banner-six .floating-watermark img {
  position: absolute;
}
.banner-section.banner-six .floating-watermark img:nth-child(1) {
  top: 0;
}
.banner-section.banner-six .floating-watermark img:nth-child(2) {
  right: 0;
  top: 0px;
  width: 45%;
}
.banner-section.banner-six h1 {
  font-size: 48px;
  color: #fff;
}
.banner-section.banner-six p {
  font-weight: 400;
  font-size: 24px;
  color: #fff;
  margin: 29px 0px;
}
.head-contact-six i,
.head-contact-six a {
  color: #fff !important;
  font-size: 14px;
}
.head-contact i {
  font-weight: 600;
}
.header .car-list-btn {
  background-color: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 28px;
  gap: 8px;
}
.car-list-btn span {
  color: #c10037;
  font-weight: 400;
  font-size: 16px;
}
.banner-contents a {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 32px;
  gap: 8px;
  background: #000000;
  border-radius: 8px;
  color: #fff;
}
.car-filter-section {
  box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.06),
    0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
  border-radius: 15px;
  padding: 25px;
  background-color: #fff;
}
.car-filter-section .nav-pills .nav-link.active,
.car-filter-section .nav-pills .show > .nav-link {
  color: #c10037;
  border: none;
  border-bottom: 2px solid #c10037;
}
.car-filter-section .nav-pills .nav-link {
  background-color: #fff;
  font-weight: 700;
  font-size: 16px;
  border: none;
  border-radius: 0px;
  color: #9c9c9c;
}
.car-filter-section .nav-pills .nav-link.active:hover,
.car-filter-section .nav-pills .show > .nav-link:hover {
  background: #fff;
  color: #c10037;
}
.car-filter-section .lable {
  color: #7f7f7f;
  font-weight: 400;
  font-size: 16px;
}
.car-filter-section .form-control {
  color: #374b5c;
  min-height: 30px;
  font-weight: 600;
}
.car-filter-section .search-tab-col ul li .car-search-grid {
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  padding: 10px 22px;
  min-width: 275px;
}
.car-search-grid input::placeholder {
  color: #374B5C;
  font-weight: 600;
}
.car-filter-section .car-search-btn li {
  border: none !important;
}
.car-search-btn {
  display: flex;
  justify-content: center;
  padding-right: 0px;
}
.car-filter-section .car-search-btn .car-search-icon {
  width: 70px;
  height: 70px;
  border-radius: 100px !important;
  background: #C10037;
  transition: all 0.5s;
  padding: 0;
}
.car-search-filter {
  margin-top: -107px;
  position: relative;
  z-index: 1;
}
.common-padding {
  padding: 80px 0px;
}
.home-six-heading-section {
  text-align: center;
}
.home-six-heading-section h2 {
  font-weight: 800;
  font-size: 33px;
  color: #000000;
  margin-right: 10px;
}
.home-six-heading-section p {
  margin: 10px auto 50px auto;
  font-family: "Encode Sans";
  font-weight: 400;
  font-size: 16px;
  max-width: 339px;
  color: #666;
}
.car-brand-slider.owl-carousel .owl-stage-outer,
.car-class-slider.owl-carousel .owl-stage-outer {
  padding: 10px 0px;
}
.trending-slider-item {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.trending-slider-item:hover a img {
  transform: scale(1.5);
}
.trending-slider-item a img {
  transition: all 0.5s;
}
.trending-slider-item span {
  font-weight: 600;
  font-size: 20px;
  position: absolute;
  color: #fff;
  bottom: 10px;
  left: 26px;
  max-width: 145px;
  transition: all 0.5s;
}
.trending-slider-item:hover a span {
  color: #c10037;
}
.car-class-item:hover {
  background-color: #374b5c;
}
.car-class-item {
  transition: all 0.5s;
}
.car-class-item:hover .car-class-title h5,
.car-class-item:hover .car-class-title span {
  color: #fff;
}
.trending-carousel .owl-dots,
.car-class-carousel .owl-dots {
  margin-top: 45px;
}
.trending-carousel .owl-dots .owl-dot span,
.car-class-carousel .owl-dots .owl-dot span {
  width: 65px;
  height: 4px;
  border-radius: 0px;
  background: #d9d9d9;
}
.trending-carousel .owl-dots .owl-dot:last-child span,
.car-class-carousel .owl-dots .owl-dot:last-child span {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.trending-carousel .owl-dots .owl-dot:first-child span,
.car-class-carousel .owl-dots .owl-dot:first-child span {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.trending-carousel .owl-dots .owl-dot.active span,
.car-class-carousel .owl-dots .owl-dot.active span {
  background: #c10037;
  border-radius: 10px;
}
.car-class-item-block img {
  max-height: 170px;
}
.car-class-item-block {
  display: inline-flex;
  flex-direction: column;
}
.car-class-item:first-child {
    margin-bottom: 24px;
}
.car-class-item {
  padding: 30px;
  box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.06), 0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
  border-radius: 5px;
}
.car-class-item span {
  color: #374B5C;
  font-weight: 400;
  font-size: 18px;
}
.car-class-item h5 {
  font-weight: 600;
  font-size: 20px;
  color: #C10037;
}
.car-class-title {
  margin-bottom: 24px;
}
.car-testimonial {
  background: #C10037;
}
.car-testimonial-content {
  padding: 40px;
  border: 1px solid #fff;
  border-radius: 10px;
  text-align: center;
}
.car-testimonial-content h3 {
  font-weight: 800;
  font-size: 60px;
  color: #FFFFFF;
}
.car-testimonial-content h5 {
  font-weight: 800;
  font-size: 24px;
  color: #F1F1F1;
  margin: 40px auto;
  max-width: 336px;
}
.car-testimonial-content p {
  color: #FFFFFF;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  max-width: 282px;
  margin: auto;
}
.browse-by-brand-item {
  padding: 25px;
  border: 1px solid #F1F1F1;
  box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.06), 0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
  border-radius: 10px;
  flex-direction: column;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 35px;
  background: #fff;
}
.car-brand-slider.owl-carousel .owl-item img {
  height: 70px;
}
.main-wrapper.home-six {
  background: #F9FAFC;
}
.choose-car-class-section {
  background-color: #fff;
}
.browse-by-brand {
  background-color: #fff;
  position: relative;
}
.car-float-bg img:nth-child(1){
  position: absolute;
  right: 0px;
  width: 215px;
  top: 148px;
}
.car-float-bg img:nth-child(2) {
  position: absolute;
  left: 0px;
  top: 148px;
  width: 6%;
  transform: rotate(180deg);
}
.featured-car-item:hover .featured-car-center {
  background: #c10037;
}
.featured-car-item:hover .featurecar-img img {
  transform: scale(1.1);
}
.featured-car-item:hover .featurecar-img img {
  transition: all 0.8s;
}
.featured-car-item .featurecar-img  {
  overflow: hidden;
}
.car-class-item-block img {
  max-height: 170px;
}
.car-class-item-block {
  display: inline-flex;
  flex-direction: column;
}
.car-class-item:first-child {
  margin-bottom: 24px;
}
.car-class-item {
  padding: 30px;
  box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.06),
    0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
  border-radius: 5px;
}
.car-class-item span {
  color: #374b5c;
  font-weight: 400;
  font-size: 18px;
}
.car-class-item h5 {
  font-weight: 600;
  font-size: 20px;
  color: #c10037;
}
.car-class-title {
  margin-bottom: 24px;
}
.car-testimonial {
  background: #c10037;
}
.car-testimonial-content {
  padding: 40px;
  border: 1px solid #fff;
  border-radius: 10px;
  text-align: center;
}
.car-testimonial-content h3 {
  font-weight: 800;
  font-size: 60px;
  color: #ffffff;
}
.car-testimonial-content h5 {
  font-weight: 800;
  font-size: 24px;
  color: #f1f1f1;
  margin: 40px auto;
  max-width: 336px;
}
.car-testimonial-content p {
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  max-width: 282px;
  margin: auto;
}
.browse-by-brand-item {
  padding: 25px;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.06),
    0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
  border-radius: 10px;
  flex-direction: column;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 35px;
  background: #fff;
}
.car-brand-slider.owl-carousel .owl-item img {
  height: 70px;
  width: 147px !important;
}
.main-wrapper.home-six {
  background: #f9fafc;
}
.choose-car-class-section {
  background-color: #fff;
}
.browse-by-brand {
  background-color: #fff;
  position: relative;
}
.car-float-bg img:nth-child(1) {
  position: absolute;
  right: 0px;
  width: 215px;
  top: 148px;
}
.car-float-bg img:nth-child(2) {
  position: absolute;
  left: 0px;
  top: 148px;
  width: 6%;
  transform: rotate(180deg);
}
.featured-car-item:hover .featured-car-center {
  background: #c10037;
}
.featured-car-item:hover .featurecar-img img {
  transform: scale(1.1);
}
.featured-car-item:hover .featurecar-img img {
  transition: all 0.8s;
}
.featured-car-item .featurecar-img {
  overflow: hidden;
}
.featured-car-bottom h3:hover {
  color: #c10037;
}
.featured-car-item:hover .featured-car-item:hover .browse-by-brand-item p {
  color: #000000;
  font-weight: 600;
  font-size: 20px;
  transition: all 0.5s;
}
section.home-six-trending-search {
  background: #f9fafc;
}
.browse-by-brand-item p span {
  color: #c10037;
  font-weight: 400;
  font-size: 16px;
}
.featured-car-item {
  max-width: 410px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.06),
    0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
}
.car-featured-slider.owl-carousel .owl-stage-outer {
  padding: 10px 5px;
}
.featured-car-item-list {
  box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.06),
    0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
  border-radius: 10px;
  display: inline-flex;
  flex-direction: column;
}
.featured-car-center {
  background: #374B5C;
  display: flex;
  gap: 15px;
  padding: 20px;
  justify-content: center;
  transition: all 0.5s;
}
.featured-car-center-list p {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}
.featured-car-center-list h5 {
  font-weight: 400;
  font-size: 14px;
  color: #fff;
}
.featured-car-bottom {
  padding: 20px 20px 0px 20px;
}
.featured-car-bottom h6 {
  color: #374b5c;
  font-weight: 700;
  font-size: 16px;
}
.featured-car-bottom h3 {
  color: #000000;
  font-weight: 800;
  font-size: 24px;
  margin: 20px auto;
  transition: all 0.5s;
}
.featured-car-bottom p {
  color: #666666;
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
}
.featured-car-foot {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.featured-car-foot-right {
  display: flex;
  align-items: center;
  gap: 10px;
}
.featured-car-foot-right p {
  color: #374b5c;
  font-weight: 600;
  transition: all 0.5s;
  font-size: 14px;
}
.featured-car-foot-right p:hover span {
  color: #000;
}
.featured-car-foot-right img {
  width: auto !important;
}
.featured-car-foot-right p span {
  color: #c10037;
}
.featured-car-foot-left span {
  color: #000000;
  font-weight: 600;
  font-size: 20px;
}
.car-service-container {
  padding: 40px;
  border: 1px solid #D5D5D5;
  text-align: center;
  background: #fff;
  transition: ease-in-out 1s;
  margin-bottom: 25px;
}
.car-service-container p {
  color: #666666;
  font-weight: 400;
  font-size: 16px;
  max-width: 228px;
  margin: auto;
}
.car-service-container h4 {
  color: #000000;
  font-weight: 600;
  font-size: 20px;
  padding: 20px 0px;
}
.car-service-container:hover {
  transform: translateY(-10px);
  box-shadow: 0px 4.4px 12px -1px rgb(19 16 34 / 6%),
    0px 2px 6.4px -1px rgb(19 16 34 / 3%);
  cursor: pointer;
  background-color: #c10037;
}
.car-service-container:hover img {
  filter: brightness(0) invert(1);
}
.car-service-container:hover h4 {
  color: #fff;
}
.car-service-container:hover p {
  color: #fff;
}
.our-team-block {
  display: inline-block;
  position: relative;
  padding-top: 246px;
  cursor: pointer;
}
.our-team-bottom {
  padding: 120px 20px 20px 20px;
  background: #374b5c;
  margin-left: 68px;
  min-width: 349px;
  transition: all 0.5s;
}
.our-team-block:hover .our-team-bottom {
  background: #c10037;
}
.our-team-block:hover .our-team-bottom {
  background: #c10037;
}
.our-team-bottom h4 {
  font-weight: 600;
  font-size: 20px;
  color: #fff;
}
.our-team-bottom p {
  color: #f1f1f1;
  font-weight: 400;
  font-size: 16px;
  margin-top: 10px;
}
.our-team-img {
  max-width: 347px;
  position: absolute;
  bottom: 100px;
}
.car-location {
  padding-bottom: 65px;
}
.car-location-img {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 24px;
}
.car-location-img:hover::before {
  display: none;
}
.car-location-img:hover span {
  background: linear-gradient(
    186.56deg,
    rgba(0, 0, 0, 0.46) 3.35%,
    rgba(0, 0, 0, 0.46) 95.75%
  );
  transform: translateY(-5px);
}
.car-location-img::before {
  content: "";
  background: linear-gradient(
    186.56deg,
    rgba(0, 0, 0, 0.46) 3.35%,
    rgba(0, 0, 0, 0.46) 95.75%
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.car-location-img span {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -15px 0 0 -50px;
  font-weight: 600;
  font-size: 20px;
  color: #fff;
  transition: all 0.5s;
  padding: 2px 8px;
  border-radius: 5px;
}
.owner-name a:hover {
  color: #c10037;
}
.car-clients {
  background: #f1f1f1;
}
.car-client-user {
  height: 70px;
  width: 70px;
  margin: auto;
  border-radius: 100%;
  margin-top: 40px;
  overflow: hidden;
  border: 3px solid #FFFFFF;
}
.car-client-review {
  position: relative;
  padding: 40px;
  background: #ffffff;
  box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.06),
    0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
  border-radius: 10px;
  text-align: center;
}
.car-client-review img {
  position: absolute;
  width: auto !important;
  bottom: -24px;
  display: none !important;
  left: 190px;
}
.car-client-review p {
  max-width: 338px;
  font-weight: 400;
  font-size: 16px;
  color: #374b5c;
  padding: 20px 0px;
}
.car-client-review h5 {
  font-weight: 400;
  font-size: 18px;
  color: #c10037;
}
.car-client-review h3 {
  color: #000000;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
}
.car-service-section {
  background-color: #fff;
}
.our-team-car {
  background: #f1f1f1;
}
.car-client-rating i {
  color: #ffba08;
  font-size: 10px;
}
.car-client-rating {
  text-align: center;
}
.car-more-blog {
  margin-top: 58px;
}
.car-more-blog a {
  display: inline-flex;
  padding: 13px 32px;
  background: #c10037;
  border-radius: 8px;
  color: #fff;
}
.car-more-blog a:hover {
  background: #374b5c;
}
.joinus-dealer-content p {
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  margin-top: 15px;
}
.join-us-dealer {
  background: #c10037;
  position: relative;
}
.join-us-dealer-bg img {
  position: absolute;
  left: 0;
  top: 14px;
  width: 7%;
}
.joinus-dealer-content h3 {
  font-weight: 800;
  font-size: 42px;
  color: #fff;
}
.join-as-dealer-btn {
  display: flex;
  justify-content: end;
}
.join-as-dealer-btn a {
  background: #ffffff;
  border-radius: 8px;
  padding: 13px 32px;
  font-weight: 600;
  font-size: 14px;
  color: #374b5c;
}
.join-as-dealer-btn a:hover {
  background: #374b5c;
  color: #fff;
}
.footer-six {
  background: #1a1a1a;
  position: relative;
}
.footer-six-bg img {
  position: absolute;
  right: 0;
  bottom: 0;
}
.footer-six-top-list ul li {
  margin-bottom: 15px;
}
.footer-six-top-list ul li a {
  font-weight: 400;
  font-size: 16px;
  color: #fff;
}
.footer-six-top-list h4 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 23px;
  color: #fff;
}
.footer-six-top-list ul li:last-child {
  margin-bottom: 0px;
}
.footer-send-mail {
  background: #fff;
  padding: 8px 8px 8px 15px;
  display: flex;
  gap: 10px;
  border-radius: 5px;
}
.footer-send-mail input {
  border: none;
  width: 78%;
}
.footer-send-mail a {
  background: #c10037;
  border-radius: 5px;
  padding: 13px 26px;
  color: #fff;
  transition: all 0.5s;
  border: 1px solid #c10037;
}
.footer-send-mail a:hover {
  background: #fff;
  color: #c10037;
}
.social-icon-six ul {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  list-style: none;
  padding: 0;
}
.social-icon-six ul li {
  margin-right: 12px;
}
.social-icon-six ul li a {
  color: #666666;
  font-size: 18px;
  transition: all 0.4s ease 0s;
  border-radius: 50%;
  padding: 15px 15px;
  border: 1px solid #666;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 30px;
  width: 30px;
}
.social-icon-six ul li a:hover {
  color: #c10037;
}
.social-icon-six ul li a:hover {
  background: #fff;
  border: 1px solid #fff;
}
.social-icon-six {
  margin-top: 50px;
}
.social-icon-six h3 {
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 14px;
}
.footer-six-center {
  display: flex;
  padding-top: 50px;
  justify-content: space-between;
}
.footer-six-center-content a {
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
}
.footer-six-center-content h6 {
  font-weight: 500;
  font-size: 14px;
  color: #666666;
}
.footer-six-center-list ul {
  display: flex;
  gap: 20px;
}
.footer-six-center-list ul li a {
  color: #fff;
}
.footer-six-top-list ul li a:hover,
.footer-six-center-content a:hover,
.footer-six-center-list ul li a:hover {
  color: #c10037;
}
.footer-six.common-padding {
  padding: 80px 0px 0px 0px;
}
.custom-line {
  background-color: #374b5c;
  margin: 50px 0px;
}
.footer-six-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 0;
}
.footer-six-bottom p {
  color: #e3e3e3;
  font-weight: 400;
  font-size: 14px;
}
.car-rental-slider-section {
  position: relative;
}
.car-rental-carousel-content {
  position: absolute;
  top: 16%;
  left: 126px;
}
.car-rental-slider .car-rental-slider-item::before {
  content: "";
  background: linear-gradient(
    126.67deg,
    rgba(0, 0, 0, 0.6) 54.44%,
    rgba(0, 0, 0, 0) 71.34%
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.car-rental-slider .owl-nav {
  position: absolute;
  right: 100px;
  bottom: 176px;
}
.car-rental-slider .owl-nav .owl-prev i,
.car-rental-slider .owl-nav .owl-next i {
  width: 48px;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgb(192 192 192 / 25%);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s all;
}
.car-rental-slider .owl-nav .owl-prev i:hover,
.car-rental-slider .owl-nav .owl-next i:hover {
  background: #263238;
  color: #fff;
}
.car-rental-carousel-content h6 {
  font-weight: 700;
  font-size: 16px;
  color: #fff;
}
.car-rental-carousel-content h3 {
  font-weight: 800;
  font-size: 43px;
  color: #fff;
}
.car-rental-carousel-content h5 span {
  font-weight: 800;
  font-size: 28px;
  color: #ffba08;
  margin-right: 5px;
}
.car-rental-carousel-content h5 {
  font-weight: 400;
  font-size: 22px;
  color: #fff;
  display: flex;
  padding: 14px 0px;
  align-items: center;
}
.car-rental-carousel-content p {
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  margin-bottom: 25px;
}
.car-rental-carousel-content a {
  background: #C10037;
  padding: 13px 32px;
  border-radius: 8px;
  color: #fff !important;
  font-size: 16px;
  display: inline-block;
  transition: all 0.5;
}
.car-rental-carousel-content a:hover {
  background: #fff;
  color: #c10037;
}
.header-six .main-nav > li > a:hover {
  color: #ffc107;
}
.header-six .main-nav .has-submenu.active > a {
  color: #ffc107;
}
.menu-opened .header-six .main-nav .has-submenu.active > a {
  color: #c10037;
}
.head-contact-six:hover a,
.head-contact-six:hover i {
  color: #ffc107;
}
.footer-six.footer-nine .footer-six-bottom {
  padding-bottom: 0;
}
.car-top-left li a:hover {
  color: #ffc107;
}
.header .car-list-btn:hover {
  background-color: #374b5c;
}
.header .car-list-btn img {
  transition: all 0.5s;
}
.header .car-list-btn:hover img {
  filter: brightness(0) invert(1);
}
.header .car-list-btn:hover span {
  color: #fff;
}
.banner-contents a:hover {
  background: #374b5c;
}
button.btn.car-search-icon:hover {
  background: #374b5c;
  color: #fff;
}
.browse-by-brand-item {
  transition: all 0.5s;
}
.browse-by-brand-item:hover {
  box-shadow: 0px 4.4px 12px -1px rgb(19 16 34 / 12%),
    0px 2px 6.4px -1px rgb(19 16 34 / 7%);
  transform: translateY(-5px);
}
.browse-by-brand-item:hover p {
  color: #c10037;
}
.top-featured-car.common-padding {
  background-color: #fff;
  padding: 0 0 80px 0;
}

/*-----------------.
	37. Home 9
-----------------------*/

.header-nine .header-top {
  background-color: #f9fafc;
  border-bottom: none;
}
.header-nine .head-contact i,
.header-nine .head-contact a {
  color: #374b5c;
  font-weight: 600;
  transition: all 0.5s;
}
.header-nine .car-top-left li a {
  color: #c10037;
  border-bottom: 2px solid #c10037;
  padding-bottom: 0;
}
.header-nine .car-top-left li a:hover {
  color: #374b5c;
  border-bottom: 2px solid #374b5c;
}
.home-nine .header .car-list-btn {
  background-color: #c10037;
  border-radius: 8px;
  padding: 13px 32px;
  gap: 8px;
  border: 1px solid transparent;
}
.home-nine .header .car-list-btn.header-phone {
  background-color: #fff;
  margin-right: 15px;
}
.home-nine .header .car-list-btn.header-phone:hover {
  background-color: #c10037;
}
.home-nine .header .car-list-btn:hover {
  background-color: #fff;
  border: 1px solid #c10037;
}
.home-nine .header .car-list-btn.header-phone:hover span,
.home-nine .header .car-list-btn.header-phone:hover i {
  color: #fff;
}
.home-nine .header .car-list-btn:hover span,
.home-nine .header .car-list-btn:hover i {
  color: #374b5c;
}
.home-nine .car-list-btn span {
  color: #fff;
}
.home-nine .car-list-btn i {
  color: #fff;
}
.home-nine .car-list-btn.header-phone span {
  color: #374b5c;
  font-size: 18px;
}
.home-nine .car-list-btn.header-phone i {
  color: #374b5c;
}
.home-nine .main-nav li a {
  font-weight: 400;
}
.header-nine .header-top .selection-list {
  justify-content: end;
}
.header-nine .header-top ul li {
  display: inline-block;
  font-size: 14px;
  margin-right: 11px;
}
.home-nine.progress-wrap {
  border-radius: 10px;
  -webkit-border-radius: 10px;
}
.home-nine.progress-wrap::after {
  content: "\f061";
  transform: rotate(-45deg);
  color: #000;
}
.home-nine.progress-wrap.active-progress {
  transform: rotate(-45deg);
  background: #fff;
  box-shadow: none;
}
.home-nine.progress-wrap svg.progress-circle path {
  stroke-width: 5;
}
.header-nine .header-top .selection-list span {
  margin: 0 7px;
  color: #374b5c;
}
.banner-section.banner-nine {
  position: relative;
  background-image: url(../img/bg/banner-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 590.76px;
  margin-top: 130px;
  padding: 75px 0 0;
}
.banner-section.banner-nine h1 {
  font-weight: 800;
  font-size: 55px;
  color: #fff;
  margin-bottom: 0px;
  max-width: 439px;
}
.banner-section.banner-nine h6 {
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  padding: 20px 0;
}
.banner-section.banner-nine p {
  font-weight: 400;
  font-size: 16px;
  color: #fff;
}
.banner-nine-btn-group {
  padding-top: 40px;
  display: flex;
  gap: 20px;
}
.banner-nine-btn-group a {
  font-weight: 400;
  font-size: 16px;
  border-radius: 8px;
}
.banner-nine-btn-group a:nth-child(1) {
  background-color: #c10037;
  color: #fff;
  transition: all 0.5s;
}
.banner-nine-btn-group a:nth-child(1):hover {
    background-color:#374b5c ;
}
.banner-nine-btn-group a:nth-child(2) {
  background-color: #fff;
  color: #374b5c;
  transition: all 0.5s;
}
.banner-nine-btn-group a:nth-child(2):hover {
  background-color:#c10037 ;
  color: #fff;
}
.real-estate-search {
  padding: 10px 15px;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  align-items: center;
}
.real-estate-search  .icon-blk img{
  margin-top: -4px;
}
.real-estate-search.real-select {
  padding: 4px 15px;
}
.real-estate-search.real-select
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  padding-right: 30px;
  padding-left: 0;
}
.real-estate-search.real-select
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #666666;
}
.real-estate-search.real-select .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #666666;
}
.real-estate-search.real-select .select2-container .select2-selection--single {
  border: none;
}
.realestate-search-filter .col-lg-6,
.realestate-search-filter .col-lg-2 {
  padding-left: 7px;
  padding-right: 7px;
}
.realestate-search-filter form input::placeholder {
  color: #666666;
  font-weight: 400;
}
.car-search-icon {
  background: #c10037;
  padding: 14px 32px;
  color: #fff;
  transition: all 0.5s;
}
.car-search-filter.realestate-search-filter {
  margin-top: -91px;
  position: relative;
}
.section-heading-nine.section-heading h2 {
  justify-content: center;
}
.section-heading-nine.section-heading .btn-view {
  font-weight: 700;
  padding: 11px 32px;
}
.featured-listing-section {
  position: relative;
}
.feature-rent {
  background: #ffffff;
  box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.06),
    0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
  position: relative;
  margin-bottom: 20px;
  border-radius: 10px;
}
.house-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 25px;
}
.house-type .room-type {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  font-size: 14px;
  color: #374b5c;
}
.house-type .double-tick {
  width: 32px;
  height: 32px;
  background: #22c55e;
  border-radius: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.home-img-text {
  padding: 20px;
}
.home-img-text h3 {
  margin: 0;
  padding: 25px 0;
  max-width: 270px;
  font-size: 20px;
}
.home-img-text h3 a {
    color: #000000;
    font-weight: 600;
}
.home-img-text h3 a:hover {
    color: #c10037;
}
.home-img-text .star-rated i {
  color: #ffc700;
  font-size: 12px;
}
.home-img-text .star-rated span {
  color: #666666;
  margin-left: 8px;
  font-weight: 700;
  font-size: 12px;
}
.feature-rent .green-logo {
  position: absolute;
  top: 0;
  left: -1px;
}
.feature-rent .select-favourite-room {
  display: flex;
  gap: 8px;
  align-items: center;
  position: absolute;
  top: 15px;
  right: 20px;
}
.feature-rent .select-favourite-room i {
  color: #ffc700;
}
.feature-rent .house-location span {
  font-size: 20px;
  color: #ffffff;
  position: absolute;
  top: 225px;
  left: 30px;
}
.feature-rent .house-location p {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  position: absolute;
  top: 230px;
  right: 30px;
  font-size: 14px;
}
.favourite-small-user {
  height: 25px;
  width: 25px;
  overflow: hidden;
  border: 2px solid #fff;
  border-radius: 100px;
}
.feature-home-img {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
.feature-home-img img {
  transition: 0.5s all;
}
.feature-home-img:hover img {
  transform: scale(1.14);
}
.top-room-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
  border-bottom: 1px solid #f1f1f1;
}
.area span {
  display: flex;
  align-items: center;
  gap: 8px;
}
.real-featured-listing .owl-stage-outer {
  padding: 0 5px;
}
.choose-set {
  box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.06),
    0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
  padding: 25px;
  border-radius: 10px;
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  transition: all 0.5s;
  position: relative;
}
.choose-set:hover {
  transform: translateY(-10px);
  box-shadow: 0px 4.4px 12px -1px rgb(19 16 34 / 8%), 0px 2px 6.4px -1px rgb(19 16 34 / 5%);
  cursor: pointer;
}
.choose-set:hover  h6 {
    color: #c10037;
}
.real-why-us {
  background: #f9fafc;
}
.choose-set > span {
  background: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 10px;
  padding: 13px;
  margin-bottom: 25px;
}
.choose-set > span img {
  max-width: 100%;
}
.choose-set h6 {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  margin-bottom: 25px;
  transition: all 0.5s;
}
.choose-set p {
  font-size: 14px;
  color: #1a1a1a;
}
.companies-set {
  border: 1px solid #d5d5d5;
  border-radius: 10px;
  padding: 20px;
  background: #f1f1f1;
  transition: all 0.5s;
}
.companies-set:hover {
  box-shadow: 0px 4.4px 12px -1px rgb(19 16 34 / 10%), 0px 2px 6.4px -1px rgb(19 16 34 / 8%);
}
.companies-set:hover a {
  color: #374B5C;
}
.companies-set:hover .companies-set-content h6 {
  color: #c10037;
}
.btn-linebtn:hover {
  background-color: #c10037;
  color: #fff !important;
}
.companies-set-head {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #d5d5d5;
}
.companies-set-logo {
  margin-right: 15px;
}
.companies-set-logo span {
  width: 60px;
  height: 60px;
  display: block;
  background: #fff;
  border-radius: 50px;
  padding: 10px;
}
.companies-set-logo span img {
  width: 100%;
}
.companies-set-content h5 {
  font-size: 14px;
  color: #374b5c;
}
.companies-set-content h5 span {
  color: #c10037;
  margin-right: 6px;
  font-weight: 700;
}
.companies-set-content h6 {
  font-size: 16px;
  font-weight: 700;
  transition: all 0.5s;
}
.companies-set-count {
  padding-top: 20px;
}
.companies-set-count ul {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}
.companies-set-count ul li {
  width: 33.3%;
  text-align: center;
}
.companies-set-count ul li h6 {
  color: #c10037;
  font-weight: 700;
  margin-bottom: 10px;
}
.btn-linebtn {
  border: 1px solid #666666;
  width: 100%;
  color: #666666;
  transition: all 0.5s;
  font-size: 14px;
}
.listee-premium {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
.listee-premium.bottom-list-premium {
  margin-bottom: 0px;
}
.listee-premium-img span {
  background: #f1f1f1;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  padding: 20px;
  justify-content: center;
}
.listee-premium-img span img {
  width: 40px !important;
}
.listee-premium-img span img {
  width: 100%;
}
.listee-premium-img {
  padding-right: 15px;
}
.listee-premium-content h6 {
  font-weight: 700;
  font-size: 16px;
}
.listee-premium-content p {
  color: #666666;
  font-size: 14px;
}
.perfect-holiday-cabin-section {
  position: relative;
}
.holiday-cabin-img-slider .item::before {
  content: "";
  background: linear-gradient(
    186.56deg,
    rgba(0, 0, 0, 0.46) 3.35%,
    rgba(0, 0, 0, 0.46) 95.75%
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.holiday-cabin-info {
  position: absolute;
  top: 10%;
  z-index: 1;
}
.holiday-cabin-info .rate-per-day {
  width: 149px;
  height: 43px;
  background: #c10037;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  gap: 5px;
  margin-bottom: 10px;
}
.holiday-cabin-info .rate-per-day h6 {
  margin: 0;
  color: #fff;
  font-size: 14px;
}
.holiday-cabin-info .section-heading h2 {
  margin: 0;
  font-style: normal;
  font-size: 48px;
  color: #ffffff;
}
.holiday-cabin-info .section-heading span {
  font-size: 24px;
  color: #C10037;
}
.holiday-cabin-info .section-heading p {
  max-width: 875px;
  padding: 20px 0;
  font-size: 16px;
  color: #ffffff;
}
.holiday-cabin-info .room-categories {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: start;
}
.holiday-cabin-info .room-categories span {
  background: #fff;
  border-radius: 60px;
  width: 180px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #444444;
}
.holiday-cabin-info .room-categories span img {
  width: 18px;
}
.holiday-cabin-img-slider .owl-nav {
  display: flex;
  position: absolute;
  bottom: -141px;
}
.holiday-cabin-img-slider .owl-nav .owl-prev i,
.holiday-cabin-img-slider .owl-nav .owl-next i {
  width: 48px;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(192, 192, 192, 0.25);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s all;
}
.holiday-cabin-img-slider .owl-nav .owl-prev i {
  position: absolute;
  bottom: 188px;
  left: 125px;
}
.holiday-cabin-img-slider .owl-nav .owl-next i {
  position: absolute;
  bottom: 188px;
  left: 185px;
}
.holiday-cabin-img-slider .owl-nav .owl-prev i:hover,
.holiday-cabin-img-slider .owl-nav .owl-next i:hover {
  background: #c10037;
  color: #fff;
}
.best-room .best-room-type i {
  color: #fff;
}
.best-places-to-rent-section {
  background: #f3f3f9;
  padding: 80px 0;
}
.best-places-rent-slider .best-room {
  position: relative;
  overflow: hidden;
}
.best-places-rent-slider .best-room .best-room-type {
  position: absolute;
  bottom: 20px;
  left: 13px;
  transition: 0.5s all;
}
.best-places-rent-slider .best-room:hover .best-room-type {
  width: 90%;
  background: #c10037;
  border-radius: 5px;
  padding: 10px;
  color: #C10037;
}
.best-room-type i {
  position: absolute;
  right: 22px;
  opacity: 0;
  bottom: 25px;
}
.best-room .best-room-type h5 {
  font-size: 20px;
  color: #ffffff;
  margin: 0;
}
.best-room .best-room-type span {
  font-size: 14px;
  color: #ffffff;
}
.best-places-rent-slider .best-room:hover .best-room-type i {
  opacity: 1;
  transition: 0.5s all;
}
.best-places-rent-slider .best-room:hover .best-room-type h5 {
  color: #fff;
}
.best-places-rent-slider .best-room:hover .best-room-type span {
  color: #fff;
}
.best-places-rent-slider .owl-nav .owl-prev i,
.best-places-rent-slider .owl-nav .owl-next i {
  width: 58px;
  height: 58px;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(192, 192, 192, 0.25);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s all;
}
.best-places-rent-slider .owl-nav .owl-prev i {
  position: absolute;
  top: 200px;
  left: -30px;
}
.best-places-rent-slider .owl-nav .owl-next i {
  position: absolute;
  top: 200px;
  right: -30px;
}
.best-places-rent-slider .owl-nav .owl-prev i:hover,
.best-places-rent-slider .owl-nav .owl-next i:hover {
  background: #263238;
  color: #fff;
}
.property-counter {
  background-color: #c10037;
}
.property-counter-list h4 {
  font-weight: 800;
  font-size: 28px;
  color: #fff;
  margin-bottom: 8px;
}
.property-counter-list p {
  color: #e3e3e3;
  font-weight: 600;
  font-size: 14px;
}
.top-listings-to-rent-section {
  padding: 80px 0;
}
.top-rent-room-info {
  padding: 15px;
}
.top-rent-room {
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(236, 236, 236, 0.54);
  margin-bottom: 10px;
}
.top-rent-room .top-room-img {
  position: relative;
  overflow: hidden;
  transition: 0.5s all;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.top-rent-room .top-room-img img {
  transition: 0.5s all;
}
.top-rent-room .top-room-img:hover img {
  transform: scale(1.14);
}
.top-rent-room-info .top-rent-room-location {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid #e3e3e9;
}
.top-rent-room-info .top-room-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
}
.top-rent-room .top-listing-rounded-img {
  position: absolute;
  top: 10px;
  left: 10px;
}
.top-listing-rounded-img .favourite-small-user {
  height: 35px;
  width: 35px;
}
.top-rent-room .pent-house {
  width: 115px;
  height: 36px;
  background: #c10037;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: absolute;
  top: 10px;
  right: 10px;
}
.top-rent-room-info .top-rent-room-location .room-address span {
  font-size: 16px;
  color: #7c7c7c;
}
.top-rent-room-location .room-address span i {
  padding-right: 5px;
}
.top-rent-room-location .star-rated i {
  color: #ffc700;
}
.top-rent-room-location p {
  color: #7c7c7c;
}
.top-rent-room-location p span {
  font-size: 20px;
  color: #C10037;
}
.top-rent-room-info .top-rent-room-location .room-address h5 {
  font-size: 20px;
  color: #263238;
  margin: 0;
  padding-top: 5px;
}
.top-rent-room-info .top-rent-room-location .room-address a h5:hover {
  color: #C10037;
}
.top-room-details .area span {
  font-size: 16px;
  color: #444444;
  display: flex;
  align-items: center;
  gap: 7px;
}
.top-listings-rent-slider .owl-nav .owl-prev i,
.top-listings-rent-slider .owl-nav .owl-next i {
  width: 48px;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(192, 192, 192, 0.25);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s all;
}
.top-listings-rent-slider .owl-nav .owl-prev i {
  position: absolute;
  top: 120px;
  left: -30px;
}
.top-listings-rent-slider .owl-nav .owl-next i {
  position: absolute;
  top: 120px;
  right: -30px;
}
.top-listings-rent-slider .owl-nav .owl-prev i:hover,
.top-listings-rent-slider .owl-nav .owl-next i:hover {
  background: #263238;
  color: #fff;
}
.top-listings-rent-slider .owl-dots .owl-dot {
  height: 4px;
  background: #d9d9d9;
  border-radius: 10px;
}
.top-listings-rent-slider .owl-dots {
  text-align: center;
}
.premium-and-essential-services {
  background: #f9fafc;
}
.premium-header h2 {
  font-weight: 600;
  font-size: 20px;
  color: #000000;
  max-width: 693px;
  margin-bottom: 50px;
  margin: auto;
  margin-bottom: 50px;
}
.make-it-easy {
  background: url("../img/bg/make-it-easy.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.make-it-easy h2 {
  max-width: 710px;
  font-weight: 800;
  font-size: 48px;
  color: #fff;
}
.make-it-easy p {
  font-size: 24px;
  color: #fff;
  margin: 30px 0px;
}
.make-it-easy a {
  border-radius: 8px;
  background: #c10037;
  padding: 12px 31px;
  color: #fff;
  display: inline-block;
  border: 1px solid #c10037;
}
.make-it-easy a:hover {
  background-color: #fff;
  color: #c10037;
}
.verified-owner-section {
  background: #F9FAFC;
  padding: 80px 0;
}
.owner-card {
  background: #ffffff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.06), 0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
}
.owner-card .owner-name .double-tick i {
  background: #22C55E;
  width: 20px;
  height: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-size: 10px;
}
.owner-list-span {
    color: #c10037;
}
.owner-card .owner-card-img {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
.owner-card .owner-card-img img {
  border-radius: 10px;
  transition: 0.5s all;
}
.owner-card .owner-card-img:hover img {
  transform: scale(1.14);
}
.owner-card .owner-name {
  display: flex;
  align-items: center;
  gap: 5px;
}
.owner-name {
   padding-bottom: 8px;
}
.owner-name a  {
  font-weight: 700;
  color: #000000;
}
.double-tick {
  margin-left: 10px;
}
.owner-card .owner-info {
  padding: 15px 0 0px 0;
}
.verified-owner-slider .owl-nav .owl-prev i,
.verified-owner-slider .owl-nav .owl-next i {
  width: 48px;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(192, 192, 192, 0.25);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s all;
}
.verified-owner-slider .owl-nav {
  display: flex;
  justify-content: end;
  align-items: center;
  padding-top: 40px;
  gap: 10px;
}
.verified-owner-slider .owl-nav .owl-prev i:hover,
.verified-owner-slider .owl-nav .owl-next i:hover {
  background: #263238;
  color: #fff;
}
.verified-owner-list-btn {
  margin-top: 50px;
  border: 1px solid #C10037;
  color: #C10037;
  padding: 13px 32px;
  border-radius: 8px;
  display: inline-block;
  transition: all 0.5s;
}
.verified-owner-list-btn:hover {
  background-color: #c10037;
  color: #fff;
}
.property-for-rent {
  position: relative;
}
.testimonial-float-bg img {
  position: absolute;
  z-index: -1;
}
.real-why-us-floating-bg img {
  position: absolute;
}
.property-counter-float-bg img{
  position: absolute;
}
.preminum-float-bg img {
  position: absolute;
  right: 0;
}
.property-testimonial-item {
  padding: 30px;
  box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.06), 0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
  border-radius: 10px;
  background: #F9FAFC;
}
.property-testimonial-item:hover {
  box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.15), 0px 2px 6.4px -1px rgba(19, 16, 34, 0.10);
  cursor: pointer;
}
.property-testimonial-item .car-client-rating {
  padding: 30px 0;
  text-align: start;
}
.property-testimonial-item p {
  color: #000000;
  padding-bottom: 30px;
  margin-bottom: 0;
}
.property-profile-block {
  display: flex;
  align-items: center;
  gap: 15px;
}
.property-profile {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  border: 3px solid #FFFFFF;
  overflow: hidden;
}
.property-profile.feature-owner {
  height: 60px;
  width: 60px;
}
.property-client-details {
  display: flex;
  flex-direction: column;
}
.property-client-details a {
  color: #374B5C;
  font-size: 20px;
}
.property-client-details span {
  font-weight: 600;
  font-size: 14px;
  color: #c10037;
}
.buy-property-content {
  background: url('../img/bg/join-us.jpg');
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 200px ;
}
.buy-property-content h2 {
  font-weight: 800;
  font-size: 48px;
  color: #fff;
  max-width: 730px;
}
.buy-property-content p {
  font-weight: 500;
  font-size: 20px;
  color: #fff;
  padding: 30px 0;
  max-width: 852px;
}
.buy-property-content a {
  display: inline-block;
  background-color: #c10037;
  border-radius: 5px;
  padding: 18px 74px;
  border: 1px solid transparent;
  color: #fff;
  transition: all 0.5s;
}
.buy-property-content a:hover {
  border: 1px solid #c10037;
  background-color: #fff;
  color: #c10037;
}
.property-articles {
  background: #F9FAFC;
}
.property-articles .section-heading-nine.section-heading h2 {
  justify-content: start;
}
.property-articles .grid-blog .blog-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.foot-nine-logo p {
  font-size: 14px;
  color: #fff;
  max-width: 396px;
  line-height: 27px;
  margin-top: 20px;
}
.footer-six-center.foot-nine-list {
  padding-top: 0;
}
.social-icon-six.social-icon-nine {
  margin-top: 0;
}
.footer-six.footer-nine .footer-six-top-list ul {
  list-style:disc;
  padding-left: 20px;
}
.footer-six-top-list ul li::marker {
  color: #fff;
}
.footer-nine .footer-six-bottom {
  justify-content: space-between;
  position: relative;
}
.foot-nine-line.custom-line {
  margin: 30px 0;
}
.footer-six.footer-nine.common-padding {
  padding: 80px 0 30px 0;
}
.footer-six-bg.footer-nine-bg img {
  position: absolute;
  right: unset;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.join-us-dealer-bg.home-nine-bg img {
  position: absolute;
  left: unset;
  top: unset;
  right: 0;
  bottom: 14px;
  width: 11%;
}
.head-contact:hover i, .head-contact:hover a {
    color: #c10037;
}

/*-----------------.
	35. Home 7
-----------------------*/

.header-seven {
  background-color: transparent;
  box-shadow: none;
}
.header-seven .main-nav>li>a {
  color: #ffffff;
}
.header-seven .main-nav .has-submenu.active>a {
  color: #ffffff;
  background-color: transparent;
}
.header.header-seven.fixed .main-nav>li>a {
  background-color: transparent;
  color: #ffffff !important;
}
.header-seven .main-nav .has-submenu.active>a:before {
  position: absolute;
  content: "";
  width: 0px;
  height: 0px;
  background: #C10037;
  border-radius: 50%;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
}
.header.header-seven a.nav-link {
  color: #ffffff;
}
.header.header-eight.fixed a.nav-link {
  color: #374B5C;
}
.header-seven .main-nav li a:hover,
.header-two .main-nav li a:hover {
  color: #C10037;
}
.header-seven.fixed .main-nav li a:hover,
.header-two .main-nav li a:hover {
  color: #C10037 !important;
}
.header-seven.fixed .main-nav .has-submenu.active>a {
  color: #C10037 !important;
}
.header-seven .header-navbar-rht li>a.header-login {
  background-color: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
  box-shadow: inset 0 0 0 #ffffff;
  border-radius: 4px;
}
.header-seven .header a.nav-link {
  color: #ffffff;
}
.header-seven.fixed .header-navbar-rht li>a.header-login {
  background-color: #C10037;
  border: 1px solid #C10037;
  color: #fff;
}
.header-seven.fixed .header-navbar-rht li>a.header-login:hover {
  background-color: #fff;
  border: 1px solid #C10037;
  box-shadow: inset 0 70px 0 0 #ffffff;
  color: #C10037;
}
.header-seven.fixed {
  background-color: #0D233E;
  box-shadow: 0px 3px 53px rgb(197 197 197 / 27%);
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}
.header-seven .header-navbar-rht li>a.header-login:hover {
  background-color: #fff;
  border: 1px solid #C10037;
  box-shadow: inset 0 70px 0 0 #ffffff;
  color: #C10037;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.banner-seven .banner-bg-img .bg-one {
  position: absolute;
  top: 10px;
  right: 0px;
}
.banner-seven {
  background: linear-gradient(102.93deg, #374B5C 0%, #C10037 100%);
}
.banner-seven .banner-bg-img .bg-two {
  position: absolute;
  left: -17px;
  top: 0px;
}
.banner-seven .banner-bg-img .bg-three {
  position: absolute;
  bottom: -17px;
  left: 0px;
}
.banner-seven .banner-imgs {
  position: absolute;
  bottom: 100px;
  right: 200px;
}
.banner-seven .banner-imgs img {
  max-width: 650px;
}
.banner-seven .section-search {
  position: relative;
  z-index: 9;
  padding: 80px 0 0px;
}
.banner-seven h6 {
  font-size: 96px;
  font-weight: 400;
  color: #fff;
  color: #BABABA;
  font-family: 'Sassy Frass', cursive;
  line-height: 0.8;
  margin-bottom: 24px;
}
.banner-seven h1 {
  color: #fff;
  max-width: 612px;
}
.banner-seven p {
  font-weight: 700;
  color: #fff;
  margin-bottom: 24px;
}
.banner-seven .search-box {
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  padding: 15px;
  max-width: 730px;
}
.banner-seven .search-box label {
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  margin-bottom: 6px;
}
.banner-seven .search-box .form-control {
  background: transparent;
  border: 0;
  font-size: 14px;
  color: #fff;
  padding: 0;
  min-height: 20px;
  padding: 14px 9px 14px 35px;
}
.banner-seven::placeholder {
  color: #374B5C;
}
.banner-seven .search-box .form-control::placeholder {
  color: #fff;
}
.banner-seven .search-btn .btn-primary {
  min-width: 180px;
}
.banner-seven .search-btn .btn-primary i {
  margin: 0 0 0 10px;
}
.banner-seven .form-block {
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
}
.banner-seven .main-banner-2 img {
  position: absolute;
  top: 8px;
  right: 275px;
  max-width: 140px !important;
  animation: bouncing alternate infinite 1s;
}
@keyframes bouncing {
  to {
      transform: translateY(-10px);
  }
}
@keyframes bouncing1 {
  to {
      transform: translateX(-5px);
  }
}
.banner-seven .main-banner-3 img {
  position: absolute;
  max-width: 159px !important;
  bottom: 15px;
  right: -79px;
  animation: bouncing alternate infinite 2s;
}
.banner-seven .main-banner-4 img {
  position: absolute;
  max-width: 95px !important;
  bottom: 45px;
  left: 39px;
  animation: bouncing1 alternate infinite 2s;
}
.banner-seven .search-box .search-input {
  margin: 0 15px 0 0;
  border: 1px solid #E3E3E3;
  border-radius: 8px;
  min-width: 216px;
}
.banner-seven .form-group {
  position: relative;
}
.banner-seven .select-location i {
  position: absolute;
  left: 14px;
  top: 18px;
  color: #8d8d8d;
}
.search-box.search-box-seven .select-location .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 52px;
  padding-left: 35px;
  padding-right: 25px;
  color: #374B5C;
}
.search-box.search-box-seven .select-location .select2-container--default .select2-selection--single,
.filter-content .select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  height: 52px;
}
.search-box.search-box-seven .select2-container--default .select2-selection--single,
.filter-content .select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  height: 52px;
  min-width: 240px;
}
.banner-seven .foods-title {
  margin-top: 20px;
}
.banner-seven .foods-title a {
  padding: 2px 8px;
  color: #0D233E;
  background-color: #ffffff;
  margin-right: 10px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
}
.banner-seven .foods-title a:hover {
  color: #ffffff;
  background-color: #C10037;
}
.banner-seven ::placeholder {
  color: #B7B7B7 !important;
}
.banner-seven .home-banner {
  padding-bottom: 200px;
}

.heading-img {
  padding-right: 20px;
}
.section-heading-seven img{
  margin-bottom: 10px;
}
.our-category-section-seven {
  padding: 80px 0 56px;
}
.our-category-section-seven .more-btn.more-btn-two {
  padding: 9px 18px;
  font-weight: 600;
}
.our-category-section-seven .restaurants-sec .our-category-box .our-category-info h5 a:hover{
  color: #C10037;
}
.our-category-section-seven .our-category-img {
  margin-bottom: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  transition: all 0.5;
}
.our-category-section-seven .our-category-box {
  text-align: center;
  margin-bottom: 24px;
}
.our-category-section-seven .section-heading.heading-two {
  justify-content: center;
}
.our-category-section-seven .section-heading.heading-two img {
  justify-content: center;
  text-align: center;
  display: flex;
  margin: 0 auto;
  padding-bottom: 10px;
}
.our-category-section-seven  .our-category-img img {
  border-radius: 50%;
  transition: all 0.5s;
}
.our-category-img:hover img{
  transform: translateY(-10px);
  -webkit-filter: drop-shadow(2px 2px 2px rgba(19, 16, 34, 0.7));
  filter: drop-shadow(2px 2px 2px rgba(19, 16, 34, 0.7));
}
.restaurant-section.restaurant-section-seven {
  padding: 80px 0px 55px;
  background: url(../img/bg/honeycomb-texture-bg-1.png), rgba(193, 0, 55, 0.04);
  background-size: cover;
}
.restaurant-section.restaurant-section-seven .dishes-box {
  margin-bottom: 0px;
}
.restaurant-section.restaurant-section-seven .heading-two p {
  width: 100%;
  color: #374B5C;
  font-weight: 400;
  margin: 0;
  font-size: 16px;
}
.restaurant-section.restaurant-section-seven .dishes-box {
  position: relative;
  margin-bottom: 25px;
}
.restaurant-section.restaurant-section-seven .dishes-img {
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
.restaurant-section.restaurant-section-seven .dishes-img img {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
  -o-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
  -webkit-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
  transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
  border-radius: 20px;
  position: relative;
}
.restaurant-section.restaurant-section-seven .dishes-img:hover img {
  -webkit-transform: scale(1.35) rotate(15deg);
  -moz-transform: scale(1.35) rotate(15deg);
  transform: scale(1.35) rotate(15deg);
}
.restaurant-section.restaurant-section-seven .dishes-box .fav-item {
  position: absolute;
  top: 15px;
  right: 15px;
}
.restaurant-section.restaurant-section-seven .dishes-box .feature-content {
  position: absolute;
  top: 20px;
  left: 15px;
}
.restaurant-section.restaurant-section-seven .dishes-img .featured-text {
  background: #C10037;
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  padding: 5px 12px;
}
.restaurant-section.restaurant-section-seven .dishes-overlay {
  bottom: 0;
  left: 13px;
  right: 13px;
  text-align: center;
  width: 90%;
  background: #333;
  border-radius: 0px 0px 20px 20px;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.restaurant-section.restaurant-section-seven .dishes-review {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.restaurant-section.restaurant-section-seven .dishes-content {
  position: relative;
  top: 0;
  width: 90%;
  background-color: #ffffff;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: -46px;
}
.restaurant-section.restaurant-section-seven .dishes-content .dishes-inner {
  padding: 0 15px 15px;
}
.restaurant-section.restaurant-section-seven .dishes-content .dishes-inner h5 {
  margin: 0;
}
.restaurant-section.restaurant-section-seven .dishes-content .dishes-review h6{
  margin: 0;
}
.restaurant-section.restaurant-section-seven .dishes-content .dishes-footer  h5{
  margin: 0;
}
.restaurant-section.restaurant-section-seven .dishes-content .dishes-inner h5 a:hover {
  color: #C10037;
}
.restaurant-section.restaurant-section-seven .dishes-inner img {
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}
.restaurant-section.restaurant-section-seven .dishes-content h5 i.fa-circle-check {
  color: #22C55E;
  font-size: 11px;
}
.restaurant-section.restaurant-section-seven .dishes-inner .inner-img {
  text-align: center;
  flex-shrink: 0;
  border-radius: 50%;
}
.restaurant-section.restaurant-section-seven .dishes-inner .inner-img img {
  border-radius: 50%;
  height: 43px;
  width: 43px;
  border: 2px solid #ffffff;
}
.restaurant-section.restaurant-section-seven .author-img-new {
  position: absolute;
  display: flex;
  justify-content: center;
  left: 50%;
  right: 50%;
  top: -22px;
}
.restaurant-section.restaurant-section-seven .dishes-content .title {
  color: #B7B7B7;
  font-size: 14px;
  font-weight: 400;
  max-width: 100%;
  padding-top: 20px;
}
.restaurant-section.restaurant-section-seven .dishes-review h6 i {
  color: #B7B7B7;
  padding-right: 6px;
  padding-left: 3px;
}
.restaurant-section.restaurant-section-seven .dishes-review h6 {
  color: #666666;
  display: flex;
  align-items: center;
}
.restaurant-section.restaurant-section-seven .review-color {
  color: #FF823B !important;
}
.restaurant-section.restaurant-section-seven .dishes-review .review-color i {
  font-size: 13px;
}
.restaurant-section.restaurant-section-seven .dishes-review span {
  color: #B7B7B7;
  font-size: 12px;
  font-weight: 400;
}
.restaurant-section.restaurant-section-seven .dishes-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #F1F1F1;
  padding: 10px 15px;
  border-radius: 0px 0px 10px 10px;
}
.restaurant-section.restaurant-section-seven .dishes-footer .landmark h5 {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
}
.restaurant-section.restaurant-section-seven .dishes-footer .landmark h5 i {
  color: #B7B7B7;
}
.restaurant-section.restaurant-section-seven .dishes-footer .amount {
  color: #374B5C;
}
.restaurant-section.restaurant-section-seven .dishes-footer .amount h5 span {
  color: #B7B7B7;
  font-weight: 400;
  font-size: 12px;
}
.cities-section {
  padding: 80px 0 55px;
}
.cities-section .cities-box {
  position: relative;
  background: #fff;
  margin-bottom: 25px;
}
.cities-section .cities-box .cities-overlay-item {
  opacity: 1;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
}
.cities-section .cities-img img {
  width: 100%;
  border-radius: 10px;
}
.cities-section .cities-overlay-item {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
}
.cities-section .cities-overlay {
  border-radius: 4px;
  background: transparent;
  text-align: center;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 10px 10px;
  border: 4px solid #fff;
}
.cities-section .cities-overlay .rating {
  margin-bottom: 15px;
}
.cities-section .cities-overlay .rating i {
  color: #FFC107;
  margin-right: 0px;
}
.cities-section .cities-overlay h5 {
  font-weight: 600;
  font-size: 18px;
  color: #FFFFFF;
  margin-bottom: 5px;
}
.cities-section .cities-overlay p {
  color: #ffffff;
  margin-bottom: 0px;
  font-size: 12px;
}
.cities-section .cities-location {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 20px;
}
.cities-section .cities-location i {
  margin-right: 5px;
}
.restaurateurs-bg .restaurateurs-img {
  max-width: 100%;
  height: 100%;
}
.restaurateurs-bg .restaurateurs-img img{
  width: 100%;
  height: 100%;
}
.restaurateurs-bg .restaurateurs-main-bg {
  background: linear-gradient(104.09deg, #374B5C 42.39%, #C10037 91.11%);
}
.restaurateurs-bg .restaurateurs-content {
  padding: 40px 50px 30px;
  max-width: 100%;
}
.restaurateurs-bg .restaurateurs .right-content {
  display: flex;
  align-items: center;
  max-height: 100%;
  height: auto;
}
.restaurateurs-bg .restaurateurs-content h2 {
  color: #ffffff;
  margin-bottom: 26px;
}
.restaurateurs-bg .restaurateurs-content p {
  color: #ffffff;
  margin-bottom: 0;
}
.restaurateurs-bg .restaurateurs-content a {
  color: #C10037;
  background: #FFFFFF;
  border-radius: 6px;
  padding: 9px 18px;
  margin-top: 40px;
}
.restaurateurs-bg .restaurateurs-content a:hover {
  background-color: #C10037;
  border: 1px solid #C10037;
  box-shadow: inset 0 70px 0 0 #C10037;
  color: #fff;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.latest-restaurant-section {
  padding: 80px 0px 55px;
  background: #ffffff;
  background-size: cover;
}
.latest-restaurant-section .heading-two p {
  width: 100%;
  color: #374B5C;
  font-weight: 400;
  margin: 0;
  font-size: 16px;
}
.latest-restaurant-section .dishes-box {
  position: relative;
  margin-bottom: 25px;
}
.latest-restaurant-section .dishes-img {
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
.latest-restaurant-section .dishes-img img {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
  -o-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
  -webkit-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
  transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
  border-radius: 20px;
  position: relative;
}
.latest-restaurant-section .dishes-img:hover img {
  -webkit-transform: scale(1.35) rotate(15deg);
  -moz-transform: scale(1.35) rotate(15deg);
  transform: scale(1.35) rotate(15deg);
}
.latest-restaurant-section .dishes-box .fav-item {
  position: absolute;
  top: 15px;
  right: 15px;
}
.latest-restaurant-section .dishes-box .feature-content {
  position: absolute;
  top: 20px;
  left: 15px;
}
.latest-restaurant-section .dishes-img .featured-text {
  background: #C10037;
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  padding: 5px 12px;
}
.latest-restaurant-section .dishes-overlay {
  bottom: 0;
  left: 13px;
  right: 13px;
  text-align: center;
  width: 90%;
  background: #333;
  border-radius: 0px 0px 20px 20px;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
}
.latest-restaurant-section .dishes-review {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.latest-restaurant-section .dishes-content {
  position: relative;
  top: 0;
  width: 90%;
  background-color: #ffffff;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: -57px;
}
.latest-restaurant-section .dishes-content .dishes-inner {
  padding: 0 15px 15px;
  text-align: left;
}
.latest-restaurant-section .dishes-content .dishes-inner h5 {
  margin: 0;
}
.latest-restaurant-section .dishes-content .dishes-review h6 {
  margin: 0;
}
.latest-restaurant-section .dishes-content .dishes-footer h5 {
  margin: 0;
}
.latest-restaurant-section .dishes-inner img {
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}
.latest-restaurant-section .dishes-content h5 i.fa-circle-check {
  color: #22C55E;
  font-size: 11px;
}
.latest-restaurant-section .dishes-inner .inner-img {
  text-align: center;
  flex-shrink: 0;
  border-radius: 50%;
}
.latest-restaurant-section .dishes-inner .inner-img img {
  border-radius: 50%;
  height: 43px;
  width: 43px;
  border: 2px solid #ffffff;
}
.latest-restaurant-section .dishes-inner .author-img-new {
  position: absolute;
  display: flex;
  justify-content: center;
  left: 50%;
  right: 50%;
  top: -22px;
}
.latest-restaurant-section .dishes-content .dishes-inner h5 a:hover {
  color: #C10037;
}
.latest-restaurant-section .dishes-content .title {
  color: #B7B7B7;
  font-size: 14px;
  font-weight: 400;
  max-width: 100%;
  padding-top: 20px;
}
.latest-restaurant-section .dishes-review h6 i {
  color: #B7B7B7;
  padding-right: 6px;
  padding-left: 3px;
}
.latest-restaurant-section .dishes-review h6 {
  color: #666666;
  display: flex;
  align-items: center;
}
.latest-restaurant-section .review-color {
  color: #FF823B !important;
}
.latest-restaurant-section .dishes-review .review-color i {
  font-size: 13px;
}
.latest-restaurant-section .dishes-review span {
  color: #B7B7B7;
  font-size: 12px;
  font-weight: 400;
}
.latest-restaurant-section .dishes-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #F1F1F1;
  padding: 10px 15px;
  border-radius: 0px 0px 10px 10px;
}
.latest-restaurant-section .dishes-footer .landmark h5 {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
}
.latest-restaurant-section .dishes-footer .landmark h5 i {
  color: #B7B7B7;
}
.latest-restaurant-section .dishes-footer .amount {
  color: #374B5C;
}
.latest-restaurant-section .dishes-footer .amount h5 span {
  color: #B7B7B7;
  font-weight: 400;
  font-size: 12px;
}
.pricing-plan.pricing-plan-seven {
  padding: 80px 0px 55px;
  background: url(../img/bg/honeycomb-texture-bg-1.png), rgba(193, 0, 55, 0.04);
  background-size: cover;
}
.pricing-plan-seven .price-wrap {
  background: transparent;
  box-shadow: none;
  border-radius: none;
  margin: 30px 0;
}
.pricing-plan-seven .price-seven {
  border: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  flex-direction: column;
}
.pricing-plan-seven .price-card {
  background: #fff;
  border: 1px solid #F3F3F3;
  padding: 20px;
  z-index: 1;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 4.8px 24.4px -6px rgba(19, 16, 34, 0.1), 0px 4px 13px -2px rgba(19, 16, 34, 0.06);
  border-radius: 20px;
}
.pricing-plan-seven .price-card:hover {
  background-color: #C10037;
}
.pricing-plan-seven .price-seven .price-head h4 {
  font-size: 36px;
  font-weight: 700;
}
.pricing-plan-seven .price-head h4 {
  font-size: 28px;
  font-weight: 800;
  margin: 0 0 20px;
  color: #0D233E;
}
.pricing-plan-seven .price-seven .price-head h4 span {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}
.pricing-plan-seven .price-level {
  margin: 0 0 15px;
}
.pricing-plan-seven .price-seven.active {
  background: url(../img/bg/active-img.png), #C10037;
}
.pricing-plan-seven .price-seven.active .viewdetails-btn {
  background: #fff;
  border: 1px solid #fff;
  color: #C10037;
}
.pricing-plan-seven .price-seven .feature {
  background: #374B5C;
  border-radius: 13.5px;
  color: #fff;
  padding: 6px 16px;
  font-weight: 400;
  font-size: 12px;
  display: inline-block;
  margin-bottom: 20px;
}
.pricing-plan-seven .price-seven .price-level h6 {
  font-weight: 800;
  font-size: 28px;
  color: #0D233E;
}
.pricing-plan-seven .price-card.active {
  background: url(../img/bg/active-img.png), #C10037;
  color: #ffffff;
  transition: all 0.5s ease-in-out;
  -webkit-transition: ease all 0.5s;
}
.pricing-plan-seven .price-card:hover,
.pricing-plan-seven .price-card:hover .price-head h4,
.pricing-plan-seven .price-card:hover .price-level h6,
.pricing-plan-seven .price-card:hover .price-head h4 span,
.pricing-plan-seven .price-card:hover .price-body li {
  color: #fff;
}
.pricing-plan-seven .price-card:hover .viewdetails-btn {
  background: #fff;
  color: #C10037;
  border: 1px solid #fff;
}
.pricing-plan-seven .price-level h6 {
  color: #C10037;
  font-weight: 700;
  margin: 0 0 20px;
}
.pricing-plan-seven .price-body p,
.price-body li {
  line-height: 27px;
  color: #666;
  margin: 0 0 20px;
}
.pricing-plan-seven .price-seven .price-body li {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}
.pricing-plan-seven .price-seven .price-body ul li:before {
  content: 'none';
  font-family: 'none';
  background-image: none;
  color: none;
}
.pricing-plan-seven .price-seven .price-body ul li:before {
  position: absolute;
  content: "";
  background-image: url(../img/icons/check-circle-1.png);
  top: 10px;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
.pricing-plan-seven .price-seven.price-card:hover .price-body ul li:before {
  background-image: url(../img/icons/check-circle-2.png);
}
.pricing-plan-seven .price-card.active:hover{
  background: #C10037;
}
.pricing-plan-seven .choose-btn .viewdetails-btn i{
  margin-left: 2px;
}
.pricing-plan-seven .price-body ul li:before {
  position: absolute;
  content: "";
  top: 11px;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
.pricing-plan-seven .price-seven .viewdetails-btn {
  background: #C10037;
  border-radius: 6px;
  border: 1px solid #C10037;
  color: #fff;
  font-weight: 400;
  padding: 7px 50px;
}
.pricing-plan-seven .price-seven.active .price-body ul li:before {
  color: #333;
  background-image: url(../img/icons/check-circle-2.png);
}
.pricing-plan-seven .price-seven.price-card:hover .price-body ul li:before {
  color: #333;
}
.pricing-plan-seven .price-card:hover::before {
  display: none;
}
.price-seven.active .price-head h4,
.price-seven.active p,
.price-seven.active ul li,
.price-seven.active .price-level h6,
.price-seven.active .price-head h4 span {
  color: #fff;
}
.pop {
  display: block;
}
.price-card.price-seven:hover .pop {
  display: block;
}
.testimonial-section-seven {
  padding: 80px 0;
}
.testimonial-section-seven .testi-widget-seven {
  background: url(../img/bg/test-bg.png);
  background-repeat: no-repeat;
  background-position: top 25px left 25px;
  margin: 0;
}
.testimonial-section-seven .testi-widget-seven .icon-blk {
  width: auto;
  height: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: -60px;
}
.testimonial-section-seven .testi-widget-seven .icon-blk img {
  width: 78px;
  height: 78px;
  border-radius: 50%;
}
.testimonial-section-seven .testi-carousel .owl-stage-outer {
  padding-top: 60px !important;
}
.testimonial-section-seven .testi-widget-seven {
  background-color: #FFFFFF;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 0px;
  text-align: center;
  border-bottom: 4px solid #C10037;
}
.testimonial-section-seven .test-details img {
  margin-bottom: 15px;
}
.testimonial-section-seven .testi-widget-seven .people-name h5 {
  color: #4E4E4E;
  margin-bottom: 4px;
}
.testimonial-section-seven .testi-widget-seven .testi-location i{
  margin-right: 3px;
}
.testimonial-section-seven .testi-widget .test-details:after {
  position: absolute;
  content: "";
  background: url(../img/bg/test-bg.png) no-repeat left top;
  width: 80px;
  height: 80px;
  top: 34px;
  opacity: 0.05;
  left: 20px;
}
.testimonial-section-seven .review {
  color: #FCAF01;
  font-size: 12px;
  margin-bottom: 25px;
}
.testimonial-section-seven .testi-content h5 {
  font-size: 25px;
  color: #000000;
  margin-bottom: 10px;
}
.testimonial-section-seven .testi-content p {
  font-size: 14px;
  line-height: 27px;
  color: #000000;
  margin-bottom: 15px;
}
.testimonial-section-seven .testi-location {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  grid-gap: 12px;
  justify-content: center;
}
.testimonial-section-seven .testi-location h6 {
  font-size: 16px;
  line-height: 20px;
  color: #374B5C;
  margin: 0;
}
.testimonial-section-seven .testi-location i {
  color: #B1B1B1;
}
.testimonial-section-seven .testi-location p {
  color: #666666;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}
.testimonial-section-seven .test-slider.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 20px;
  display: none;
}
.blog-seven-section {
  padding: 80px 0 160px;
  background: url(../img/bg/honeycomb-texture-bg-1.png), rgba(193, 0, 55, 0.04);
  background-size: cover;
}
.blog-seven-section .grid-blog-seven:hover {
  box-shadow: none;
}
.blog-seven-section .grid-blog-seven .blog-image-seven {
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  position: relative;
}
.blog-seven-section .blog-content-seven .blog-description-seven {
  font-size: 16px;
  margin-bottom: 0 0 16px;
}
.blog-seven-section .blog-content-seven .blog-category-seven a span {
  color:  #0D233E;
  background: #DEE2E7;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 12px;
}
.blog-seven-section .blog-content-seven .entry-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0px;
}
.blog-seven-section .grid-blog-seven .entry-meta li {
  margin-bottom: 15px;
  font-size: 12px;
  color: #9c9c9c;
}
.blog-seven-section .blog-content-seven {
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px 4px 14px rgba(226, 226, 226, 0.25);
  border-radius: 0px 0px 10px 10px;
}
.blog-seven-section .blog-seven-section .blog-content .post-author {
  display: flex;
  align-items: center;
}
.blog-seven-section .blog-content-seven .post-author-img,
.bloglist-content .post-author-img {
  width: 25px;
  margin-right: 7px;
}
.blog-seven-section .grid-blog-seven .post-author img,
.bloglist-content .post-author-img img {
  width: 25px;
  height: 25px;
  border-radius: 50px;
}
.blog-seven-section .blog.grid-blog-seven .post-author a {
  margin-bottom: 15px;
  font-size: 12px;
  color: #B7B7B7;
}
.blog-seven-section .grid-blog-seven .entry-meta li {
  margin-bottom: 0px;
  font-size: 12px;
  color: #9c9c9c;
}
.blog-seven-section .grid-blog-seven .blog-title {
  line-height: 30px;
  margin: 0 0 15px;
}
.blog-seven-section .grid-blog-seven .blog-title a {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #0D233E;
}
.blog-seven-section .blog-content-seven .blog-description {
  margin: 0 0 5px 0px;
  line-height: 27px;
  height: 88px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blog-seven-section .blog-content-seven .viewlink {
  margin: 0;
}
.blog-seven-section .blog-seven-section .viewlink a {
  font-size: 16px;
  color: #374B5C;
  display: flex;
  align-items: center;
}
.blog-seven-section .blog-seven-section .viewlink a i {
  margin-left: 7px;
}
.blog-seven-section .blog-image-seven img {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
  -o-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
  -webkit-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
  transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
  width: 100%;
}
.blog-seven-section .blog-image-seven :hover img {
  -webkit-transform: scale(1.35) rotate(15deg);
  -moz-transform: scale(1.35) rotate(15deg);
  transform: scale(1.35) rotate(15deg);
}
.blog-seven-section .blog-date {
  background: #C10037;
  border-radius: 4px;
  padding: 5px 10px;
  position: absolute;
  top: 20px;
  left: 20px;
  text-align: center;
}
.blog-seven-section .blog-date h4 {
  color: #FFFFFF;
  line-height: 20px;
  font-weight: 800;
  margin: 0;
}
.blog-seven-section .blog-date span {
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
}
.blog-seven-section .post-author-seven {
  display: flex;
  grid-gap: 12px;
  align-items: center;
}
.blog-seven-section .post-author-img-seven img {
  border-radius: 50%;
  height: 31px;
  width: 31px;
}
.grid-blog-seven .blog-title a:hover {
  color: #C10037;
}
.footer-seven .stay-tuned {
  color: #fff;
  text-align: center;
  border-radius: 10px;
  margin-top: -88px;
  padding: 50px;
  position: relative;
  overflow: hidden;
  background: #C10037;
  background-repeat: no-repeat;
  background-position: center;
}
.footer-seven .stay-tuned form {
  display: block;
}
.footer-seven .footer-right-part {
  position: relative;
}
.footer-seven .stay-tuned form .btn-primary:hover {
  color: #fff;
  border: 2px solid #fff;
  background: #C10037;
  box-shadow: none;
}
.footer-seven .stay-tuned form .btn-primary {
  font-weight: normal;
  padding: 7px 30px;
  font-size: 15px;
  border-radius: 30px;
  position: absolute;
  top: 50%;
  right: 10px;
  color: #C10037;
  font-weight: 700;
  border: 2px solid #fff;
  transform: translateY(-50%);
}
.footer-seven .stay-tuned form input {
  width: 100%;
  min-height: 60px;
  background: #97002B;
  border-radius: 30px;
  border: 2px solid #97002B;
  font-size: 14px;
  margin: 0;
  padding: 16px 110px 16px 45px;
  color: #ffffff;
}
.footer-seven .stay-tuned .group-img i {
  color: #ffffff;
  position: absolute;
  top: 22px;
  left: 14px;
}
.footer-seven .footer-left-part {
  text-align: left;
}
.footer-seven .stay-tuned .form-group ::placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.footer-seven .footer-left-part h4 {
  text-align: left;
  color: #FFFFFF;
  font-weight: 800;
}
.footer-seven .footer-left-part p {
  font-weight: 400;
  font-size: 16px;
  margin: 0;
}
.footer.footer-seven {
  background: #0D233E;
  margin-top: 0px;
  border-top: 1px solid transparent;
}
.footer-seven .footer-contact-info .footer-icon {
  margin: 0;
  padding-right: 10px;
}
.footer-seven .footer-contact-info .footer-icon i {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
}
.footer-seven .footer-address p {
  color: #9A9EA6;
}
.footer-seven .footer-address {
  display: flex;
  align-items: normal;
  justify-content: flex-start;
  margin: 0 0 25px;
}
.footer.footer-seven .footer-menu ul li a {
  font-size: 16px;
  font-weight: 400;
  color: #9A9EA6;
}
.footer-seven .footercount {
  border-top: none;
  margin: 0;
  padding: 50px 0;
}
.footer.footer-seven .social-icon ul li a {
  color: rgba(193, 0, 55, 1);
  font-size: 20px;
  transition: all 0.4s ease 0s;
  border-radius: 50%;
  padding: 15px 15px;
  border: 1px solid #666;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 45px;
  width: 45px;
  background: #E3EBFD;
}
.footer-seven .social-icon {
  margin: 0;
}
.footer-seven .footercount p {
  color: #9A9EA6;
  font-size: 14px;
  font-weight: 500;
}
.footer.footer-seven .footer-top .footer-title {
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 24px;
}
.footer.footer-seven .social-icon ul li a:hover {
  color: #fff;
  background: #C10037;
}

/*-----------------.
	36. Home 8
-----------------------*/

.header.header-eight {
  background: transparent;
  box-shadow: none;
}
.header.header-eight.fixed {
  background-color: #fff;
  box-shadow: 0px 3px 53px rgb(197 197 197 / 27%);
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}
.header-eight .header-navbar-rht li>a.header-login.add-listing {
  background-color: #C10037;
  border: 1px solid #C10037;
  display: flex;
  align-items: center;
  padding: 9px 20px;
}
.banner-eight {
  position: relative;
  background: rgba(193, 0, 55, 0.07);
  padding: 85px 0 0;
}
.bannerleftop {
  position: absolute;
  top: 110px;
  left: 13px;
}
.bannerleftbottom {
  position: absolute;
  bottom: 32px;
  left: 75px;
}
.bannermiddle {
  position: absolute;
  top: 93px;
  right: 54%;
}
.main-banner-right {
  position: absolute;
  bottom: -38px;
  right: 0px;
}
.main-banner-right-shadow {
  position: absolute;
  bottom: -81px;
  z-index: -9999999;
  right: 4px;
}
.main-banner-left {
  position: absolute;
  right: 535px;
  bottom: 0;
  z-index: -9;
}
.main-banner-left-shadow {
  position: absolute;
  right: 483px;
  bottom: -21px;
  z-index: -9999;
}
.banner-eight .banner-section h1 {
  font-weight: 800;
  font-size: 50px;
  color: rgba(55, 75, 92, 1);
  margin-bottom: 25px;
}
.banner-eight .banner-imgs img {
  max-width: -webkit-fill-available;
  margin-bottom: -65px;
}
.banner-imgs-2 {
  position: absolute;
  top: 84px;
  right: 30px;
  z-index: -9999999;
}
.banner-eight .banner-section p {
  color: #666666;
  margin-bottom: 30px;
}
.banner-section.banner-eight .search-box .group-img input {
  padding: 14px;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  min-width: 196px;
}
.popular-search {
  margin-top: 4px;
}
.popular-search p {
  margin: 0;
  padding-left: 3px;
}
.popular-search p a {
  font-size: 14px;
  border-radius: 4px;
  padding: 0px 8px;
  background: #FFFFFF;
  margin: 0 5px;
}
.popular-search p a:hover{
  color: #C10037;
}
.topic-content img {
  background-color: #C10037;
  padding: 12px;
  overflow: visible;
  border-radius: 50%;
  overflow-clip-margin: content-box;
}
.topic-content {
  width: 210px;
  padding: 10px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  border-radius: 30px 30px 0px 30px;
}
.topic-content.one .topic-img {
  margin-right: 10px;
}
.topic-content.two .topic-img {
  margin-right: 10px;
}
.topic-content.three .topic-img {
  margin-left: 10px;
}
.topic-content.four .topic-img {
  margin-left: 10px;
}
.topic-content h4 {
  font-size: 14px;
  font-weight: 600;
  color: #374B5C;
}
.post-author-btn a:hover {
  color: #000;
}
.right-ads a:hover {
  background: #c10037;
  color: #fff;
}
.search-box .search-input.line .group-img input {
  min-width: 300px;
}
.topic-content p {
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  margin: 0;
}
.topic-content.one {
  position: absolute;
  top: 149px;
  right: 505px;
}
.topic-content.two {
  position: absolute;
  top: 46%;
  right: 498px;
}
.topic-content.three {
  border-radius: 30px 30px 30px 0px;
  position: absolute;
  right: 60px;
  top: 190px;
}
.cta-section h3 span.counterUp {
  color: #374B5C;
}
.topic-content.four {
  border-radius: 30px 30px 30px 0px;
  position: absolute;
  right: 0px;
  bottom: 300px;
}
.banner-imgs {
  position: absolute;
  right: 62px;
  bottom: 0;
}
.category-section.category-eight {
  padding: 100px 0 80px;
}
.heading-eight h2 {
  display: block;
  color: #374B5C;
  margin: 0 0 10px;
  font-weight: 700;
  font-size: 40px;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}
.heading-eight p {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}
.category-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 90px;
  padding: 15px;
  margin-bottom: 25px;
  background: #FFFFFF;
  border: 1px solid #F1F1F1;
  box-shadow: 0px 4.4px 20px -1px rgba(19, 16, 34, 0.05), 0px 4px 9px -1px rgba(19, 16, 34, 0.03);
  border-radius: 10px;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
}
.category-inner:hover {
  background: #C10037;
  border: 1px solid #F1F1F1;
  box-shadow: 0px 8px 18px -8px rgba(193, 0, 55, 0.15);
}
.category-inner:hover h4,
.category-inner:hover p {
  color: #ffffff;
}
.category-inner:hover .category-img-two {
  background-color: #FFFFFF;
}
.category-inner h4 {
  font-weight: 700;
  font-size: 18px;
  color: #374B5C;
}
.category-img-two {
  background-color: rgba(193, 0, 55, 0.06);
  padding: 15px;
  border-radius: 50%;
  margin-right: 15px;
}
.category-btn {
  border: 1px solid #374B5C;
  padding: 7px 28px;
  border-radius: 6px;
  margin: 0;
  display: inline-block;
  color: #374B5C;
}
.category-btn:hover {
  border: 1px solid #ffffff;
  background: #C10037;
  color: #ffffff;
}
.vaciencies-section.vaciencies-eight {
  background: rgba(193, 0, 55, 0.03);
  padding: 80px 0;
  position: relative;
}
.vacancies-box {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0px 4.4px 20px -1px rgba(19, 16, 34, 0.05), 0px 4px 9px -1px rgba(19, 16, 34, 0.03);
  border-radius: 10px;
  margin-bottom: 25px;
}
.cat-bg-one {
  position: absolute;
  top: 206px;
  left: 20px;
  z-index: -1;
}
.cat-bg-two {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: -1;
}
.vacancies-top {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  color: #666666;
}
.fav-icon-top {
  width: 35px;
  height: 35px;
  background: #FCF0F3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border-radius: 50%;
  color: #666666;
}
.vacancies-top .topic {
  background: #FFF7F9;
  border-radius: 4px;
  padding: 0px 8px;
  font-size: 14px;
  font-weight: 400;
}
.vacancies-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  grid-gap: 10px;
  margin-top: 15px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.vaca-img {
  background: #FFFFFF;
  border: 1px solid #F1F1F1;
  border-radius: 10px;
  padding: 0px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
}
.vacancies-content h4 {
  font-size: 20px;
  margin-bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.vaca-location p {
  margin: 0;
  font-size: 14px;
}
.vaca-location {
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  grid-gap: 8px;
  justify-content: space-between;
}
.vaca-details {
  padding-bottom: 24px;
  padding-top: 27px;
  color: #666666;
}
.vaca-footer {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #F1F1F1;
  padding-top: 25px;
  align-items: center;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}
.vaca-footer p {
  color: #374B5C;
  font-size: 16px;
  font-weight: 500;
}
.light-color {
  color: #DEDEDE;
}
.p-rate {
  margin-left: 10px;
}
.footer-btn .btn-view {
  padding: 6px 8px;
}
.vacancies-box:hover {
  background: #FFFFFF;
  box-shadow: 0px 8px 18px -8px rgba(193, 0, 55, 0.15);
}
.vacancies-box:hover .fav-icon-top {
  background: #C10037;
  color: #FCF0F3;
}
.vacancies-box:hover .btn-view {
  background: #C10037;
  border: 1px solid #C10037;
  color: #fff;
}
.vacancies-box:hover .vacancies-content h4 a {
  color: #C10037;
}
.cta-section.job-section-eight {
  position: relative;
  padding: 80px 0 120px;
  background-image: none;
  background-repeat: no-repeat;
  background-position: right center;
}
.job-role {
  background: #FFFFFF;
  box-shadow: 0px 4.8px 33px -6px rgba(19, 16, 34, 0.06), 0px 4px 13px -2px rgba(19, 16, 34, 0.06);
  border-radius: 10px;
  display: flex;
  align-items: center;
  position: absolute;
  padding: 15px;
  justify-content: space-between;
  grid-gap: 12px;
}
.job-role p {
  font-weight: 500;
  font-size: 18px;
  color: #9C9C9C;
  margin-bottom: 5px;
}
.job-role h3 {
  font-weight: 700;
  font-size: 25px;
  color: #374B5C;
  margin: 0;
}
.job-role.job-one {
  position: absolute;
  left: -89px;
  top: 84px;
}
.job-role.job-two {
  position: absolute;
  top: 4px;
  right: -61px;
}
.job-role.job-three {
  position: absolute;
  right: -19px;
  bottom: 131px;
}
.job-role.job-four {
  position: absolute;
  left: 34px;
  bottom: -37px;
}
.preferred-part {
  display: flex;
  justify-content: flex-start;
  grid-gap: 20px;
}
.prefer-point {
  width: 60px;
  height: 60px;
  background: #FDF7F9;
}
.prefer-point {
  width: 60px;
  border-radius: 50%;
  padding: 24px;
  height: 57px;
  background: #FDF7F9;
}
.cta-content.job-content p {
  margin-bottom: 30px;
}
.cta-content.job-content {
  margin-left: 56px;
}
.partners-section.partner-section-eight {
  background: #FDF7F9;
  padding: 80px 0;
}
.partner-section-eight .partnerslist_eight li a {
  background: #FFFFFF;
  border: 2px solid #F5F5F5;
  border-radius: 10px;
  padding: 20px 17px;
  display: flex;
  height: 79px;
  align-items: center;
}
.partner-section-eight .owl-dots {
  display: none;
}
.popular-section.popular-eight {
  padding: 80px 0;
  position: relative;
  background: none;
}
.popular-box {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0px 4.4px 20px -1px rgba(19, 16, 34, 0.05), 0px 4px 9px -1px rgba(19, 16, 34, 0.03);
  border-radius: 10px;
  margin-bottom: 25px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.pop-bg-one {
  position: absolute;
  top: 206px;
  left: 20px;
  z-index: -9;
}
.pop-bg-two {
  position: absolute;
  bottom: 111px;
  right: 0px;
  z-index: -9;
}
.popular-top {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  color: #666666;
}
.fav-icon-top {
  width: 35px;
  height: 35px;
  background: #FCF0F3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  border-radius: 50%;
  color: #666666;
}
.popular-top .topic {
  background: #FFF7F9;
  border-radius: 4px;
  padding: 0px 8px;
  font-size: 14px;
  font-weight: 400;
}
.popular-inner {
  display: flex;
  height: 65px;
  align-items: center;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  grid-gap: 10px;
  margin-top: 15px;
}
.popular-img {
  background: #FFFFFF;
  border: 1px solid #F1F1F1;
  border-radius: 10px;
  padding: 15px;
}
.popular-content h4 {
  font-size: 20px;
  margin-bottom: 10px;
}
.popular-location p {
  margin: 0;
  font-size: 14px;
}
.popular-location {
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  grid-gap: 8px;
  justify-content: space-between;
}
.popular-details {
  padding-bottom: 0px;
  padding-top: 27px;
  color: #666666;
}
.popular-footer {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #F1F1F1;
  padding-top: 25px;
  align-items: center;
  transition: ease all 0.5s;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  margin-top: 24px;
}
.popular-footer p {
  color: #374B5C;
  font-size: 16px;
  font-weight: 500;
}
.light-color {
  color: #DEDEDE;
}
.p-rate {
  margin-left: 10px;
}
.popular-btn .btn-view {
  padding: 6px 8px;
}
.popular-box:hover {
  background: #FFFFFF;
  box-shadow: 0px 8px 18px -8px rgba(193, 0, 55, 0.15);
}
.popular-box:hover .fav-icon-top {
  background: #C10037;
  color: #FCF0F3;
}
.popular-box:hover .btn-view {
  background: #C10037;
  border: 1px solid #C10037;
  color: #fff;
}
.popular-box:hover .popular-content h4 a {
  color: #C10037;
}
.popular-section.popular-eight .vacancies-content h4 {
  font-size: 20px;
  margin-bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.adv-box {
  display: flex;
  align-items: center;
  padding: 0px 34px 0;
  border-radius: 10px;
}
.adv-bg-one {
  background: url(../img/ads-bg-1.png) #0D233E;
  background-repeat: no-repeat;
  background-position: left top;
}
.adv-bg-two {
  background: url(../img/ads-bg-2.png) #C10037;
  background-repeat: no-repeat;
  background-position: left top;
}
.adv-content {
  margin-left: 10px;
}
.adv-content h4 {
  color: #ffffff;
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 20px;
}
.adv-content p {
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}
.adv-content .btn-primary {
  color: #fff;
  padding: 9px 19px;
  border-radius: 6px;
}
.adv-content .btn-primary:hover {
  color: #C10037;
}
.adv-content .btn-primary.btn-dark {
  background-color: #0D233E;
  border: 1px solid #0D233E;
}
.adv-content .btn-primary.btn-dark:hover {
  color: #0D233E;
}
section.advertisement {
  position: relative;
  margin-bottom: -128px;
  z-index: 9;
}
.pricingplan-section.pricing-eight {
  background: url(../img/pricing-main-bg.png) #F5F8FD;
  padding: 200px 0 80px;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  position: relative;
}
.pricing-eight .interset-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.pricing-eight .interset-btn {
  margin-bottom: 0px;
  margin-top: 50px;
}
.pricing-eight .section-heading {
  margin: 0 0 30px;
}
.pricing-eight .price-body li {
  line-height: 20px;
  position: relative;
  padding: 5px 0 0 5px;
  margin: 0 0 15px;
  color: #9C9C9C;
  font-size: 16px;
}
.pricing-eight .price-body ul li:before {
  display: none;
}
.pricing-eight .price-body ul {
  margin: 0 0 20px;
  text-align: center;
}
.pricing-eight .price-card {
  text-align: center;
}
.pricingplan-section.pricing-eight .viewdetails-btn {
  padding: 9px 15px;
  background: #FFFFFF;
  border: 1px solid #F1F1F1;
  border-radius: 4px;
}
.pricingplan-section.pricing-eight .price-level h2 {
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  color: #C10037;
}
.pricingplan-section.pricing-eight .price-head h3 {
  font-weight: 800;
  font-size: 24px;
  color: #374B5C;
  line-height: 35px;
}
.pricingplan-section.pricing-eight .price-head h3 span {
  font-weight: 400;
  font-size: 16px;
  color: #B7B7B7;
}
.price-card.price-content .price-head h4 {
  line-height: 35px;
  font-size: 16px;
}
.price-card.price-content {
  text-align: left;
  background: transparent;
  padding-left: 0px;
  border: none;
}
.pricing-eight .price-card.price-content .price-body ul {
  margin: 0 0 20px;
  text-align: left;
}
.pricing-eight .price-card:hover::before {
  display: none;
}
.pricing-eight .price-card:hover {
  background: #FFFFFF;
  box-shadow: 0px 8px 18px -8px rgba(19, 16, 34, 0.15);
  border-radius: 10px;
}
.pricing-eight .price-card.main-price {
  background: #FFFFFF;
  border-radius: 10px;
  transition: ease all 0.2s;
  -webkit-transition: ease all 0.2s;
  -ms-transition: ease all 0.2s;
  border: none;
}
.pricing-eight .price-card:hover .price-body p,
.pricing-eight .price-card:hover .price-body li {
  color: #9C9C9C;
}
.pricing-eight .price-card.price-content:hover {
  background-color: transparent;
  box-shadow: none;
  border: none;
}
.price-card.price-content:hover h4 {
  color: #0D233E;
}
.pricingplan-section.pricing-eight .price-card:hover .viewdetails-btn {
  background: #C10037;
  border: 1px solid #C10037;
  border-radius: 4px;
  color: #ffffff;
}
.pricing-pg-one {
  position: absolute;
  top: 155px;
  left: 30px;
}
.pricing-pg-two {
  position: absolute;
  right: 21px;
  bottom: 143px;
}
.into-color {
  color: #EF4444 !important;
}
.testimonial-section-eight {
  padding: 80px 0;
  position: relative;
}
.testimonial-section-eight .testi-widget-eight .icon-blk {
  width: auto;
  height: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: -60px;
}
.testimonial-section-eight .testi-widget-eight .icon-blk img {
  width: 78px;
  height: 78px;
  border-radius: 50%;
  border: 2px solid #F5F8FD;
}
.testimonial-section-eight .testi-carousel .owl-stage-outer {
  padding-top: 60px !important;
}
.testimonial-section-eight .testi-widget-eight {
  background: #dfdfe1;
  backdrop-filter: blur(10px);
  border-radius: 42px;
  padding: 25px;
  margin-bottom: 0px;
  text-align: center;
  box-shadow: 0px 4.8px 24.4px -6px rgba(19, 16, 34, 0.1), 0px 4px 13px -2px rgba(19, 16, 34, 0.06);
}
.testimonial-section-eight .test-details img {
  margin-bottom: 15px;
}
.testimonial-section-eight .people-name h5 {
  color: #4E4E4E;
}
.testimonial-section-eight .testi-widget .test-details:after {
  position: absolute;
  content: "";
  /* background: url(../img/home-7/test-bg.png) no-repeat left top; */
  width: 80px;
  height: 80px;
  top: 34px;
  opacity: 0.05;
  left: 20px;
}
.testi-content p {
  font-size: 14px;
  line-height: 27px;
  color: #666666;
  margin-bottom: 20px;
}
.testi-location {
  display: block;
  align-items: center;
  -webkit-align-items: center;
  grid-gap: 12px;
  justify-content: center;
}
.testi-location h4 {
  font-weight: 600;
  font-size: 20px;
  color: #0D233E;
}
.testi-location i {
  color: #B1B1B1;
}
.testi-location p {
  color: #666666;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  font-size: 12px;
}
.test-slider.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 20px;
  display: none;
}
.test-bg {
  position: absolute;
  top: 138px;
  left: 32px;
}
.job-slider .owl-prev, .owl-next {
  border: 1px solid #DEE2E7;
  border-radius: 10px;
  color: #7F7F7F;
  background: #fff;
  width: 44px;
  height: 44px;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.mynav4 .owl-next {
  position: absolute;
  top: 55%;
  right: 16px;
}
.mynav4 .owl-prev {
  position: absolute;
  top: 55%;
  left: 16px;
}
.blog-eight-section {
  padding: 0px 0 80px;
  background: #ffffff;
  position: relative;
}
.blog-eight-section .grid-blog-eight:hover {
  box-shadow: none;
}
.blog-eight-section .grid-blog-eight .blog-image-eight {
  overflow: hidden;
  position: relative;
}
.blog-eight-section .blog-content-eight p {
  font-size: 14px;
  margin: 0 0 15px;
  color: #9C9C9C;
}
.blog-category-eight a {
  background: #DEE2E7;
  padding: 10px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  color: #374B5C;
  margin: 0 10px 0px 0;
  display: inline-block;
  line-height: normal;
}
.blog-content-eight .entry-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0px;
}
.grid-blog-eight .entry-meta li {
  margin-bottom: 0px;
  font-size: 15px;
  color: #9c9c9c;
}
.blog-content-eight {
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px 4.4px 20px -1px rgba(19, 16, 34, 0.05), 0px 4px 9px -1px rgba(19, 16, 34, 0.03);
}
.blog-eight-section .blog-content .post-author {
  display: flex;
  align-items: center;
}
.blog-content-eight .post-author-img,
.bloglist-content .post-author-img {
  width: 25px;
  margin-right: 7px;
}
.grid-blog-eight .post-author img,
.bloglist-content .post-author-img img {
  width: 25px;
  height: 25px;
  border-radius: 50px;
}
.blog.grid-blog-eight .post-author a {
  margin-bottom: 15px;
  font-size: 12px;
  color: #B7B7B7;
}
.grid-blog-eight .entry-meta li a span {
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 14px;
  color: #131022;
}
.grid-blog-eight .blog-title {
  line-height: 30px;
  margin: 0 0 15px;
}
.grid-blog-eight .blog-title a {
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  color: #0D233E;
}
.blog-content-eight .blog-description {
  margin: 0 0 5px 0px;
  line-height: 27px;
  height: 88px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blog-content-eight .viewlink {
  margin: 0;
}
.blog-eight-section .viewlink a {
  font-size: 16px;
  color: #374B5C;
  display: flex;
  align-items: center;
}
.blog-eight-section .viewlink a i {
  margin-left: 7px;
}
.blog-image-eight img {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -moz-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
  -ms-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
  -o-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
  -webkit-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
  transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
  width: 100%;
}
.blog-image-eight :hover img {
  -webkit-transform: scale(1.35) rotate(0deg);
  -moz-transform: scale(1.35) rotate(0deg);
  transform: scale(1.35) rotate(0deg);
}
.blog-eight-section .blog-date {
  background: #C10037;
  border-radius: 4px;
  padding: 5px 10px;
  position: absolute;
  top: 20px;
  left: 20px;
  text-align: center;
}
.blog-eight-section .blog-date h4 {
  color: #FFFFFF;
  line-height: 20px;
  font-weight: 800;
  margin: 0;
}
.blog-eight-section .blog-date span {
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
}
.blog-eight-section .post-author-eight {
  display: flex;
  grid-gap: 10px;
  align-items: center;
}
.blog-eight-section .post-author-img-eight img {
  border-radius: 50%;
  height: 31px;
  width: 31px;
}
.grid-blog-eight .blog-title a:hover {
  color: #C10037;
}
.post-author-btn a {
  color: #C10037 !important;
  font-weight: 400;
  font-size: 16px;
}
.post-author-btn a i {
  margin-left: 5px;
}
.blog-eight-section .category-btn {
  border: 1px solid #374B5C;
  padding: 7px 28px;
  border-radius: 6px;
  margin: 25px 0 0 0;
  display: inline-block;
  color: #374B5C;
}
.blog-eight-section .category-btn:hover {
  border: 1px solid #ffffff;
  background: #C10037;
  color: #ffffff;
}
.blog-eight-section {
  background: url(../img/news-article-bg.png);
  background-repeat: no-repeat;
  background-position: right top 50px;
  background-size: auto;
  position: relative;
}
.advertisement-section {
  background-color: #C10037!important;
  position: relative;
}
.advertisement-section::before{
  content: '';
  background: url(../img/bg-left-1.png), url(../img/bg-right-1.png) ;
  background-repeat: no-repeat;
  background-position: left bottom, right bottom;
  background-size: contain, contain;
  margin: 0 !important;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  position: absolute;
}
.ads-img {
  background: url(../img/ads-left-banner.png);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: contain;
}
.right-ads{
  position: relative;
  z-index: 99;
}
.right-ads h1 {
  color: #ffffff;
  font-size: 40px;
  font-weight: 800;
}
.right-ads p {
  font-weight: 400;
  font-size: 14px;
  color: #F1F1F1;
}
.right-ads a {
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  color: #C10037;
  font-size: 16px;
  padding: 9px 20px;
  font-weight: 700;
}
.ads-content {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
}
.footer-eight .footercount {
  border-bottom: 1px solid #F1F1F1;
  margin: 0px 0 50px;
  padding: 0px 0 50px;
  border-top: none;
}
.footer.footer-eight {
  background: url(../img/footer-bg-1.png) #FFFFFF;
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: contain;
  margin: 0 !important;
}
.footer-logo-sec {
  text-align: center;
  margin-bottom: 40px;
}
.footer .footer-top {
  padding-top: 0px;
}
.visitor-content {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}
.footer-eight .footercount .visitor-title {
  font-size: 14px;
  color: #AAAAAA;
  font-weight: 400;
  margin-bottom: 2px;
}
.footer-eight .footercount .visitors-value {
  font-weight: 700;
  font-size: 16px;
  color: #374B5C;
}
.footer.footer-eight .footer-title {
  color: #C10037;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 24px;
}
.footer.footer-eight .footer-menu ul li a {
  font-weight: 400;
  font-size: 16px;
  color: #374B5C;
}
.footer.footer-eight .update-form .form-control {
  min-height: auto;
  background: #ffffff;
  border-radius: 0px;
  border: 1px solid #AAAAAA;
  font-size: 14px;
  margin: 0;
  padding: 10px;
  color: #AAAAAA;
}
.footer.footer-eight .update-form .btn {
  font-weight: normal;
  padding: 7px 15px;
  font-size: 15px;
  border-radius: 0px;
  position: relative;
  top: 0px;
  margin-top: 15px;
  right: 0px;
  transform: none;
  max-width: 100%;
  width: 100%;
}
.footer.footer-eight .policy-menu li::after {
  display: none;
}
.footer.footer-eight .policy-menu li {
  display: inline-block;
  margin-right: 24px;
}
.footer.footer-eight .footer-bottom .copyright {
  padding: 20px 0;
  border-top: 1px solid #F1F1F1;
}
.footer.footer-eight .footer-bottom {
  background: #FFFFFF;
  margin-top: 50px;
}
.footer .footer-bottom .copyright-text p {
  color: #374B5C;
}
.footer.footer-eight .footer-bottom .copyright-text p {
  color: #0d233e;
}
.footer-eight .policy-menu li a {
  color: #374B5C;
}
.testimonial-section-eight .testimonial-nav .owl-prev {
  position: absolute;
  top: 61%;
  left: 16px;
}
.testimonial-section-eight .testimonial-nav .owl-next {
  position: absolute;
  top: 61%;
  right: 16px;
}
.header-eight.fixed .header-navbar-rht li>a.header-login.add-listing {
  color: #fff;
}
.header-eight.fixed .header-navbar-rht li>a.header-login.add-listing:hover {
  color: #c10037;
}

/*-----------------.
	38. Footer
-----------------------*/

.footer {
  background-color: #1a1a1a;
  /* margin-top: 230px; */
  border-top: 1px solid transparent;
}
.footer-content {
  max-width: 304px;
}
.footer-content p {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 27px;
}
.footer .footer-top {
  padding: 50px 0 0;
}
.footer-logo {
  margin-bottom: 20px;
}
.footer-title {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
}
.footer-subtitle {
  font-size: 20px;
  font-weight: 600;
  color: #374b5c;
  margin-bottom: 18px;
}
.footer-six-top {
  padding-bottom: 0px;
}
.footer-nine-top {
  padding-bottom: 40px;
}
.footer .footer-widget.footer-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  outline: none;
}
.footer .footer-menu ul li {
  line-height: 35px;
  position: relative;
  display: block;
  text-transform: capitalize;
}
.footer .footer-menu ul li:last-child {
  margin-bottom: 0;
}
.footer .footer-menu ul li a {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
.footer .footer-widget.footer-menu ul li a:hover {
  color: #c10037;
}
.footer-contact-info {
  color: #0d233e;
  font-size: 14px;
}
.footer-contact-info p {
  color: #fff;
  position: relative;
}
.footer-address img {
  margin: 0 10px 0 0;
}
.footer-address {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 25px;
}
.footer-contact-info span {
  margin: 0 0 11px;
  color: #666666;
  display: inline-block;
}
.subscribe-form p {
  color: #fff;
  font-weight: 600;
}
.subscribe-form form {
  display: block;
  position: relative;
}
.subscribe-form .form-control {
  height: 40px;
  border-radius: 6px;
  background: none;
  border: 2px solid #2f2f2f;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  color: #666666;
  width: 300px;
  margin: 0 0 16px;
}
.footer .footer-bottom .copyright {
  padding: 40px 0;
}
.footer .footer-bottom .copyright-text p {
  color: #0d233e;
  font-size: 15px;
}
.footer .footer-bottom .copyright-text p a {
  color: #4c40ed;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.footer .footer-bottom .copyright-text p a:hover {
  color: #4c40ed;
}
.footer .footer-bottom .copyright-text p.title {
  font-weight: 400;
  margin: 10px 0 0;
}
.footer .social-icon ul {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  list-style: none;
  padding: 0;
}
.social-icon {
  margin: 20px 0 0;
}
.footer .social-icon ul li {
  margin-right: 12px;
}
.footer .social-icon ul li:last-child {
  margin-right: 0;
}
.social-icon p {
  color: #fff;
  margin-bottom: 10px;
}
.footercount > .vistors-details ul {
  justify-content: end;
  -webkit-justify-content: end;
}
.vistors-details ul li {
  margin: 0 8px 0 0;
}
.vistors-details ul li:last-child {
  margin: 0;
}
.footer .social-icon ul li a {
  color: #666666;
  font-size: 18px;
  transition: all 0.4s ease 0s;
  border-radius: 50%;
  padding: 15px 15px;
  border: 1px solid #666;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 30px;
  width: 30px;
}
.footer .social-icon ul li a:hover {
  background: #fff;
  border: 1px solid #fff;
}
.footer .social-icon ul li a:hover {
  color: #c10037;
}
.footer .footer-bottom .copyright-text p {
  color: #fff;
}
.policy-menu {
  font-size: 14px;
  margin: 0;
  padding: 0;
  text-align: right;
}
.policy-menu li {
  display: inline-block;
  margin-right: 25px;
}
.policy-menu li:last-child {
  margin-right: 0;
}
.policy-menu li a {
  color: #fff !important;
}
.policy-menu li a:hover,
.policy-menu li a:focus {
  color: #c10037;
}
.policy-menu li::after {
  color: #fff;
  content: "|";
  font-weight: 300;
  position: relative;
  left: 15px;
}
.footer-bottom {
  background: #131313;
}
.policy-menu li:last-child::after {
  content: "";
}
.footercount {
  border-top: 1px solid #666;
  margin: 40px 0 0;
  padding: 50px 0;
}
.footercount p {
  color: #666666;
  font-size: 14px;
  font-weight: 500;
}
.footercount p.visitors-value {
  color: #fff;
}
.subscribe-form {
  margin: 15px 0 0;
}
.stay-tuned {
  color: #fff;
  text-align: center;
  border-radius: 10px;
  margin-top: -230px;
  padding: 50px 0;
  position: relative;
  overflow: hidden;
  background: url(../img/footer-bg.png), #c10037;
  background-repeat: no-repeat;
  background-position: center;
}
.stay-tuned form {
  display: flex;
  justify-content: center;
}
.stay-tuned h3 {
  font-size: 42px;
  font-weight: 800;
  color: #fff;
  margin-bottom: 20px;
}
.stay-tuned .form-group {
  margin-bottom: 0;
}
.stay-tuned p {
  max-width: 600px;
  margin: 0 auto 20px;
}
.stay-tuned .group-img i {
  color: #c10037;
}
.stay-tuned form input {
  width: 376px;
  border-radius: 6px;
  color: #8d8d8d;
  font-size: 14px;
}
.stay-tuned form input::placeholder {
  color: #8d8d8d;
}
.stay-tuned form .btn-primary {
  margin: 0px 0 0 20px;
  background: #fff;
  border-radius: 8px;
  color: #374b5c;
  font-size: 16px;
  padding: 10px 40px;
  border: 1px solid #fff;
}
.stay-tuned form .btn-primary:hover {
  color: #fff;
  border: 1px solid #fff;
  background: #c10037;
  box-shadow: none;
}
.footer-two {
  margin-top: 0;
}
.footer-social {
  background: #131313;
  padding: 15px 0;
}
.footer-social h6 {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
}
.footer-social .social-icon {
  margin: 0;
  text-align: right;
}
.footer-two .social-icon ul li a {
  height: 40px;
  width: 40px;
  background: #403e57;
  border-radius: 5px;
  border: 1px solid #403e57;
  color: #fff;
}
.footer-two .footer-top {
  padding: 30px 0 0;
}
.footer-two .footer-title {
  font-size: 16px;
  color: #c10037;
  margin-bottom: 15px;
}
.footer-two .footer-menu ul li {
  line-height: inherit;
  margin-bottom: 10px;
}
.footer-two .footer-logo {
  margin-bottom: 15px;
}
.footer-two .footer-content {
  max-width: 100%;
}
.update-form {
  position: relative;
}
.update-form span {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #c10037;
  font-size: 20px;
}
.update-form .form-control {
  min-height: 60px;
  background: #131313;
  border-radius: 10px;
  border: 1px solid #131313;
  font-size: 14px;
  margin: 0;
  padding: 16px 110px 16px 45px;
  color: #fff;
}
.update-form .btn {
  font-weight: normal;
  padding: 7px 15px;
  font-size: 15px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
.footer-two .footercount {
  border-top: 1px solid #2f2f2f;
  margin: 30px 0 0;
  padding: 20px 0;
}
.footer-two .footer-content p {
  margin-bottom: 15px;
}
.footer-two .footer-bottom .copyright {
  padding: 20px 0;
}
.footer-three {
  position: relative;
  background: #0d233e;
  width: 100%;
  min-height: 320px;
  padding-top: 180px;
  margin-bottom: 0 !important;
  z-index: 5;
}
.footer-three:before {
  position: absolute;
  background: url(../img/footer-bg-01.jpg) no-repeat center top;
  content: "";
  width: 100%;
  height: 100%;
  top: -1px;
  z-index: -1;
}
.top-foo-widget {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding-bottom: 20px;
  border-bottom: 1px solid #4b5a64;
}
.top-foo-widget .help-info {
  display: flex;
  align-items: center;
}
.top-foo-widget .help-info p {
  color: white;
  margin: 0;
  font-size: 15px;
}
.top-foo-widget .help-info p:not(:last-child) {
  margin-right: 24px;
}
.social-info ul li:not(:last-child) {
  margin-right: 10px;
}
.social-info ul li a {
  width: 44px;
  height: 44px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #374b5c !important;
  background: #e3e3e3;
}
.social-info ul li a:hover {
  background: #c10037;
  color: #fff;
}
.middle-foo-widget {
  margin-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #4b5a64;
}
.middle-foo-widget .drop-blk .navbar-nav {
  flex-direction: row;
  color: #000000;
  gap: 14px;
  justify-content: start;
}
.middle-foo-widget .drop-blk .navbar-nav li {
  min-width: 100px;
  text-align: center;
}
.middle-foo-widget .drop-blk .navbar-nav li ul {
  margin: 7px 0 7px -11px !important;
  padding: 0;
}
.middle-foo-widget .drop-blk .navbar-nav li a:hover {
  background: transparent;
  color: #000;
}
.middle-foo-widget .drop-blk .navbar-nav li ul li {
  text-align: left;
  padding: 7px 10px;
}
.middle-foo-widget .drop-blk .navbar-nav li a {
  padding: 0px;
  font-size: 14px;
}
.middle-foo-widget .drop-blk .navbar-nav li a.lang-link {
  min-width: 150px;
}
.middle-foo-widget .links-blk h5,
.middle-foo-widget .drop-blk h5 {
  color: #fff;
  font-size: 16px;
  margin-bottom: 15px;
}
.middle-foo-widget .links-blk ul li a {
  color: #ffffff;
  display: inline-block;
  margin-bottom: 10px;
  font-size: 14px;
}
.middle-foo-widget .links-blk ul li a:hover {
  color: #c10037;
}
.middle-foo-widget .links-blk ul li:last-child a {
  margin: 0;
}
.btm-foo-widget {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  padding-bottom: 20px;
}
.btm-foo-widget .copy-info p {
  color: #fff;
  font-size: 15px;
}
.btm-foo-widget .payment-info ul li:not(:last-child) {
  margin-right: 10px;
}
.gallery-blk li img {
  width: 95px;
  height: 76px;
  object-fit: cover;
  border-radius: 10px;
}
.gallery-blk li {
  margin-bottom: 10px;
}
.gallery-blk li:not(:last-child) {
  margin-right: 14px;
}
.footer-three
  .selection-list
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #000;
}
.footer-three .lang-select .select-icon,
.footer-three
  .selection-list
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #000;
}
.footer-three .lang-select {
  min-width: 73px;
}
.footer-three .selection-list .select2-container {
  width: 125px !important;
}
.footer-three
  .selection-list
  .lang-select
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  right: 20px;
}
.footer-three
  .lang-select
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  padding-left: 22px;
  padding-right: 25px;
}
.footer-three
  .selection-list
  .select2-container--default
  .select2-selection--single {
  background-color: #fff;
  border-radius: 100px;
  height: 40px;
}
.footer-three
  .selection-list
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 40px;
}
.footer-three
  .selection-list
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 38px;
}
.footer-three .lang-select .select-icon {
  top: 50%;
  left: 20px;
}
.footer-four {
  background: url(../img/bg/cate-bg.png), #fff;
  background-size: cover;
  margin: 0;
}
.footer-four .footer-top {
  padding: 50px 0;
}
.footer-four .footer-title {
  color: #0d233e;
  margin-bottom: 20px;
}
.footer-four .footer-content p {
  color: #0d233e;
}
.footer-four .footer-menu ul li a {
  color: #0d233e;
  font-size: 15px;
  font-weight: 400;
}
.footer-four .footer-contact-info ul li {
  color: #0d233e;
  font-size: 15px;
  margin-bottom: 15px;
}
.footer-four .social-icon ul li a {
  color: #374b5c;
  font-size: 22px;
  height: unset;
  width: unset;
  padding: 0;
  border: 0;
}
.footer-four .social-icon ul li {
  margin-right: 20px;
}
.footer-four .social-icon ul li a:hover {
  border: 0;
}
.footer-four .footer-bottom {
  background: #fff;
}
.footer-four .footer-bottom .copyright {
  padding: 20px 0;
  border-top: 1px solid #d0d5dd;
}
.footer-four .footer-bottom .copyright-text p {
  color: #0d233e;
  font-weight: 500;
}
.footer-five {
  margin: 0;
  background: #0d233e;
}
.footer-five .footer-top {
  padding: 50px 0;
}
.footer-five .footer-bottom {
  background: #0d233e;
}
.footer-five .copyright {
  border-top: 1px solid #4b5c6a;
}
.footer-five .footer-bottom .copyright {
  padding: 30px 0;
}
.footer-five .footer-menu ul li a,
.footer-five .footer-content p,
.footer-five .footer-contact-info p {
  color: #dddddd;
}
.footer-five .social-icon ul li a {
  border: 0;
  padding: 0;
  color: #fff;
}
.footer-five .footer-address i {
  color: #fff;
  font-size: 16px;
}
.car-client-review::after {
  position: absolute;
  content: "";
  border-width: 20px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
}
.car-client-review {
    position: relative;
}
/*-----------------
	39. Responsive
-----------------------*/

@media (min-width: 992.98px) {
  .pricing-eight .col-lg-2.d-flex.col-md-6 {
      width: 19.30%;
  }
  .header-eight .main-nav>li>a>i {
      font-size: 14px;
      margin-left: 0px;
      margin-right: 0.2rem;
  }
  .section-search.section-search-eight {
      position: relative;
      padding: 170px 0;
  }
}
@media (min-width: 992px) {
  .pricingplan-section.pricing-eight .price-head h3{
    margin-bottom: 15px;
  }
  .main-nav > li {
    margin-right: 30px;
  }
  .main-nav > li:last-child {
    margin-right: 0;
  }
  .main-nav li {
    display: block;
    position: relative;
  }
  .main-nav > li > a {
    line-height: 85px;
  }
  .header-two .main-nav > li > a {
    line-height: 68px;
  }
  .header-three .main-nav > li > a {
    line-height: 60px;
    color: #d5d5d5;
  }
  .header-three.fixed .main-nav > li > a {
    line-height: 60px;
    color: #374b5c;
  }
  .main-nav > li > a > i {
    font-size: 12px;
    margin-left: 3px;
  }
  .main-nav li > ul {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    display: block;
    font-size: 14px;
    left: 0;
    margin: 0;
    min-width: 200px;
    opacity: 0;
    padding: 0;
    position: absolute;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    top: 100%;
    visibility: hidden;
    z-index: 1000;
  }
  .main-nav li.has-submenu:hover > .submenu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .main-nav .has-submenu.active > a {
    color: #c10037;
  }
  .header-three .main-nav .has-submenu.active > a {
    color: #fff;
  }
  .header-three.fixed .main-nav .has-submenu.active > a {
    color: #c10037;
  }
  .main-nav .has-submenu.active .submenu li.active > a {
    color: #c10037;
  }
  .main-nav > li .submenu li:first-child a {
    border-top: 0;
  }
  .main-nav
    > li.has-submenu:hover
    > .submenu
    > li.has-submenu:hover
    > .submenu {
    visibility: visible;
    opacity: 1;
    margin-top: -1px;
    margin-right: 0;
  }
  .main-nav > li .submenu > li .submenu {
    left: 100%;
    top: 0;
    margin-top: 10px;
  }
  .main-nav li .submenu a:hover {
    color: #c10037;
  }
  .main-nav > .has-submenu > .submenu > .has-submenu > .submenu::before {
    top: 20px;
    margin-left: -35px;
    box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.15);
    border-color: transparent #fff #fff transparent;
  }
  .header-navbar-rht .dropdown-toggle.show + .dropdown-menu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .header-navbar-rht li .dropdown-menu {
    border-radius: 5px;
    padding: 0;
    margin: 0;
    min-width: 200px;
    visibility: hidden;
    opacity: 0;
    top: 120%;
    right: 0;
    left: auto;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    display: block;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
  .header-navbar-rht li.flag-nav > a {
    padding: 0;
    border: 0;
    display: flex;
    align-items: center;
  }
  .header-navbar-rht li.flag-nav > a img {
    margin-right: 10px;
  }
  .header-navbar-rht li.flag-nav .dropdown-menu {
    top: 94%;
  }
  .header-navbar-rht li.flag-nav .dropdown-menu.show {
    opacity: 1;
    visibility: visible;
  }
  .mini-sidebar .header-left .logo img {
    height: auto;
    max-height: 40px;
    width: auto;
  }
  .mini-sidebar .header .header-left .logo {
    display: none;
  }
  .mini-sidebar .header-left .logo.logo-small {
    display: block;
  }
  .mini-sidebar .header .header-left {
    padding: 0 5px;
    width: 80px;
  }
  .mini-sidebar .header .header-left #toggle_btn i {
    transform: rotate(180deg);
    color: #7e84a3;
    padding: 0 7px;
  }
  .mini-sidebar .sidebar {
    width: 80px;
  }
  .mini-sidebar.expand-menu .sidebar {
    width: 270px;
  }
  .mini-sidebar .menu-title {
    visibility: hidden;
    white-space: nowrap;
  }
  .mini-sidebar.expand-menu .menu-title {
    visibility: visible;
  }
  .mini-sidebar .menu-title a {
    visibility: hidden;
  }
  .mini-sidebar.expand-menu .menu-title a {
    visibility: visible;
  }
  .modal-open.mini-sidebar .sidebar {
    z-index: 1051;
  }
  .mini-sidebar .sidebar .sidebar-menu ul > li > a span {
    display: none;
    transition: all 0.2s ease-in-out;
    opacity: 0;
  }
  .mini-sidebar.expand-menu .sidebar .sidebar-menu ul > li > a span {
    display: inline;
    opacity: 1;
  }
  .mini-sidebar .page-wrapper {
    margin-left: 78px;
  }
  .popular-locations .location-info:nth-child(3) {
    margin-right: 0;
  }
  .testimonials-section .testimonial-heading {
    position: absolute;
    right: 0;
    top: 60px;
  }
  .col-lg-6.map-right {
    padding: 0;
  }
  .listingmappage-content,
  .listingmaplist-content {
    padding: 25px;
  }
  .contactright-map {
    padding-left: 0;
  }
  .contactus-img {
    padding-right: 0;
  }
  .dashboard-info .card {
    margin: 0;
  }
  .aboutlocation-details:hover .aboutloc-img img {
    -webkit-transform: scale(1.35) rotate(12deg);
    -moz-transform: scale(1.35) rotate(12deg);
    transform: scale(1.35) rotate(12deg);
  }
  .header-three .main-nav li .nav-link {
    color: #ffffff;
  }
  .main-nav li.megamenu {
    position: unset;
  }
  .single-demo a {
    padding: 0 !important;
  }
  .main-nav li > ul.mega-submenu {
    border-radius: 0 0 5px 5px;
  }
  .header-four .main-nav .has-submenu.active > a {
    position: relative;
  }
  .header-four .main-nav .has-submenu.active > a:before {
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    background: #c10037;
    border-radius: 50%;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    display: none;
  }
}

@media (max-width: 1499.98px) {
  .holiday-cabin-img-slider .owl-nav .owl-prev i {
    left: 97%;
  }
  .holiday-cabin-img-slider .owl-nav .owl-next i {
    left: 154%;
  }
    .holiday-cabin-info {
      top: 10%;
  }
  .section-heading {
    margin: 0 0 40px;
  }
  .home-nine .header .car-list-btn.header-phone {
    display: none;
  }
  .car-service-container h4 {
    font-size: 17px;
    padding: 16px 0px;
  }
  .car-service-container {
      padding: 30px;
  }
  .car-brand-slider.owl-carousel .owl-item img {
      width: 100% !important;
  }
  .car-rental-carousel-content {
      top: 10%;
  }
  .car-testimonial-content h5 {
      font-size: 20px;
  }
  .footer-send-mail a {
    padding: 14px 17px;
  }
  .car-search-icon {
    padding: 13px 20px;
  }
  .business-slider.owl-carousel .owl-nav .owl-prev {
    left: -50px;
  }
  .business-slider.owl-carousel .owl-nav .owl-next {
    right: -50px;
  }
}
@media (max-width: 1599.98px) {
  .banner-section.banner-three {
    background-position: 180% 100%;
  }
}
@media (min-width: 1399.99px) {
  
  .restaurateurs-content {
      max-width: 100%;
      max-height: 100%;
      display: flex;
      height: 100%;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
  }
  .container.footer-container {
      max-width: 1000px;
  }
  .right-content {
      align-items: center;
      display: flex;
      height: auto;
  }
  .banner-seven .banner-imgs img {
      max-width: 350px;
  }
}
@media (max-width: 1399.98px) {	

  .banner-seven .main-nav > li {
    margin-right: 20px;
  }
  .banner-seven .banner-imgs {
    right: 90px;
  }
  .banner-seven .banner-imgs img {
    max-width: 350px;
  }
  .container.footer-container {
    max-width: 1000px;
  }
  .restaurateurs-content {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
  }
  .main-nav > li {
		margin-right: 25px;
	}
	.package-section {
		background-size: 300px;
	}
	.package-wrap ul li {
		font-size: 15px;
		margin-bottom: 15px;
	}
	.package-wrap {
		padding: 67px 60px;
	}
	.offer-section {
		padding: 35px 0 70px;
	}
	.offer-img-left img {
		width: 330px;
	}
  .our-team-bottom{
    margin: 0;
    min-width: 347px;
  }
  .category-item a {
		font-size: 15px;
	}	
  .featured-car-center-list p {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .car-filter-section .search-tab-col ul li .car-search-grid{
    min-width: auto;
  }
	.banner-section.banner-three {
		min-height: 730px;
		background-position: 366px 0;
	}
	.section-blk .service-content {
		padding: 40px;
	}
	.ban-image-03 {
		top: 45%;
	}
	.ban-image-03 img {
		width: 340px;
	}
	.listee-content {
		padding: 50px 0;
	}		
	.banner-four .banner-imgs {
		right: 90px;
	}	
	.banner-four .banner-imgs img {
		max-width: 650px;
	}
	.categories-info h6 {
		font-size: 18px;
	}
}


@media (max-width: 1199.98px) {
  .banner-seven .banner-main-img {
    display: none;
  }
  .header-four .main-menu-wrapper {
    gap: 20px;
  }
  .banner-section.banner-seven h1 {
      font-size: 42px;
  }
  .banner-seven .search-box .search-input {
    margin: 0 10px 0 0;
  }
  .banner-seven .banner-imgs img {
      max-width: 500px;
  }
  .banner-imgs {
      display: none;
  }
  .dropdown.noti-dropdown {
    margin-top: 0px !important;
  }
  .header-nav {
    padding-left: 10px;
    padding-right: 10px;
  }
  .header-navbar-rht > li.desc-list a > span {
    display: none;
  }
  .header .has-arrow .dropdown-toggle::after {
    display: none;
  }
  .main-nav > li {
    margin-right: 9px;
  }
  .header-navbar-rht > li {
    padding-right: 10px;
  }
  .header-navbar-rht > li > .add-listing {
    display: none !important;
  }
  .banner-imgs img {
    max-width: 490px;
  }
  .arrow-img {
    right: 72px;
    top: 32px;
  }
  .search-box .search-input {
    margin: 0 10px 19px 0;
  }
  .search-btn {
    margin: 0px 0 0 0;
  }
  .section-search {
    padding: 40px 0;
  }
  .banner-section h1 {
    font-size: 42px;
  }
  .category-section a.category-links {
    width: 150px;
    margin-right: 0;
  }
  .cta-section h3 {
    font-size: 40px;
  }
  .viewdetails-btn {
    padding: 10px 20px;
  }
  .location-info:last-child {
    margin-right: 0;
  }
  .testimonials-section .testimonial-heading {
    padding: 60px 0 60px 46px;
  }
  .map-listing {
    height: 450px;
    margin: 0 0 25px;
  }
  .showfilter {
    display: none;
  }
  .header .has-arrow .dropdown-toggle:after {
    display: inline-block;
  }
  .header-navbar-rht li > a.header-login {
    min-width: 90px;
    padding: 10px 16px;
  }
  .main-nav li a {
    font-size: 14px;
  }
  .dashboard-content ul.dashborad-menus li {
    margin-right: 5px;
  }
  .dashboard-content ul.dashborad-menus li a {
    padding: 12px 12px;
  }
  .chat-footer .attach-btn {
    width: 88%;
  }
  .descriptionlinks ul li a {
    font-size: 14px;
  }
  .details-main-wrapper .rightsidebar .card ul li {
    font-size: 12px;
  }
  .details-main-wrapper .card .ratings-info p {
    font-size: 13px;
  }
  .banner-section-two .search-box .form-group,
  .banner-section-two
    .search-box
    .select2-container--default
    .select2-selection--single,
  .banner-section-two
    .search-box
    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    min-width: 100px;
    font-size: 14px;
  }
  .banner-section-two .search-box .search-input {
    margin-bottom: 0;
  }
  .banner-section-two .search-icon {
    width: 40px;
    height: 40px;
  }
  .offer-img-left img {
    width: 300px;
  }
  .offer-section {
    padding: 20px 0 70px;
  }
  .offer-sec h3,
  .section-heading-two h2 {
    font-size: 30px;
  }
  .venue-content ul li {
    width: 32%;
  }
  .package-wrap .section-heading-two h2 {
    font-size: 21px;
  }
  .category-item {
    margin-bottom: 15px;
  }
  .category-item a {
    font-size: 15px;
    padding: 7px 15px;
  }
  .header-three .main-nav li:not(:last-child) {
    margin-right: 12px;
  }
  .header-three .cta-btn a {
    font-size: 14px;
  }
  h2 {
    font-size: 28px;
  }
  .shadow-box h4 {
    font-size: 18px;
  }
  .activities-blk .shadow-box p {
    font-size: 14px;
  }
  .subscribe-blk .input-group {
    padding: 10px;
  }
  .subscribe-blk .form-control {
    min-height: 40px;
  }
  .gallery-blk {
    flex-wrap: wrap;
  }
  .box-style .btm-box-style h3 {
    font-size: 22px;
  }
  .theme-btn,
  .theme-btn a {
    font-size: 15px;
  }
  .service-info-card .service-content .body-content {
    margin-bottom: 20px;
  }
  .header-three a.nav-link.cur-link,
  .header-three a.nav-link.lang-link {
    font-size: 14px;
  }
  .service-info-card .service-content .body-content p {
    font-size: 14px;
  }
  .section-blk .service-info-card .img-blk {
    width: 340px;
    padding: 55px 0;
  }
  .section-blk .service-content {
    padding: 30px;
  }
  .banner-section.banner-three {
    background-position: 329px 0;
  }
  .space-content h5,
  .space-content .price span,
  .city-content h5,
  .grid-blog .blog-title a {
    font-size: 18px;
  }
  .price-four .price-head h4 {
    font-size: 32px;
  }
  .price-four .price-level h6 {
    font-size: 20px;
  }
  .banner-four .search-box .search-input {
    margin: 0 10px 0 0;
  }
  .category-box span img {
    width: 50px;
  }
  .banner-four .banner-imgs img {
    max-width: 500px;
  }
  .header-four .header-navbar-rht li > a.header-login {
    padding: 10px 12px;
    font-size: 14px;
    font-weight: 700;
  }
  .business-slider.owl-carousel .owl-nav .owl-prev {
    left: -16px;
  }
  .business-slider.owl-carousel .owl-nav .owl-next {
    right: -16px;
  }
}

@media (max-width: 991.98px) {
  .adv-content h4 {
    font-size: 26px;
  }
  .car-search-icon {
    width: 100%;
  }
  .pricingplan-section.pricing-eight .price-level h2 {
    font-size: 24px;
  }
  .pricingplan-section.pricing-eight .price-head h3 {
    font-size: 21px;
  }
  .car-rental-slider-section  {
    display: none;
  }
  .cta-content.job-content {
    margin-left: 56px;
    margin-top: 20px;
  }
  .latest-restaurant-section .dishes-content .dishes-inner h5 {
    font-size: 18px;
  }
  .pricing-plan-seven .price-seven .price-head h4 {
    font-size: 30px;
  }
  .pricing-plan-seven .price-seven .price-level h6 {
    font-size: 25px;
  }
  .car-filter-section .search-tab-col ul li .car-search-grid {
    min-width: 330px;
  }
  .grid-view .card-body, .grid-view .business-card .card-body {
    padding: 15px;
  }
  .articles-info {
    align-items: stretch;
  }
  .articles-img {
    width: 100%;
  }
  .banner-six .search-group > ul {
    justify-content: space-evenly;
  }
  .car-filter-section .car-search-btn .car-search-icon {
    width: 100%;
    height: 60px;
  }
  .heading-eight h2 {
    font-size: 28px;
  }
  .banner-main-bg {
    display: none;
  }
  .search-btn .btn-primary {
    min-width: auto;
    width: 100%;
  }
  .banner-section h1{
    font-size: 32px;
  }
  .banner-main-img{
    display: none;
  }
  .banner-section.banner-eight .section-search {
    padding: 40px 0;
    z-index: 99;
  }
  .category-section.category-eight {
    padding: 40px 0 40px;
  }
  .vaciencies-section.vaciencies-eight {
    padding: 40px 0 40px;
  }
  .cta-section.job-section-eight{
    padding: 40px 0;
  }
  .partners-section.partner-section-eight {
    padding: 40px 0;
  }
  .popular-section.popular-eight {
    padding: 40px 0;
  }
  .pricingplan-section.pricing-eight {
    padding: 150px 0 40px;
  }
  .pricing-eight .interset-btn {
    margin-bottom: 0px;
    margin-top: 25px;
  }
  .testimonial-section-eight {
    padding: 40px 0;
  }
  .testimonial-section-eight .owl-dots {
    margin-top: 20px;
  }
  .blog-category-eight a {
    margin: 0 10px 10px 0;
  }
  .blog-eight.grid-blog-eight{
    margin-bottom: 20px;
  }
  .right-ads h1 {
    font-size: 28px;
  }
  .blog-eight-section {
    background-position: right top 108px;
    background-size: 96px;
    padding: 40px 0;
  }
  .footer-eight {
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 179px;
    margin: 0 !important;
    padding-top: 40px;
  }
  .footer-eight .footer-title {
    margin-bottom: 20px;
  }
  .adv-box{
    margin-bottom: 20px;
  }
  .search-box .search-input {
    margin: 0 0px 15px 0;
  }
  .bannerleftop {
    top: 87px;
    width: 39px;
    left: 7px;
  }
  .bannerleftbottom {
    width: 50px;
  }
  .bannermiddle {
    width: 40px;
  }
  .job-role.job-one {
    position: relative;
    left: 0;
    top: 0px;
    margin-bottom: 10px;
  }
  .job-role.job-two {
    position:relative;
    left: 0;
    top: 0px;
    margin-bottom: 10px;
  }
  .job-role.job-three {
    position: relative;
    left: 0;
    top: 0px;
    margin-bottom: 10px;
  }
  .job-role.job-four {
    position: relative;
    left: 0;
    top: 0px;
    margin-bottom: 10px;
  }
  .job-role {
    justify-content: start;
  }
  .job-img img {
    max-width: 100%;
    width: 100%;
    margin-bottom: 20px;
  }
  .our-category-section-seven{
      padding: 0;
  }
  .banner-seven .search-box.search-box-seven {
      width: auto;
  }
  .section-heading-seven h1 {
      font-size: 28px;
  }
  .header-four.header-seven .main-menu-wrapper {
      background: #C10037;
  }
  .header-four.header-seven .main-nav>li .submenu li a {
      border-top: 0;
      color: #ffffff;
  }
  .header.header-seven.fixed .main-nav>li>a {
      color: #ffffff !important;
  }
  .header-seven.fixed .main-nav .has-submenu.active>a {
      color: #000000 !important;
  }
  .header-seven .main-nav .has-submenu.active>a{
      color: #000000 !important;
  }
  .header-seven .bar-icon span {
      background-color: #ffffff;
  }
  .header-seven .menu-close {
      font-size: 18px;
      color: #ffffff;
  }
  .banner-section.banner-seven h1 {
      font-size: 32px;
  }
  .banner-seven .home-banner {
      padding-bottom: 50px;
  }
  .banner-seven {
      background-size: 100% 100%;
  }
  .banner-seven h6 {
      font-size: 50px;
  }
  .banner-seven .section-search {
      padding: 50px 0 10px;
  }
  .banner-seven .search-box form {
      display: flex !important;
  }
  .restaurants-sec {
      padding: 40px 0 16px;
  }
  .restaurant-section.restaurant-section-seven {
      padding: 40px 0px 15px;
  }
  .cities-section {
      padding: 40px 0 20px;
  }
  .pricing-plan-seven .price-wrap{
      margin: 0;
  }
  .cities-box {
      margin-bottom: 20px;
  }
  .restaurateurs-bg .restaurateurs-content {
      padding: 40px 30px 40px;
      max-width: 100%;
  }
  .loca-height img {
      height: 300px;
      max-height: 300px;
  }
  .restaurant-section-seven .restaurant-section {
      padding: 40px 0;
  }
  .restaurateurs-img {
      max-width: 100%;
  }
  .restaurateurs-bg {
      display: block;
  }
  .dishes-img img {
      width: 100%;
  }
  .latest-restaurant-section {
      padding: 40px 0 15px;
  }
  .pricing-plan.pricing-plan-seven {
      padding: 40px 0px 20px;
  }
  .testimonial-section-seven {
      padding: 40px 0;
  }
  .blog-seven-section {
      padding: 40px 0px 110px;
  }
  .blog-seven.grid-blog-seven {
      margin-bottom: 20px;
  }
  .footer-seven .stay-tuned form .btn-primary {
      margin: 0;
  }
  .pricing-plan-seven .price-seven.active {
      margin: 10px 0 20px;
  }
  .footer-seven .stay-tuned{
      padding: 20px;
  }
  .cities-height img {
      height: 220px;
  } 
   .cities-spl-box  .cities-overlay {
      height: calc(100% - 20px);
  }
  .footer-seven .footercount {
      border-top: none;
      margin: 0;
      padding: 0px 0 30px;
  }
  .footer-six-top {
    padding-bottom: 0px;
  }
  .footer-six.footer-nine .footer-send-mail {
      margin-bottom: 20px;
  }
  .footer-six-center.foot-nine-list {
    padding-bottom: 35px;
  }
  .foot-nine-logo ,
  .footer-six-top-list {
    margin-bottom: 15px;
  }
    .buy-property-content {
      padding: 37px 71px;
  }
  .buy-property-content h2 {
    font-size: 33px;
  }
  .buy-property-content p {
    font-size: 18px;
    padding: 18px 0;
  }
  .buy-property-content a {
    padding: 11px 27px;
  }
  .owner-card {
    margin-bottom: 20px;
  }
  .verified-owner-list-btn {
    margin-top: 30px;
  }
  .property-testimonial-item p {
    padding-bottom: 20px;
  }
  .property-profile {
    height: 65px;
    width: 65px;
  }
  .top-rent-room-location p span {
    font-size: 17px;
  }
  .top-rent-room-location .star-rated i {
    font-size: 14px;
  }
  .listee-premium-img span {
    width: 70px;
    height: 70px;
    padding: 16px;
  }
  .make-it-easy h2 {
    max-width: 678px;
    font-size: 35px;
  }
  .make-it-easy {
    background-size: cover;
    background-repeat: no-repeat;
  }
  .make-it-easy p {
    font-size: 22px;
    margin: 20px 0px;
  }
  .make-it-easy a {
    padding: 13px 16px;
  }
  .top-rent-room-info .top-rent-room-location .room-address h5 {
    font-size: 18px;
  }
    .top-rent-room-info .top-room-details {
      justify-content: space-evenly;
      flex-wrap: wrap;
  }
    .holiday-cabin-img-slider .owl-nav .owl-prev i {
      left: 15%;
      bottom: 175px;
  }
  .holiday-cabin-img-slider .owl-nav .owl-next i {
    left: 66%;
    bottom: 175px;
  }
  .holiday-cabin-img-slider .owl-nav .owl-prev i, .holiday-cabin-img-slider .owl-nav .owl-next i {
    width: 38px;
    height: 38px;
  }
  .holiday-cabin-info .room-categories span {
    width: 165px;
    height: 47px;
  }
    .holiday-cabin-info .section-heading, .section-heading-two, .interset-btn {
      margin: 0 0 20px;
  }
    .holiday-cabin-info .rate-per-day {
      width: 121px;
      height: 35px;
      margin-bottom: 8px;
  }
  .holiday-cabin-info .section-heading h2 {
    font-size: 27px;
  }
  .holiday-cabin-info .section-heading p {
    display: none;
  }
  .choose-set {
    margin-bottom: 15px;
  }
  .real-search-bar {
    text-align: center;
  }
  .section-heading-nine.section-heading .btn-view {
    padding: 11px 15px;
    font-size: 14px;
  }
  .home-img-text h3 {
    padding: 15px 0;
    font-size: 18px;
  }
  .top-room-details {
    padding-bottom: 16px;
  }
  .house-type {
    padding-top: 15px;
  }
    .real-estate-search {
      margin-bottom: 15px;
  }
  .banner-section.banner-nine {
    height: 522px;
    margin-top: 84px;
    padding: 65px 0 0;
  }
  .banner-section.banner-nine h1 {
    font-size: 45px;
  }
  .banner-section.banner-nine h6 {
    font-size: 18px;
    padding: 15px 0;
  }
  .banner-section.banner-nine p {
    margin-bottom: 0;
  }
  .car-float-bg,
  .join-us-dealer-bg {
    display: none;
  }
  .banner-section.banner-six .floating-watermark img {
    display: none;
  }
  .sidebar-overlay.opened {
    display: block;
  }
  .header-navbar-rht > li {
    padding-right: 15px;
  }
  .main-nav {
    padding: 0;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .main-nav ul {
    display: none;
    list-style: none;
    margin: 0;
    padding-left: 0;
  }
  .main-nav > li {
    margin-left: 0;
  }
  .main-nav li + li {
    margin-left: 0;
  }
  .main-nav > li > a {
    line-height: 1.5;
    padding: 15px 20px;
    font-size: 14px;
    font-weight: 500;
  }
  .main-nav > li > a > i {
    float: right;
    margin-top: 5px;
  }
  .main-nav > li .submenu li a {
    border-top: 0;
    color: #374b5c;
    padding: 10px 15px 10px 35px;
  }
  .main-nav > li .submenu ul li a {
    padding: 10px 15px 10px 45px;
  }
  .main-nav > li .submenu > li.has-submenu > a::after {
    content: "\f078";
  }
  .main-nav .has-submenu.active > a {
    color: #c10037;
  }
  .main-nav .has-submenu.active .submenu li.active > a {
    color: #c10037;
  }
  .main-nav > li > a:hover::after,
  .main-nav > li.active > a::after {
    top: 0;
  }
  .main-menu-wrapper {
    order: 3;
    width: 260px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 11111;
    transform: translateX(-260px);
    transition: all 0.4s;
    background-color: #fff;
    margin: 0;
  }
  .menu-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
  }
  .navbar-header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: flex;
    align-items: center;
  }
  .header-four .navbar-header {
    width: 100%;
    justify-content: center;
  }
  .header-four #mobile_btn {
    margin-right: 0px;
    position: absolute;
    top: 20px;
    left: 0;
  }
  .main-nav > li {
    border-bottom: 1px solid #e1e2e5;
    margin-right: 0;
  }
  #mobile_btn {
    display: inline-block;
    padding-top: 6px;
  }
  .sidebar {
    margin-left: -225px;
    width: 225px;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    z-index: 1041;
  }
  .header-navbar-rht > li.desc-list {
    display: none;
  }
  .container {
    max-width: 100%;
  }
  .footer-widget {
    margin-bottom: 30px;
  }
  .footer .footer-top {
    padding-bottom: 10px;
  }
  .footer .social-icon ul {
    margin: 0;
  }
  .footercount {
    margin: 0;
  }
  .footer {
    padding: 0 20px;
  }
  .arrow-img {
    display: none;
  }
  .banner-circle,
  .bannerleftarrow,
  .banner-imgs,
  .banner-elipse {
    display: none;
  }
  .search-box label {
    display: block;
  }
  .banner-section p.explore-text span {
    font-size: 14px;
  }
  .search-box .search-input {
    margin: 0 10px 0 0;
  }
  .category-section a.category-links {
    width: 100%;
    margin: 0 0px 20px 0;
  }
  .cta-section .col-lg-7,
  .cta-section .col-lg-5 {
    width: 50%;
  }
  .cta-content .postad-btn {
    padding: 12px 15px;
  }
  .cta-content a.browse-btn {
    padding: 9px 15px;
  }
  .popular-locations .location-content {
    padding: 0 0px 0 15px;
    width: 100%;
  }
  .single-demo {
    margin-bottom: 0;
  }
  .testimonials-section .testimonial-heading {
    padding: 0 0 30px 10px;
  }
  .testimonial-heading img {
    display: none;
  }
  .testimonials-slidersection {
    margin-top: 0;
    background: #c10037;
    padding-bottom: 25px;
  }
  .testimonials-section .rightimg {
    display: none;
  }
  .testi-author p {
    font-size: 14px;
  }
  .testi-author h6 {
    font-size: 20px;
  }
  .section-heading h2,
  .section-heading-two h2 {
    font-size: 32px;
  }
  .price-card {
    margin: 0 0 20px;
  }
  .grid-blog .blog-title a {
    font-size: 17px;
  }
  .subscribe-form .btn-secondary {
    width: 300px;
  }
  .cta-section h3 {
    font-size: 32px;
  }
  .header-nav {
    padding-left: 0;
    padding-right: 0;
  }
  .category-section {
    padding: 40px 0 20px;
  }
  .section-heading,
  .section-heading-two,
  .interset-btn {
    margin: 0 0 40px;
  }
  .section-heading h2,
  .section-heading-two h2 {
    font-size: 28px;
  }
  .featured-section,
  .cta-section,
  .blog-section,
  .popular-locations,
  .partners-section {
    padding: 40px 0;
  }
  .latestad-section {
    padding: 40px 0 20px;
  }
  .pricingplan-section {
    padding: 40px 0 20px;
  }
  .featured-slider.grid-view .lateestads-content .card {
    margin-bottom: 20px;
  }
  .subscribe-form {
    margin: 15px 0 0 0;
  }
  .footercount {
    padding: 40px 0;
  }
  .testimonials-section {
    position: relative;
    overflow: hidden;
    padding: 40px 0;
  }
  .featured-section .service-content h3 {
    max-width: 150px;
  }
  .partners-section {
    padding-top: 0px;
  }
  .callnow {
    text-align: left;
    margin: 35px 0 0;
  }
  .grid-blog .post-author {
    width: 200px;
  }
  .details-main-wrapper {
    padding: 40px 0;
  }
  .featureslist {
    margin: 0 0px 15px 0;
  }
  .reviewpage-content .review-img {
    width: 82px;
    height: 82px;
  }
  .rightsidebar .card .info-list {
    margin: 0px 0 0 38px;
  }
  .map-details {
    display: flex;
  }
  .statistics-list li .statistic-details {
    width: 98%;
  }
  .vistors-details {
    margin-bottom: 0px;
  }
  .ratings-info {
    margin: 0 0 25px;
  }
  .rate-details h2 {
    font-size: 32px;
  }
  .citi-img img {
    width: 100%;
  }
  .owl-prev:hover,
  .owl-next:hover {
    border: 1px solid #c10037;
    color: #c10037;
    background: #fff;
  }
  .lateestads-content .row {
    justify-content: center;
  }
  .amenities .search-btn .btn {
    margin: 0 0 20px;
    width: 45%;
  }
  .gallery-content {
    align-items: center;
    justify-content: center;
  }
  .bloglist-section .rightsidebar {
    margin-top: 25px;
  }
  .bloglisting .blog-pagination {
    margin: 0 0px 30px;
  }
  .blogbanner-content h1 {
    font-size: 28px;
  }
  .blogbanner-content {
    bottom: 15px;
  }
  .blogbanner-content .entry-meta li,
  .blogbanner-content .post-author a {
    font-size: 14px;
  }
  .blogdetails-pagination ul li .prev-link,
  .blogdetails-pagination ul li .next-link {
    font-size: 16px;
  }
  .progress-wrap.active-progress {
    display: none;
  }
  .about-listee {
    flex-direction: column;
  }
  .about-img {
    width: auto;
    text-align: center;
  }
  .about-info {
    margin: 25px 0 0;
    position: static;
    width: auto;
    transform: none;
    padding: 20px;
  }
  .about-content {
    padding: 60px 0;
  }
  .about-info h4 {
    font-size: 32px;
  }
  .howitwork-info h5 {
    font-size: 50px;
  }
  .howitwork-info h6 {
    font-size: 20px;
  }
  .howitwork-info h5:after {
    top: 30px;
  }
  .aboutlocation-details h3 {
    font-size: 18px;
    margin: 0 0 5px;
  }
  .aboutlocations-info {
    bottom: 10px;
    left: 10px;
  }
  .aboutpopular-locations .section-heading h2 {
    font-size: 24px;
  }
  .blogbanner {
    min-height: 400px;
  }
  .contactinfo-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    right: inherit;
  }
  .contactleft-info {
    text-align: center;
  }
  .work-section .work-info h5:after {
    width: calc(100% - 98px);
  }
  .work-section .work-info h5 {
    font-size: 50px;
  }
  .work-section .work-info h6 {
    font-size: 20px;
  }
  .work-section h4 {
    font-size: 32px;
  }
  .work-section {
    padding: 80px 0;
  }
  .faq-section h1 {
    font-size: 32px;
  }
  .faq-section h4 {
    font-size: 18px;
  }
  .faq-section .faq-card .card-collapse p {
    font-size: 14px;
  }
  .dashboard-content ul.dashborad-menus li a {
    padding: 8px 8px;
    font-size: 14px;
  }
  .dashboard-content ul.dashborad-menus li {
    margin-right: 7px;
  }
  .dashboard-content ul.dashborad-menus {
    padding: 25px 15px;
  }
  .listing-table th,
  .listing-table td {
    white-space: nowrap;
  }
  .listing-table .avatar-img {
    width: 100%;
    height: 100%;
    min-width: 130px;
  }
  .profile-sidebar .card-body .group-img,
  .profile-sidebar .card-body .btn-primary {
    width: 40%;
  }
  .google-maps {
    height: 300px;
    margin: 20px 0 0;
  }
  .stay-tuned h3 {
    font-size: 33px;
  }
  .cta-section:before {
    display: none;
  }
  .review-img {
    margin-bottom: 20px;
    margin-right: 20px;
  }
  .review-details {
    margin-left: 0;
  }
  .error-wrap h2 {
    font-size: 38px;
  }
  .faq-section h2 {
    font-size: 28px;
  }
  .sorting-div {
    margin: 25px 0 25px;
  }
  .rightsidebar .card .article {
    width: 52%;
  }
  .navbar-brand.logo img {
    width: auto;
  }
  .sidebar-overlay.opened {
    display: block;
  }
  .aboutlocation-details {
    height: 200px;
    margin: 0 0 25px;
  }
  .aboutlocation-details .aboutloc-img img {
    width: 100%;
  }
  .australi-loc img {
    height: 200px;
  }
  .popular-locations .location-content a.view-detailsbtn {
    padding: 8px 30px;
  }
  .map-right {
    position: static;
    padding-right: 15px;
  }
  .profile-img-del,
  .profile-img-del:hover {
    width: 30px;
    height: 30px;
  }
  .category-listing .col-lg-4 {
    width: 30%;
  }
  .partners-section .partners-heading {
    font-size: 30px;
    margin: 0 0 30px;
  }
  .chat-cont-left,
  .chat-cont-right {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    transition: 0.3s ease-in-out 0s;
    width: 100%;
  }
  .chat-window.chat-slide .chat-cont-right {
    right: 0;
    opacity: 1;
    margin: 0 15px;
    width: 96%;
    visibility: visible;
  }
  .chat-window.chat-slide .chat-cont-left {
    left: -104%;
  }
  .chat-cont-right .chat-header .back-user-list {
    display: block;
  }
  .chat-cont-right .chat-header {
    display: flex;
    align-items: center;
  }
  .chat-footer .attach-btn {
    width: 88%;
  }
  .chat-cont-right {
    position: absolute;
    right: -100%;
    top: 0;
    opacity: 0;
    visibility: hidden;
  }
  .details-main-wrapper .rightsidebar .card ul li {
    font-size: 16px;
  }
  .map-frame iframe {
    width: 100%;
    height: 100%;
  }
  .dashboard-content {
    padding: 60px 0;
  }
  .menu-opened .sidebar-overlay {
    display: block;
  }
  .faq-section {
    padding: 70px 0 45px;
  }
  .dashboard-info .review-list .review-box {
    display: flex;
  }
  .dashboard-info .review-list .review-img {
    margin-right: 20px;
  }
  .login-content,
  .bloglisting,
  .contactus-info {
    padding: 70px 0;
  }
  .contactusform-section {
    padding: 0 0 70px;
  }
  .categorieslist-section {
    padding: 70px 0 45px;
  }
  .listmapgrid-content,
  .listmaplist-content {
    padding: 0px 0 25px 0px;
  }
  .media-section .settings-upload-btn {
    margin: 0 0 25px;
  }
  .testimonials-slidersection .owl-nav {
    position: static;
    text-align: right;
    padding-top: 30px;
  }
  .popular-locations .location-info-details {
    margin-bottom: 20px;
  }
  .browse-btn {
    margin: 20px 0 0 0;
  }
  .footercount {
    padding: 30px 0 30px;
  }
  .footer .footer-bottom .copyright {
    padding: 0 0 30px;
  }
  .footercount p {
    margin-bottom: 10px;
  }
  .footercount .visitors-value {
    margin-bottom: 0;
  }
  .aboutbanner {
    padding: 140px 0 60px;
  }
  .contactbanner {
    padding: 140px 0 60px;
  }
  .breadcrumb-bar {
    padding: 140px 0 60px;
  }
  .breadcrumb-title {
    font-size: 32px;
  }
  .aboutpopular-locations {
    padding: 60px 0 35px;
  }
  .home-banner-two h1 {
    font-size: 32px;
  }
  .banner-section p {
    font-size: 16px;
  }
  .wedding-section {
    padding: 40px 0 26px;
  }
  .venue-section {
    padding: 0 0 40px;
    background-size: 220px;
  }
  .wedding-wrap-img {
    display: none;
  }
  .wedding-img {
    flex: 0 0 160px;
  }
  .offer-section {
    padding: 15px 0 40px;
  }
  .offer-sec {
    padding: 25px;
  }
  .offer-sec h3 {
    font-size: 24px;
  }
  .offer-img-left img {
    width: 240px;
  }
  .package-wrap {
    margin-bottom: 24px;
  }
  .package-section {
    background-size: 200px;
    padding: 40px 0 16px;
  }
  .package-wrap .section-heading-two h2 {
    font-size: 24px;
  }
  .popular-section,
  .portfolio-section,
  .timeline-section,
  .blog-section-two,
  .vendor-section {
    padding: 40px 0;
  }
  .celebrate-section {
    padding: 0 0 40px;
  }
  .success-section {
    padding: 40px 0;
    background-size: 220px;
  }
  .success-sec {
    padding: 24px 24px 0;
  }
  .success-item {
    margin-bottom: 24px;
  }
  .blog-two .blog-title a {
    font-size: 16px;
  }
  .vendor-info h3 {
    font-size: 24px;
  }
  .time-box .date {
    width: 80px;
    height: 80px;
  }
  .timeline-wrap li:before {
    left: 80px;
  }
  .time-box .box-content {
    left: 40px;
  }
  .time-box {
    min-height: 272px;
  }
  .box-bottom .box-content::before {
    top: -41px;
  }
  .box-bottom .box-content::after {
    top: -8px;
  }
  .box-bottom .box-content p {
    font-size: 14px;
  }
  .category-item {
    padding: 10px;
    margin-bottom: 20px;
  }
  .category-item a {
    font-size: 14px;
    padding: 8px 15px;
  }
  .searchbox-list {
    display: block;
    width: 100%;
  }
  .banner-section-two .search-box .search-input {
    width: 100%;
    margin-bottom: 10px;
    border: 0;
    width: 100%;
  }
  .banner-section-two .search-btn .btn {
    width: 100%;
  }
  .search-box form {
    display: block !important;
  }
  .categories-section {
    padding: 0 0 16px;
  }
  .footer-two .footer-bottom .copyright {
    padding: 20px 0;
  }
  .testimonial-item,
  .blog-two,
  .venue-item,
  .portfolio-wrap {
    margin-bottom: 40px;
  }
  .header-top {
    display: none;
  }
  .banner-section.banner-three .banner-info-blk {
    padding-bottom: 35px;
  }
  .banner-btm-img-blk {
    display: none;
  }
  .banner-btm {
    border-radius: 23px;
    padding: 23px;
  }
  .mb-50 {
    margin-bottom: 30px;
  }
  .banner-btm .search-group ul li:not(:last-child) {
    border-right: 0px solid transparent;
  }
  .search-group > ul {
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 8px;
  }
  .blog-blk .tab-content {
    padding-top: 0;
  }
  .banner-btm .datepicker-col.search-group ul li {
    border-bottom: 1px solid #d6d6d6;
  }
  .banner-btm .search-group ul li {
    width: 45%;
    padding-bottom: 10px;
  }
  .search-group .theme-btn {
    margin-top: 23px;
    width: 100%;
  }
  .banner-section.banner-three .banner-info-blk {
    padding-top: 35px;
  }
  .banner-section.banner-three {
    background: transparent;
    min-height: 630px;
  }
  .section-blk {
    margin: 40px 0;
  }
  .gallery-blk {
    flex-wrap: wrap;
    gap: 14px;
    justify-content: flex-start !important;
  }
  .header-three {
    box-shadow: 0px 3px 53px rgb(197 197 197 / 27%);
  }
  .service-info-card .service-content .body-content p {
    font-size: 14px;
  }
  .service-info-card .service-content .body-content {
    margin-bottom: 20px;
  }
  h2 {
    font-size: 26px;
  }
  .section-blk .service-content h2 {
    margin-bottom: 10px;
  }
  .section-blk .service-content .service-list li:not(:last-child) {
    margin-right: 35px;
  }
  .section-blk .service-info-card .img-blk {
    width: 290px;
  }
  .section-blk.service-blk {
    padding-bottom: 40px;
  }
  .recommended-blk.style1-blk,
  .blog-block,
  .price-section,
  .faq-blk {
    padding: 40px 0;
  }
  .faq-blk:before {
    top: 30px;
  }
  .box-style .btm-box-style {
    padding: 25px 20px;
  }
  .home-banner-two .banner-content {
    padding-top: 60px;
  }
  .offer-img-right img {
    width: 220px;
  }
  .ban-image-02 {
    display: none;
  }
  .testi-blk .title-blk {
    margin-top: 40px;
  }
  .section-blk .service-info-card .img-blk {
    padding: 122px 0;
  }
  .demo-img {
    display: none;
  }
  .demo-info {
    opacity: 1;
    position: relative;
    bottom: 0;
    text-align: left;
  }
  .megamenu-wrapper {
    padding: 0;
  }
  .single-demo:hover {
    transform: translateY(0);
  }
  .header-four .main-menu-wrapper {
    display: block;
    margin: 0;
  }
  .header-four .header-navbar-rht {
    display: none;
  }
  .banner-section.banner-four h1 {
    font-size: 32px;
  }
  .category-sec,
  .cowork-sec,
  .space-sec,
  .city-sec,
  .blog-section-four {
    padding: 40px 0 16px;
  }
  .cate-sec,
  .testimonial-section,
  .pricing-section {
    padding: 40px 0;
  }
  .list-sec {
    margin-top: 40px;
  }
  .testi-four .testi-author h6,
  .testi-four .testimonialslider-content h6,
  .testi-four .testimonialslider-content p {
    font-size: 16px;
  }
  .listee-content h3 {
    font-size: 30px;
  }
  .listee-content p,
  .blog-four .blog-title a,
  .cat-wrap h5,
  .cowork-info h5 {
    font-size: 18px;
  }
  .listee-img {
    text-align: center;
  }
  .listee-content {
    padding: 50px 0 30px;
  }
  .price-wrap {
    margin: 0;
  }
  .footer-four .footer-top {
    padding: 40px 0 10px;
  }
  .footer-four .footer-bottom .copyright {
    padding: 20px 0;
  }
  .more-btn {
    padding: 6px 15px;
  }
  .banner-four {
    background-size: 89% 100%;
  }
  .banner-four h6 {
    font-size: 50px;
  }
  .banner-four .section-search {
    padding: 50px 0;
  }
  .banner-four .search-box form {
    display: flex !important;
  }
  .price-four .price-head h4 {
    font-size: 30px;
  }
  .price-four .price-level h6 {
    font-size: 18px;
  }
  .price-four.active {
    margin: 0 0 20px;
  }
  .price-four,
  .price-four.active {
    margin: 0;
  }
  .business-slider.owl-carousel .owl-nav .owl-prev {
    left: -10px;
  }
  .business-slider.owl-carousel .owl-nav .owl-next {
    right: -10px;
  }
  .banner-five .search-box form {
    display: flex !important;
    align-items: center;
  }
  .categories-info h6 {
    font-size: 15px;
  }
  .category-items li {
    width: 25%;
    margin-bottom: 15px;
  }
  .cate-row2 {
    margin-top: 10px;
  }
  .city-overlay {
    flex-direction: column;
    align-items: start;
  }
  .city-overlay .rating {
    margin-top: 5px;
  }
  .city-name h5 {
    font-size: 16px;
    margin-bottom: 2px;
  }
  .adventure-section .featuring-img {
    display: none;
  }
  .section-heading.heading-five h2 {
    font-size: 24px;
    justify-content: start;
  }
  .grid-view .card .amount-details .validrate {
    font-size: 19px;
  }
  .price-three.price-five .price-level h6 {
    font-size: 20px;
  }
  .price-body li {
    font-size: 15px;
  }
  .price-three .price-body h4 {
    font-size: 21px;
  }
  .price-five .viewdetails-btn {
    font-size: 15px;
  }
  .testi-five-slider.owl-carousel .owl-nav .owl-next {
    right: 0%;
  }
  .testi-five-slider.owl-carousel .owl-nav .owl-prev {
    left: 0%;
  }
  .latest-blog-five .articles-info {
    flex-direction: column;
  }
  .latest-blog-five .articles-info .articles-left {
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .banner-five .section-search {
    padding: 150px 0;
  }
  .single-demo:hover .demo-info {
    bottom: 0;
  }
  .category-five-section,
  .business-section,
  .feature-section,
  .adventure-section,
  .gallery-section-five,
  .business-section,
  .pricing-plan,
  .testimonial-five-section,
  .latest-blog-five {
    padding: 50px 0;
  }
  .banner-section.banner-six .floating-bg img {
    display: none;
  }
  .banner-section.banner-six h1 {
    font-size: 37px;
  }
  .banner-section.banner-six p {
    font-size: 20px;
    margin: 18px 0px;
  }
  .car-search-filter {
    margin-top: -77px;
  }
  .car-filter-section .nav-pills .nav-link {
    font-size: 15px;
  }
  .car-search-btn {
    margin-top: 15px;
  }
  .common-padding {
    padding: 45px 0px;
  }
  .home-six-heading-section h2 {
    font-size: 22px;
  }
  .home-six-heading-section p {
    margin: 5px auto 30px auto;
    font-size: 15px;
  }
  .trending-slider-item span {
    font-size: 17px;
  }
  .car-class-item {
    padding: 15px;
  }
  .car-class-item h5 {
    font-size: 17px;
  }
  .car-class-item span {
    font-size: 16px;
  }
  .car-testimonial-content h3 {
    font-size: 45px;
  }
  .car-testimonial-content h5 {
    font-size: 20px;
    margin: 30px auto;
  }
  .car-testimonial-content {
    padding: 25px;
  }
  section.car-testimonial.common-padding .row {
    justify-content: center;
  }
  .car-testimonial-content:last-child {
    margin-top: 15px;
  }
  .browse-by-brand-item p {
    font-size: 16px;
  }
  .featured-car-bottom h3 {
    font-size: 20px;
  }
  .featured-car-foot-left span {
    font-size: 18px;
  }
  .car-rental-carousel-content h3 {
    font-size: 25px;
  }
  .car-rental-carousel-content h5 span {
    font-size: 20px;
  }
  .car-rental-carousel-content h5 {
    font-size: 16px;
    padding: 6px 0px;
    margin-bottom: 0px;
  }
  .car-rental-carousel-content p {
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    margin-bottom: 20px;
  }
  .car-rental-carousel-content p {
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
  }
  .car-rental-slider .owl-nav {
    position: absolute;
    right: 55px;
    bottom: 86px;
  }
  .car-rental-slider .owl-nav {
    position: absolute;
    right: 40px;
    bottom: 86px;
  }
  .car-rental-slider .owl-nav .owl-prev i,
  .car-rental-slider .owl-nav .owl-next i {
    width: 33px;
    height: 33px;
  }
  .car-rental-carousel-content {
    position: absolute;
    top: 5%;
    left: 126px;
  }
  .car-rental-carousel-content a {
    padding: 7px 21px;
    font-size: 15px;
  }
  .car-service-container {
    margin-bottom: 15px;
  }
  .car-service-container {
    padding: 25px;
  }
  .car-service-container h4 {
    font-size: 17px;
    padding: 15px 0px;
  }
  .car-location {
    display: none;
  }
  .car-more-blog {
    margin-top: 30px;
  }
  .joinus-dealer-content h3 {
    font-size: 30px;
  }
  .join-as-dealer-btn {
    justify-content: center;
  }
  .joinus-dealer-content {
    text-align: center;
    margin-bottom: 20px;
  }
  .footer-six-right {
    margin-top: 20px;
  }
  .footer-six-center {
    gap: 19px;
  }
  .footer-six-center-list ul {
    gap: 10px;
  }
  .footer-six-center-list ul li a {
    font-size: 14px;
  }
  .footer-six-center-content a {
    font-size: 15px;
  }
  .custom-line {
    background-color: #374b5c;
    margin: 20px 0px;
  }
  .footer-send-mail {
    justify-content: space-between;
  }
  .banner-section.banner-six {
    height: 516px;
  }
  .preminum-float-bg,
  .property-counter-float-bg,
  .real-why-us-floating-bg,
  .testimonial-float-bg{
    display: none;
  }

}

@media (max-width: 767.98px) {

  .heading-eight h2 {
    font-size: 22px;
  }
  .banner-section h1{
      font-size: 26px;
  }
  .banner-main-bg{
      display: none;
  }
  .cat-bg-one {
      top: 108px;
      width: 62%;
      left: 20px;
      z-index: -1;
  }
  .job-img{
      display: none;
  }
  .cta-content.job-content {
      margin-left: 0px;
  }
  .job-role.job-one {
      position: relative;
      left: 0;
      top: 0px;
      margin-bottom: 10px;
  }
  .job-role.job-two {
      position:relative;
      left: 0;
      top: 0px;
      margin-bottom: 10px;
  }
  .job-role.job-three {
      position: relative;
      left: 0;
      top: 0px;
      margin-bottom: 10px;
  }
  .job-role.job-four {
      position: relative;
      left: 0;
      top: 0px;
      margin-bottom: 10px;
  }
  .job-role {
      justify-content: start;
  }
  .adv-box {
      display: block;
      align-items: center;
      padding: 0px 20px 20px;
      margin-bottom: 20px;
  }
  .adv-content {
      margin-left: 0px;
  }
  .price-card.price-content {
      padding-left: 15px;
  }
  .mynav4{
      display: none;
  }
  .blog-category-eight a {
      margin: 0 10px 10px 0;
  }
  .right-ads h1 {
      font-size: 22px;
  }
  .popular-footer {
      display: block;
  }
  .popular-footer .popular-btn{
      margin-top: 10px;
  }
  .vacancies-box .vaca-footer{
      display: block;
  }
  .vacancies-box .vaca-footer .footer-btn{
      margin-top: 10px;
  }
  .popular-inner {
      display: block;
  }
  .pricing-pg-one {
      top: 122px;
      left: 0px;
  }
  .advertisement-section {
    padding-bottom: 20px;
  }
  .vaca-details {
      padding-bottom: 10px;
      padding-top: 15px;
  }
  .vacancies-inner {
      display: block;
  }
  .popular-footer p {
      margin-top: 10px;
  }
  .price-card.price-content .price-level{
      display: none;
  }
  .price-card.price-content .price-head h4 {
      line-height: 1.2;
  }
  .ads-img {
      margin-bottom: 20px;
  }
  .banner-seven .search-box .select2-container--default .select2-selection--single,
  .filter-content .select2-container--default .select2-selection--single {
      min-width: 100%;
  }
  .banner-seven {
      background-size: 100% 100%;
  }
  .banner-seven .section-search {
      padding: 50px 0 10px;
  }
  .banner-seven .home-banner {
      padding-bottom: 50px;
  }
  .banner-section.banner-seven h1 {
      font-size: 26px;
  }
  .banner-seven .search-box form {
      display: block !important;
  }
  .banner-seven .search-box .search-input {
      margin: 0 0 15px 0!important;
  }
  .banner-seven .search-btn .btn-primary {
      width: 100%;
  }
  .banner-seven {
      padding: 70px 0 0;
  }
  .price-seven .price-body h4 {
      font-size: 22px;
  }
  .price-seven .price-level h6 {
      font-size: 22px;
  }
  .cities-box {
      margin-bottom: 20px;
  }
  .dishes-img img {
      width: 100%;
  }
  .restaurateurs-content {
      padding: 50px 50px 50px;
      max-width: 100%;
  }
  .restaurateurs-img {
      max-width: 100%;
  }
  .loca-height img {
      height: 300px;
      max-height: 300px;
  }
  body {
    font-size: 14px;
  }
  h2 {
    font-size: 24px;
  }
  h5 {
    font-size: 16px;
  }
  .header-navbar-rht li > a.header-login.add-listing {
    display: none;
  }
  .arrow-img {
    display: none;
  }
  .mynav2,
  .mynav1 {
    margin-bottom: 20px;
  }
  .mynav1 {
    margin-bottom: 0;
  }
  .policy-menu {
    margin: 10px 0 0 0;
    text-align: left;
  }
  .cta-section .col-lg-7,
  .cta-section .col-lg-5 {
    width: auto;
  }
  .testimonial-heading img {
    display: none;
  }
  .testimonials-section .testimonial-heading {
    padding: 0px 0 20px 20px;
  }
  .popular-locations .location-content a {
    font-size: 16px;
  }
  .popular-locations .location-content a.view-detailsbtn {
    font-size: 14px;
    padding: 10px 20px;
  }
  .latestad-section .btn-view {
    margin-top: 20px;
  }
  .lateestads-content .service-widget {
    margin-bottom: 25px;
  }
  .testimonial-heading img {
    display: none;
  }
  .category-section .btn-view {
    margin: 20px 0 0;
    font-size: 14px;
  }
  .section-blk .service-info-card .img-blk {
    padding: 0;
  }
  .cta-img {
    display: none;
  }
  .viewdetails-btn {
    width: 100%;
    margin: 0 auto;
    font-size: 14px;
    padding: 8px 15px;
  }
  .about-content {
    padding: 40px 0;
  }
  .aboutbanner {
    padding: 120px 0 60px;
  }
  .contactbanner {
    padding: 140px 0 60px;
  }
  .breadcrumb-bar {
    padding: 120px 0 60px;
  }
  .about-info h4 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  .banner-section p {
    margin-bottom: 20px;
  }
  .about-info p {
    margin-bottom: 20px;
  }
  .howitworks h3 {
    margin-bottom: 30px;
    font-size: 28px;
  }
  .howitwork-info {
    padding: 15px;
    margin: 20px 0 0;
  }
  .aboutpopular-locations {
    padding: 40px 0 20px;
  }
  .aboutlocation-details {
    margin-bottom: 20px;
  }
  .bloglistleft-widget .bloglist-content,
  .bloglistleft-widget.blog-listview .blog-img {
    width: auto;
  }
  .bloglistleft-widget.blog-listview h3 {
    font-size: 18px;
  }
  .bloglistleft-widget.blog-listview .blog-category a {
    font-size: 12px;
    padding: 4px 12px;
  }
  .stay-tuned form input {
    width: 100%;
  }
  .stay-tuned p {
    max-width: 550px;
  }
  .aboutlocation-details .aboutloc-img img {
    width: 100%;
  }
  .aboutlocation-details {
    margin: 0 0 20px;
  }
  .gallerypage-info .gallery-widget {
    text-align: center;
  }
  .cta-section {
    background: #f3f3f3;
  }
  .blog-listview .blog-img img,
  .grid-view .blog-img img {
    width: 100%;
  }
  .access-feature.featureslist {
    margin: 0 0px 15px 0;
  }
  .blog-listview .bloglist-content,
  .list-content .blog-listview .blog-img {
    width: auto;
  }
  .contactform-img {
    text-align: center;
  }
  .blog-listview .card .blog-widget {
    display: block;
  }
  .dashboard-content ul.dashborad-menus {
    padding: 25px 15px;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .dashboard-content ul.dashborad-menus {
    align-items: normal;
  }
  .dashboard-content ul.dashborad-menus li,
  .dashboard-content ul.dashborad-menus li a {
    display: block;
  }
  .dashboard-content ul.dashborad-menus li {
    margin-bottom: 10px;
  }
  .descriptionlinks ul li a {
    font-size: 12px;
  }
  .partners-section .partners-heading {
    font-size: 24px;
    margin: 0 0 30px;
  }
  .profile-sidebar .card-body .group-img,
  .profile-sidebar .card-body .btn-primary {
    width: 100%;
  }
  .profile-form .form-group.formlast-input {
    margin-bottom: 25px;
  }
  .messages-form .form-group.formlast-input {
    margin: 0 0 20px;
  }
  .settings-upload-btn .file-upload {
    padding: 12px 15px;
    font-size: 14px;
  }
  .profile-photo span {
    font-size: 14px;
  }
  .gallerypage-info .gallery-widget a {
    display: block;
  }
  .section-search {
    padding: 30px 0 40px;
  }
  .search-box form {
    flex-direction: column;
  }
  .search-btn .btn-primary {
    padding: 8px 15px;
    min-width: 130px;
  }
  .cta-content .postad-btn,
  .cta-content a.browse-btn {
    padding: 8px 15px;
    font-size: 14px;
  }
  .cta-content a.browse-btn {
    padding: 7px 15px;
  }
  .blog-section .btn-view {
    margin-top: 20px;
  }
  .blog-content {
    padding: 15px;
  }
  .testimonial-info {
    padding: 15px;
  }
  .price-card {
    padding: 15px;
  }
  .blog-section {
    padding: 40px 0 20px;
  }
  .stay-tuned form .btn-primary {
    font-size: 14px;
    padding: 8px 40px;
  }
  .stay-tuned {
    padding: 30px 15px;
  }
  .footer .footer-top {
    padding: 40px 0 0;
  }
  .footer-widget {
    margin-bottom: 20px;
  }
  .footer-title {
    margin-bottom: 20px;
  }
  .dashboard-content {
    padding: 40px 0;
  }
  .messages-form .card {
    padding: 15px;
  }
  .dashboard-content ul.dashborad-menus li a {
    font-size: 15px;
    padding: 0;
  }
  .messages-form .form-group.formlast-input {
    margin-bottom: 15px;
  }
  .messages-form .form-group.formlast-input-inner {
    margin: 0 0 0;
  }
  .messages-form .col-form-label {
    margin: 0 0 10px;
  }
  .home-banner-two .banner-content {
    padding-top: 95px;
  }
  .home-banner-two h1 {
    font-size: 26px;
  }
  .banner-section p {
    font-size: 14px;
  }
  .section-heading-two h2 {
    font-size: 24px;
  }
  .celebrate-img .play-icon {
    width: 30px;
    height: 30px;
    font-size: 10px;
  }
  .animate-circle {
    left: -25px;
    top: -25px;
    width: 50px;
    height: 50px;
  }
  .wedding-form {
    background-size: 500px;
    padding: 110px 80px 36px;
    max-width: 500px;
  }
  .timeline-wrap {
    text-align: center;
  }
  .time-box {
    width: 120px;
    display: inline-block;
    float: unset;
  }
  .time-box:last-child {
    width: 120px;
  }
  .footer-two .selection-list {
    margin-bottom: 15px;
  }
  .selection-list {
    justify-content: start;
  }
  .footer-social h6,
  .header-top .selection-list {
    margin-bottom: 15px;
  }
  .footer-social .social-icon {
    text-align: left;
  }
  .offer-img {
    display: none;
  }
  .header-top ul {
    text-align: left;
  }
  .home-banner-two .banner-content {
    padding-top: 35px;
  }
  .success-img,
  .celebrate-wrap:before {
    display: none;
  }
  .vendor-info h3 {
    font-size: 20px;
  }
  .blog-two .entry-meta li.date-icon {
    font-size: 14px;
  }
  .price-review h6 span {
    padding: 7px 8px;
  }
  .price-review p {
    font-size: 15px;
  }
  .timeline-wrap li:before {
    content: none;
  }
  .package-wrap {
    padding: 67px 116px;
  }
  .package-wrap .section-heading-two h2 {
    font-size: 20px;
  }
  .wedding-section {
    background-size: 200px;
  }
  .offer-sec {
    background-position: center;
  }
  .popular-section,
  .success-section {
    background-size: 120px;
  }
  .wedding-form .submit-section {
    margin-top: 0px;
  }
  .wedding-form .btn {
    padding: 9px 34px;
  }
  .vistors-details ul {
    justify-content: start;
    -webkit-justify-content: start;
  }
  .banner-btm .search-group ul li {
    width: 100%;
  }
  .banner-section.banner-three {
    padding-top: 23px;
  }
  .section-blk .title-blk {
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }
  .section-blk .title-blk .title-left {
    margin-bottom: 25px;
  }
  .section-blk .service-info-card {
    padding: 23px;
    justify-content: center;
    flex-wrap: wrap;
  }
  .section-blk .service-info-card .img-blk {
    width: 203px;
  }
  .section-blk .service-info-card .img-blk img {
    position: relative;
  }
  .section-blk .service-content {
    padding: 14px;
    width: 100%;
    text-align: center;
  }
  .service-list {
    flex-wrap: wrap;
    text-align: left;
  }
  .sm-mb-23 {
    margin-bottom: 23px;
  }
  .box-style .btm-box-style {
    flex-wrap: wrap;
    justify-content: center;
    padding: 23px 0px;
  }
  .box-style .btm-box-style:before {
    left: 0px;
    margin-top: -21px;
  }
  .box-style .btm-box-style > div {
    padding: 5px;
  }
  .promo-style {
    padding: 32px 14px;
  }
  .testi-widget .testi-btm-blk .circle-grp img {
    width: 50px;
    height: 50px;
  }
  .subscribe-style {
    border-radius: 14px;
    padding: 15px;
  }
  .top-foo-widget {
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 14px;
  }
  .sm-row-gap-23 {
    row-gap: 23px;
  }
  .gallery-blk li:not(:last-child) {
    margin: 0px;
  }
  .btm-foo-widget {
    flex-wrap: wrap;
    row-gap: 14px;
  }
  .promo-style .flex-style .list-style ul {
    row-gap: 23px;
  }
  .section-blk .service-info-card:before {
    right: -10px;
  }
  .banner-section h1 {
    font-size: 28px;
  }
  .banner-section.banner-three .banner-info-blk {
    padding-top: 75px;
  }
  .section-blk .title-blk {
    margin-bottom: 40px;
  }
  .theme-btn,
  .theme-btn a {
    font-size: 14px;
  }
  .theme-btn span {
    width: 35px;
    height: 35px;
  }
  .body-three .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 40px;
  }
  .recommended-slider.owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 30px;
  }
  .testim-slider.owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 20px;
  }
  .banner-section.banner-three h5 {
    font-size: 16px;
    margin-bottom: 0;
  }
  .section-blk .service-info-card .img-blk {
    background: transparent;
  }
  .promo-style h3 {
    font-size: 22px;
  }
  .faq-blk:before {
    content: none;
  }
  .price-three .price-body h4 {
    font-size: 22px;
  }
  .offer-blk {
    padding-top: 60px;
  }
  .price-three .price-level h6 {
    font-size: 22px;
  }
  .testi-widget h4 {
    font-size: 18px;
  }
  .wedding-form .form-control {
    min-height: 40px;
    font-size: 14px;
  }
  .wedding-form .select2-container .select2-selection--single {
    height: 40px;
  }
  .wedding-form
    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    line-height: 40px;
  }
  .wedding-form
    .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    height: 38px;
  }
  .wedding-wrap.wedding-align {
    flex-direction: row-reverse;
  }
  .wedding-img.align-right {
    margin-left: 0;
    margin-right: 24px;
  }
  .header-three .cta-btn {
    display: none;
  }
  .banner-section.banner-four h1 {
    font-size: 26px;
  }
  .banner-four .search-box form {
    display: block !important;
  }
  .banner-four .search-box .search-input {
    margin: 0 0 15px 0;
  }
  .banner-four .search-btn .btn-primary {
    width: 100%;
  }
  .banner-four {
    padding: 70px 0 0;
  }
  .interset-btn {
    justify-content: start;
    -webkit-justify-content: start;
    -ms-flex-pack: start;
  }
  .listee-content h3,
  .price-four .price-head h4 {
    font-size: 24px;
  }
  .listee-content {
    padding: 40px 0 30px;
  }
  .listee-content p {
    font-size: 16px;
  }
  .header .car-list-btn {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .banner-seven .search-box .search-input {
    margin: 0 10px 10px 0;
  }
  .section-heading-seven h1 {
      font-size: 26px !important;
  }
  .banner-seven .search-box .select2-container--default .select2-selection--single,
  .filter-content .select2-container--default .select2-selection--single {
      min-width: 100%;
  }
  .banner-seven {
      background-size: 100% 100%;
  }
  .banner-seven .section-search {
      padding: 50px 0 10px;
  }
  .banner-seven .home-banner {
      padding-bottom: 50px;
  }
  .restaurants-sec {
      padding: 40px 0;
  }
  .heading-img {
      padding-right: 10px;
  }
  .restaurant-section.restaurant-section-seven {
      padding: 40px 0px 15px;
  }
  .restaurateurs-content {
      padding: 50px 50px 50px;
      max-width: 100%;
  }
  .restaurateurs-img {
      max-width: 100%;
  }

  .loca-height img {
      height: 293px;
      max-height: 293px;
  }
  .footer-right-part {
      margin-top: 20px;
  }
  .section-heading-nine.section-heading .btn-view {
    font-size: 14px;
    display: block;
    margin-top: 15px;
  }
  .banner-section.banner-nine h1 {
    font-size: 34px;
  }
  .banner-section.banner-nine h6 {
    font-size: 16px;
    padding: 15px 0;
  }
  .banner-section.banner-nine {
    height: 522px;
    margin-top: 70px;
    padding: 50px 0 0;
  }
  .car-filter-section .nav-pills .nav-link {
    font-size: 14px;
  }
  .header-navbar-rht {
    display: none;
  }
  .main-nav li.login-link {
    display: block;
  }
  .about-info {
    padding: 15px;
  }
  .testimonials-section .testimonial-heading {
    padding: 0px 0 20px 10px;
  }
  .testimonial-heading img {
    display: none;
  }
  .testimonials-slidersection {
    margin-top: -484px;
  }
  .testimonial-heading h4 {
    font-size: 28px;
  }
  .search-box .search-input {
    margin: 0 0px 15px 0;
  }
  .partnerslist.owl-carousel .owl-item img {
    width: auto;
    margin: 0 auto;
    height: auto;
  }
  .footer {
    padding: 0;
  }
  .policy-menu li a,
  .footer .footer-bottom .copyright-text p {
    font-size: 12px;
  }
  .search-box .select2-container {
    width: 100%;
  }
  .search-box form {
    flex-direction: column;
  }
  .search-btn {
    margin: 0;
  }
  .popular-locations .location-info {
    width: 100%;
  }
  .cta-content p {
    margin-bottom: 20px;
  }
  .testi-author {
    margin: 0px 0 0 20px;
  }
  .testi-author h6 {
    font-size: 16px;
  }
  .testimonials-slidersection {
    margin-top: 0;
  }
  .blog-section .btn-view {
    margin: 20px 0 0;
  }
  .grid-blog {
    margin: 20px 0;
  }
  .vistors-details {
    margin: 20px 0 0;
  }
  .footer .subscribe-form {
    margin: 20px 0 0;
  }
  .banner-section h1 {
    font-size: 32px;
    margin-bottom: 15px;
  }
  .header-nav {
    height: 70px;
  }
  .section-heading h2 {
    font-size: 28px;
  }
  .featured-section,
  .cta-section,
  .popular-locations {
    padding: 40px 0;
  }
  .subscribe-form .btn-secondary,
  .subscribe-form .form-control {
    width: 100%;
  }
  .navbar-brand.logo {
    width: 100%;
    text-align: center;
    display: block;
    margin: 0;
  }
  .banner-section p.explore-text span {
    padding: 6px 20px;
  }
  .popular-locations .location-content a {
    font-size: 14px;
  }
  .popular-locations .location-content a.view-detailsbtn {
    font-size: 14px;
    padding: 6px 15px;
  }
  .featured-info a,
  .subscribe-form .btn-secondary {
    font-size: 14px;
  }
  .featured-section .service-content h3 {
    max-width: none;
  }
  .testimonials-slidersection {
    padding-bottom: 10px;
  }
  .cta-section h3 {
    font-size: 24px;
  }
  .about-details {
    display: block;
    text-align: center;
  }
  .about-headings {
    width: 100%;
    display: block;
  }
  .authordetails .rating {
    justify-content: center;
  }
  .author-img {
    margin: 0 0px 20px 0;
  }
  .contactbusinessform .submit-section .btn-primary {
    width: 100%;
  }
  .authordetails h5 {
    font-size: 22px;
  }
  .rate-details {
    width: 100%;
    text-align: center;
    margin: 20px 0 0;
  }
  .rate-details h2 {
    font-size: 32px;
  }
  .descriptionlinks ul li {
    margin: 0 0 15px;
  }
  .descriptionlinks ul {
    display: block;
  }
  .callnow {
    margin-top: 20px;
  }
  .details-main-wrapper {
    padding: 40px 0;
  }
  .card {
    padding: 30px 20px;
  }
  .featureslist {
    width: 100%;
  }
  .review-box {
    display: block;
  }
  .review-img {
    margin-bottom: 20px;
  }
  .review-details {
    margin-left: 0;
    margin-top: 20px;
  }
  .review-details .rating {
    display: block;
  }
  .gallery-content {
    display: block;
  }
  .details-main-wrapper .gallery-content .gallery-widget img {
    width: 100%;
  }
  .gallery-section .gallery-widget {
    margin: 0 0px 20px 0;
    width: auto;
    height: auto;
  }
  .details-main-wrapper .card-header {
    display: inline-flex;
  }
  .review-gallery {
    display: block;
  }
  .review-details .reply-box p {
    margin: 0 0 10px;
  }
  .review-details .reply-box p a {
    display: block;
    margin: 10px 0;
  }
  .review-list li:nth-child(2) {
    margin-left: 0px;
  }
  .sideauthor-img {
    margin: 0 0 10px;
  }
  .sidebarauthor-details {
    display: block;
    text-align: center;
  }
  .feedbackbox .namefield {
    display: block;
  }
  .namefield .form-group {
    width: 100%;
    margin-right: 0;
  }
  .review-list .review-gallery li {
    width: auto;
    height: auto;
    margin: 0 0px 20px 0;
  }
  .rightsidebar .card .info-list {
    margin: 15px 0 0 0px;
  }
  .map-details {
    display: block;
  }
  .ratings-table {
    width: 100%;
    overflow: auto;
  }
  .feedbackbox .review-details h6 {
    font-size: 18px;
  }
  .amenities .search-btn .btn {
    width: 100%;
  }
  .breadcrumb-title,
  .innerbanner .breadcrumb-title {
    font-size: 28px;
  }
  .sorting-div .col-lg-8 {
    display: block;
    padding: 0;
  }
  .sorting-div .col-lg-4 {
    padding: 0;
  }
  .grid-listview {
    margin: 10px 0 0 0;
  }
  .sorting-div .count-search {
    margin: 0 0 10px;
  }
  .blog-listview .card .blog-widget {
    display: block;
  }
  .blog-listview h6 {
    width: auto;
  }
  .previtem a,
  .nextlink a {
    padding: 5px 8px;
    min-width: auto;
  }
  .blog-pagination {
    margin: 0 0 0px;
  }
  .pagelink ul li a {
    padding: 5px 8px;
    margin: 0 8px 0 0;
  }
  .previtem a i,
  .nextlink a i {
    display: none;
  }
  .showphotos {
    bottom: 26px;
    font-size: 12px;
    border-radius: 5px;
    right: 15px;
    padding: 4px 12px;
  }
  .showphotos a {
    padding: 10px 20px;
  }
  .listmaplist-content .bloglist-content,
  .listmaplist-content .blog-img {
    width: 100%;
  }
  .listmaplist-content .blog-img img {
    height: auto;
    width: 100%;
  }
  .categorieslastsec.categories-content {
    margin-bottom: 23px;
  }
  .showfilter {
    display: none;
  }
  .sorting-div {
    margin: 25px 0 25px;
  }
  .rightsidebar .card .article ul h6 {
    font-size: 16px;
  }
  .bloglisting .blog-pagination {
    margin: 0 0px 30px;
  }
  .blogbanner-content {
    padding: 15px;
  }
  .blogbanner-content span.blog-hint {
    display: none;
  }
  .blogbanner-content h1 {
    font-size: 20px;
  }
  .blogbanner-content .entry-meta li:last-child {
    margin-right: 0;
  }
  .blogdetail-content {
    padding: 60px 0;
  }
  .sharelink {
    margin: 0 0 25px;
  }
  .tag-list .tags li {
    margin-bottom: 15px;
  }
  .tag-list .tags {
    flex-wrap: wrap;
  }
  .tag-list {
    justify-content: flex-start;
  }
  .blogdetails-pagination ul li h3 {
    display: none;
  }
  .howitwork-info h5:after {
    top: 30px;
  }
  .bloginner-img {
    margin: 15px 0;
  }
  .innerbanner .blogbanner-img img {
    min-height: 150px;
  }
  .innerbanner .inner-breadcrumb {
    top: 40px;
  }
  .blogbanner {
    min-height: 300px;
  }
  .requiremnts-info {
    margin: 15px 0 0;
  }
  .contactinfo-content {
    padding: 20px;
  }
  .contact-hours li {
    font-size: 14px;
  }
  .contactinfo-content h6 {
    font-size: 20px;
  }
  .contact-info h1 {
    font-size: 32px;
  }
  .contact-info p {
    font-size: 14px;
  }
  .contactform-img {
    margin: 0 0 25px;
  }
  .work-section h4 {
    font-size: 32px;
  }
  .work-section .work-info h1:after {
    width: 64%;
  }
  .work-section .work-info {
    margin: 0 0 20px;
  }
  .work-section {
    padding: 80px 0 0px;
  }
  .faq-section h4 {
    font-size: 16px;
  }
  .faq-section h1 {
    font-size: 28px;
  }
  .faq-section {
    padding: 60px 0;
  }
  .login-header h3 {
    font-size: 28px;
  }
  .login-content {
    padding: 60px 0;
  }
  .terms-content {
    padding: 60px 0;
  }
  .dashboard-content ul.dashborad-menus {
    padding: 15px;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .dashboard-content ul.dashborad-menus li a {
    display: block;
  }
  .dashboard-content ul.dashborad-menus li {
    margin-bottom: 17px;
    margin-right: 0;
  }
  .dashboard-content ul.dashborad-menus li:last-child {
    margin-bottom: 0;
  }
  .dashboard-content {
    background: #f9fafc;
  }
  .listing-search {
    flex-direction: column;
  }
  .dash-listingcontent .group-img input {
    width: 100%;
  }
  .dash-listingcontent .header-login.add-listing {
    display: none;
  }
  .sorting-div .sortbyset {
    margin: 0;
  }
  .listing-search .sortbyset .sorting-select {
    width: 76%;
  }
  .settings-upload-btn .file-upload {
    padding: 12px 30px;
    margin: 15px 20px 15px 0;
  }
  .profile-photo {
    display: block;
  }
  .settings-upload-btn {
    margin: 0;
  }
  .profile-sidebar .card-body .group-img {
    width: auto;
  }
  .google-maps {
    margin: 0;
  }
  .gallery-widget:nth-child(3),
  .gallery-widget:nth-child(4) {
    display: none;
  }
  .rightsidebar {
    margin: 20px 0 0;
  }
  .chat-cont-right {
    margin-top: 20px;
  }
  .chat-footer .attach-btn {
    width: 75%;
  }
  .chat-cont-right {
    margin: 20px 0 0;
  }
  .lat-input {
    margin-bottom: 1rem;
  }
  .media-section .settings-upload-btn {
    margin: 0 0 20px;
  }
  .gallery-upload {
    margin: 0 0px 20px 0;
  }
  .galleryimg-upload {
    display: block;
  }
  .media-section .gallery-media .settings-upload-btn {
    margin: 0 0 0px;
  }
  .stay-tuned h3 {
    font-size: 22px;
  }
  .stay-tuned form {
    display: block;
  }
  .stay-tuned form .btn-primary {
    margin: 20px 0 0 0px;
  }
  .breadcrumb-bar:after,
  .breadcrumb-bar:before,
  .cta-section:before {
    display: none;
  }
  .access-feature {
    margin-bottom: 20px;
  }
  .error-wrap h2 {
    font-size: 28px;
  }
  .gallery-section .gallery-widget a:hover::before,
  .gallery-section .gallery-widget a:hover::before {
    display: none;
  }
  .faq-section h2 {
    font-size: 28px;
  }
  .list-content .blog-listview .blog-img {
    border-radius: 0px 10px 10px 0;
  }
  .blog-listview .blog-img img,
  .grid-view .blog-img img {
    width: 100%;
  }
  .map-listing {
    margin: 0 0 25px;
  }
  .pagelink ul li a {
    width: 35px;
    height: 35px;
  }
  .rightsidebar .card .article {
    width: 100%;
  }
  .bloglistleft-widget.blog-listview .blog-img {
    border-radius: 10px;
  }
  .work-section .work-info h5:after {
    width: calc(100% - 98px);
  }
  .profile-img {
    display: block;
    align-items: center;
  }
  .navbar-brand.logo img {
    width: auto;
  }
  #mobile_btn {
    margin-right: 0px;
    position: absolute;
    top: 20px;
    left: 0;
  }
  .australi-loc img {
    min-height: 240px;
  }
  .aboutlocation-details a {
    left: 15px;
    bottom: 15px;
  }
  .navbar-header {
    width: 100%;
    justify-content: center;
  }
  .blog-pagination .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .previtem a,
  .nextlink a {
    display: none;
  }
  .featuresform-list ul li {
    width: 100%;
    float: none;
  }
  .gallery-upload img {
    width: 100%;
  }
  .feedbackbox .review-details {
    margin-left: 0;
    margin-top: 0;
  }
  .dashboard-content ul.dashborad-menus {
    align-items: initial;
  }
  .category-listing .col-lg-4 {
    width: 100%;
  }
  .section-heading,
  .section-heading-two,
  .interset-btn {
    margin: 0 0 30px;
    text-align: center;
  }
  .access-feature.featureslist {
    margin: 0 0px 15px 0;
  }
  .chat-footer .attach-btn {
    margin: 0 10px 0 0;
  }
  .listing-table .avatar-img {
    min-width: 120px;
  }
  .details-main-wrapper .review-gallery img {
    width: 100%;
  }
  .search-box form {
    flex-direction: column;
  }
  .gallerypage-info .gallery-widget img {
    width: 100%;
  }
  .dashboard-info .review-list .review-box {
    display: block;
  }
  .card-dropdown li.dropdown.has-arrow a.pageviews-link {
    min-width: 150px;
  }
  .messages-form .custom_check {
    margin: 0 0 15px;
  }
  .settings-upload-btn .file-upload {
    padding: 10px 15px;
    font-size: 14px;
  }
  .profile-photo span {
    font-size: 14px;
    display: block;
    margin: 0 0 15px;
  }
  .chat-window.chat-slide .chat-cont-right {
    width: 94%;
  }
  .footercount {
    padding: 0 0 30px;
  }
  .banner-section-two img {
    height: 260px;
    width: 100%;
    object-fit: cover;
  }
  .home-banner-two h1 {
    font-size: 20px;
  }
  .wedding-form {
    background-size: 300px;
    padding: 68px 35px 36px;
  }
  .package-wrap {
    padding: 67px 60px;
  }
  .wedding-wrap.wedding-align {
    flex-direction: column-reverse;
  }
  .wedding-wrap {
    flex-direction: column;
  }
  .wedding-img {
    margin: 0 0 15px;
    text-align: center;
  }
  .wedding-content {
    text-align: center;
  }
  .footer-two .selection-list {
    margin: 0;
  }
  .wedding-img.align-right {
    margin: 15px 0 15px;
  }
  .block-e .cta-btn a {
    display: none;
  }
  .header-three .header-nav {
    height: 50px;
  }
  .header-three #mobile_btn {
    top: 11px;
  }
  .section-blk .title-blk h2:before {
    right: 13px;
  }
  .subscribe-blk .input-group {
    padding: 6px;
  }
  .subscribe-btn-grp .btn {
    padding: 0;
  }
  .subscribe-btn-grp {
    padding: 8px 10px;
  }
  .subscribe-blk .banner-blk {
    display: none;
  }
  .banner-section.banner-three h1 {
    font-size: 22px;
  }
  .shadow-box h4 {
    font-size: 16px;
  }
  .top-foo-widget .help-info {
    display: block;
  }
  .top-foo-widget .help-info p:not(:last-child) {
    margin: 0 0 10px;
  }
  .middle-foo-widget .drop-blk .navbar-nav li a.lang-link {
    min-width: 130px;
  }
  .faq-shadow-blk {
    margin-bottom: 15px;
  }
  .banner-images {
    display: none;
  }
  .section-heading.heading-four h2 {
    font-size: 22px;
  }
  .banner-five .search-box {
    border-radius: 10px;
  }
  .banner-five
    .search-box
    .select2-container--default
    .select2-selection--single {
    min-width: 268px;
  }
  .banner-five .search-box {
    border-radius: 10px;
  }
  .banner-five .section-search {
    padding: 50px 0;
  }
  .category-items li {
    width: 49%;
  }
  .section-heading.heading-five h2 {
    font-size: 20px;
  }
  .adventure-section {
    padding: 40px 0;
  }
  .latest-blog-five .articles-right {
    text-align: center;
  }
  .latest-blog-five .articles-content .articles-list {
    justify-content: center;
  }
  .latest-blog-five .articles-content h4 {
    font-size: 16px;
  }
  .latest-blog-five .articles-content p {
    font-size: 14px;
  }
  .banner-section.banner-six h1 {
    font-size: 28px;
  }
  .banner-section.banner-six p {
    font-size: 18px;
  }
  .banner-section.banner-six {
    height: 394px;
  }
  .car-search-filter {
    margin-top: 20px;
  }
  .banner-contents a {
    padding: 10px;
  }
  .car-filter-section .car-search-btn .car-search-icon {
    width: 100%;
    height: 50px;
  }
  .home-six-heading-section p {
    margin: 5px auto 22px auto;
    font-size: 14px;
  }
  .home-six-heading-section h2 {
    font-size: 20px;
  }
  .trending-slider-item span {
    font-size: 15px;
  }
  .car-class-item-block img {
    width: 70% !important;
  }
  .common-padding {
    padding: 30px 0px;
  }
  .browse-by-brand-item {
    padding: 15px;
  }
  .browse-by-brand-item p {
    font-size: 15px;
  }
  .car-rental-slider-section {
    display: none;
  }
  .footer-send-mail {
    flex-direction: column;
  }
  .footer-send-mail {
    flex-direction: column;
  }
  .footer-send-mail a {
    text-align: center;
  }
  .footer-six-center {
    gap: 14px;
    flex-direction: column;
    justify-content: center;
  }
  .joinus-dealer-content h3 {
    font-size: 22px;
  }
  .footer-six.common-padding {
    padding: 40px 0px 0px 0px;
  }
  .our-team-bottom {
    padding: 120px 152px 20px 20px;
    background: #374b5c;
    margin-left: 0px;
    min-width: unset;
  }
  .section-heading h2 {
    font-size: 25px;
    justify-content: center;
  }
  .interset-btn {
    justify-content: center;
  }
  .perfect-holiday-cabin-section {
    display: none;
  }
  .property-counter-list.text-center {
    margin-bottom: 15px;
  }
  .top-rent-room-info .top-rent-room-location {
    flex-direction: column;
    justify-content: center;
    gap: 12px;
  }
  .room-address {
    text-align: center;
  }
  .top-rent-room-info .top-room-details {
    gap: 10px;
  }
  .listee-premium.bottom-list-premium ,
  .listee-premium {
    margin-bottom: 25px;
  }
  .make-it-easy h2 {
    font-size: 20px;
  }
  .make-it-easy p {
    font-size: 16px;
  }
  .make-it-easy a {
    padding: 11px 10px;
  }
  .buy-property-content {
    padding: 10px;
  }
  .buy-property-content h2 {
    font-size: 22px;
  }
  .buy-property-content p {
    font-size: 16px;
    padding: 15px 0;
  }
  .footer-six-bg.footer-nine-bg img {
    z-index: -1;
  }
  .footer-nine .footer-six-bottom {
    flex-direction: column;
    gap: 20px;
  }
  .footer-six.footer-nine.common-padding {
    padding: 40px 0;
  }
  .banner-five .search-btn {
    width: 100%;
  }
  .banner-five .search-btn .btn-primary {
    min-width: auto;
    border-radius: 50px;
    width: 100%;
  }
  .category-five-section {
    background: none;
  }
  .banner-seven .select-location i {
    top: 21px;
  }
  .popular-img {
    padding: 10px;
    text-align: center;
  }
  .popular-content h4 {
    font-size: 16px;
    margin-top: 10px;
  }
  .car-search-icon {
    width: 100%;
  }
  .search-group > ul >li {
    width: 100%;
  }
  .featured-car-center {
    flex-direction: column;
  }
  .cta-content.job-content {
    margin-top: 0px;
  }
  .owner-card .owner-card-img img {
    width: 100%;
  }
  .feature-rent .house-location span {
    top: 212px;
  }
  .feature-rent .house-location p {
    top: 217px;
  }
  .cat-bg-two {
    display: none;
  }
  .section-blk .service-content .service-list li:not(:last-child) {
    margin-right: 0px;
  }
  .section-blk .service-content .service-list {
    gap: 10px;
  }
  .car-filter-section .search-tab-col ul li .car-search-grid {
    min-width: auto;
  }
}

.shape-1 {
  animation: hero-thumb-animation 2.5s linear infinite alternate;
  -webkit-animation: hero-thumb-animation 2.5s linear infinite alternate;
  -moz-animation: hero-thumb-animation 2.5s linear infinite alternate;
  -o-animation:  hero-thumb-animation 2.5s linear infinite alternate;
}
.shape-2 {
  animation: hero-thumb1-animation 2.5s linear infinite alternate;
  -webkit-animation: hero-thumb1-animation 2.5s linear infinite alternate;
  -moz-animation: hero-thumb1-animation 2.5s linear infinite alternate;
  -o-animation:  hero-thumb1-animation 2.5s linear infinite alternate;
}
.shape-3 {
  animation: hero-thumb2-animation 2.5s linear infinite alternate;
  -webkit-animation: hero-thumb2-animation 2.5s linear infinite alternate;
  -moz-animation: hero-thumb2-animation 2.5s linear infinite alternate;
  -o-animation:  hero-thumb2-animation 2.5s linear infinite alternate;
}
@keyframes hero-thumb-animation {
  0%{transform:translateY(-20px)}to{transform:translateY(0)}
}
@keyframes hero-thumb1-animation {
  0%{transform:translateY(-20px) translateX(50px)}to{transform:translateY(-20px) translateX(0)}
}
@keyframes hero-thumb2-animation {
  0%{transform:translateY(-50px)}to{transform:translateY(0)}
}

/* Added Stles */
.slick-slide > div {
  margin: 0 24px 0 0;
}
.slick-list {
  margin: 0 -24px 0 0;
}
.magentaCircle{
  background: #c10037 !important;
}
.whiteCircle{
  background: white !important;
}
.l70{
  left: 70px;
}
.prl0{
padding: 6px 0;}
.selectHeight{
  border: none;
  padding: 0;
  min-height: 25px;
}
.h50{
  height: 50px;
}
.carrental .owl-nav{
  position: absolute;
    right: 100px;
    bottom: 176px;
}
.carrental .owl-nav button{
  border-radius: 55%;
  background: white !important;
}
.car-class-carousel .owl-dots .owl-dot span {
  width: 65px !important;
  height: 4px!important;
  background: #d9d9d9 !important;
}
.car-class-carousel .owl-dots .owl-dot.active span {
  background: #c10037 !important;
  border-radius: 10px;
  width: 65px !important;
  height: 4px !important;
}
.car-class-carousel .owl-dots {
  margin-top: 45px !important;
}
.footer-six-top-list ul li a{
  color: #fff !important;
}
.footer-six-center-content a {
  color: #fff !important;
}
.footer-six-center-list ul li a {
  color: #fff !important;
}
.trending-carousel-button .owl-dots .owl-dot span {
  width: 65px !important;
  height: 4px!important;
  background: #d9d9d9 !important;
}
.trending-carousel-button .owl-dots .owl-dot.active span {
  background: #c10037 !important;
  border-radius: 10px;
  width: 65px !important;
  height: 4px !important;
}
.trending-carousel-button .owl-dots {
  margin-top: 45px !important;
}
.job-slider .owl-nav .owl-prev{
  position: absolute;
  top: 45%;
  left: -66px;
  margin: 0 12px 0 0 !important;
  border: 1px solid #dee2e7 !important;
  border-radius: 10px !important;
  color: #7f7f7f !important;
  background: #fff;
  width: 44px !important;
  height: 44px !important;


}
.job-slider .owl-nav .owl-next{
  position: absolute;
top: 45%;
right: -66px;
margin: 0 12px 0 0 !important;
  border: 1px solid #dee2e7 !important;
  border-radius: 10px !important;
  color: #7f7f7f !important;
  background: #fff;
  width: 44px !important;
  height: 44px !important;

}
@media (max-width: 1499.98px){
.home-nine .header .car-list-btn.header-phone {
    display: none;
}



}
:where(.css-dev-only-do-not-override-1vtf12y).ant-table-wrapper .ant-table-pagination.ant-pagination{
  display: none;
}
.wedding .css-1dimb5e-singleValue{
  margin-left: 0px !important;
}
.countrys .css-1dimb5e-singleValue{
  margin-left: 26px !important;
}
.discover .css-1ejdc64-control{
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 24px;
  height: 52px;
  min-width: 285px;
}
.discover  .css-1dimb5e-singleValue {
  grid-area: 1/1/2/3;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: hsl(0, 0%, 20%);
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  /* line-height: 52px; */
  padding-left: 15px;
  padding-right: 25px;
  font-size: 16px;
  font-weight: normal;
  color: #999;
}
.silderBotton .slick-prev{
  left: -49px !important;
  width: 32px !important;
  height: 32px !important;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
  border-radius: 50% !important;
  margin: auto;
}
.silderBotton .slick-next{
  right: -50px !important;
  width: 32px !important;
  height: 32px !important;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
  border-radius: 50% !important;
  margin: auto;
}
.silderBotton .slick-prev:before{
  color: #7f7f7f !important;
}
.silderBotton .slick-next:before{
  color: #7f7f7f !important;
}
.banner-seven .search-box .form-control{
  padding: 0 !important;
}
.country .css-1dimb5e-singleValue{
  margin-left: 38px !important;
}
.input-range__label--max .input-range__label-container{
  display: none;
}
.input-range__label--min .input-range__label-container{
  display: none;
}
.input-range__label-container{
  top: -16px !important;
  color: white;
    font-size: 12px;
    line-height: 1.333;
    text-shadow: none;
    padding: 4px 10px;
    background-color: #c10037;
    border-radius: 2px;
    top: -10px;
}
.input-range__track--active {
  /* background: #3f51b5; */
  top: -3px !important;
  background-color: #c10037 !important;
}
.input-range__track--background {
  top: 7px !important;
  height: 13px !important;
    background-color: #e1e4e9 !important;
    border-radius: 4px !important;
}
.input-range__slider{
  background-color: #c10037 !important;
    border-radius: 50px !important;
}
.input-range__track--active {
  height: 16px !important;
}
.input-range__slider {
  margin-top: -1.4rem !important;
  width: 25px !important;
    height: 25px !important;
}
.countryoptions .css-1ejdc64-control {
  border-radius: 50px !important;
  padding-left: 42px !important;
  height: 40px !important;
} 
.countryoptions .css-9udaz7-control {

  border-radius: 50px !important;
  padding-left: 42px !important;
  height: 40px !important;
}
/* .css-wu0a4c-indicatorContainer{
  padding-left: 42px !important;
  border-radius: 16px !important;
}  */
.country .css-qbdosj-Input {
  padding-top: 17px;
}
.categoryselectbox .css-qbdosj-Input{
  padding-top: 17px;
}
.slick-dots li button:before{
  font-size: 12px !important;
  color: #c10037 !important;
}
.material-icons.md-dark{
  color: #c10037 !important;
}